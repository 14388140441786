import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Common/Loader";
import PageWrapper from "../../Common/PageWrapper";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import Header from "../../Components/Layout/Header/Header";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import { NO_CONTENT_MESSAGE } from "../../Constants";
import { ThemeState } from "../../Context";
import useDeviceDetect from "../../Hooks/useIsDesktop";
import {
  updateSport,
  updateTab,
  useSettings,
  useSport,
  useTab,
} from "../../Redux/Accessors/game.accessors";
import Games from "../../Services/Games";
import { updateSportTab } from "../../Utils/tabs";
import PremiumMatches from "./PremiumMatches";
import PremiumMatchesMobile from "./PremiumMatchesMobile";

const Premium = () => {
  // ... other states ...
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const sport = useSport() || defaultSport;
  const { mode } = ThemeState();
  const tab = useTab();

  const [page, setPage] = useState(1); // to keep track of loaded pages
  const [hasMore, setHasMore] = useState(true); // to know when to stop loading more
  const [isLoading, setIsLoading] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [scheduleGames, setScheduleGames] = useState([]);
  const { isMobile, isDesktop } = useDeviceDetect();
  const GAMES_PER_PAGE = 4;

  const fetchSchedulesGames = async () => {
    try {
      setScheduleGames([]);
      setAllGames([]);
      updateSport(sport);
      setIsLoading(true);
      const res = await Games.schedules(sport);
      if (res?.data?.data?.scheduledGames?.length) {
        setAllGames(res?.data?.data?.scheduledGames);

        // Setting the first batch of games into scheduleGames
        const initialGames = res?.data?.data?.scheduledGames.slice(
          0,
          GAMES_PER_PAGE
        );
        setScheduleGames(initialGames);

        updateSport(res?.data?.data?.scheduledGames[0]?.sport);
        updateSportTab(
          res?.data?.data?.scheduledGames[0]?.sport,
          updateTab,
          tab
        );
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNewGames = () => {
    if (scheduleGames?.length >= allGames?.length) {
      setHasMore(false);
      return;
    }
    setIsLoading(true);
    const start = page * GAMES_PER_PAGE;
    const end = start + GAMES_PER_PAGE;

    const newGames = allGames?.slice(start, end);

    if (newGames && newGames?.length) {
      setScheduleGames((prevGames) => [...prevGames, ...newGames]);
      setPage((prevPage) => prevPage + 1); // Increment the page
    } else {
      setHasMore(false);
    }

    setIsLoading(false);
  };

  // ... useEffect and other parts ...
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSchedulesGames();

    const intervalId = setInterval(fetchSchedulesGames, 10 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  return (
    <PageWrapper>
      {isDesktop ? (
        <div className="home_desktop">
          <Header premium />
          <div style={{ minHeight: "500px" }}>
            {isLoading ? (
              <Loader />
            ) : sport === "NHL" ? (
              <div
                className={`visit_later
              ${mode === "Light" ? "visit_later_light_theme" : ""}
            `}
              >
                <p className="text-center">Season not started, visit later</p>
              </div>
            ) : scheduleGames?.length ? (
              <InfiniteScroll
                dataLength={scheduleGames?.length}
                next={fetchNewGames}
                hasMore={hasMore}
                loader={<Loader />}
                endMessage={
                  <p
                    className="pb-4"
                    style={{ textAlign: "center", color: "#FEB919" }}
                  >
                    <b>No more data to show</b>
                  </p>
                }
              >
                {scheduleGames?.map((schedule, index) => (
                  <PremiumMatches
                    key={index}
                    slideIndex={index}
                    schedule={schedule}
                    isLoading={isLoading}
                    isPremiumPage={true}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <p className="text-center text-white">{NO_CONTENT_MESSAGE}</p>
            )}
          </div>

          <Footer />
        </div>
      ) : null}
      {isMobile ? (
        <div className="home_mobile">
          <MobileHeader premium />
          {isLoading ? (
            <Loader />
          ) : sport === "NHL" ? (
            <div
              className={`visit_later
              ${mode === "Light" ? "visit_later_light_theme" : ""}
            `}
            >
              <p className="text-center">Season not started, visit later</p>
            </div>
          ) : scheduleGames?.length ? (
            <InfiniteScroll
              dataLength={scheduleGames?.length}
              next={fetchNewGames}
              hasMore={hasMore}
              loader={<Loader />}
              endMessage={
                <p
                  className="pb-4"
                  style={{ textAlign: "center", color: "#FEB919" }}
                >
                  <b>No more data to show</b>
                </p>
              }
            >
              {/* <div style={{ overflow: "visible", height: "auto" }}> */}
              {scheduleGames?.map((schedule, index) => (
                <PremiumMatchesMobile
                  key={index}
                  slideIndex={index}
                  schedule={schedule}
                  isLoading={isLoading}
                  isPremiumPage={true}
                />
              ))}
              {/* </div> */}
            </InfiniteScroll>
          ) : (
            <p className="text-center text-white mt-3 mb-3">
              {NO_CONTENT_MESSAGE}
            </p>
          )}
          <MobileFooter />
        </div>
      ) : null}
    </PageWrapper>
  );
};

export default Premium;
