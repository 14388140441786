/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import {
  Accordion,
  Col,
  Container,
  Dropdown,
  Form,
  Nav,
  Row,
} from "react-bootstrap";
import { AiFillCaretDown } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import DarkLoader from "../../../Common/Loader/DarkLoader";
import { sports } from "../../../Constants";
import { ThemeState } from "../../../Context";
import {
  logoutAuth,
  updateNotifications,
  updateThemeMode,
  useUser,
} from "../../../Redux/Accessors/auth.accessors";
import {
  updateSport,
  useSettings,
  useSport,
} from "../../../Redux/Accessors/game.accessors";
import {
  updateHeaderTab,
  useHeaderTab,
} from "../../../Redux/Accessors/header.accessors";
import Games from "../../../Services/Games";
import Notification from "../../../Services/Notification";
import SearchSuggestion from "./SearchSuggestion";
import { googleLogout } from "@react-oauth/google";
import { requestMicrophoneAccess } from "../../../Utils/requestMicrophoneAccess";
import { teamNavigator } from "../../../Utils/navigator";

const MobileHeader = ({ premium, bgWhite, isComparePage = false }) => {
  const { user } = useUser();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const sport = useSport() || defaultSport;
  const navigate = useNavigate();
  const location = useLocation();
  const headerTab = useHeaderTab() || "HOME";
  const audioRef = useRef(null);
  const suggestionRef = useRef(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const { mode, setMode } = ThemeState();
  const [teams, setTeams] = useState(false);
  const [search, setSearch] = useState("");
  const [players, setPlayers] = useState([]);
  const [notificationsData, setNotificationsData] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [onUpdate, setOnUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);
  const [permission, setPermission] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  const fetchTeamsList = async (sport) => {
    try {
      setIsLoading(true);
      const res = await Games.teams(sport);
      setTeamsList(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching teams list:", error);
    }
  };

  const fetchUserNotifications = async () => {
    try {
      const res = await Notification.getNotifications(sport, 0, 50);
      updateNotifications(res?.data);
      setNotificationsData(res?.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching schedules:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchUserNotifications();

    const intervalId = setInterval(fetchUserNotifications, 5 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdate]);

  useEffect(() => {
    if (isListening) {
      onSearch(transcript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  const logout = () => {
    googleLogout();
    logoutAuth();
    navigate("/login");
  };

  const onUpdateTab = (tab) => {
    updateHeaderTab(tab);
    switch (tab) {
      case "HOME":
        updateSport(defaultSport);
        navigate("/");
        break;
      case "ABOUT_US":
      case "COMPARE":
      case "PROMOS":
        navigate(
          tab === "HOME"
            ? "/"
            : tab === "ABOUT_US"
            ? "/about-us"
            : tab === "PROMOS"
            ? "/promos"
            : "/compare"
        );
        tab === "HOME" && updateSport(defaultSport);
        return;

      case "PREMIUM":
        navigate("/premium");
        updateSport(defaultSport);
        return;

      case "NFL":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/nfl")
          : navigate("/nfl");
        // location.pathname !== "/premium" && navigate("/nfl");
        return;

      case "NBA":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/nba")
          : navigate("/nba");
        // location.pathname !== "/premium" && navigate("/nba");
        return;

      case "MLB":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/mlb")
          : navigate("/mlb");
        // location.pathname !== "/premium" && navigate("/mlb");
        return;

      case "NHL":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/nhl")
          : navigate("/nhl");
        // location.pathname !== "/premium" && navigate("/nhl");
        return;

      default:
        navigate("/");
        return;
    }
  };

  const onSearch = async (name) => {
    setSearch(name);
    if (name?.length >= 3) {
      try {
        setIsNotificationLoading(true);
        const res = await Games.search(name);
        setPlayers(res?.data?.data);
      } catch (error) {
        setIsNotificationLoading(false);
        console.log("Error in searching players");
      } finally {
        setIsNotificationLoading(false);
      }
    }
  };

  const onDayMode = (mode) => {
    setMode(mode);
    updateThemeMode(mode);
  };

  const onNightMode = (mode) => {
    setMode(mode);
    updateThemeMode(mode);
  };

  const handleNavigate = () => {
    window.location.href = "/";
  };

  const handleAudio = () => {
    audioRef?.current?.play();
  };

  const handleSpeech = (status) => {
    resetTranscript();
    setIsListening(!isListening);
    requestMicrophoneAccess();

    try {
      if (status === "start") {
        handleAudio();
        SpeechRecognition.startListening({ continuous: false });

        const stopTimeout = setTimeout(() => {
          SpeechRecognition.stopListening();
          setIsListening(false);
        }, 5000); // 5000 milliseconds = 5 seconds

        SpeechRecognition.onresult = (event) => {
          clearTimeout(stopTimeout); // Clear the timeout if recognition produces results
        };
      } else {
        SpeechRecognition.stopListening();

        resetTranscript();
      }
    } catch (error) {
      console.log("Error in speach recognization", error);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn`t support speech recognition.</span>;
  }

  return (
    <div
      className={`mobile_header_main ${premium ? "premium_header" : ""} ${
        mode === "Light" ? "light_theme" : ""
      } ${bgWhite && "bg_white"}`}
    >
      <Container fluid>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center pt-3">
              {!premium ? (
                mode === "Light" ? (
                  <img
                    src="/images/light_logo.png"
                    alt="betting predictions"
                    style={{ width: "140px", cursor: "pointer" }}
                    onClick={() => {
                      onUpdateTab("HOME");
                      handleNavigate();
                    }}
                  />
                ) : (
                  <img
                    src="/images/logo.png"
                    alt="betting predictions"
                    style={{ width: "140px", cursor: "pointer" }}
                    onClick={() => {
                      onUpdateTab("HOME");
                      handleNavigate();
                    }}
                  />
                )
              ) : (
                <img
                  src="/images/premium-logo.svg"
                  style={{ width: "200px", cursor: "pointer" }}
                  alt="logo"
                  onClick={() => {
                    onUpdateTab("HOME");
                    handleNavigate();
                  }}
                />
              )}

              {user ? (
                <div className="auth_btn_container">
                  {isComparePage && (
                    <div>
                      {!premium ? (
                        <div className="toggle_mobile">
                          {mode === "Dark" ? (
                            <img
                              src="/images/theme.png"
                              alt="theme"
                              onClick={() => onDayMode("Light")}
                            />
                          ) : (
                            <img
                              src="/images/theme-2.png"
                              alt="theme"
                              onClick={() => onNightMode("Dark")}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="premium_toggle_mobile">
                          {mode === "Dark" ? (
                            <img
                              src="/images/Sun.png"
                              alt="theme"
                              onClick={() => onDayMode("Light")}
                            />
                          ) : (
                            <img
                              src="/images/Moon.png"
                              alt="theme"
                              onClick={() => onNightMode("Dark")}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                            <Dropdown>
                              <Dropdown.Toggle id="profile-dropdown">
                                <img
                                  src={
                                    user?.user?.provider === "google" ||
                                    user?.user?.provider === "facebook"
                                      ? user?.user?.profile
                                      : `/images/${
                                          user?.user?.profile || "profile.png"
                                        }`
                                  }
                                  alt=""
                                  className="profile_icon"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                className="profile-dropdown-menu"
                                style={{
                                  marginLeft: "0px",
                                  backgroundColor: "#feb91a",
                                  width: "200px",
                                  marginTop:"40px"
                                }}
                              >
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/dashboard-1.png" />
                                  <h6>Dashboard</h6>
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  href="/"
                                  className="profile-dropdown-item"
                                >
                                  Account Setting
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/key.png" />

                                  <h6>Change Password</h6>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    logout();
                                    // onSuccess("Logout Successfully!");
                                  }}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/logout.png" />

                                  <h6 style={{ color: "red" }}>Logout</h6>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                  
                </div>
              ) : (
                <div className="auth_btn_container">
                  {isComparePage && (
                    <div style={{ padding: "0px" }}>
                      {!premium ? (
                        <div className="toggle_mobile">
                          {mode === "Dark" ? (
                            <img
                              src="/images/theme.png"
                              alt="theme"
                              onClick={() => onDayMode("Light")}
                            />
                          ) : (
                            <img
                              src="/images/theme-2.png"
                              alt="theme"
                              onClick={() => onNightMode("Dark")}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="premium_toggle_mobile">
                          {mode === "Dark" ? (
                            <img
                              src="/images/Sun.png"
                              alt="theme"
                              onClick={() => onDayMode("Light")}
                            />
                          ) : (
                            <img
                              src="/images/Moon.png"
                              alt="theme"
                              onClick={() => onNightMode("Dark")}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <button
                    className="bg_green text-white sign-up"
                    onClick={() => navigate("/signup")}
                  >
                    Sign Up
                  </button>
                  <button className="login" onClick={() => navigate("/login")}>
                    Log In
                  </button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        {!isComparePage && (
          <Row>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                paddingTop: "15px",
              }}
            >
              <Form
                className="d-flex w-100"
                style={{ flex: 1 }}
                ref={suggestionRef}
              >
                <div className="search-wrapper" style={{ width: "inherit" }}>
                  <div
                    className={`search_bar w-100  ${
                      bgWhite ? "search_bar_bgWhite" : "search_bar"
                    }`}
                  >
                    {/* {premium && ( */}
                    <img
                      src="/images/search.png"
                      alt="search"
                      style={{ left: 9 }}
                    />

                    <Form.Control
                      type="search"
                      className="search-focus"
                      placeholder="Search for player totals or results"
                      aria-label="Search"
                      value={search}
                      onChange={(e) => onSearch(e.target.value)}
                      onFocus={() => setShowDropdown(true)}
                    />
                    <div
                      className="mic-wrapper"
                      onClick={() =>
                        handleSpeech(isListening ? "stop" : "start")
                      }
                    >
                      <img
                        className="mic"
                        src={
                          !isListening
                            ? "/images/mic.png"
                            : "/images/mic-animation.gif"
                        }
                        alt="mic"
                      />
                    </div>
                  </div>
                  {search?.length >= 4 && showDropdown && (
                    <SearchSuggestion
                      players={players}
                      setPlayerName={setSearch}
                      isLoading={isNotificationLoading}
                      showDropdown={showDropdown}
                      setShowDropdown={setShowDropdown}
                    />
                  )}
                </div>
              </Form>

              {!premium ? (
                <div className="theme_toggle_mobile">
                  {mode === "Dark" ? (
                    <img
                      src="/images/theme.png"
                      alt="theme"
                      onClick={() => onDayMode("Light")}
                    />
                  ) : (
                    <img
                      src="/images/theme-2.png"
                      alt="theme"
                      onClick={() => onNightMode("Dark")}
                    />
                  )}
                </div>
              ) : (
                <div className="premium_theme_toggle_mobile">
                  {mode === "Dark" ? (
                    <img
                      src="/images/Sun.png"
                      alt="theme"
                      onClick={() => onDayMode("Light")}
                    />
                  ) : (
                    <img
                      src="/images/Moon.png"
                      alt="theme"
                      onClick={() => onNightMode("Dark")}
                    />
                  )}
                </div>
              )}
            </div>
          </Row>
        )}
        <Row style={{ position: "relative" }}>
          <Dropdown show={teams} onToggle={() => setTeams(!teams)}>
            <div className="nav_link_container tablet_header">
              <Nav.Link
                href=""
                onClick={() => onUpdateTab("HOME")}
                className={`${headerTab === "HOME" && "selected-sport"}`}
              >
                HOME
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => onUpdateTab("PREMIUM")}
                className={`${headerTab === "PREMIUM" && "selected-sport"}`}
              >
                PREMIUM
              </Nav.Link>
              <Nav.Link
                href=""
                className={`${headerTab === "NFL" && "selected-sport"}`}
                onClick={() => onUpdateTab("NFL")}
              >
                NFL
              </Nav.Link>
              <Nav.Link
                href=""
                className={`${headerTab === "NBA" && "selected-sport"}`}
                onClick={() => onUpdateTab("NBA")}
              >
                NBA
              </Nav.Link>
              <Nav.Link
                href=""
                className={`abc ${headerTab === "MLB" && "selected-sport"}`}
                onClick={() => onUpdateTab("MLB")}
              >
                MLB
              </Nav.Link>
              <Nav.Link
                href=""
                className={`${headerTab === "NHL" && "selected-sport"}`}
                onClick={() => onUpdateTab("NHL")}
              >
                NHL
              </Nav.Link>
              {!premium && (
                <Nav.Link
                  href=""
                  className={`${headerTab === "PROMOS" && "selected-sport"}`}
                  onClick={() => onUpdateTab("PROMOS")}
                >
                  PROMOS
                </Nav.Link>
              )}
              <Nav.Link
                onClick={() => onUpdateTab("COMPARE")}
                className={`${headerTab === "COMPARE" && "selected-sport"}`}
              >
                COMPARE
              </Nav.Link>

              {!premium && (
                <Nav.Link
                  href=""
                  style={{ minWidth: "80px" }}
                  onClick={() => onUpdateTab("ABOUT_US")}
                  className={`${headerTab === "ABOUT_US" && "selected-sport"}`}
                >
                  About Us
                </Nav.Link>
              )}
              <Dropdown.Toggle
                className="team_header_link"
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Nav.Link
                  onClick={() => {
                    setTeams(!teams);
                  }}
                  className={`${headerTab === "TEAMS" && "selected-sport"}`}
                >
                  TEAMS <AiFillCaretDown />
                </Nav.Link>
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="team_accordion_dropdown" align="end">
              <Accordion
                className={`team_header_accordion ${
                  mode === "Dark" && "dark_theme"
                } `}
              >
                {sports.map((sport, index) => {
                  return (
                    <Accordion.Item
                      className={`team_accordion_item ${
                        mode === "Dark" && "dark_theme"
                      } `}
                      key={index}
                      eventKey={index}
                    >
                      <Accordion.Header
                        onClick={() => fetchTeamsList(sport)}
                        className="team_accordion_header"
                      >
                        {sport}
                      </Accordion.Header>
                      <Accordion.Body className="team_accordion_body">
                        {isLoading ? (
                          <DarkLoader />
                        ) : teamsList?.length ? (
                          teamsList?.map((team) => (
                            <p style={{ cursor: "pointer" }}>
                              <span style={{ display: "flex", gap: 15 }}>
                                <img
                                  className={`${
                                    team?.name === "Pirates" && "team_logo_bg"
                                  }`}
                                  src={team?.wikipediaLogoUrl || "/"}
                                  alt={
                                    team?.logoAltText ||
                                    `${team?.city} ${team?.name}`
                                  }
                                />
                                <span
                                  onClick={() => {
                                    updateHeaderTab(team?.sport);
                                    updateSport(team?.sport);
                                    teamNavigator(team, navigate, updateSport);
                                    setTeams(false);
                                  }}
                                >
                                  {team?.city} {team?.name}
                                </span>
                              </span>
                              <span>{team?.sport}</span>
                            </p>
                          ))
                        ) : (
                          <p style={{ color: "#778899" }}>No Teams Found</p>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Dropdown.Menu>
          </Dropdown>
        </Row>
      </Container>
      <audio ref={audioRef} src="./images/beep.mp3" />
    </div>
  );
};

export default MobileHeader;
