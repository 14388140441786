import { MdClose } from "react-icons/md";
import React,{useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { onSuccess } from "../../../Utils/toast";
import { logoutAuth, useUser } from "../../../Redux/Accessors/auth.accessors";
import { googleLogout } from "@react-oauth/google";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { Collapse } from "@mui/material";

function MobileSidebar({ navbarRef, NavHandler,sideBarItems }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const [subNav, setSubNav] = useState(false);
  const [openRow, setOpenRow] = useState({ name: null, close: false });
  const handleRowsCollapse = (name) => {
    setSubNav(!subNav);
    setOpenRow({ name: name, close: !openRow.close });
  };

  const logout = () => {
    googleLogout();
    logoutAuth();
    navigate("/login");
  };

  return (
    <div className="overlay" ref={navbarRef}>
      <span className="closebtn" onClick={NavHandler}>
        <MdClose />
      </span>
      <div className={"overlay-content"}>
        <div className="d-flex align-items-center text-white gap-2 mb-3">
          <div className="mobile_sidebar_avatar">
            <img
              src={
                user?.user?.provider === "google" ||
                user?.user?.provider === "facebook"
                  ? user?.user?.profile
                  : `/images/${user?.user?.profile || "avatar.png"}`
              }
              alt="profile-pic"
            />
          </div>
          <div>
            <h3>{`${user?.user?.firstName || ""} ${
              user?.user?.lastName || ""
            }`}</h3>
          </div>
        </div>
        {sideBarItems &&
          sideBarItems.map((s, i) => {
            if (!s.isSubNav) {
              return (
                <Link
                  to={s.path}
                  className={pathname === s.path ? "nav-active " : "nav-link"}
                >
                  <span className="overlay-content-number">
                    0{`${i + 1}`} -{" "}
                  </span>
                  {s.title}
                </Link>
              );
            } else {
              return (
                <>
                  <Link
                    to={"#"}
                    onClick={() => handleRowsCollapse(s.title)}
                    className="nav-link"
                  >
                    <span className="overlay-content-number">
                      0{`${i + 1}`} -{" "}
                    </span>
                    {s.title}
                    {s?.title === openRow.name && openRow.close === true ? (
                      <IoMdArrowDropdown className={"subNav"} />
                    ) : (
                      <IoMdArrowDropright className={"subNav"} />
                    )}
                  </Link>
                  {s.subNav ? (
                    <Collapse
                      in={s?.title === openRow.name && openRow.close === true}
                      unmountOnExit
                    >
                      <React.Fragment>
                        {s.subNav.map((subItem, index) => (
                          <Link
                            to={subItem.path}
                            className={
                              pathname === subItem.path
                                ? "nav-active "
                                : "nav-link"
                            }
                          >
                            <span className="overlay-content-number ps-5">
                                  {/* 0{`${index + 1}`} -{" "} */}
                                </span>
                            {subItem.title}
                          </Link>
                        ))}
                      </React.Fragment>
                    </Collapse>
                  ) : null}
                </>
              );
            }
          })}
        <button
          className="mobile_sidebar_logout_btn"
          onClick={() => {
            logout();
            onSuccess("Logout Successfully!");
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default MobileSidebar;
