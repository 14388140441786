import { apiRequest } from "../Utils/apiRequest";
import { promosRoute } from "../Utils/routes";

const Promos = {
  promos: () => {
    return apiRequest(promosRoute.getAllPromos)
  },
  createPromo: (data) => {
    return apiRequest(promosRoute.createPromos, "POST", data,true);
  },
  updatePromo: (data) => {
    return apiRequest(promosRoute.updatePromos, "PUT", data, true);
  },
  deletePromo:(id)=>{
    return apiRequest(`${promosRoute.deletePromo}/${id}`, "DELETE");
  }
};

export default Promos;
