import { createSlice } from "@reduxjs/toolkit";

const subAdminSlice = createSlice({
  name: "subAdmin",
  initialState: {
    subAdmin: {},
    // success:true
  },
  reducers: {
    createSubAdmin: (state, action) => {
      state.subAdmin = action.payload;
    },
  },
});
export default subAdminSlice;
