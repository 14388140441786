import React, { useState, useEffect } from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { ThemeState } from "../../../Context";
import {
  updateMLBTab,
  updateTab,
  useSport,
  useTab,
} from "../../../Redux/Accessors/game.accessors";
import {
  MLB_BATTING_TABS,
  MLB_PITCHING_TABS,
  NBA_TABS,
  NFL_TABS,
  SPORTS,
} from "../../../Constants";
import { useNavigate, useParams } from "react-router-dom";

const MobilePointsTableHeader = ({ position = "B" }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { mode } = ThemeState();
  const sport = useSport();
  const selectedTab = useTab();

  // const MLB_TABS = ["Batting", "Pitching"];
  const [selectedTabMLB, setSelectedTabMLB] = useState("Batting");

  const [tabs, setTabs] = useState(
    sport === "NBA"
      ? NBA_TABS
      : sport === "MLB" && selectedTabMLB === "Batting"
      ? MLB_BATTING_TABS
      : sport === "MLB" && selectedTabMLB === "Pitching"
      ? MLB_PITCHING_TABS
      : sport === "NFL"
      ? NFL_TABS
      : NBA_TABS
  );

  const updateGameTab = (sport) => {
    switch (sport) {
      case "NBA":
        updateTab("Points");
        break;
      case "MLB":
        if (selectedTabMLB === "Batting") {
          updateTab("Home Runs");
        } else {
          updateTab("Strikeouts");
        }
        break;
      case "NFL":
        updateTab("Anytime TD");
        break;
      case "NHL":
        updateTab("Points");
        break;
      default:
        updateTab("Home Runs");
    }
  };

  useEffect(() => {
    updateGameTab(sport);

    if (sport === SPORTS.NBA) {
      setTabs(NBA_TABS);
      return;
    }
    if (sport === SPORTS.MLB) {
      if (selectedTabMLB === "Pitching" || position === "P") {
        setSelectedTabMLB("Pitching");
        updateMLBTab("Pitching");
        setTabs(MLB_PITCHING_TABS);
        return;
      } else {
        setSelectedTabMLB("Batting");
        updateMLBTab("Batting");
        setTabs(MLB_BATTING_TABS);
        return;
      }
    }
    if (sport === SPORTS.NFL) {
      setTabs(NFL_TABS);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabMLB, sport, position, id]);

  return (
    <>
      <div className="mb-3 mb-md-2" style={{ marginLeft: "15px" }}>
        {sport === SPORTS.MLB && (
          <div
            className={`points_table_header_main_mlb ${
              mode === "Light" && "light_theme"
            }`}
          >
            <div>
              {/* {MLB_TABS.map((tab, index) => ( */}
              <button
                // key={index}
                className={`tab_button ${mode === "Light" && "light_theme"} ${
                  selectedTabMLB === "Batting" ? "points_active" : ""
                } `}
                onClick={() => {
                  setSelectedTabMLB("Batting");
                  updateMLBTab("Batting");
                }}
              >
                {"Batting"}
              </button>
              <span style={{ color: "#dddddd" }}>|</span>
              <button
                //  key={index}
                className={`tab_button ${mode === "Light" && "light_theme"} ${
                  selectedTabMLB === "Pitching" ? "points_active" : ""
                } `}
                onClick={() => {
                  setSelectedTabMLB("Pitching");
                  updateMLBTab("Pitching");
                }}
              >
                {"Pitching"}
              </button>
              {/* ))} */}
            </div>
          </div>
        )}
      </div>
      <div
        className={`mb-3 mb-md-2 sticky_points_table_header ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div className="mobile_points_table_header_main">
          <button onClick={() => navigate(-1)}>
            <HiOutlineArrowNarrowLeft
              style={{
                color: mode === "Light" ? "white" : "black",
                fontSize: "26px",
              }}
            />
          </button>
          <div>
            {tabs.map(({ tab }, index) => (
              <button
                key={index}
                onClick={() => {
                  updateTab(tab);
                }}
                className={`${selectedTab === tab ? "points_active" : ""}`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobilePointsTableHeader;

