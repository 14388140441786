import React from "react";

const Loader = () => {
  return (
    <img
      src="/images/loader.gif"
      alt="loader"
      style={{ width: "25px", height: "25px" }}
    />
  );
};

export default Loader;
