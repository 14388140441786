import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Info from "../../../Common/Info";
import ViewMore from "../../../Common/ViewMore";
import { ThemeState } from "../../../Context";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import Games from "../../../Services/Games";
import { getDataValues, getTab } from "../../../Utils/getStats";
import SearchSuggestion from "../../Layout/Header/SearchSuggestion";
import "./PremiumCard.css";
import PremiumRow from "./PremiumRow";
import { playerNavigator, teamNavigator } from "../../../Utils/navigator";

const MobilePremiumCard = ({
  onlyOne,
  player,
  search,
  onlyList,
  showViewMore,
  team,
  teamData,
  playerDetails,
  schedule,
  id,
  premium,
  list,
  tab
}) => {
  const navigate = useNavigate();
  const selectedTab = useTab();
  const { mode } = ThemeState();
  const audioRef = useRef(null);
  const suggestionRef = useRef(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [toggle, setToggle] = useState(onlyList || list ? "LIST" : "GRID");
  const [premiumListTab, setPremiumListTab] = useState(5);
  const [viewMore, setViewMore] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [playersData, setPlayersData] = useState([]);
  const [gameStats, setGameStats] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const teamKey = schedule?.homeTeamPlayers?.find(({ _id }) => _id === id)
    ? "homeTeam"
    : "awayTeam";
    const premiumValue =
    schedule?.playerPremium &&
    schedule?.playerPremium.filter((premium) => premium.player === playerDetails._id)
  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.playerStats(data);
      const stats = res?.data?.data?.find(({ player }) => player?._id === id);
      setGameStats(stats);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {

    if (schedule) {
      fetchTeamPlayerStats({
        gameId: schedule?._id,
        teamId: schedule[teamKey]?._id,
        bettingBetType: getTab(selectedTab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedTab, schedule]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  useEffect(() => {
    if (isListening) {
      onSearch(transcript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  // const checkPermissions = () => {
  //   const permissions = navigator.mediaDevices.getUserMedia({
  //     audio: true,
  //   });
  //   permissions
  //     .then((stream) => {
  //       console.log("have permission");
  //       return true;
  //     })
  //     .catch((err) => {
  //       console.log(`${err.name} : ${err.message}`);
  //       return false;
  //     });
  // };

  const handleSpeech = (status) => {
    resetTranscript();
    setIsListening(!isListening);

    try {
      if (status === "start") {
        handleAudio();
        SpeechRecognition.startListening({ continuous: false });

        const stopTimeout = setTimeout(() => {
          console.log("stopTimeout");
          SpeechRecognition.stopListening();
          setIsListening(false);
        }, 5000); // 5000 milliseconds = 5 seconds

        SpeechRecognition.onresult = (event) => {
          clearTimeout(stopTimeout); // Clear the timeout if recognition produces results
        };
      } else {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    } catch (error) {
      console.log("Error in speach recognization", error);
    }
  };

  const handleAudio = () => {
    audioRef?.current?.play();
  };

  const onSearch = async (name) => {
    setPlayerName(name);
    if (name?.length >= 4) {
      try {
        const res = await Games.search(name);
        setPlayersData(res?.data?.data);
      } catch (error) {
        console.log("Error in searching players");
      }
    }
  };

  // const handleTeamNavigate = (id) => {
  //   scrollToTop();
  //   navigate(`/team/${id}`);
  // };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn`t support speech recognition.</span>;
  }

  const filteredTeamData =
    teamData?.filter((game) => !getDataValues(game, selectedTab)) || [];
  console.log(schedule, "schedule");
  return (
    <div className="premium_card_box_main">
      <div className={`premium_card_box ${mode === "Light" && "light_theme"}`}>
        {search && (
          <div className="search-wrapper" ref={suggestionRef}>
            <div className="premium_search_box">
              <img src="/images/search.png" alt="search" />
              <input
                placeholder="Search for player totals or results"
                value={playerName}
                onChange={(e) => onSearch(e.target.value)}
                onFocus={() => setShowDropdown(true)}
              />
              <div
                className="premium-mic-wrapper"
                onClick={() => handleSpeech(isListening ? "stop" : "start")}
              >
                <img
                  src={
                    !isListening
                      ? "/images/mic.png"
                      : "/images/mic-animation.gif"
                  }
                  alt="mic"
                  className="mt-1"
                  // onClick={() => handleSpeech(isListening ? "stop" : "start")}
                />
              </div>
            </div>
            {playerName?.length >= 4 && showDropdown && (
              <SearchSuggestion
                players={playersData}
                setPlayerName={setPlayerName}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
              />
            )}
          </div>
        )}
        <div
          className={`d-flex align-items-center justify-content-between py-2 px-2 premium_menu_box ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div className="d-flex align-items-center">
            {!player ? (
              <>
                <img
                  src={team?.wikipediaLogoUrl}
                  style={{ width: "30px", height: "30px" }}
                  alt=""
                  onClick={() => teamNavigator(team, navigate, updateSport)}
                />
                <h3
                  onClick={() => teamNavigator(team, navigate, updateSport)}
                  className={`cursor-pointer ${
                    mode === "Light" ? "text-white" : "text-white"
                  } ps-2`}
                >
                  {team?.city} {team?.name}
                </h3>
              </>
            ) : (
              <h3 className={`${mode === "Light" ? "text-white" : "col-blue"}`}>
                {player}
              </h3>
            )}
          </div>
          <div className="d-flex align-items-center gap-3">
            {!onlyList && (
              <>
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setToggle("GRID")}
                >
                  {toggle === "GRID" ? (
                    <img src="/images/grid.png" alt="" />
                  ) : mode === "Light" ? (
                    <img src="/images/grid-white.png" alt="" />
                  ) : (
                    <img src="/images/grid-white.png" alt="" />
                  )}
                  {toggle === "GRID" ? (
                    <p style={{ color: mode === "Light" ? "#fff" : "#fff" }}>
                      GRID
                    </p>
                  ) : (
                    <p
                      className={`${
                        mode === "Light" ? "text-white" : "text-white"
                      }`}
                    >
                      GRID
                    </p>
                  )}
                </div>
                <div
                  style={{
                    width: "1px",
                    height: "20px",
                    background: "rgb(77, 77, 77)",
                  }}
                ></div>
              </>
            )}
            <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: "pointer" }}
              onClick={() => setToggle("LIST")}
            >
              {toggle === "LIST" ? (
                <img src="/images/redlist.png" alt="" />
              ) : mode === "Light" ? (
                <img src="/images/list.png" alt="" />
              ) : (
                <img src="/images/list.png" alt="" />
              )}
              {toggle === "LIST" ? (
                <p style={{ color: mode === "Light" ? "#fff" : "#fff" }}>
                  LIST
                </p>
              ) : (
                <p
                  className={`${
                    mode === "Light" ? "text-white" : "text-white"
                  }`}
                >
                  LIST
                </p>
              )}
            </div>
          </div>
        </div>

        {toggle !== "GRID" && (
          <>
            <div className="premium_card_header bg_red w-100 text-white">
              <h3>PREMIUM</h3>
            </div>
            <div
              className={`premium_card_body ${
                mode === "Light" && "light_theme"
              }`}
            >
              <div>
                <div className="d-flex justify-content-between align-items-center premium_list_text">
                {tab && tab === "playerPremium" && premiumValue ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: premiumValue[0]?.premium,
                      }}
                    ></p>
                  ) : tab && tab === "team" && team?.premium ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: team?.premium,
                      }}
                    ></p>
                  ) : !tab && premium ? (
                    <p dangerouslySetInnerHTML={{ __html: premium }}></p>
                  ) : (
                    <h3>No Player Picks for this Team.</h3>
                  )}
                  <p>{/* 0 30.5 pts <span>(-120)</span> */}</p>
                </div>
                {/* <div className="pt-2 mb-3">
                  <ul>
                    <li>74% hit rate away</li>
                    <li>83% hit rate without James Harden</li>
                  </ul>
                </div> */}
              </div>
              <hr style={{ color: "white" }} />
              {!onlyOne && (
                <>
                  <div style={{ height: "70px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Zach LaVine</h3>
                      <p>
                        0 27.5 pts <span>(-115)</span>
                      </p>
                    </div>
                    <div className="pt-2 mb-3">
                      <ul>
                        <li>74% hit rate away</li>
                        <li>83% hit rate without James Harden</li>
                      </ul>
                    </div>
                  </div>
                  <div style={{ height: "70px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Zach LaVine</h3>
                      <p>
                        0 27.5 pts <span>(-115)</span>
                      </p>
                    </div>
                    <div className="pt-2 mb-3">
                      <ul>
                        <li>74% hit rate away</li>
                        <li>83% hit rate without James Harden</li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {viewMore && (
                <div style={{ height: "70px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Zach LaVine</h3>
                    <p>
                      0 27.5 pts <span>(-115)</span>
                    </p>
                  </div>
                  <div className="pt-2 mb-3">
                    <ul>
                      <li>74% hit rate away</li>
                      <li>83% hit rate without James Harden</li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="mb-2 text-center text-white"></div>
            </div>
          </>
        )}

        {toggle !== "LIST" && (
          <>
            <div className="premium_card_header bg_green w-100 text-white list_head ps-2 pe-4 pe-md-2">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ gap: "8px" }}
              >
                <h3 style={{ fontSize: "13px" }}>
                  PREMIUM <BiChevronDown />
                </h3>
                <h3
                  className={`${premiumListTab === 5 ? "active" : ""}`}
                  onClick={() => setPremiumListTab(5)}
                >
                  Last 5{" "}
                </h3>
                <h3
                  className={`${premiumListTab === 10 ? "active" : ""}`}
                  onClick={() => setPremiumListTab(10)}
                >
                  Last 10{" "}
                </h3>
                <h3
                  className={`${premiumListTab === "season" ? "active" : ""}`}
                  onClick={() => setPremiumListTab("season")}
                >
                  Season{" "}
                </h3>
              </div>
            </div>

            <div className="list_box">
              <Row>
                <Col xs={4} className="p-0">
                  <h3
                    style={{ textAlign: "start", fontSize: "16px" }}
                    className={`${
                      mode === "Light" ? "col-green" : "col-yellow"
                    }`}
                  >
                    {selectedTab ? selectedTab : "Stats"}
                  </h3>
                </Col>
                <Col xs={8}>
                  <Row className="list_header p-0 pe-3">
                    <Col xs={3} className="premium-labels-mobile">
                      <h3>Avg Finish</h3>
                    </Col>
                    <Col xs={3}>
                      <h3>Avg Line </h3>
                    </Col>
                    <Col xs={3}>
                      <h3>% Finish</h3>
                    </Col>
                    <Col xs={3}>
                      <h3>Projection</h3>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {!!filteredTeamData?.length ? (
                filteredTeamData
                  ?.slice(0, viewMore ? filteredTeamData?.length : 3)
                  .map((game, index) => {
                    const allStatsNull = getDataValues(game, selectedTab);

                    if (allStatsNull) {
                      return null;
                    }
                    return (
                      <div className="list_data" key={index}>
                        <Row className="p-0 pe-3">
                          <Col xs={4} className="premium_player">
                            <p
                              className="mobile_premium_player_name"
                              onClick={() =>
                                playerNavigator(
                                  game?.player,
                                  navigate,
                                  updateSport
                                )
                              }
                            >
                              {game?.player?.firstName} {game?.player?.lastName}{" "}
                            </p>
                            <p className="info-icon">
                              <Info />
                            </p>
                          </Col>
                          <Col xs={8}>
                            <PremiumRow
                              playerId={game?.player?._id}
                              matches={premiumListTab}
                              projection={game?.consensus}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  })
              ) : (
                <div className="list_data">
                  <Row className="p-0 pe-3">
                    <Col xs={4} className="premium_player">
                      {playerDetails && (
                        <>
                          <p className="mobile_premium_player_name">
                            {playerDetails?.firstName} {playerDetails?.lastName}
                          </p>
                          <p className="info-icon">
                            <Info />
                          </p>
                        </>
                      )}
                    </Col>
                    <Col xs={8}>
                      {playerDetails ? (
                        <PremiumRow
                          playerId={playerDetails?._id}
                          matches={premiumListTab}
                          projection={gameStats?.consensus}
                        />
                      ) : (
                        <p style={{ fontSize: "12px" }}>No Premium Stats</p>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <audio ref={audioRef} src="./images/beep.mp3" />

      {showViewMore && (
        <div
          className={`d-flex justify-content-center mt-4 view_more_btn ${
            mode === "Light" && "light_theme"
          }`}
        >
          <ViewMore viewMore={viewMore} setViewMore={setViewMore} />
        </div>
      )}
    </div>
  );
};

export default MobilePremiumCard;
