import React from "react";
import Modal from "../../../Components/Modal/SiteModal";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { cloudURL } from "../../../Utils/helper";
import { useState } from "react";
// import { useSelector } from "react-redux";

const EditBannerSectionModal = ({
  show,
  setShow,
  banner,
  onChangeHandler,
  handleImageChange,
  uploadedImages,
  onCloseModal,
  submitHandler,
  loading
}) => {
  // console.log(banner);

  // const {loading}=useSelector((state)=>state.aboutUpdate)
  // const [loading, setLoading] = useState(false);

  return (
    <div>
      <Modal
        show={show}
        setShow={setShow}
        style="promo_modal"
        size="md"
        heading="Edit Banner"
        onCloseModal={onCloseModal}
      >
        <>
          <Form onSubmit={submitHandler}>
            <Row className="justify-content-center edit_promo">
              <Col md={11} className="mt-4">
                <Row className="gx-5">
                  <Col md={12}>
                    <Form.Label>Image</Form.Label>
                    <Form.Group className="mb-3 cms_image_input">
                      <Form.Control
                        type="file"
                        style={{ fontSize: "13px", paddingBottom: "7px" }}
                        name={`image`}
                        onChange={handleImageChange()}
                      />
                    </Form.Group>
                    <div className="previewImg_div">
                      <img
                        src={
                          uploadedImages?.BannerImgPreview
                            ? uploadedImages?.BannerImgPreview
                            : banner?.bannerImg?.filename
                            ? `${cloudURL}/${banner?.bannerImg?.filename}`
                            : "/images/empty.jpg"
                        }
                        alt=""
                        style={{}}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="gx-5">
                  <Col md={12}>
                    <Form.Label>Title</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="title"
                        value={banner?.title}
                        onChange={onChangeHandler("title")}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row className="justify-content-end">
                  <button
                    type="submit"
                    style={{
                      width: "fit-content",
                      backgroundColor: "red",
                      border: "none",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "6px",
                    }}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      </Modal>
    </div>
  );
};

export default EditBannerSectionModal;
