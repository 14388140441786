import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../Common/Loader";
import PageWrapper from "../../Common/PageWrapper";
import TextMessage from "../../Common/TextMessage";
import CouponsSlider from "../../Components/Home/CouponsSlider/CouponsSlider";
import MobilePointsTableHeader from "../../Components/Home/PointsTableHeader/MobilePointsTableHeader";
import GoPremium from "../../Components/Home/PremiumCard/GoPremium";
import MobilePremiumCard from "../../Components/Home/PremiumCard/MobilePremiumCard";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import Notifications from "../../Components/Layout/Notification/Notifications";
import BorderLine from "../../Components/Teams/BorderLine/BorderLine";
import TeamsAccordionMobile from "../../Components/Teams/TeamsDetails/TeamAccordionMobile";
import TeamHeader from "../../Components/Teams/TeamsDetails/TeamHeader";
import TeamsDetails from "../../Components/Teams/TeamsDetails/TeamsDetails";
import TeamsTable from "../../Components/Teams/TeamsDetails/TeamsTable";
import { ThemeState } from "../../Context";
import {
  updateFavourite,
  updateGameNotifications,
} from "../../Redux/Accessors/auth.accessors";
import { updateSport, useSport } from "../../Redux/Accessors/game.accessors";
import { updateHeaderTab } from "../../Redux/Accessors/header.accessors";
import {
  updateTeamMatches,
  updateTeams,
  useTeamGames,
  useTeams,
} from "../../Redux/Accessors/previousGames.accessors";
import Games from "../../Services/Games";
import Notification from "../../Services/Notification";
import User from "../../Services/User";
import { TEAMS_DETAILS } from "../../Constants";

const Teams = () => {
  const { slug, sport: teamSport } = useParams();
  console.log(slug, "slug");
  const { mode } = ThemeState();
  const sport = useSport();
  const sportTeams = useTeams();
  const matches = useTeamGames();
  const ref = useRef(null);

  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [teams, setTeams] = useState(sportTeams || []);
  const [games, setGames] = useState(matches || []);
  const [isFavoriteFetched, setIsFavoriteFetched] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [onUpdate, setOnUpdate] = useState(false);
  const [team, setTeam] = useState(null);
  const [enableScroll, setEnableScroll] = useState(false);
  const previousGames = games?.data?.previousGames;
  const [premiumData, setPremiumData] = useState({});
  const [teamKey, setTeamKey] = useState();
  const schedule = games?.data?.scheduledGame || {};

  console.log(schedule)

  const fetchTeamBySlug = async () => {
    try {
      const res = await Games.getTeamBySlug(slug);
      setId(res?.data?.data?._id);
      updateSport(res?.data?.data?.sport);
    } catch (error) {
      console.error("An error occurred while fetching team details:", error);
    }
  };

  const fetchTeamPreviousGames = async (skip = 0) => {
    try {
      setIsLoading(true);
      const res = await Games.teamGames(slug, skip, teamSport);
      setGames(res?.data);
      updateTeamMatches(res?.data);
      window.scrollTo(0, 0);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching player games:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTeams = async () => {
    try {
      const gameTeam = await Games.singleTeam(id);
      updateSport(gameTeam?.data?.data?.sport);
      const res = await Games.teams(gameTeam?.data?.data?.sport || sport);
      updateTeams(res?.data?.data);
      setTeams(res?.data?.data);
      const team = res?.data?.data?.find(({ _id }) => _id === id);
      setTeam(team);
      if (team) {
        updateSport(team?.sport);
      }
      // updateSportTab(team?.sport, updateTab, tab);
    } catch (error) {
      console.error("An error occurred while fetching player games:", error);
    }
  };

  const getFavouritePlayer = async () => {
    try {
      const res = await User.getFavouritePlayer();
      updateFavourite(res?.data?.data);
    } catch (error) {
      console.log("Error in getting favourite player", error);
    } finally {
      setIsFavoriteFetched(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateHeaderTab("TEAM");
  }, []);

  useEffect(() => {
    fetchTeams();
    ref.current.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug, sport, teamSport]);

  useEffect(() => {
    fetchTeamBySlug();
    fetchTeamPreviousGames();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug, sport, teamSport]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getFavouritePlayer();
  }, []);

  const fetchTeamNotifications = async () => {
    try {
      const res = await Notification.getTeamNotifications(id, sport, 0, 50);
      updateGameNotifications(res?.data?.data);
      setNotificationsData(res?.data?.data);
    } catch (error) {
      console.error(
        "An error occurred while fetching team notifications:",
        error
      );
    }
  };

  useEffect(() => {
    if (!isFavoriteFetched) return;
    fetchTeamNotifications();

    const intervalId = setInterval(fetchTeamNotifications, 5 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug, isFavoriteFetched, sport, teamSport, onUpdate]);

  let team_n;
  let tabTitle = !!team && team?.logoAltText && team?.logoAltText;
  if (tabTitle) {
    const string = tabTitle.replace(/[^a-zA-Z0-9\s]/g, ``);
    const words = string.split(" ");
    const title = words.slice(0, 3).join("_").toUpperCase();
    team_n = title;
  }

  return (
    <PageWrapper
      enableScroll={enableScroll}
      setEnableScroll={setEnableScroll}
      id={id}
      tabTitle={!!team && team?.logoAltText && team?.logoAltText}
    >
      <div ref={ref} className="home_desktop">
        <BorderLine />
        <CouponsSlider />
        {!isLoading ? (
          <>
            <TeamsDetails
              page={page}
              setPage={setPage}
              notificationsData={notificationsData}
              onUpdate={onUpdate}
              setOnUpdate={setOnUpdate}
              fetchTeamPreviousGames={(skip) => fetchTeamPreviousGames(skip)}
              setEnableScroll={setEnableScroll}
              id={id}
              tabTitle={!!team && team?.logoAltText && team?.logoAltText}
              premiumData={premiumData}
              setPremiumData={setPremiumData}
              teamKey={teamKey}
              setTeamKey={setTeamKey}
              teamData={team}
            />
          </>
        ) : (
          <div style={{ minHeight: "400px" }}>
            <Loader />
          </div>
        )}
        <Footer />
      </div>
      <div ref={ref} className="home_mobile">
        <BorderLine />
        <MobileHeader bgWhite />
        <CouponsSlider />

        {!isLoading ? (
          <>
            <div
              className={`players_details_main ${
                mode === "Light" && "light_theme"
              }`}
            >
              <TeamHeader id={id} teamDetail={TEAMS_DETAILS[team_n]} />
            </div>
            <TeamsTable />
            <MobilePointsTableHeader id={id} />
            {previousGames?.length ? (
              <>
                <TeamsAccordionMobile
                  id={id}
                  page={page}
                  setPage={setPage}
                  fetchTeamPreviousGames={(skip) =>
                    fetchTeamPreviousGames(skip)
                  }
                  setEnableScroll={setEnableScroll}
                />
              </>
            ) : (
              <div className="text-center mt-5">
                <TextMessage message={"No Previous Game"} />
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
        <Notifications
          page="team"
          data={notificationsData}
          onUpdate={onUpdate}
          setOnUpdate={setOnUpdate}
        />
        <MobilePremiumCard
          onlyOne
          onlyList
          search
          team={team}
          id={id}
          premium={
            schedule?.premium && schedule?.premium[teamKey]
              ? schedule?.premium[teamKey]
              : null
          }
        />
        <GoPremium color="red" />
        <MobileFooter />
      </div>
    </PageWrapper>
  );
};

export default Teams;
