import React from "react";
import Header from "../../Components/User/Header/Header";
import { Outlet } from "react-router-dom";
import { AdminSideBarItems } from "../Admin/Routes/Routes";
import { useUser } from "../../Redux/Accessors/auth.accessors";

const AdminLayout = () => {
    const {user} = useUser()
    // console.log(user,"user")
  // let userAuth = JSON.parse(localStorage.getItem("user"));

  return (
    <React.Fragment>
       {
                user?.user.role === "sub-admin" ? 
      <Header sideBarItems={AdminSideBarItems}>
        <Outlet />
      </Header>
      :""
      }
    </React.Fragment>
  );
};
export default AdminLayout;
