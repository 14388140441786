import React, { useEffect } from "react";
import "./CouponsSlider.css";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeState } from "../../../Context";

const CouponsSlider = () => {
  const { mode } = ThemeState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom_slick_prev`}
        style={{
          ...style,
        }}
        onClick={onClick}
      />
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom_slick_next`}
        style={{
          ...style,
        }}
        onClick={onClick}
      />
    );
  }
  const couponData = [
    {
      image: "/images/coupon-1.jpg",
      alt: "How to bet for win",
    },
    {
      image: "/images/coupon-2.jpg",
      alt: "How to bet for win",
    },
    {
      image: "/images/coupon-3.jpg",
      alt: "betting predictions",
    },
    {
      image: "/images/coupon-4.jpg",
      alt: "draftkings odds",
    },
    {
      image: "/images/coupon-5.jpg",
      alt: "How to bet for win",
    },
    {
      image: "/images/coupon-6.jpg",
      alt: "How to bet for win",
    },
    
  ];
  const settings = {
    dots: true,
    infinite: true,
    draggable: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "50px",
        },
      },
    ],
  };
  return (
    <div className={`coupons_slider_main ${mode === "Light" && "light_theme"}`}>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Slider {...settings} aria-hidden="false">
              {couponData.map((d, index) => (
                <div key={index} className="d-flex justify-content-center">
                  <img src={d.image} style={{ width: "95%",height:"160px" }} alt={d?.alt} />
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CouponsSlider;
