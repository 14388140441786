import { apiRequest } from "../Utils/apiRequest";
import { aboutRoute, promosRoute } from "../Utils/routes";

const Abouts = {
  about: () => {
    return apiRequest(aboutRoute.getAboutUs);
  },
  createAbout: (data) => {
    return apiRequest(aboutRoute.createAbout, "POST", data, true);
  },
  updateAbout: (data) => {
    return apiRequest(aboutRoute.updateAbout, "PUT", data, true);
  },
  deleteAbout: (id) => {
    return apiRequest(`${aboutRoute.deleteAbout}/${id}`, "DELETE");
  },
};

export default Abouts;
