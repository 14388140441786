import moment from "moment";

export const getScheduleTime = (date) => {
  // return moment(new Date(date + "Z")).format("dddd MMMM Do [@] h:mm A");
  return moment.utc(date).local().format("dddd MMMM Do [@] h:mm A");
};

export const getGameTime = (date) => {
  return moment(date).calendar(null, {
    sameDay: "[Today] h:mm A",
    nextDay: "[Tomorrow] h:mm A",
    nextWeek: "dddd h:mm A",
    lastDay: "[Yesterday] h:mm A",
    lastWeek: "[Last] dddd h:mm A",
    sameElse: "MMMM Do YYYY, h:mm A",
  });
};

export const getMonthAndDay = (date) => {
  return moment(date).format("MMMM D");
};

export const getHourMonth = (date) => {
  return moment.utc(date).local().format("h:mm");
};

export const getTeamPageScheduleTime = (date) => {
  return moment(date).format("dddd h:mm A");
};

export const getTeamPagePreviousGameTime = (date) => {
  return moment(date).format("MMMM D, h:mm A z");
};
