import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    themeMode: "Dark",
    favourites: [],
    notifications: [],
    playerNotifications: [],
    teamNotifications: [],
    gameNotifications: [],
    users:[],
    subAdmins:[],
    adminUser:null
  },
  reducers: {
    saveAuth: (state, action) => {
      state.user = action.payload;
    },
    updateThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    updateFavourite: (state, action) => {
      state.favourites = action.payload;
    },
    updateNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    updatePlayerNotifications: (state, action) => {
      state.playerNotifications = action.payload;
    },
    updateTeamNotifications: (state, action) => {
      state.teamNotifications = action.payload;
    },
    updateGameNotifications: (state, action) => {
      state.gameNotifications = action.payload;
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    updateIcon: (state, action) => {
      state.icon = action.payload;
    },
    logoutAuth: (state) => {
      state.user = null;
    },
    updateUsers: (state, action) => {
      state.users = action.payload;
    },
    updateSubAdmins: (state, action) => {
      state.subAdmins = action.payload;
    },
    updateAdminUser: (state, action) => {
      state.adminUser = action.payload;
    },
  },
});

export default authSlice;
