import React, { useState, useEffect } from "react";
import HomeMatches from "../../Components/Home/HomeMatches/HomeMatches";
import {
  useSettings,
  useSport,
  useTab,
} from "../../Redux/Accessors/game.accessors";
import Games from "../../Services/Games";
import { getTab } from "../../Utils/getStats";
import Notification from "../../Services/Notification";

const PremiumMatches = ({ slideIndex, schedule, isLoading, isPremiumPage }) => {
  const tab = useTab();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const sport = useSport() || defaultSport;

  const [homeTeamData, setHomeTeamData] = useState(null);
  const [awayTeamData, setAwayTeamData] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [isPlayerDataLoading, setIsPlayerDataLoading] = useState(false);
  const [onUpdate, setOnUpdate] = useState(false);
  const [slide, setSlide] = useState(slideIndex);

  const handleSlider = (val) => {
    setSlide(val);
    localStorage.setItem("slider", val);
  };

  const fetchTeamPlayerStats = async (key, data) => {
    setHomeTeamData(null);
    setAwayTeamData(null);
    // updateSport(schedule?.sport);
    try {
      setIsPlayerDataLoading(true);
      const res = await Games.playerStats(data);
      if (key === "HOME") {
        setHomeTeamData(res.data.data);
      } else {
        setAwayTeamData(res.data.data);
      }
    } catch (error) {
      setHomeTeamData(null);
      setAwayTeamData(null);
      setIsPlayerDataLoading(false);
      console.error("An error occurred while fetching player stats:", error);
    } finally {
      setIsPlayerDataLoading(false);
    }
  };

  useEffect(() => {
    if (schedule?._id && schedule?.homeTeam?._id && schedule?.awayTeam?._id) {
      fetchTeamPlayerStats("HOME", {
        gameId: schedule?._id,
        teamId: schedule?.homeTeam?._id,
        bettingBetType: getTab(tab),
      });

      fetchTeamPlayerStats("AWAY", {
        gameId: schedule?._id,
        teamId: schedule?.awayTeam?._id,
        bettingBetType: getTab(tab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, tab, schedule]);

  const fetchGameNotifications = async (id) => {
    try {
      setIsNotificationLoading(true);
      const res = await Notification.getGameNotifications(id, sport, 0, 50);
      setNotifications(res?.data?.data);
      setIsNotificationLoading(false);
    } catch (error) {
      console.log("Error in getting game notifications");
    } finally {
      setIsNotificationLoading(false);
    }
  };

  useEffect(() => {
    if (schedule?._id && schedule?.homeTeam?._id && schedule?.awayTeam?._id) {
      fetchGameNotifications(schedule?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, onUpdate]);

  // useEffect(() => {
  //   updateSportTab(sport, updateTab, tab);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sport]);

  return (
    <div>
      <HomeMatches
        slide={slide}
        handleSlider={(val) => handleSlider(val)}
        homeTeamData={homeTeamData}
        awayTeamData={awayTeamData}
        scheduleGames={[schedule]}
        isLoading={isPlayerDataLoading}
        isNotificationLoading={isNotificationLoading}
        data={notifications}
        onUpdate={onUpdate}
        setOnUpdate={setOnUpdate}
        isPremiumPage={isPremiumPage}
      />
    </div>
  );
};

export default PremiumMatches;
