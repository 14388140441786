import React, { useState } from "react";
import { ThemeState } from "../../Context";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import useDeviceDetect from "../../Hooks/useIsMobile";

const Info = ({ status = "" }) => {
  const { mode } = ThemeState();

  const [showTooltip, setShowTooltip] = useState(false);
  const { isMobile } = useDeviceDetect();

  const hideTooltip = () => {
    if (isMobile) {
      setShowTooltip(false);
    }
  };

  const infotampTooltip = (
    <Tooltip
      id="timestamp-tooltip"
      className={`info-desktop tooltip_new ${
        mode === "Light" && "light_theme"
      }`}
      show={showTooltip}
    >
      <div onClick={() => hideTooltip()}>
        <h3>Tip: </h3>
        <p>
          Click any Player/Team for <br /> in-depth research
        </p>
        <AiFillCloseCircle
          className="info-close"
          onClick={() => hideTooltip()}
        />
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      className="info-desktop"
      placement="top"
      overlay={infotampTooltip}
      rootClose={true}
      trigger={isMobile ? [] : ["hover", "focus"]} // Conditional trigger
      show={isMobile ? showTooltip : undefined}
    >
      <div onClick={() => setShowTooltip(!showTooltip)}>
        {status !== "playerHeader" ? (
          mode === "Light" ? (
            <img
              src="/images/info-light.png"
              alt="info"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <img
              src="/images/info.png"
              alt="info"
              style={{ cursor: "pointer" }}
            />
          )
        ) : mode !== "Light" ? (
          <img
            src="/images/info-light.png"
            alt="info"
            style={{ cursor: "pointer" }}
          />
        ) : (
          <img
            src="/images/info.png"
            alt="info"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </OverlayTrigger>
  );
};

export default Info;
