import React, { useEffect, useRef } from "react";

const NotificationSearch = ({
  players,
  setPlayerName,
  searchByPlayer,
  showDropdown,
  setShowDropdown,
}) => {
  const suggestionRef = useRef(null);

  const handleClick = (id) => {
    setPlayerName("");
    searchByPlayer(id);
    setShowDropdown(false);
  };

  useEffect(() => {
    if (players.length) setShowDropdown(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [players]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionRef]);

  return (
    <div ref={suggestionRef} className="w-100 suggestion-wrapper">
      {players?.map((player, index) => (
        <p key={index} onClick={() => handleClick(player?._id)}>
          <span>{player?.sport}</span> -
          <img
            src={player?.team?.wikipediaLogoUrl}
            alt="logo"
            style={{
              width: "14px",
              height: "14px",
              marginLeft: "4px",
              marginRight: "4px",
            }}
          />
          {player?.firstName} {player?.lastName} {player?.position && "-"}{" "}
          {player?.position}
        </p>
      ))}
    </div>
  );
};

export default NotificationSearch;
