import React from "react";
import Header from "../../Components/User/Header/Header";
import { Outlet } from "react-router-dom";
import { SuperAdminSideBarItems } from "../SuperAdmin/Routes";
import { useUser } from "../../Redux/Accessors/auth.accessors";
const SuperAdminLayout = () => {
  const { user } = useUser();
  // console.log(user,"user")
  // let userAuth = JSON.parse(localStorage.getItem("user"));

  return (
    <React.Fragment>
      {user?.user.role === "admin" ? (
        <Header sideBarItems={SuperAdminSideBarItems}>
          <Outlet />
        </Header>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default SuperAdminLayout;
