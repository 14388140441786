import axios from "axios";

const getToken = () => {
    const token = localStorage.getItem("token");
    return JSON.parse(token)
};

export async function apiRequest(url, method = "GET", data = {},formData) {
  const token = getToken();

  const config = {
    url: `${url}`,
    method: method,
    ...(method !== "GET" && {
      data,
      headers: formData ? {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      }:{
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }),
    ...(method === "GET" && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  };

  return axios(config);
}
