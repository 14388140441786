import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeState } from "../../Context";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SiteModal from "../Modal/SiteModal";
import { cloudURL } from "../../Utils/helper";

const AboutContent = ({ content }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [modalImg, setModalImg] = useState("");
  console.log(content);
  const onCloseModal = () => {
    setShow(false);
  };
  const handleOpenModel = (image) => {
    setModalImg(`${cloudURL}/${image}`);
    
    setShow(true);
  };
  return (
    <>
      <div className="about_content_main">
        <Container fluid>
          <Row className="about_section justify-content-center">
            {content &&
              content.map((c, i) => (
                <Col md={10} key={i}>
                  <h4>{c.heading}</h4>
                  <p>{c.text}</p>
                  <div>
                    <button onClick={() => handleOpenModel(c.image?.filename)}>
                      PREVIEW
                    </button>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
      <SiteModal
        show={show}
        setShow={setShow}
        onCloseModal={onCloseModal}
        size="lg"
      >
        <img src={modalImg} />
      </SiteModal>
    </>
  );
};

export default AboutContent;
