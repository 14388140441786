import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../../Context";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { useParams, useNavigate } from "react-router-dom";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import StatsBox from "../../../Common/StatsBox";
import {
  getDataValues,
  getTab,
  isResultGreater,
} from "../../../Utils/getStats";
import ViewMore from "../../../Common/ViewMore";
import Games from "../../../Services/Games";
import Alert from "../../../Common/Alert";
import Info from "../../../Common/Info";
import { playerNavigator } from "../../../Utils/navigator";

const AccordianBody = ({ game, teamId }) => {
  const { id } = useParams();
  const tab = useTab();
  const { mode } = ThemeState();
  const navigate = useNavigate();

  const [playerStats, setPlayerStats] = useState(null);
  const [viewMore, setViewMore] = useState(false);

  // const handleNavigate = (id) => {
  //   navigate(`/player/${id}`);
  // };

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.playerStats(data);
      setPlayerStats(res.data.data);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    fetchTeamPlayerStats({
      gameId: game?._id,
      teamId,
      bettingBetType: getTab(tab),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  const filteredTeamData =
    playerStats?.filter((game) => !getDataValues(game, tab)) || [];

  return (
    <div>
      <div
        className={`player_stats_main team_assist_table  ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div className="player_assets_header ">
          <Row>
            <Col md={4} className="header_left_section">
              <div className="d-flex justify-content-between align-items-center">
                <h3
                  className={`ps-4 py-2  ${
                    mode === "Light" ? "col-green" : "col-yellow"
                  }`}
                >
                  {tab}
                </h3>
                <h3
                  className={`pe-4 py-2 ${
                    mode === "Light" ? "text-black" : "text-white"
                  }`}
                >
                  Results
                </h3>
              </div>
            </Col>
            <Col md={8} className="header_right_section">
              <Row className="align-items-center assets_images teams_section">
                <Col md={2}>
                  <img src="/images/fanduel.png" alt="fanduel sportsbook" />
                </Col>
                <Col md={2}>
                  {mode === "Light" ? (
                    <img
                      src="/images/draft-light.png"
                      style={{ height: "34px" }}
                      alt="logo"
                    />
                  ) : (
                    <img
                      src="/images/draft.png"
                      style={{ height: "34px" }}
                      alt="draftkings sportsbook"
                    />
                  )}
                </Col>
                <Col md={2}>
                  <img
                    src="/images/betmgm.png"
                    style={{ height: "40px" }}
                    alt="betmgm sportsbook"
                  />
                </Col>
                <Col md={2}>
                  {mode === "Light" ? (
                    <img
                      src="/images/caesars-light.png"
                      style={{ height: "20px" }}
                      alt="logo"
                    />
                  ) : (
                    <img
                      src="/images/caesars.png"
                      style={{ height: "20px" }}
                      alt="caesars sportbook"
                    />
                  )}
                </Col>
                <Col md={2}>
                  <img
                    src="/images/bet365_img.png"
                    style={{ height: "22px" }}
                    // src="/images/bwin_img.png" style={{ height: "22px" }}
                    alt="bet365 bonus code"
                  />
                </Col>
                <Col md={2}>
                  {mode === "Light" ? (
                    <img
                      src="/images/pointsbet-light.png"
                      style={{ height: "45px" }}
                      alt="logo"
                    />
                  ) : (
                    <img
                      src="/images/pointsbet.png"
                      style={{ height: "45px" }}
                      alt="pointsbet sportsbook"
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={`player_assets_body teams_section ${
            mode === "Light" && "light_theme"
          }`}
        >
          {filteredTeamData &&
            filteredTeamData
              ?.slice(0, viewMore ? filteredTeamData?.length : 3)
              .map((game, index) => {
                const allStatsNull = getDataValues(game, tab);

                if (allStatsNull) {
                  return null;
                }

                return (
                  <Row key={index}>
                    <Col
                      md={4}
                      className="d-flex flex-column justify-content-center ps-4 gap-2 body_left_section"
                      style={{ position: "relative" }}
                    >
                      <h3
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          playerNavigator(game?.player, navigate, updateSport)
                        }
                      >
                        {game?.player?.firstName} {game?.player?.lastName}
                      </h3>
                      <div className="d-flex gap-3 align-items-center">
                        <Alert playerId={game?.player?._id} />
                        <Info />
                      </div>

                      <div
                        className="d-flex align-items-center"
                        style={
                          isResultGreater(game)
                            ? {
                                position: "absolute",
                                right: "20px",
                                top: "26px",
                                color: "#5caa00",
                              }
                            : {
                                position: "absolute",
                                right: "20px",
                                top: "26px",
                                color: "#FF003A",
                              }
                        }
                      >
                        <p
                          className={
                            mode === "Light" &&
                            isResultGreater(game) &&
                            "text-black"
                          }
                        >
                          {game?.result || 0}
                        </p>
                        {isResultGreater(game) ? (
                          <HiOutlineArrowNarrowUp />
                        ) : (
                          <HiOutlineArrowNarrowDown />
                        )}
                      </div>
                    </Col>
                    <Col md={8} className="body_right_section">
                      <Row className="align-items-center assets_images">
                        <Col md={2}>
                          <StatsBox playerStats={game?.fanduel} />
                        </Col>
                        <Col md={2}>
                          <StatsBox playerStats={game?.draftkings} />
                        </Col>
                        <Col md={2}>
                          <StatsBox playerStats={game?.betmgm} />
                        </Col>
                        <Col md={2}>
                          <StatsBox playerStats={game?.caesars} />
                        </Col>
                        <Col md={2}>
                          <StatsBox playerStats={game?.consensus} />
                        </Col>
                        <Col md={2}>
                          <StatsBox playerStats={game?.pointsbet} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
        </div>
      </div>
      <div
        className={`d-flex justify-content-center pt-3 view_more_btn ${
          mode === "Light" && "light_theme"
        }`}
      >
        <ViewMore viewMore={viewMore} setViewMore={setViewMore} />
      </div>
    </div>
  );
};

export default AccordianBody;
