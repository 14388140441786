export const SPORTS = {
  NBA: "NBA",
  MLB: "MLB",
  NFL: "NFL",
  NHL: "NHL",
};

export const sports = ["NFL", "NBA", "MLB", "NHL"];
export const defaultSport = SPORTS.NFL;

export const avatars = [
  "male_avatar_1.png",
  "male_avatar_2.jpeg",
  "female_avatar_1.png",
  "female_avatar_2.png",
];

export const states = [
  { value: "", label: "State" },
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District Of Columbia", label: "District Of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
  { value: "Other", label: "Other" },
];

export const NBA_TABS = [
  {
    tab: "Points",
  },
  {
    tab: "Rebounds",
  },
  {
    tab: "Assists",
  },
  {
    tab: "Threes",
  },
  {
    tab: "Steals",
  },
  {
    tab: "Blocks",
  },
  {
    tab: "Pts + Ast",
  },
  {
    tab: "Pts + Rebs",
  },
  {
    tab: "Pts + Rebs + Ast",
  },
];

export const MLB_BATTING_TABS = [
  {
    tab: "Home Runs",
  },
  {
    tab: "1 + Hits",
  },
  {
    tab: "1 + Runs",
  },
  {
    tab: "1 + RBIs",
  },
  {
    tab: "Stolen Bases",
  },
];

export const MLB_PITCHING_TABS = [
  {
    tab: "Strikeouts",
  },
  {
    tab: "Earned Runs",
  },
];

export const NFL_TABS = [
  {
    tab: "Anytime TD",
  },
  {
    tab: "Passing Yds",
  },
  {
    tab: "Passing TD",
  },
  {
    tab: "Rushing Yds",
  },
  {
    tab: "Receptions",
  },
  {
    tab: "Receiving Yds",
  },
  {
    tab: "First TD",
  },
];

export const dataCompanies = {
  fanduel: "fanduel",
  draftkings: "draftkings",
  betmgm: "betmgm",
  caesars: "caesars",
  consensus: "consensus",
  pointsbet: "pointsbet",
};

export const MLB_TABS = [
  "Home Runs",
  "1 + Hits",
  "1 + Runs",
  "1 + RBIs",
  "Stolen Bases",
];

export const PAGE_HEADER_TABS = {
  HOME: "HOME",
  PLAYER: "PLAYER",
  TEAM: "TEAM",
  NFL: "NFL",
  NBA: "NBA",
  MLB: "MLB",
  NHL: "NHL",
  PROMOS: "PROMOS",
  COMPARE: "COMPARE",
  ABOUT_US: "ABOUT_US",
  PREMIUM: "PREMIUM",
  CONTACT_US: "CONTACT_US",
  ADVERTISE: "ADVERTISE",
  NOTIFICATIONS: "NOTIFICATIONS",
  ARIZONA_CARDINALS_NFL: "ARIZONA_CARDINALS_NFL",
  ATLANTA_FALCONS_NFL: "ATLANTA_FALCONS_NFL",
  BALTIMORE_RAVENS_NFL: "BALTIMORE_RAVENS_NFL",
  BUFFALO_BILLS_NFL: "BUFFALO_BILLS_NFL",
  CAROLINA_PANTHERS_NFL: "CAROLINA_PANTHERS_NFL",
  CHICAGO_BEARS_NFL: "CHICAGO_BEARS_NFL",
  CINCINNATI_BENGALS_NFL: "CINCINNATI_BENGALS_NFL",
  CLEVELAND_BROWNS_NFL: "CLEVELAND_BROWNS_NFL",
  DALLAS_COWBOYS_NFL: "DALLAS_COWBOYS_NFL",
  DENVER_BRONCOS_NFL: "DENVER_BRONCOS_NFL",
  GREEN_BAY_PACKERS: "GREEN_BAY_PACKERS",
  HOUSTON_TEXANS_NFL: "HOUSTON_TEXANS_NFL",
  INDIANAPOLIS_COLTS_NFL: "INDIANAPOLIS_COLTS_NFL",
  JACKSONVILLE_ANALYZING_JAGUARS: "JACKSONVILLE_ANALYZING_JAGUARS",
  DETROIT_LIONS_NFL: "DETROIT_LIONS_NFL",
  KANSAS_CITY_CHIEFS: "KANSAS_CITY_CHIEFS",
  LAS_VEGAS_RAIDERS: "LAS_VEGAS_RAIDERS",
  LOS_ANGELES_TOP: "LOS_ANGELES_TOP",
  LOS_ANGELES_RAMS: "LOS_ANGELES_RAMS",
  MIAMI_DOLPHINS_NFL: "MIAMI_DOLPHINS_NFL",
  MINNESOTA_VIKINGS_NFL: "MINNESOTA_VIKINGS_NFL",
  NEW_ENGLAND_PATRIOTS: "NEW_ENGLAND_PATRIOTS",
  NEW_ORLEANS_SAINTS: "NEW_ORLEANS_SAINTS",
  NEW_YORK_GIANTS: "NEW_YORK_GIANTS",
  NEW_YORK_JETS: "NEW_YORK_JETS",
  PHILADELPHIA_EAGLES_NFL: "PHILADELPHIA_EAGLES_NFL",
  PITTSBURGH_STEELERS_NFL: "PITTSBURGH_STEELERS_NFL",
  SAN_FRANCISCO_49ERS: "SAN_FRANCISCO_49ERS",
  SEATTLE_SEAHAWKS_NFL: "SEATTLE_SEAHAWKS_NFL",
  TAMPA_BAY_BUCCANEERS: "TAMPA_BAY_BUCCANEERS",
  TENNESSEE_TITANS_NFL: "TENNESSEE_TITANS_NFL",
  WASHINGTON_COMMANDERS_NFL: "WASHINGTON_COMMANDERS_NFL",
  ATLANTA_HAWKS_NBA: "ATLANTA_HAWKS_NBA",
  BOSTON_CELTICS_NBA: "BOSTON_CELTICS_NBA",
  BROOKLYN_NETS_NBA: "BROOKLYN_NETS_NBA",
  CHARLOTTE_HORNETS_NBA: "CHARLOTTE_HORNETS_NBA",
  CHICAGO_BULLS_NBA: "CHICAGO_BULLS_NBA",
  CLEVELAND_CAVALIERS_NBA: "CLEVELAND_CAVALIERS_NBA",
  DALLAS_MAVERICKS_NBA: "DALLAS_MAVERICKS_NBA",
  DENVER_NUGGETS_NBA: "DENVER_NUGGETS_NBA",
  DETROIT_PISTONS_NBA: "DETROIT_PISTONS_NBA",
  GOLDEN_STATE_WARRIORS: "GOLDEN_STATE_WARRIORS",
  HOUSTON_ROCKETS_NBA: "HOUSTON_ROCKETS_NBA",
  INDIANA_PACERS_NBA: "INDIANA_PACERS_NBA",
  LOS_ANGELES_CLIPPERS: "LOS_ANGELES_CLIPPERS",
  LOS_ANGELES_LAKERS: "LOS_ANGELES_LAKERS",
  MEMPHIS_GRIZZLIES_NBA: "MEMPHIS_GRIZZLIES_NBA",
  MIAMI_HEAT_NBA: "MIAMI_HEAT_NBA",
  MILWAUKEE_BUCKS_NBA: "MILWAUKEE_BUCKS_NBA",
  MINNESOTA_TIMBERWOLVES_NBA: "MINNESOTA_TIMBERWOLVES_NBA",
  NEW_ORLEANS_PELICANS: "NEW_ORLEANS_PELICANS",
  NEW_YORK_KNICKS: "NEW_YORK_KNICKS",
  OKLAHOMA_CITY_THUNDER: "OKLAHOMA_CITY_THUNDER",
  ORLANDO_MAGIC_NBA: "ORLANDO_MAGIC_NBA",
  PHILADELPHIA_76ERS_NBA: "PHILADELPHIA_76ERS_NBA",
  PHOENIX_SUNS_NBA: "PHOENIX_SUNS_NBA",
  PORTLAND_TRAIL_BLAZERS: "PORTLAND_TRAIL_BLAZERS",
  SACRAMENTO_KINGS_NBA: "SACRAMENTO_KINGS_NBA",
  SAN_ANTONIO_SPURS: "SAN_ANTONIO_SPURS",
  TORONTO_RAPTORS_NBA: "TORONTO_RAPTORS_NBA",
  UTAH_JAZZ_NBA: "UTAH_JAZZ_NBA",
  WASHINGTON_WIZARDS_NBA: "WASHINGTON_WIZARDS_NBA",
  ARIZONA_DIAMONDBACKS_MLB: "ARIZONA_DIAMONDBACKS_MLB",
  ATLANTA_BRAVES_MLB: "ATLANTA_BRAVES_MLB",
  BALTIMORE_ORIOLES_MLB: "BALTIMORE_ORIOLES_MLB",
  BOSTON_RED_SOX: "BOSTON_RED_SOX",
  CHICAGO_CUBS_MLB: "CHICAGO_CUBS_MLB",
  CINCINNATI_REDS_MLB: "CINCINNATI_REDS_MLB",
  CLEVELAND_GUARDIANS_MLB: "CLEVELAND_GUARDIANS_MLB",
  COLORADO_ROCKIES_MLB: "COLORADO_ROCKIES_MLB",
  DETROIT_TIGERS_MLB: "DETROIT_TIGERS_MLB",
  HOUSTON_ASTROS_MLB: "HOUSTON_ASTROS_MLB",
  KANSAS_CITY_ROYALS: "KANSAS_CITY_ROYALS",
  LOS_ANGELES_DODGERS: "LOS_ANGELES_DODGERS",
  LOS_ANGELES_ANGELS: "LOS_ANGELES_ANGELS",
  MIAMI_MARLINS_MLB: "MIAMI_MARLINS_MLB",
  MILWAUKEE_BREWERS_MLB: "MILWAUKEE_BREWERS_MLB",
  MINNESOTA_TWINS_MLB: "MINNESOTA_TWINS_MLB",
  NEW_YORK_METS: "NEW_YORK_METS",
  NEW_YORK_YANKEES: "NEW_YORK_YANKEES",
  OAKLAND_ATHLETICS_MLB: "OAKLAND_ATHLETICS_MLB",
  PHILADELPHIA_PHILLIES_MLB: "PHILADELPHIA_PHILLIES_MLB",
  PITTSBURGH_PIRATES_MLB: "PITTSBURGH_PIRATES_MLB",
  SAN_DIEGO_PADRES: "SAN_DIEGO_PADRES",
  SAN_FRANCISCO_GIANTS: "SAN_FRANCISCO_GIANTS",
  SEATTLE_MARINERS_MLB: "SEATTLE_MARINERS_MLB",
  ST_LOUIS_CARDINALS: "ST_LOUIS_CARDINALS",
  TAMPA_BAY_RAYS: "TAMPA_BAY_RAYS",
  TEXAS_RANGERS_MLB: "TEXAS_RANGERS_MLB",
  TORONTO_BLUE_JAYS: "TORONTO_BLUE_JAYS",
  WASHINGTON_NATIONALS_MLB: "WASHINGTON_NATIONALS_MLB",
  LOS_ANGELES_ANGELS: "LOS_ANGELES_ANGELS",
};

export const META_TAGS = {
  [PAGE_HEADER_TABS.HOME]: {
    title: "Elite Sports Betting: NBA, NHL, MLB, NFL Props | Bet and Win Big!",
    description:
      "Discover Winning Prop Bets for NBA, NHL, MLB, and NFL! Get an Edge with Our Expertly Simulated Free Prop Picks. Bet Smarter Today!",
  },
  [PAGE_HEADER_TABS.NOTIFICATIONS]: {
    title: "Stay Informed with Betting Notifications - Player Totals",
    description:
      "Receive real-time betting notifications and stay updated on the latest odds and predictions. Never miss a winning opportunity again!",
  },
  [PAGE_HEADER_TABS.PLAYER]: {
    title: "Player Analysis and Betting Tips -Player Totals",
    description:
      "Explore in-depth player analysis and betting tips for your favorite athletes. Make informed bets and increase your chances of winning.",
  },
  [PAGE_HEADER_TABS.TEAM]: {
    title: "Team Insights and Betting Strategies",
    description:
      "Discover comprehensive team insights and betting strategies to help you make smart wagers. Maximize your profits with our expert advice.",
  },
  [PAGE_HEADER_TABS.NFL]: {
    title: "NFL Player Prop Bets: Winning Strategies & Expert Predictions",
    description:
      "Explore expert NFL player prop bet predictions and analysis. Your winning edge for NFL betting.",
  },
  [PAGE_HEADER_TABS.NBA]: {
    title: "NBA Player Prop Bets: Elevate Your Betting Game with Insights",
    description:
      "Elevate your NBA betting game with our player prop bet insights and tips. Get the advantage you need.",
  },
  [PAGE_HEADER_TABS.MLB]: {
    title: "MLB Player Prop Bets: Uncover Valuable Predictions and Analysis",
    description:
      "Uncover valuable MLB player prop bet recommendations and odds analysis. Maximize your betting potential.",
  },
  [PAGE_HEADER_TABS.NHL]: {
    title: "NHL Player Prop Bets: Stay Ahead Qith Expert Forecasts",
    description:
      "Dive into NHL player prop bet forecasts and strategies. Stay ahead in the world of NHL betting.",
  },
  [PAGE_HEADER_TABS.PROMOS]: {
    title: "Exclusive Betting Promotions: Boost Your Bankroll Today",
    description:
      "Discover the latest betting promos and offers. Boost your bankroll and enhance your betting experience.",
  },
  [PAGE_HEADER_TABS.COMPARE]: {
    title: "Betting Site Comparison: Find the Best Odds and Features",
    description:
      "Compare top betting sites, odds, and features. Make informed choices for your sports betting journey.",
  },
  [PAGE_HEADER_TABS.ABOUT_US]: {
    title: "About Us - Your Trusted Source For Betting Insights",
    description:
      "Get to know us, your trusted source for player prop bet insights. Learn about our expertise and commitment to your success.",
  },
  [PAGE_HEADER_TABS.PREMIUM]: {
    title: "Unlock Premium Betting Insights And Tips",
    description:
      "Gain access to our premium betting content, expert predictions, and exclusive tips. Elevate your betting experience with our premium offerings.",
  },
  [PAGE_HEADER_TABS.CONTACT_US]: {
    title: "Contact Us for Expert Betting Predictions and Advice",
    description:
      "Whether you're looking for NBA, NHL, MLB, or NFL props predictions, we're here to provide you with valuable insights and assistance. Reach out today and enhance your sports betting experience.",
  },
  [PAGE_HEADER_TABS.ADVERTISE]: {
    title: "Advertise with Player Totals Today",
    description:
      "Interested in advertising with us? We'd love to hear from you. Get in touch with us today to learn more.",
  },
  [PAGE_HEADER_TABS.ARIZONA_CARDINALS_NFL]: {
    title: "Arizona Cardinals Stats, Player Props, and Betting Predictions",
    description:
      "Explore Arizona Cardinals player totals, exciting player props, and winning betting predictions for the ultimate NFL experience.",
  },
  [PAGE_HEADER_TABS.ATLANTA_FALCONS_NFL]: {
    title: "Atlanta Falcons Stats, Player Props, and Betting Predictions",
    description:
      "Discover Atlanta Falcons player totals, dynamic player props, and expert betting predictions for NFL enthusiasts.",
  },
  [PAGE_HEADER_TABS.BALTIMORE_RAVENS_NFL]: {
    title: "Ravens' Roost: Baltimore Player Stats, Props, & Betting Tips",
    description:
      "Dive into Baltimore Ravens player stats, intriguing player props, and top-notch betting tips for avid NFL followers.",
  },
  [PAGE_HEADER_TABS.BUFFALO_BILLS_NFL]: {
    title: "Buffalo Bills Stats, Player Props, and Winning Bets",
    description:
      "Explore Buffalo Bills player statistics, exciting player props, and tips for successful NFL betting.",
  },
  [PAGE_HEADER_TABS.CAROLINA_PANTHERS_NFL]: {
    title:
      "Carolina Panthers: Your One-Stop Shop for Betting Tips and Predictions",
    description:
      "Get the latest betting tips and predictions for the Carolina Panthers from our team of experts. We analyze all the data to give you the best chance of winning your bets.",
  },
  [PAGE_HEADER_TABS.CHICAGO_BEARS_NFL]: {
    title: "Bear Down with Chicago: Player Stats, Props, & Winning Tips",
    description:
      "Immerse yourself in Chicago Bears player statistics, captivating player props, and insider tips for triumphant NFL bets.",
  },
  [PAGE_HEADER_TABS.CINCINNATI_BENGALS_NFL]: {
    title:
      "Cincinnati Bengals: Betting Tips and Predictions for the 2023 NFL Season | Player Totals",
    description:
      "Get the latest betting tips and predictions for the Cincinnati Bengals from our team of experts. Find out who the key players.",
  },
  [PAGE_HEADER_TABS.CLEVELAND_BROWNS_NFL]: {
    title: "Cleveland Browns Betting Tips | Player Totals",
    description:
      "Get the latest betting tips for the Cleveland Browns from our team of experts.",
  },
  [PAGE_HEADER_TABS.DALLAS_COWBOYS_NFL]: {
    title:
      "Star-Struck Betting: Dallas Cowboys' Player Insights & Winning Props",
    description:
      "Unlock the secrets of success with Dallas Cowboys player statistics, thrilling player props, and unique strategies for winning NFL bets.",
  },
  [PAGE_HEADER_TABS.DENVER_BRONCOS_NFL]: {
    title:
      "Mile High Excellence: Denver Broncos Player Stats and Betting Insights",
    description:
      "Elevate your game with Denver Broncos player statistics, valuable betting insights, and strategies for NFL success.",
  },
  [PAGE_HEADER_TABS.DETROIT_LIONS_NFL]: {
    title: "Lionhearted Insights: Detroit Lions Player Stats & Winning Edge",
    description:
      "Unearth the heart of the game with Detroit Lions player statistics, distinctive player props, and the winning edge for NFL betting.",
  },
  [PAGE_HEADER_TABS.GREEN_BAY_PACKERS]: {
    title: "Green Bay Packers: Player Metrics, Props, and Betting Prowess",
    description:
      "Explore Green Bay Packers' player metrics, intriguing prop bets, and strategies for betting prowess in the NFL.",
  },
  [PAGE_HEADER_TABS.HOUSTON_TEXANS_NFL]: {
    title: "Houston Texans Player Stats, Prop Bets, and Betting Success",
    description:
      "Discover the secrets to success with Houston Texans player statistics, unique prop bets, and expert tips for triumphant NFL betting.",
  },
  [PAGE_HEADER_TABS.INDIANAPOLIS_COLTS_NFL]: {
    title: "Indianapolis Colts Secrets: Stats, Props, and Winning Wagers",
    description:
      "Unlock the hidden treasures of Indianapolis Colts' player stats, enticing prop bets, and strategies for victorious NFL wagers.",
  },
  [PAGE_HEADER_TABS.JACKSONVILLE_ANALYZING_JAGUARS]: {
    title:
      "Jaguars in Action: Jacksonville Player Statistics, Props, and Betting Success",
    description:
      "Witness Jacksonville Jaguars' player metrics, exciting prop bets, and the roadmap to NFL betting victory.",
  },
  [PAGE_HEADER_TABS.KANSAS_CITY_CHIEFS]: {
    title:
      "Chiefs' Playbook: Kansas City Player Stats, Props, and Winning Bets",
    description:
      "Explore the Kansas City Chiefs' player statistics, captivating prop bets, and strategies for a winning edge in NFL betting",
  },
  [PAGE_HEADER_TABS.LAS_VEGAS_RAIDERS]: {
    title:
      "Raiders of the Gridiron: Las Vegas Player Stats, Props, and Betting Wins",
    description:
      "Join the adventure with Las Vegas Raiders' player stats, exciting prop bets, and the keys to winning big in NFL betting",
  },
  [PAGE_HEADER_TABS.LOS_ANGELES_TOP]: {
    title:
      "Charging to Victory: Los Angeles Player Stats, Props, and Betting Triumph",
    description:
      "Power up with Los Angeles Chargers' player statistics, electrifying prop bets, and the path to triumph in NFL betting",
  },
  [PAGE_HEADER_TABS.LOS_ANGELES_RAMS]: {
    title:
      "Rams Rivals: Los Angeles Player Stats, Props, and Betting Dominance",
    description:
      "Unlock the playbook with Los Angeles Rams' player stats, enticing prop bets, and the formula for NFL betting dominance",
  },
  [PAGE_HEADER_TABS.MIAMI_DOLPHINS_NFL]: {
    title:
      "Dive into Success: Miami Dolphins Player Stats, Props, and Betting Strategies",
    description:
      "Swim with the Miami Dolphins as we explore player statistics, intriguing prop bets, and expert betting strategies for NFL victory",
  },
  [PAGE_HEADER_TABS.MINNESOTA_VIKINGS_NFL]: {
    title:
      "Viking Voyages: Minnesota Player Stats, Props, and Winning Strategies",
    description:
      "Embark on a journey with Minnesota Vikings' player stats, captivating prop bets, and the path to NFL betting success",
  },
  [PAGE_HEADER_TABS.NEW_ENGLAND_PATRIOTS]: {
    title:
      "Patriots Unleashed: New England Player Stats, Props, and Betting Triumph",
    description:
      "Join the winning ranks with New England Patriots' player statistics, thrilling prop bets, and the blueprint for NFL betting triumph",
  },
  [PAGE_HEADER_TABS.NEW_ORLEANS_SAINTS]: {
    title:
      "Saints in Action: New Orleans Player Stats, Props, and Betting Excellence",
    description:
      "Get in the game with New Orleans Saints' player statistics, exciting prop bets, and strategies for NFL betting excellence",
  },
  [PAGE_HEADER_TABS.NEW_YORK_GIANTS]: {
    title: "Giants' Glory: New York Player Stats, Props, and Betting Success",
    description:
      "Experience success with New York Giants' player statistics, captivating prop bets, and a winning formula for NFL betting",
  },
  [PAGE_HEADER_TABS.NEW_YORK_JETS]: {
    title: "Jet-Set to Victory: New York Player Stats, Props, and Betting Wins",
    description:
      "Take off with New York Jets' player statistics, exciting prop bets, and the path to NFL betting success",
  },
  [PAGE_HEADER_TABS.PHILADELPHIA_EAGLES_NFL]: {
    title:
      "Eagles Soar: Philadelphia Player Stats, Props, and Betting Brilliance",
    description:
      "Witness Philadelphia Eagles' player statistics, captivating prop bets, and the path to betting brilliance in the NFL",
  },
  [PAGE_HEADER_TABS.PITTSBURGH_STEELERS_NFL]: {
    title:
      "Steelers Forge Success: Pittsburgh Player Stats, Props, and Betting Triumph",
    description:
      "Join the ranks of victory with Pittsburgh Steelers' player statistics, thrilling prop bets, and strategies for NFL betting triumph",
  },
  [PAGE_HEADER_TABS.SAN_FRANCISCO_49ERS]: {
    title:
      "Golden Gate Gridiron: San Francisco Player Stats, Props, and Betting Wins",
    description:
      "Cross the bridge to success with San Francisco 49ers' player statistics, exciting prop bets, and the path to NFL betting triumph",
  },
  [PAGE_HEADER_TABS.SEATTLE_SEAHAWKS_NFL]: {
    title: "Seahawks Soar: Seattle Player Stats, Props, and Betting Excellence",
    description:
      "Witness Seattle Seahawks' player statistics, captivating prop bets, and the strategy for NFL betting excellence",
  },
  [PAGE_HEADER_TABS.TAMPA_BAY_BUCCANEERS]: {
    title:
      "Buccaneers' Bounty: Tampa Bay Player Stats, Props, and Betting Treasure",
    description:
      "Set sail with Tampa Bay Buccaneers' player statistics, exciting prop bets, and the path to treasure in NFL betting",
  },
  [PAGE_HEADER_TABS.TENNESSEE_TITANS_NFL]: {
    title:
      "Titans Triumph: Tennessee Player Stats, Props, and Betting Strategies",
    description:
      "Ascend to greatness with Tennessee Titans' player statistics, captivating prop bets, and winning strategies for NFL betting",
  },
  [PAGE_HEADER_TABS.WASHINGTON_COMMANDERS_NFL]: {
    title:
      "Commanding the Gridiron: Washington Player Stats, Props, and Betting Dominance",
    description:
      "Take charge with Washington Commanders' player statistics, thrilling prop bets, and the path to dominance in NFL betting",
  },
  [PAGE_HEADER_TABS.ATLANTA_HAWKS_NBA]: {
    title: "Atlanta Hawks NBA Betting Predictions: Strategies for Success",
    description:
      "Discover winning strategies and predictions for Atlanta Hawks NBA betting. Maximize your chances with expert insights",
  },
  [PAGE_HEADER_TABS.BOSTON_CELTICS_NBA]: {
    title: "Celtics Court Advantage: Boston NBA Betting Predictions",
    description:
      "Gain the upper hand in NBA betting with Boston Celtics predictions and expert insights. Elevate your game today",
  },
  [PAGE_HEADER_TABS.BROOKLYN_NETS_NBA]: {
    title: "Brooklyn Nets NBA Betting Insights: Predictions for Victory",
    description:
      "Explore expert predictions and insights for Brooklyn Nets NBA betting. Elevate your strategy and enhance your chances of success",
  },
  [PAGE_HEADER_TABS.CHARLOTTE_HORNETS_NBA]: {
    title: "Hornets' Nest: Charlotte NBA Betting Predictions and Insights",
    description:
      "Unlock valuable predictions and insights for Charlotte Hornets NBA betting. Navigate the hive for a winning strategy",
  },
  [PAGE_HEADER_TABS.CHICAGO_BULLS_NBA]: {
    title: "Bullish Predictions: Chicago NBA Betting Insights for Success",
    description:
      "Discover expert insights and predictions for Chicago Bulls NBA betting. Sharpen your strategy for a winning edge",
  },
  [PAGE_HEADER_TABS.CLEVELAND_CAVALIERS_NBA]: {
    title: "Cavaliers' Court: Cleveland NBA Betting Predictions and Tips",
    description:
      "Ride to victory with expert predictions and tips for Cleveland Cavaliers NBA betting. Elevate your strategy and boost your chances of winning",
  },
  [PAGE_HEADER_TABS.DALLAS_MAVERICKS_NBA]: {
    title: "Maverick Moves: Dallas NBA Betting Predictions and Strategies",
    description:
      "Uncover winning strategies and predictions for Dallas Mavericks NBA betting. Take your game to the next level with expert insights",
  },
  [PAGE_HEADER_TABS.DENVER_NUGGETS_NBA]: {
    title: "Nuggets Unearthed: Denver NBA Betting Predictions and Insights",
    description:
      "Dig into expert predictions and insights for Denver Nuggets NBA betting. Elevate your strategy and increase your chances of success",
  },
  [PAGE_HEADER_TABS.DETROIT_PISTONS_NBA]: {
    title: "Pistons Power: Detroit NBA Betting Predictions and Strategies",
    description:
      "Rev up your NBA betting game with expert predictions and strategies for Detroit Pistons. Maximize your chances for success",
  },
  [PAGE_HEADER_TABS.GOLDEN_STATE_WARRIORS]: {
    title:
      "Warriors' Wisdom: Golden State NBA Betting Predictions and Insights",
    description:
      "Gain a competitive edge with expert predictions and insights for Golden State Warriors NBA betting. Elevate your strategy for victory",
  },
  [PAGE_HEADER_TABS.HOUSTON_ROCKETS_NBA]: {
    title: "Rockets Ascend: Houston NBA Betting Predictions and Tips",
    description:
      "Blast off with expert predictions and tips for Houston Rockets NBA betting. Elevate your strategy for success on the court",
  },
  [PAGE_HEADER_TABS.INDIANA_PACERS_NBA]: {
    title: "Pacers' Path: Indiana NBA Betting Predictions and Strategies",
    description:
      "Find the winning route with expert predictions and strategies for Indiana Pacers NBA betting. Boost your chances of victory today",
  },
  [PAGE_HEADER_TABS.LOS_ANGELES_CLIPPERS]: {
    title: "Clippers' Edge: Los Angeles NBA Betting Predictions and Insights",
    description:
      "Discover the edge with expert predictions and insights for Los Angeles Clippers NBA betting. Elevate your strategy for success on the court",
  },
  [PAGE_HEADER_TABS.LOS_ANGELES_LAKERS]: {
    title: "Lakers Legacy: Los Angeles NBA Betting Predictions and Strategies",
    description:
      "Continue the legacy with expert predictions and strategies for Los Angeles Lakers NBA betting. Elevate your strategy for success on the court",
  },
  [PAGE_HEADER_TABS.MEMPHIS_GRIZZLIES_NBA]: {
    title: "Grizzlies' Grind: Memphis NBA Betting Predictions and Insights",
    description:
      "Get in on the grind with expert predictions and insights for Memphis Grizzlies NBA betting. Elevate your strategy for a winning edge",
  },
  [PAGE_HEADER_TABS.MIAMI_HEAT_NBA]: {
    title: "Heatwave Predictions: Miami NBA Betting Tips and Insights",
    description:
      "Ride the Heatwave with expert tips and insights for Miami Heat NBA betting. Maximize your chances for success on the court",
  },
  [PAGE_HEADER_TABS.MILWAUKEE_BUCKS_NBA]: {
    title:
      "Bucks Breakthrough: Milwaukee NBA Betting Predictions and Strategies",
    description:
      "Discover the key to breakthrough with expert predictions and strategies for Milwaukee Bucks NBA betting. Elevate your game for success on the court",
  },
  [PAGE_HEADER_TABS.MINNESOTA_TIMBERWOLVES_NBA]: {
    title: "Timberwolves Trail: Minnesota NBA Betting Predictions and Insights",
    description:
      "Navigate the trail to success with expert predictions and insights for Minnesota Timberwolves NBA betting. Elevate your strategy for a winning edge",
  },
  [PAGE_HEADER_TABS.NEW_ORLEANS_PELICANS]: {
    title: "Pelicans Take Flight: New Orleans NBA Betting Predictions and Tips",
    description:
      "Soar to victory with expert predictions and tips for New Orleans Pelicans NBA betting. Elevate your strategy for success on the court",
  },
  [PAGE_HEADER_TABS.NEW_YORK_KNICKS]: {
    title: "Knicks Knowhow: New York NBA Betting Predictions and Strategies",
    description:
      "Gain the edge with expert predictions and strategies for New York Knicks NBA betting. Elevate your game for success on the court",
  },
  [PAGE_HEADER_TABS.OKLAHOMA_CITY_THUNDER]: {
    title: "Thunder Strike: Oklahoma City NBA Betting Projections",
    description:
      "Feel the electricity with expert betting projections and insights for Oklahoma City Thunder. Elevate your game for success on the court",
  },
  [PAGE_HEADER_TABS.ORLANDO_MAGIC_NBA]: {
    title: "Magic Unveiled: Orlando NBA Betting Predictions and Tips",
    description:
      "Unlock the secrets of success with Orlando Magic NBA betting predictions and tips. Elevate your strategy for a winning edge on the court",
  },
  [PAGE_HEADER_TABS.PHILADELPHIA_76ERS_NBA]: {
    title: "76ers Playbook: Philadelphia NBA Betting Predictions and Insights",
    description:
      "Master the playbook with Philadelphia 76ers NBA betting predictions and insights. Elevate your game for a winning edge on the court",
  },
  [PAGE_HEADER_TABS.PHOENIX_SUNS_NBA]: {
    title: "Suns' Brilliance: Phoenix NBA Betting Predictions and Strategies",
    description:
      "Bask in the brilliance of Phoenix Suns with expert betting predictions and strategies for success in NBA. Elevate your game and increase your chances of winning",
  },
  [PAGE_HEADER_TABS.PORTLAND_TRAIL_BLAZERS]: {
    title: "Blazing Trails: Portland's NBA Betting Insights",
    description:
      "Explore unique insights into Portland Trail Blazers' NBA betting. Gain an edge for a winning strategy on the court",
  },
  [PAGE_HEADER_TABS.SACRAMENTO_KINGS_NBA]: {
    title: "Kings' Court: Sacramento NBA Betting Predictions",
    description:
      "Unlock Sacramento Kings' potential with expert NBA betting predictions. Elevate your game for a victorious season",
  },
  [PAGE_HEADER_TABS.SAN_ANTONIO_SPURS]: {
    title: "Spurs' Playbook: San Antonio NBA Betting Tips",
    description:
      "Master the playbook with San Antonio Spurs' NBA betting tips. Discover the strategies for success on the court",
  },
  [PAGE_HEADER_TABS.TORONTO_RAPTORS_NBA]: {
    title: "Raptors' Flight: Toronto NBA Betting Projections",
    description:
      "Embark on a flight with Toronto Raptors' NBA betting projections and soar to victory. Elevate your game and seize the opportunities",
  },
  [PAGE_HEADER_TABS.UTAH_JAZZ_NBA]: {
    title: "Jazzed Up: Utah NBA Betting Strategies",
    description:
      "Get jazzed up with unique Utah Jazz NBA betting strategies. Find your rhythm for a successful season",
  },
  [PAGE_HEADER_TABS.WASHINGTON_WIZARDS_NBA]: {
    title: "Wizards' Magic: Washington NBA Betting Insights",
    description:
      "Unveil the magic of Washington Wizards with exclusive NBA betting insights. Elevate your game for a victorious journey",
  },
  [PAGE_HEADER_TABS.ARIZONA_DIAMONDBACKS_MLB]: {
    title: "Diamondbacks' Diamond Pursuit: Exclusive MLB Insights",
    description:
      "Dive into the world of Arizona Diamondbacks with exclusive MLB insights. Elevate your game for a victorious season on the field",
  },
  [PAGE_HEADER_TABS.ATLANTA_BRAVES_MLB]: {
    title: "Braves of Atlanta: Unveiling MLB Betting Predictions",
    description:
      "Uncover Atlanta Braves' secrets with expert MLB betting predictions. Elevate your strategy for a winning season on the field",
  },
  [PAGE_HEADER_TABS.BALTIMORE_ORIOLES_MLB]: {
    title: "Orioles' Flight Plan: Baltimore MLB Betting Tips",
    description:
      "Master the flight plan with Baltimore Orioles' MLB betting tips. Discover the strategies for success on the field",
  },
  [PAGE_HEADER_TABS.CHICAGO_WHITE_SOX]: {
    title: "White Sox Wisdom: MLB Betting Insights for Chicago Fans",
    description:
      "Tap into Chicago White Sox's wisdom for MLB betting insights. Elevate your strategy for a successful season on the field.",
  },
  [PAGE_HEADER_TABS.BOSTON_RED_SOX]: {
    title: "Boston Red Sox Betting Projections",
    description:
      "Explore betting projections for Boston Red Sox. Find opportunities for a winning season.",
  },

  [PAGE_HEADER_TABS.CINCINNATI_REDS_MLB]: {
    title: "Reds' Winning Formula: Cincinnati's MLB Betting Tips",
    description:
      "Improve your MLB betting game with the Cincinnati Reds' winning formula.",
  },
  [PAGE_HEADER_TABS.CLEVELAND_GUARDIANS_MLB]: {
    title: "Guardians of Victory: MLB Betting Secrets for Cleveland Fans",
    description:
      "Uncover the secrets to success with the Cleveland Guardians' MLB betting insights. Elevate your strategy for a winning season on the field.",
  },
  [PAGE_HEADER_TABS.COLORADO_ROCKIES_MLB]: {
    title: "Rockies' Path to Glory: Colorado's MLB Betting Strategies",
    description:
      "Enhance your MLB betting game with the Colorado Rockies' strategies.",
  },
  [PAGE_HEADER_TABS.DETROIT_TIGERS_MLB]: {
    title: "Tigers Unleashed: Detroit's MLB Betting Tips",
    description:
      "Witness the Tigers' strength with Detroit's MLB betting tips. Discover the strategies for success on the field.",
  },
  [PAGE_HEADER_TABS.HOUSTON_ASTROS_MLB]: {
    title: "Astros' Game Plan: Houston's MLB Betting Success",
    description:
      "Explore the game plan behind Houston Astros' MLB betting success. Enhance your strategy for a successful season on the field.",
  },
  [PAGE_HEADER_TABS.KANSAS_CITY_ROYALS]: {
    title: "Royals' Roadmap: Navigating Kansas City's MLB Betting Excellence",
    description:
      "Embark on a journey with the Royals as we unveil the roadmap to MLB betting excellence in Kansas City. Elevate your strategy for a triumphant season on the field.",
  },
  [PAGE_HEADER_TABS.LOS_ANGELES_DODGERS]: {
    title: "Dodgers' Dominance: Los Angeles MLB Betting Insights",
    description:
      "Uncover the secrets to the Dodgers' dominance with exclusive MLB betting insights in Los Angeles. Level up your game for an unforgettable season.",
  },
  [PAGE_HEADER_TABS.MIAMI_MARLINS_MLB]: {
    title: "Marlins Magic: Miami's MLB Betting Secrets",
    description:
      "Unlock the magic behind the Miami Marlins with exclusive MLB betting secrets. Supercharge your game for an unforgettable season.",
  },
  [PAGE_HEADER_TABS.MILWAUKEE_BREWERS_MLB]: {
    title: "Brewers' Playbook: Milwaukee's MLB Betting Triumph",
    description:
      "Discover Milwaukee Brewers' winning playbook for MLB betting triumph. Elevate your strategy for a remarkable season.",
  },
  [PAGE_HEADER_TABS.MINNESOTA_TWINS_MLB]: {
    title: "Twins' Winning Blueprint: Minnesota's MLB Betting Strategies",
    description:
      "Explore the blueprint for success with the Minnesota Twins' MLB betting strategies. Boost your game for an extraordinary season.",
  },
  [PAGE_HEADER_TABS.NEW_YORK_METS]: {
    title: "Mets' Masterclass: New York's MLB Betting Insights",
    description:
      "Join the masterclass with New York Mets' MLB betting insights. Enhance your game for an extraordinary season.",
  },
  [PAGE_HEADER_TABS.NEW_YORK_YANKEES]: {
    title: "Yankees' Excellence: New York MLB Betting Projections",
    description:
      "Experience excellence with New York Yankees' MLB betting projections. Elevate your game for a remarkable season.",
  },
  [PAGE_HEADER_TABS.OAKLAND_ATHLETICS_MLB]: {
    title: "Athletics' Allure: Oakland's MLB Betting Strategies",
    description:
      "Explore the allure of Oakland Athletics with exclusive MLB betting strategies. Amplify your game for a thrilling season.",
  },
  [PAGE_HEADER_TABS.PHILADELPHIA_PHILLIES_MLB]: {
    title: "Phillies' Phenomenon: Philadelphia MLB Betting Secrets",
    description:
      "Unveil the phenomenon of the Philadelphia Phillies with exclusive MLB betting secrets. Elevate your game for an electrifying season.",
  },
  [PAGE_HEADER_TABS.PITTSBURGH_PIRATES_MLB]: {
    title: "Pirates' Pursuit: Charting Uncharted MLB Betting Waters",
    description:
      "Join the Pirates' daring pursuit as we navigate uncharted MLB betting waters in Pittsburgh. Elevate your game for an unforgettable season.",
  },
  [PAGE_HEADER_TABS.SAN_DIEGO_PADRES]: {
    title: "Padres' Path to Glory: San Diego's MLB Betting Strategies",
    description:
      "Discover San Diego Padres' path to glory with MLB betting strategies. Elevate your game for a sensational season on the field.",
  },
  [PAGE_HEADER_TABS.SAN_FRANCISCO_GIANTS]: {
    title: "Giants Unleashed: San Francisco MLB Betting Mastery",
    description:
      "Unleash the power of the San Francisco Giants with MLB betting mastery. Elevate your game for an exceptional season.",
  },
  [PAGE_HEADER_TABS.SEATTLE_MARINERS_MLB]: {
    title: "Mariners' Voyage: Seattle's MLB Betting Adventure",
    description:
      "Embark on a thrilling voyage with the Seattle Mariners' MLB betting adventure. Elevate your game for an extraordinary season on the field.",
  },
  [PAGE_HEADER_TABS.ST_LOUIS_CARDINALS]: {
    title: "Cardinals' Crusade: St. Louis MLB Betting Secrets",
    description:
      "Embark on a crusade with the St. Louis Cardinals and unveil exclusive MLB betting secrets. Elevate your game for an extraordinary season.",
  },
  [PAGE_HEADER_TABS.TAMPA_BAY_RAYS]: {
    title: "Rays of Success: Tampa Bay's MLB Betting Triumph",
    description:
      "Bask in the rays of success with Tampa Bay Rays' MLB betting triumph. Elevate your game for a remarkable season on the field.",
  },
  [PAGE_HEADER_TABS.TEXAS_RANGERS_MLB]: {
    title: "Rangers' Resurgence: Texas MLB Betting Insights",
    description:
      "Witness the resurgence of the Texas Rangers with exclusive MLB betting insights. Elevate your game for a thrilling season on the field.",
  },
  [PAGE_HEADER_TABS.CHICAGO_CUBS_MLB]: {
    title: "Cubs' Comeback: Chicago's MLB Betting Renaissance",
    description:
      "Witness the Cubs' comeback with Chicago's MLB betting renaissance. Elevate your game for a sensational season on the field.",
  },
  [PAGE_HEADER_TABS.LOS_ANGELES_ANGELS]: {
    title: "Angels' Ascension: Los Angeles' MLB Betting Dominance",
    description:
      "Witness the Angels' ascension with Los Angeles' MLB betting dominance. Elevate your game for a sensational season on the field.",
  },
  [PAGE_HEADER_TABS.TORONTO_BLUE_JAYS]: {
    title: "Blue Jays' Blueprint: Toronto's MLB Betting Mastery",
    description:
      "Explore the blueprint to success with Toronto Blue Jays' MLB betting mastery. Elevate your game for a sensational season on the field.",
  },
  [PAGE_HEADER_TABS.WASHINGTON_NATIONALS_MLB]: {
    title: "Nationals' Nirvana: Washington's MLB Betting Excellence",
    description:
      "Experience the Nationals' nirvana with Washington's MLB betting excellence. Elevate your game for an exceptional season on the field.",
  },
};

export const TEAMS_DETAILS = {
  ARIZONA_CARDINALS_NFL: {
    h1: "Arizona Cardinals Depth Chart:",
    p: "Delve into the Arizona Cardinals' depth chart to discover the strengths and key players that make up this formidable NFL team.",
  },
  ATLANTA_FALCONS_NFL: {
    h1: "Atlanta Falcons 2023 NFL Draft:",
    p: "Explore the Atlanta Falcons' strategy in the 2023 NFL Draft as they select the talent that will shape their future success in the league.",
  },
  BALTIMORE_RAVENS_NFL: {
    h1: "Baltimore Ravens 2023 Draft Picks",
    p: "Discover the Baltimore Ravens' 2023 draft picks and gain insights into the future stars that will contribute to the team's success in the NFL.",
  },
  BUFFALO_BILLS_NFL: {
    h1: "Buffalo Bills 2023 Betting Insights",
    p: "Explore the Buffalo Bills' 2023 draft picks from a betting perspective, and discover predictions that could impact your wagers on this NFL team.",
  },
  CAROLINA_PANTHERS_NFL: {
    h1: "Carolina Panthers Betting Predictions and News",
    p: "Stay ahead in your betting game with Carolina Panthers news and betting predictions, giving you the inside edge on this NFL team's performance.",
  },
  CHICAGO_BEARS_NFL: {
    h1: "NFL Chicago Bears",
    p: "Explore the world of the NFL Chicago Bears, a team with a storied history and an unwavering commitment to winning.",
  },
  CINCINNATI_BENGALS_NFL: {
    h1: "Cincinnati Bengals: Betting Tips and Predictions",
    p: "Get the latest betting tips and predictions for the Cincinnati Bengals from our team of experts. Find out who the key players are and how they could impact your wagers.",
  },
  CLEVELAND_BROWNS_NFL: {
    h1: "Cleveland Browns Betting Tips",
    p: "Discover the winning strategies with our Cleveland Browns betting tips and enhance your success in wagering on this NFL team.",
  },
  DALLAS_COWBOYS_NFL: {
    h1: "Dallas Cowboys Insights for Success",
    p: "Gain valuable insights for success with our Dallas Cowboys predictions, helping you make informed wagers on this iconic NFL team.",
  },
  DENVER_BRONCOS_NFL: {
    h1: "Denver Broncos Today Game Bet",
    p: "Get smart wagering insights for the Denver Broncos game today, enhancing your chances of success in NFL betting.",
  },
  DETROIT_LIONS_NFL: {
    h1: "Detroit Lions Depth Chart Betting Insights",
    p: "Explore valuable betting insights based on the Detroit Lions' depth chart, providing a strategic advantage for NFL wagering.",
  },
  GREEN_BAY_PACKERS: {
    h1: "Green Bay Packers: Player Metrics, Props, and Betting",
    p: "Dive into the world of Green Bay Packers' player metrics, props, and betting prowess, arming you with the tools to succeed in NFL wagering.",
  },
  HOUSTON_TEXANS_NFL: {
    h1: "Houston Texans Player Stats: Unlocking Betting Insights",
    p: "Unlock valuable betting insights through Houston Texans player stats, empowering your NFL wagering decisions.",
  },
  INDIANAPOLIS_COLTS_NFL: {
    h1: "Indianapolis Colts Betting: Winning Strategies and Insights",
    p: "Discover winning strategies and insights for betting on the Indianapolis Colts, elevating your NFL wagering game.",
  },
  JACKSONVILLE_ANALYZING_JAGUARS: {
    h1: "Jacksonville Jaguars Standings: Keeping Score",
    p: "Stay up-to-date with Jacksonville Jaguars standings, keeping track of their performance in the NFL.",
  },
  KANSAS_CITY_CHIEFS: {
    h1: "Kansas City Chiefs Player Stats, Props",
    p: "Explore Kansas City Chiefs player stats, props, and strategies for winning bets, giving you the edge in NFL wagering.",
  },
  LAS_VEGAS_RAIDERS: {
    h1: "Las Vegas Raiders Player Props",
    p: "Delve into Las Vegas Raiders player props, player odds, and betting predictions to elevate your NFL wagering game with expert insights.",
  },
  LOS_ANGELES_TOP: {
    h1: "Los Angeles Chargers Player Odds, and Betting Predictions",
    p: "Dive into Los Angeles Chargers player props, player odds, and betting predictions, arming you with the knowledge to excel in NFL wagering.",
  },
  LOS_ANGELES_RAMS: {
    h1: "Los Angeles Rams Betting Predictions:",
    p: "Gain game day insights with Los Angeles Rams betting predictions, equipping you for success in NFL wagering.",
  },
  MIAMI_DOLPHINS_NFL: {
    h1: "Miami Dolphins Player Stats, Props, and Betting Strategies",
    p: "Explore Miami Dolphins player stats, props, and effective betting strategies to elevate your NFL wagering experience.",
  },
  MINNESOTA_VIKINGS_NFL: {
    h1: "Minnesota Vikings Games Bet",
    p: "Discover winning strategies for betting on Minnesota Vikings games, enhancing your success in NFL wagering.",
  },
  NEW_ENGLAND_PATRIOTS: {
    h1: "New England Patriots Player Props",
    p: "Unlock valuable betting opportunities with New England Patriots player props, providing insights to boost your NFL wagering success.",
  },
  NEW_ORLEANS_SAINTS: {
    h1: "New Orleans Saints Betting Status: Stay Updated",
    p: "Stay up-to-date with the betting status of the New Orleans Saints, ensuring you're well-informed for NFL wagering.",
  },
  NEW_YORK_GIANTS: {
    h1: "New York Giants Best Betting Tips",
    p: "Access your winning playbook with the best New York Giants betting tips, designed to enhance your success in NFL wagering.",
  },
  NEW_YORK_JETS: {
    h1: "New York Jets Players Bet:",
    p: "Gain smart wagering insights on New York Jets players, empowering you to make informed bets in NFL betting.",
  },
  PHILADELPHIA_EAGLES_NFL: {
    h1: "Philadelphia Eagles Success Betting",
    p: "Discover your winning formula for Philadelphia Eagles' success betting, with strategies and insights to excel in NFL wagering.",
  },
  PITTSBURGH_STEELERS_NFL: {
    h1: "Pittsburgh Steelers Player Stats and Props:",
    p: "Gain valuable betting insights with Pittsburgh Steelers player stats and props, empowering your NFL wagering decisions.",
  },
  SAN_FRANCISCO_49ERS: {
    h1: "San Francisco 49ers Player Totals",
    p: "Get valuable betting insights from San Francisco 49ers player totals, aiding your NFL wagering decisions.",
  },
  SEATTLE_SEAHAWKS_NFL: {
    h1: "Seattle Seahawks Betting Predictions:",
    p: "Explore winning strategies with Seattle Seahawks betting predictions, giving you an edge in NFL wagering.",
  },
  TAMPA_BAY_BUCCANEERS: {
    h1: "Tampa Bay Buccaneers Best Player Total Bets",
    p: "Uncover winning insights with the best player total bets for the Tampa Bay Buccaneers, enhancing your NFL wagering strategy.",
  },
  TENNESSEE_TITANS_NFL: {
    h1: "Tennessee Titans Player Bets:",
    p: "Elevate your wagering game with Tennessee Titans player bets, offering strategic insights for success in NFL betting.",
  },
  WASHINGTON_COMMANDERS_NFL: {
    h1: "Washington Commanders Best Predictions:",
    p: "Discover strategies for success with the best predictions for the Washington Commanders, empowering your NFL wagering journey.",
  },
  ATLANTA_HAWKS_NBA: {
    h1: "Atlanta Hawks NBA Betting Predictions:",
    p: "Elevate your NBA wagering game with our expert Atlanta Hawks betting predictions, designed to give you the edge in basketball betting",
  },
  BOSTON_CELTICS_NBA: {
    h1: "Celtics Court NBA Betting Insight",
    p: "Get expert insights for success in NBA betting with our Celtics Court, unlocking the keys to winning in basketball wagering.",
  },
  BROOKLYN_NETS_NBA: {
    h1: "Brooklyn Nets NBA Betting Predictions:",
    p: "Explore winning strategies with our Brooklyn Nets NBA betting predictions, your key to success in basketball wagering.",
  },
  CHARLOTTE_HORNETS_NBA: {
    h1: "Charlotte Hornets Player Totals and Props:",
    p: "Gain valuable betting insights from Charlotte Hornets player totals and props, giving you an edge in NBA wagering.",
  },
  CHICAGO_BULLS_NBA: {
    h1: "Chicago Bulls Player Totals:",
    p: "Get valuable betting insights from Chicago Bulls player totals, enhancing your NBA wagering decisions.",
  },
  CLEVELAND_CAVALIERS_NBA: {
    h1: "Cleveland Cavaliers Pro Betting Track:",
    p: "Explore winning strategies with the Cleveland Cavaliers Pro Betting Track, providing insights for success in NBA wagering",
  },
  DALLAS_MAVERICKS_NBA: {
    h1: "Dallas Mavericks Top Predictions",
    p: "Discover winning strategies with the Dallas Mavericks' top predictions, your key to success in NBA wagering.",
  },
  DENVER_NUGGETS_NBA: {
    h1: "Denver Nuggets Player Totals:",
    p: "Gain valuable betting insights from Denver Nuggets player totals, elevating your NBA wagering strategy.",
  },
  DETROIT_PISTONS_NBA: {
    h1: "Detroit Pistons Best Player Totals Predictions",
    p: "Get the best player totals predictions for the Detroit Pistons, enhancing your NBA wagering insights.",
  },
  GOLDEN_STATE_WARRIORS: {
    h1: "Golden State Warriors Player Props:",
    p: "Elevate your NBA betting game with Golden State Warriors player props, providing valuable insights for more informed wagering.",
  },
  HOUSTON_ROCKETS_NBA: {
    h1: "Houston Rockets Winning Predictions:",
    p: "Embark on your path to success in NBA betting with Houston Rockets winning predictions, offering valuable insights for informed wagering.",
  },
  INDIANA_PACERS_NBA: {
    h1: "Indiana Pacers Expert Predictions:",
    p: "iscover winning strategies with our Indiana Pacers expert predictions, your key to success in NBA wagering.",
  },
  LOS_ANGELES_CLIPPERS: {
    h1: "Los Angeles Clippers Pro Betting Insights",
    p: "Access expert insights with the Los Angeles Clippers Pro Betting State, empowering your NBA wagering strategy.",
  },
  LOS_ANGELES_LAKERS: {
    h1: "Los Angeles Lakers Best Season Predictions",
    p: "Explore the best season predictions for the Los Angeles Lakers, providing valuable insights for your NBA wagering success.",
  },
  MEMPHIS_GRIZZLIES_NBA: {
    h1: "Memphis Grizzlies Top Betting Chart: Winning Strategies",
    p: "Discover winning strategies with the Memphis Grizzlies' top betting chart, your key to success in NBA wagering.",
  },
  MIAMI_HEAT_NBA: {
    h1: "Miami Heat: Igniting the NBA betting",
    p: "Explore the Miami Heat, a team known for igniting the NBA with their dynamic style and commitment to excellence.",
  },
  MILWAUKEE_BUCKS_NBA: {
    h1: "Milwaukee Bucks: Fear the Deer",
    p: "Discover the Milwaukee Bucks, a team that embodies the 'Fear the Deer' spirit and competes at the highest level in the NBA.",
  },
  MINNESOTA_TIMBERWOLVES_NBA: {
    h1: "Minnesota Timberwolves Player Totals Betting:",
    p: "Gain winning insights with Minnesota Timberwolves player totals betting, empowering your NBA wagering strategy.",
  },
  NEW_ORLEANS_PELICANS: {
    h1: "New Orleans Pelicans Betting Secrets:",
    p: "Uncover the betting secrets of the New Orleans Pelicans, giving you a winning edge in NBA wagering.",
  },
  NEW_YORK_KNICKS: {
    h1: "New York Knicks Player Props:",
    p: "Gain valuable betting insights from New York Knicks player props, enhancing your NBA wagering decisions",
  },
  OKLAHOMA_CITY_THUNDER: {
    h1: "Oklahoma City Thunder Accurate Betting Predictions: The Winning Formula",
    p: "Discover the winning formula with accurate betting predictions for the Oklahoma City Thunder, empowering your NBA wagering strategy.",
  },
  ORLANDO_MAGIC_NBA: {
    h1: "Orlando Magic Player Totals Insights",
    p: "Get valuable insights from Orlando Magic player totals, enhancing your understanding for NBA wagering.",
  },
  PHILADELPHIA_76ERS_NBA: {
    h1: "Philadelphia 76ers Betting Predictions Strategies for Success",
    p: "Discover winning strategies with our Philadelphia 76ers betting predictions, designed to give you the edge in NBA wagering.",
  },
  PHOENIX_SUNS_NBA: {
    h1: "Phoenix Suns Player Total Props:",
    p: "Gain valuable betting insights from Phoenix Suns player total props, enhancing your NBA wagering decisions.",
  },
  PORTLAND_TRAIL_BLAZERS: {
    h1: "Portland Trail Blazers Precise Predictions",
    p: "Elevate your NBA betting game with precise predictions for the Portland Trail Blazers, providing valuable insights for more informed wagering.",
  },
  SACRAMENTO_KINGS_NBA: {
    h1: "Sacramento Kings Best Predictions of the Day",
    p: "Get the best predictions of the day for the Sacramento Kings, offering valuable insights for your NBA wagering success.",
  },
  SAN_ANTONIO_SPURS: {
    h1: "San Antonio Spurs Expert Player Totals Prediction",
    p: "Access expert insights with San Antonio Spurs player totals predictions, empowering your NBA wagering strategy.",
  },
  TORONTO_RAPTORS_NBA: {
    h1: "Toronto Raptors NBA Betting Projections: Your Path to Victory",
    p: "Embark on your path to victory in NBA betting with Toronto Raptors NBA Betting Projections, offering valuable insights for informed wagering.",
  },
  UTAH_JAZZ_NBA: {
    h1: "Unique Utah Jazz NBA Betting Strategies",
    p: "Discover unique NBA betting strategies tailored for the Utah Jazz, providing you with an edge in your wagering pursuits.",
  },
  WASHINGTON_WIZARDS_NBA: {
    h1: "Washington Wizards Betting: Your Winning Playbook",
    p: "Access your winning playbook with Washington Wizards betting insights, designed to enhance your success in NBA wagering.",
  },
  ARIZONA_DIAMONDBACKS_MLB: {
    h1: "Arizona Diamondbacks Prop Bets:",
    p: "Gain valuable betting insights from Arizona Diamondbacks prop bets, enhancing your MLB wagering decisions.",
  },
  ATLANTA_BRAVES_MLB: {
    h1: "Atlanta Braves Best Player Prop Bets: Winning Strategies",
    p: "Discover winning strategies with the best player prop bets for the Atlanta Braves, empowering your MLB wagering game.",
  },
  BALTIMORE_ORIOLES_MLB: {
    h1: "Baltimore Orioles Player Totals Prop Bets:",
    p: "Gain valuable betting insights from Baltimore Orioles player totals prop bets, enhancing your MLB wagering strategy.",
  },
  BOSTON_RED_SOX: {
    h1: "Boston Red Sox Best Betting Today:",
    p: "Explore winning strategies for betting on the Boston Red Sox today, enhancing your MLB wagering game.",
  },
  CHICAGO_CUBS_MLB: {
    h1: "Chicago Cubs Player Totals Betting Insights today",
    p: "Get valuable betting insights from Chicago Cubs player totals, enhancing your MLB wagering decisions.",
  },
  CHICAGO_WHITE_SOX: {
    h1: "Chicago White Sox Betting and Player Totals",
    p: "Explore betting opportunities and player totals for the Chicago White Sox, empowering your MLB wagering strategy.",
  },
  CINCINNATI_REDS_MLB: {
    h1: "Cincinnati Reds High Expert Betting Predictions",
    p: "Access high-level expert predictions for betting on the Cincinnati Reds, providing valuable insights for your MLB wagering success.",
  },
  CLEVELAND_GUARDIANS_MLB: {
    h1: "Cleveland Guardians Fantasy Betting Predictions",
    p: "Explore fantasy betting predictions for the Cleveland Guardians, arming you with insights for success in MLB wagering.",
  },
  COLORADO_ROCKIES_MLB: {
    h1: "Colorado Rockies Top Betting Insights",
    p: "Access top betting insights for the Colorado Rockies, empowering your MLB wagering strategy.",
  },
  DETROIT_TIGERS_MLB: {
    h1: "Detroit Tigers Betting Props Your Winning Strategy",
    p: "Discover your winning strategy with Detroit Tigers betting props, enhancing your MLB wagering game.",
  },
  HOUSTON_ASTROS_MLB: {
    h1: "Houston Astros Free Betting Insights",
    p: "Gain valuable free betting insights for the Houston Astros, empowering your MLB wagering strategy.",
  },
  KANSAS_CITY_ROYALS: {
    h1: "Kansas City Royals Betting Tips: Winning Strategies",
    p: "Access winning strategies with our Kansas City Royals betting tips, designed to give you an edge in MLB wagering.",
  },
  LOS_ANGELES_DODGERS: {
    h1: "Los Angeles Dodgers Win Bet Today:",
    p: "Get the game day advantage with Los Angeles Dodgers win bet today, as we provide insights to help you make informed wagering decisions.",
  },
  MIAMI_MARLINS_MLB: {
    h1: "Miami Marlins Magic Betting Insights",
    p: "Uncover the magic of betting with Miami Marlins insights, providing you with an edge in MLB wagering.",
  },
  MILWAUKEE_BREWERS_MLB: {
    h1: "Milwaukee Brewers Today's Match:",
    p: "Stay updated with the Milwaukee Brewers' match today, ensuring you're in the know about their performance in MLB.",
  },
  MINNESOTA_TWINS_MLB: {
    h1: "Minnesota Twins Betting Prediction Tips:",
    p: "Access winning strategies with our Minnesota Twins betting prediction tips, designed to give you an edge in MLB wagering.",
  },
  NEW_YORK_METS: {
    h1: "New York Mets Betting Predictions for Winning Wagers",
    p: "Discover New York Mets betting predictions designed to enhance your chances of success and win your wagers in MLB.",
  },
  NEW_YORK_YANKEES: {
    h1: "New York MLB Betting Projections for Player Props",
    p: " Explore New York MLB betting projections for player props, arming you with insights to elevate your MLB wagering experience.",
  },
  OAKLAND_ATHLETICS_MLB: {
    h1: "Oakland Athletics Player Totals: Betting Insights",
    p: "Gain valuable betting insights from Oakland Athletics player totals, enhancing your MLB wagering decisions.",
  },
  PHILADELPHIA_PHILLIES_MLB: {
    h1: "Philadelphia Phillies Betting Player Stats",
    p: "Explore Philadelphia Phillies' player stats for informed betting decisions in MLB.",
  },
  PITTSBURGH_PIRATES_MLB: {
    h1: "Pittsburgh Pirates Player Bets Today",
    p: "Get insights for betting on Pittsburgh Pirates players today, enhancing your MLB wagering strategy.",
  },
  SAN_DIEGO_PADRES: {
    h1: "San Diego Padres Betting Secrets:",
    p: "Uncover the betting secrets of the San Diego Padres, giving you a winning edge in MLB wagering.",
  },
  SAN_FRANCISCO_GIANTS: {
    h1: "San Francisco Giants Top Betting Insights",
    p: "Access top betting insights for the San Francisco Giants, empowering your MLB wagering strategy.",
  },
  SEATTLE_MARINERS_MLB: {
    h1: "Seattle Mariners: On the Amazing Track to Victory",
    p: "Follow the Seattle Mariners on their amazing track to victory in MLB, and consider wagering with confidence.",
  },
  ST_LOUIS_CARDINALS: {
    h1: "St. Louis Cardinals Top-Class Betting Tips: Elevate Your Game",
    p: "Elevate your game with top-class betting tips for the St. Louis Cardinals, providing valuable insights for MLB wagering.",
  },
  TAMPA_BAY_RAYS: {
    h1: "Tampa Bay Rays Super Best Player Totals",
    p: "Discover super best player totals for the Tampa Bay Rays, arming you with insights to excel in MLB wagering.",
  },
  TEXAS_RANGERS_MLB: {
    h1: "Texas Rangers Betting Process: A Step-by-Step Guide",
    p: "Follow our step-by-step guide to the Texas Rangers betting process, ensuring you make informed MLB wagering decisions.",
  },
  TORONTO_BLUE_JAYS: {
    h1: "Toronto Blue Jays Betting Magic:",
    p: "Unlock the magic of betting on the Toronto Blue Jays, with insights that can lead to success in MLB wagering.",
  },
  WASHINGTON_NATIONALS_MLB: {
    h1: "Washington Nationals Player Prop Betting Insights",
    p: "Gain valuable insights into Washington Nationals player prop betting, empowering your MLB wagering decisions.",
  },
};

export const NO_CONTENT_MESSAGE = "No Player Props Available";
