import React, { useState, useEffect, useRef } from "react";
import "./PremiumCard.css";
import { BiChevronDown } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../../Context";
import { useTab, usePremiumTab } from "../../../Redux/Accessors/game.accessors";
import { getDataValues, getTab } from "../../../Utils/getStats";
import SearchSuggestion from "../../Layout/Header/SearchSuggestion";
import Games from "../../../Services/Games";
import PremiumRow from "./PremiumRow";
import { updatePremiumTab } from "../../../Redux/Accessors/game.accessors";
import { useLocation } from "react-router-dom";
import Loader from "../../../Common/Loader";

const PremiumCard = ({
  id,
  viewMore,
  onlyOne,
  player,
  search,
  onlyList,
  team,
  players,
  playerDetails,
  schedule,
  isStatsLoading = false,
  premium,
  list,
  tab,
  teamData,
}) => {
  const location = useLocation();
  const selectedTab = useTab();
  const { mode } = ThemeState();
  const premiumTab = usePremiumTab();
  const suggestionRef = useRef(null);

  const [toggle, setToggle] = useState(onlyList || list ? "LIST" : "GRID");
  const [premiumListTab, setPremiumListTab] = useState(5);
  const [playerName, setPlayerName] = useState("");
  const [playersData, setPlayersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gameStats, setGameStats] = useState(null);
  const [showDropdown, setShowDropdown] = useState(true);

  const teamKey = schedule?.homeTeamPlayers?.find(({ _id }) => _id === id)
    ? "homeTeam"
    : "awayTeam";

    const premiumValue =
    schedule?.playerPremium &&
    schedule?.playerPremium.filter((premium) => premium.player === playerDetails._id)

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.playerStats(data);
      const stats = res?.data?.data?.find(({ player }) => player?._id === id);
      setGameStats(stats);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    if (schedule) {
      fetchTeamPlayerStats({
        gameId: schedule?._id,
        teamId: schedule[teamKey]?._id,
        bettingBetType: getTab(selectedTab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedTab]);

  const onSearch = async (name) => {
    setPlayerName(name);
    if (name?.length >= 3) {
      try {
        setIsLoading(true);
        const res = await Games.search(name);
        setPlayersData(res?.data?.data);
      } catch (error) {
        setIsLoading(false);
        console.log("Error in searching players");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (location.pathname.includes("/player")) {
      setPremiumListTab(premiumTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  const filteredTeamData =
    players?.filter((game) => !getDataValues(game, selectedTab)) || [];
  console.log(premium, "premium");

  return (
    <div className={`premium_card_box_main`}>
      <div className={`premium_card_box ${mode === "Light" && "light_theme"}`}>
        {search && (
          <div className="search-wrapper" ref={suggestionRef}>
            <div className="premium_search_box mb-1">
              <input
                placeholder="Search for player totals or results"
                value={playerName}
                onChange={(e) => onSearch(e.target.value)}
                onFocus={() => setShowDropdown(true)}
              />
              {!playerName.length && (
                <img src={"/images/search.png"} alt="search" />
              )}
            </div>
            {playerName.length >= 4 && showDropdown && (
              <SearchSuggestion
                players={playersData}
                setPlayerName={setPlayerName}
                isLoading={isLoading}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
              />
            )}
          </div>
        )}
        <div
          className={`d-flex align-items-center justify-content-between py-2 px-2 premium_menu_box ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div className="d-flex align-items-center">
            {!player ? (
              <>
                <h3
                  className={`${
                    mode === "Light" ? "text-white" : "col-yellow"
                  } ps-2`}
                >
                  {selectedTab}
                </h3>
              </>
            ) : (
              <h3 className={`${mode === "Light" ? "text-white" : "col-blue"}`}>
                {player}
              </h3>
            )}
          </div>
          <div className="d-flex align-items-center gap-3">
            {!onlyList && (
              <>
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => setToggle("GRID")}
                >
                  {toggle === "GRID" ? (
                    <img src="/images/grid.png" alt="" />
                  ) : mode === "Light" ? (
                    <img src="/images/grid-white.png" alt="" />
                  ) : (
                    <img src="/images/grid-white.png" alt="" />
                  )}
                  {toggle === "GRID" ? (
                    <p style={{ color: mode === "Light" ? "#fff" : "#fff" }}>
                      GRID
                    </p>
                  ) : (
                    <p
                      className={`${
                        mode === "Light" ? "text-white" : "text-white"
                      }`}
                    >
                      GRID
                    </p>
                  )}
                </div>
                <div
                  style={{
                    width: "1px",
                    height: "20px",
                    background: "rgb(77, 77, 77)",
                  }}
                ></div>
              </>
            )}
            <div
              className="d-flex align-items-center gap-2"
              style={{ cursor: "pointer" }}
              onClick={() => setToggle("LIST")}
            >
              {toggle === "LIST" ? (
                <img src="/images/redlist.png" alt="" />
              ) : mode === "Light" ? (
                <img src="/images/list.png" alt="" />
              ) : (
                <img src="/images/list.png" alt="" />
              )}
              {toggle === "LIST" ? (
                <p style={{ color: mode === "Light" ? "#fff" : "#fff" }}>
                  LIST
                </p>
              ) : (
                <p
                  className={`${
                    mode === "Light" ? "text-white" : "text-white"
                  }`}
                >
                  LIST
                </p>
              )}
            </div>
          </div>
        </div>

        {toggle !== "GRID" && (
          <>
            <div className="premium_card_header bg_red w-100 text-white">
              <h3>PREMIUM</h3>
            </div>
            <div
              className={`premium_card_body ${
                mode === "Light" && "light_theme"
              }`}
            >
              <div >
                <div className="d-flex justify-content-between align-items-center premium_list_text">
                  {tab && tab === "playerPremium" && premiumValue ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: premiumValue[0]?.premium,
                      }}
                    ></p>
                  ) : tab && tab === "team" && teamData?.premium ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: teamData?.premium,
                      }}
                    ></p>
                  ) : !tab && premium ? (
                    <p dangerouslySetInnerHTML={{ __html: premium }}></p>
                  ) : (
                    <h3>No Player Picks for this Team.</h3>
                  )}
                  {/* {

                  premium ? <p dangerouslySetInnerHTML={{ __html: premium }}></p> :
                  <h3>No Player Picks for this Team.</h3>
                  } */}
                  <p>{/* 0 30.5 pts <span>(-120)</span> */}</p>
                </div>
                {/* <div className="pt-2 mb-3">
                  <ul>
                    <li>74% hit rate away</li>
                    <li>83% hit rate without James Harden</li>
                  </ul>
                </div> */}
              </div>
              {/* <hr style={{ color: "white" }} /> */}
              {!onlyOne && (
                <>
                  <div style={{ height: "70px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Zach LaVine</h3>
                      <p>
                        0 27.5 pts <span>(-115)</span>
                      </p>
                    </div>
                    <div className="pt-2 mb-3">
                      <ul>
                        <li>74% hit rate away</li>
                        <li>83% hit rate without James Harden</li>
                      </ul>
                    </div>
                  </div>
                  <div style={{ height: "70px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <h3>Zach LaVine</h3>
                      <p>
                        0 27.5 pts <span>(-115)</span>
                      </p>
                    </div>
                    <div className="pt-2 mb-3">
                      <ul>
                        <li>74% hit rate away</li>
                        <li>83% hit rate without James Harden</li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {viewMore && (
                <div style={{ height: "70px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h3>Zach LaVine</h3>
                    <p>
                      0 27.5 pts <span>(-115)</span>
                    </p>
                  </div>
                  <div className="pt-2 mb-3">
                    <ul>
                      <li>74% hit rate away</li>
                      <li>83% hit rate without James Harden</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {toggle !== "LIST" && (
          <>
            <div className="premium_card_header bg_green w-100 text-white list_head ps-2 pe-4 pe-md-2">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ gap: "8px" }}
              >
                <h3 style={{ fontSize: "13px" }}>
                  PREMIUM <BiChevronDown />
                </h3>
                <h3
                  className={`${premiumListTab === 5 ? "active" : ""}`}
                  onClick={() => {
                    setPremiumListTab(5);
                    updatePremiumTab(5);
                  }}
                >
                  Last 5{" "}
                </h3>
                <h3
                  className={`${premiumListTab === 10 ? "active" : ""}`}
                  onClick={() => {
                    setPremiumListTab(10);
                    updatePremiumTab(10);
                  }}
                >
                  Last 10{" "}
                </h3>
                <h3
                  className={`${premiumListTab === "season" ? "active" : ""}`}
                  onClick={() => {
                    setPremiumListTab("season");
                    updatePremiumTab("season");
                  }}
                >
                  Season{" "}
                </h3>
              </div>
            </div>

            <div className="list_box">
              <Row className="list_header">
                <Col xs={3}>
                  <h3>Avg Finish</h3>
                </Col>
                <Col xs={3}>
                  <h3>Avg Line</h3>
                </Col>
                <Col xs={3}>
                  <h3>% Finish</h3>
                </Col>
                <Col xs={3}>
                  <h3>Projection</h3>
                </Col>
              </Row>

              <div className="mt-2">
                {!!filteredTeamData?.length ? (
                  filteredTeamData
                    ?.slice(0, viewMore ? filteredTeamData?.length : 3)
                    .map((player, index) => {
                      const allStatsNull = getDataValues(player, selectedTab);

                      if (allStatsNull) {
                        return null;
                      }
                      return (
                        <div key={index} className="list_data">
                          <PremiumRow
                            playerId={player?.player?._id}
                            matches={premiumListTab}
                            projection={player?.consensus}
                          />
                        </div>
                      );
                    })
                ) : (
                  <div className="list_data_premium">
                    {isStatsLoading ? (
                      <Loader />
                    ) : playerDetails ? (
                      <PremiumRow
                        playerId={playerDetails?._id}
                        matches={premiumListTab}
                        projection={gameStats?.consensus}
                      />
                    ) : (
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-center mt-2"
                      >
                        No Premium Stats
                      </p>
                    )}
                  </div>
                )}
              </div>
              {/* {!onlyOne && (
                <>
                  <div className="list_data">
                    <Row className="list_body">
                      <Col xs={3}>
                        <div>
                          <p>29.5</p>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p>31.5</p>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <h5>0-40%</h5>
                          <h3>U-60%</h3>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p>33.5</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="list_data">
                    <Row className="list_body">
                      <Col xs={3}>
                        <div>
                          <p>29.5</p>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p>31.5</p>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <h5>0-40%</h5>
                          <h3>U-60%</h3>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <p>33.5</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )} */}
              {/* {viewMore && (
                <div className="list_data">
                  <Row className="list_body">
                    <Col xs={3}>
                      <div>
                        <p>29.5</p>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <p>31.5</p>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <h5>0-40%</h5>
                        <h3>U-60%</h3>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <p>33.5</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )} */}
            </div>
            {/* <div className='premium_card_body'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3>Joel Embiid</h3>
                            <p>0 30.5 pts <span>(-120)</span></p>
                        </div>
                        <div className='pt-2'>
                            <ul>
                                <li>74% hit rate away</li>
                                <li>83% hit rate without James Harden</li>
                            </ul>
                        </div>
                        <hr style={{ color: "white" }} />
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3>Zach LaVine</h3>
                            <p>0 27.5 pts <span>(-115)</span></p>
                        </div>
                        <div className='pt-2'>
                            <ul>
                                <li>74% hit rate away</li>
                                <li>83% hit rate without James Harden</li>
                            </ul>
                        </div>
                    </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default PremiumCard;
