/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { updateSport } from "../../../Redux/Accessors/game.accessors";
// eslint-disable-next-line no-unused-vars
import Games from "../../../Services/Games";
import { playerNavigator } from "../../../Utils/navigator";

const SearchSuggestion = ({
  players,
  setPlayerName,
  isLoading = false,
  showDropdown,
  setShowDropdown,
  search=false,
  setPlayerData
}) => {
  const navigate = useNavigate();
  const suggestionRef = useRef(null);
  // const [showDropdown, setShowDropdown] = useState(false);

  const handleClick = async (player) => {
    // console.log(player,"player")
    updateSport(player?.sport);
    setPlayerName("");
    setShowDropdown(false);
    if(search){
      setPlayerData(player)
    }
    else{
      playerNavigator(player, navigate, updateSport);
    }
  };

  useEffect(() => {
    if (players.length) setShowDropdown(true);
  }, [players]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  return (
    <div className="w-100 suggestion-wrapper">
      {!isLoading ? (
        players?.map((player, index) => (
          <p key={index} onClick={() => handleClick(player)}>
            <span>{player?.sport}</span> -
            <img
              src={player?.team?.wikipediaLogoUrl}
              alt="logo"
              style={{
                width: "14px",
                height: "14px",
                marginLeft: "4px",
                marginRight: "4px",
              }}
            />
            {player?.firstName} {player?.lastName} - {player?.position}
          </p>
        ))
      ) : (
        <div className="text-center pt-3 pb-3">
          <img
            src="/images/dark-loader.gif"
            alt="loader"
            style={{ width: "20px", height: "20px" }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchSuggestion;
