import React from "react";
import { ThemeState } from "../../Context";

const CompareLoader = () => {
  const { mode } = ThemeState();

  return (
    <>
      {mode !== "Light" ? (
        <img
          src="/images/loader.gif"
          alt="loader"
          style={{ width: "18px", height: "18px" }}
        />
      ) : (
        <img
          src="/images/dark-loader.gif"
          alt="loader"
          style={{ width: "18px", height: "18px" }}
        />
      )}
    </>
  );
};

export default CompareLoader;
