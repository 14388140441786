export const cloudURL = "https://api.playertotals.com/uploads";
export const FB = "https://www.facebook.com/profile.php?id=61552815775133";
export const Twitter = "https://twitter.com/playertotals";
export const Instagram = "https://www.instagram.com/playerprops";

export const about = {
  _id: { $oid: "6509a5ff6d0d22c831f9f81c" },
  bannerSection: {
    bannerImg: { filename: "1694791872911-logo_.svg" },
    title: "Test",
  },
  aboutSections: [
    {
      image: { filename: "1690898594982-premiumpage.png" },
      heading: "Premium Player Totals",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      _id: { $oid: "64c910a391f9a03f399a4a41" },
    },
    {
      image: { filename: "1690898713426-teampage.png" },
      heading: "Game and Player Odds for every Team",
      text: "Our team pages encompass game and player odds for all sports. These pages provide information on the upcoming game spread and totals, as well as comprehensive past performance data for each player on the selected team's roster. Additionally, we offer notifications specifically for players on the chosen team. These team pages function similarly to player pages, but cater to team-related information. To access any desired team, simply utilize the drop-down menu under each team bar.",
      _id: { $oid: "64c9111991f9a03f399a4a55" },
    },
    {
      image: { filename: "1690898996879-notificationpage.png" },
      heading: "Real Time Notifications for all Player Prop line Movements",
      text: "Experience real-time notifications for all player prop line movements with our app. By starring any player, you'll receive immediate alerts regarding any changes in their selected statistics across the six sports books. For instance, if Ja'Marr Chase's yards increase from 86.5 to 88.5 on FanDuel, you'll be notified of this upward adjustment. This feature holds significant value for both gamblers and fantasy players, providing an advantage to capitalize on line changes before other books catch up. It can also serve as a valuable trend indicator to identify players gaining momentum. As a premium member, you'll have access to a dedicated page displaying all lines for every sport. You can conveniently filter them based on chain, player, sport, or view all, ensuring you stay updated with every line change across the six books we track.",
      _id: { $oid: "64c91235399b62f1baad54af" },
    },
  ],
};

export const 
  promos = [
    {
      image: { filename: "1690893776990-fanduel.png" },
      dark_mode_img: { filename: "" },
      title: "FanDuel",
      heading: "Bet $5, Get $100 in Bonus Bets - Guaranteed!",
      text1: "Available in your state! New users only.",
      text2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      review:
        "On the heels of DraftKings, FanDuel was one of the first online sportsbooks to go to market in the United States. They also have the same background as DraftKings, starting as a daily fantasy sports platform. FanDuel offers numerous player props for every sport with a focus on the main sports and leagues in North America. While they do serve 19 states, they are quick to limit high rollers; however the majority of users will find they are more than well served on FanDuel.",
      claim_link: "",
      fb_link: "",
      insta_link: "",
      twitter_link: "",
      _id: { $oid: "64c8fdd191f9a03f399a49c3" },
    },
    {
      image: { filename: "1690899122386-draft.png" },
      dark_mode_img: { filename: "1690899122387-draft-light.png" },
      title: "DraftKings",
      heading: "Get a First Bet Reset up to $250 Back in Bonus Bets!",
      text1: "Available in your state! New users only.",
      text2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      review:
        "DraftKings was one of the first online sportsbooks to launch in the United States when they opened up their platform to New Jersey residents in August of 2018. Since then they have launched in 21 states, the most of any sportsbook. The company was initially a daily fantasy sports platform but quickly added a sportsbook when the law to legalize it in the U.S. went into effect. Due to all of this they are one of the best sportsbooks at creating unique and loads of player prop bets for their users.",
      claim_link: "",
      fb_link: "",
      insta_link: "",
      twitter_link: "",
      _id: { $oid: "64c912b2399b62f1baad54c2" },
    },
    {
      image: { filename: "1690899209557-betmgm.png" },
      dark_mode_img: { filename: "" },
      title: "BetMGM",
      heading:
        "Get up to $1,000 Back in Bonus Bets if Your First Bet Doesn't Win!",
      text1:
        "New users NJ, PA, IN, IA, WV, CO, TN, IL, VA, MI, AZ LA, NY, WY, MD, OH, MA only.",
      text2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      review:
        "Most people recognize the MGM name from their brick and mortar casinos but they were fast to realize the potential of an online sportsbook and thus made BetMGM that serves 19 states. BetMGM is known for their robust offering of live-betting options that include live-player props. Users boast about the favorable odds they can get, especially when live betting which has become increasingly popular. Due to this your account may be quick to be temporarily locked when on a winning betting run.",
      claim_link: "",
      fb_link: "",
      insta_link: "",
      twitter_link: "",
      _id: { $oid: "64c91309399b62f1baad54ca" },
    },
    {
      image: { filename: "1690899285659-caesars.png" },
      dark_mode_img: { filename: "1690899285660-caesars-light.png" },
      title: "Caesars",
      heading: "Bet $20, Get $100 in Bonus Bets!",
      text1: "New users in NJ only",
      text2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      review:
        "Caesars is a brand largely built on the name of their casino as they are the largest retail sportsbook in the United States. Although they were quick to launch an online sportsbook after decades of success running brick and mortar casinos and sportsbooks. Caesars knows what they are doing and will have sharp odds on their player props for those who want fair and consistent props. Users won’t be wowed by their simple sportsbook interface but it is easy to use and offers a great rewards program.",
      claim_link: "",
      fb_link: "",
      insta_link: "",
      twitter_link: "",
      _id: { $oid: "64c91355399b62f1baad54d4" },
    },
    {
      image: { filename: "1690899362873-bet365_img.png" },
      dark_mode_img: { filename: "" },
      title: "Bet365",
      heading: "Bet $1+ on Any Market, Get $200 in Bonus Bets!",
      text1: "New users NJ, IA, CO, VA, OH only",
      text2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      review:
        "If you are in one of the five states that Bet365 is legal in you can take advantage of a generous welcome bonus. They are also one of the largest sportsbooks in the world but are somewhat limited in the United States right now. Users will find that the player prop options may be more limited than some of the newer sportsbooks but the odds can commonly be better for bettors on Bet365.",
      claim_link: "",
      fb_link: "",
      insta_link: "",
      twitter_link: "",
      _id: { $oid: "64c913a3399b62f1baad54e0" },
    },
    {
      image: { filename: "1690899447599-pointsbet.png" },
      dark_mode_img: { filename: "1690899447603-pointsbet-light.png" },
      title: "PointsBet",
      heading: "Get up to $500 in Bonus Bets!",
      text1: "New users IL, IN, KS, LA, MD, MI, NJ, NY, PA, VA, WV only.",
      text2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      review:
        "PointsBet is in 14 U.S. states which gives it fairly good coverage of the legal sports betting states. They are known for having their own in-house betting odds so users will find that their lines can be vastly different from other sportsbooks, particularly with their player props. Be sure to take advantage of their promotions quickly as they may disappear soon after. PointsBet has intriguing odds for player prop bettors and those sports betting deal seekers.",
      claim_link: "https://www.google.com",
      fb_link: "",
      insta_link: "",
      twitter_link: "",
      _id: { $oid: "64c913f7399b62f1baad54ee" },
    },
  ]
