import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./TeamAccordion.css";
import { ThemeState } from "../../../Context";
import { useTeamGames } from "../../../Redux/Accessors/previousGames.accessors";
import PageNumbers from "../../../Common/Pagination";

import AccordianBodyMobile from "./AccordianBodyMobile";
import { getTeamPagePreviousGameTime } from "../../../Utils/dates";

const TeamsAccordionMobile = ({
  id,
  page,
  setPage,
  fetchTeamPreviousGames,
  setEnableScroll,
}) => {
  const games = useTeamGames();
  const { mode } = ThemeState();

  // eslint-disable-next-line no-unused-vars
  const [viewMore, setViewMore] = useState(false);

  const previousGames = games?.data?.previousGames;

  const limit = 5;

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchTeamPreviousGames(skip * limit);
  };

  const handlePrev = () => {
    setEnableScroll(true);

    if (page !== 1) {
      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchTeamPreviousGames(skip * limit);
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(games?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      fetchTeamPreviousGames(skip * limit);
    }
  };

  return (
    <>
      <div className={`team_accordion ${mode === "Light" && "light_theme"}`}>
        <Accordion defaultActiveKey="0">
          {previousGames?.map((game, i) => {
            const teamKey =
              game?.awayTeam?._id === id ? "awayTeam" : "homeTeam";

            return (
              <Accordion.Item key={i} eventKey={`${i}`}>
                <Accordion.Header onClick={() => setViewMore(false)}>
                  <p>
                    <span>{game?.awayTeam?.name}</span> <span>@</span>{" "}
                    <span>{game?.homeTeam?.name}</span>
                    {getTeamPagePreviousGameTime(game?.time)}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <AccordianBodyMobile
                    game={game}
                    teamId={game[teamKey]?._id}
                  />
                  {/* <AccordianBody players={players} teamId={awayTeam?._id} /> */}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
      <div
        className={`mt-4 players_pagination ms-3 ${
          mode === "Light" && "light_theme"
        }`}
      >
        <PageNumbers
          page={page}
          setPage={setPage}
          handleSetPage={(pageNum) => handleSetPage(pageNum)}
          handlePrev={handlePrev}
          handleNext={handleNext}
          totalRecords={games?.totalCount}
          postsPerPage={5}
        />
      </div>
    </>
  );
};

export default TeamsAccordionMobile;

// const homeTeam =
// game?.awayTeam?._id === id ? game?.homeTeam : game?.awayTeam;
// const players =
// game?.awayTeam?._id === id
//   ? game?.awayTeamPlayers
//   : game?.homeTeamPlayers;
