import React, { useState, useEffect } from "react";
import { Accordion, Col, Row, Spinner } from "react-bootstrap";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import StatsBox from "../../../Common/StatsBox";
import Games from "../../../Services/Games";
import { getTab, isResultGreater } from "../../../Utils/getStats";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import { getMonthAndDay } from "../../../Utils/dates";
import { teamNavigator } from "../../../Utils/navigator";
import MatchPlayerStatsBox from "../MatchPlayerStatsBox";
import Loader from "../../../Common/Loader";
import Modal from "../../Modal/SiteModal";
import dataUpdates from "../../../Services/dataUpdate";
import { useUser } from "../../../Redux/Accessors/auth.accessors";

const SearchPlayerAccordianItem = ({
  id,
  game,
  teamId,
  fetchPlayerPreviousGames,
  loading,
  isPlayerDataLoading,
  setIsPlayerDataLoading,
  games,
  setGames,
  setPage,
}) => {
  const tab = useTab();
  const { mode } = ThemeState();
  const navigate = useNavigate();

  const [gameStats, setGameStats] = useState(null);

  const [resultShow, setResultShow] = useState(false);
  const [data, setData] = useState({});
  const [resultLoading, setResultLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (updateSuccess) {
      onResultCloseModal();
      setData({});
      fetchPlayerPreviousGames(0);
      setPage(1);
    }
  }, [updateSuccess]);
  const handleResultModalOpen = (game) => {
    if (user?.user?.role === "admin") {
      // console.log(game);
      setResultShow(true);
      setData({
        teamId: teamId,
        bettingBetType: game?.bettingBetType,
        playerStatsId: game?._id,
        gameId: game.game,
        playerId: game?.player?._id,
        result: game.result || 0,
      });
    }
  };

  const onResultCloseModal = () => {
    setResultShow(false);
  };
  const handleUpdatedData = () => (e) => {
    const inputValue = e.target.value;

    setData((prev) => {
      return {
        ...prev,
        result: inputValue,
      };
    });
  };
  const handleFormSumbit = async (e) => {
    e.preventDefault();
    try {
      setResultLoading(true);
      // console.log(data,"data")
      const res = await dataUpdates.updatePlayerStats(data);
      console.log(res, "response");
      setUpdateSuccess(true);
      setResultShow(false);
    } catch (error) {
      setResultLoading(false);
      console.error("An error occurred while updating banner:", error);
    } finally {
      setResultLoading(false);
    }
  };

  const team = game?.homeTeamPlayers?.find(({ _id }) => _id === id)
    ? "homeTeam"
    : "awayTeam";

  const fetchTeamPlayerStats = async (data) => {
    try {
      setIsPlayerDataLoading(true);

      const res = await Games.statsByPlayer(data);
      setGameStats(res.data?.data);
      setGames(res?.data?.data);
      setIsPlayerDataLoading(false);
    } catch (error) {
      setIsPlayerDataLoading(false);

      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    fetchTeamPlayerStats({
      gameId: game?._id,
      playerId: id,
      bettingBetType: getTab(tab),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);
  console.log(gameStats, "teamName");

  return (
    <>
      <Row>
        <Col
          xs={4}
          md={4}
          className="d-flex align-items-center px-4 gap-2 body_left_section"
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <p>Date {getMonthAndDay(game?.time)}</p>
              <p
                className="cursor-status"
                onClick={() =>
                  teamNavigator(
                    game[team === "homeTeam" ? "awayTeam" : "homeTeam"],
                    navigate,
                    updateSport
                  )
                }
              >
                Opp:{" "}
                <span style={{ cursor: "pointer" }}>
                  {team !== "homeTeam" && "@"}{" "}
                  {game[team === "homeTeam" ? "awayTeam" : "homeTeam"]?.name}
                </span>
              </p>
            </div>
            <div
              className="d-flex align-items-center"
              style={
                isResultGreater(gameStats)
                  ? { color: "#5caa00", cursor: "pointer" }
                  : { color: "#FF003A", cursor: "pointer" }
              }
              onClick={() => handleResultModalOpen(gameStats)}
            >
              <p
                className={
                  mode === "Light" && isResultGreater(gameStats) && "text-black"
                }
              >
                {gameStats?.result || 0}
              </p>
              {isResultGreater(gameStats) ? (
                <HiOutlineArrowNarrowUp />
              ) : (
                <HiOutlineArrowNarrowDown />
              )}
            </div>
          </div>
        </Col>
        <Col xs={8} md={8} className="body_right_section">
          <Row className="align-items-center assets_images">
            {/* <Col md={2}> */}
            <MatchPlayerStatsBox
              playerStats={gameStats?.fanduel}
              gameType="fanduel"
              game={gameStats}
              fetchSchedulesGames={fetchPlayerPreviousGames}
              teamId={teamId}
              statsLoading={isPlayerDataLoading}
              setPage={setPage}
            />
            {/* </Col> */}
            {/* <Col md={2}> */}
            <MatchPlayerStatsBox
              playerStats={gameStats?.draftkings}
              gameType="draftkings"
              game={gameStats}
              fetchSchedulesGames={fetchPlayerPreviousGames}
              teamId={teamId}
              statsLoading={isPlayerDataLoading}
              setPage={setPage}
            />
            {/* </Col> */}
            {/* <Col md={2}> */}
            <MatchPlayerStatsBox
              playerStats={gameStats?.betmgm}
              gameType="betmgm"
              game={gameStats}
              fetchSchedulesGames={fetchPlayerPreviousGames}
              teamId={teamId}
              statsLoading={isPlayerDataLoading}
              setPage={setPage}
            />
            {/* </Col> */}
            {/* <Col md={2}> */}
            <MatchPlayerStatsBox
              playerStats={gameStats?.caesars}
              gameType="caesars"
              game={gameStats}
              fetchSchedulesGames={fetchPlayerPreviousGames}
              teamId={teamId}
              statsLoading={isPlayerDataLoading}
              setPage={setPage}
            />
            {/* </Col> */}
            {/* <Col md={2}> */}
            <MatchPlayerStatsBox
              playerStats={gameStats?.consensus}
              gameType="consensus"
              game={gameStats}
              fetchSchedulesGames={fetchPlayerPreviousGames}
              teamId={teamId}
              statsLoading={isPlayerDataLoading}
              setPage={setPage}
            />
            {/* </Col> */}
            {/* <Col md={2}> */}
            <MatchPlayerStatsBox
              playerStats={gameStats?.pointsbet}
              gameType="pointsbet"
              game={gameStats}
              fetchSchedulesGames={fetchPlayerPreviousGames}
              teamId={teamId}
              statsLoading={isPlayerDataLoading}
              setPage={setPage}
            />
            {/* </Col> */}
          </Row>
        </Col>
      </Row>
      <Modal
        show={resultShow}
        setShow={setResultShow}
        onCloseModal={onResultCloseModal}
      >
        <div className="edit_value_modal">
          <div style={{ width: "80%" }}>
            <form style={{ width: "100%" }} onSubmit={handleFormSumbit}>
              <label>Result</label>
              <input
                type="number"
                value={data?.result}
                style={{ width: "100%" }}
                onChange={handleUpdatedData()}
              />
              <div className="text-end">
                <button type="submit" disabled={resultLoading}>
                  {resultLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchPlayerAccordianItem;
