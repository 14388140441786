import React from "react";
import Modal from "../../../Components/Modal/SiteModal";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { cloudURL } from "../../../Utils/helper";
// import { useSelector } from "react-redux";
import { useState } from "react";

const EditAboutSectionModal = ({
  show,
  setShow,
  aboutSection,
  onChangeHandler,
  handleImageChange,
  sectionUploadedImages,
  onCloseModal,
  submitHandler,
  loading
}) => {
//   console.log(promo);
// const {loading}=useSelector((state)=>state.aboutUpdate)
// const [loading,setLoading]=useState(false)

  return (
    <div >

    <Modal
      show={show}
      setShow={setShow}
      style="promo_modal"
      size="lg"
      heading="Edit About Section"
      onCloseModal={onCloseModal}
    >
      <>
        <Form onSubmit={submitHandler}>
          <Row className="justify-content-center edit_promo">
            <Col md={11} className="mt-4">
              <Row className="gx-5">
                <Col md={12}>
                  <Form.Label>Image</Form.Label>
                  <Form.Group className="mb-3 cms_image_input">
                    <Form.Control
                      type="file"
                      style={{ fontSize: "13px", paddingBottom: "7px" }}
                      name={`image`}
                      onChange={handleImageChange("image")}
                    />
                  </Form.Group>
                  <div className="previewImg_div">
                    <img
                      src={
                        sectionUploadedImages?.imagePreview
                          ? sectionUploadedImages?.imagePreview
                          : aboutSection?.image?.filename
                          ? `${cloudURL}/${aboutSection.image.filename}`
                          : "/images/empty.jpg"
                      }
                      alt=""
                      style={{}}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-5">
                
                <Col md={12}>
                  <Form.Label>Heading</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      name="heading"
                      value={aboutSection.heading}
                      onChange={onChangeHandler("heading")}
                    />
                  </InputGroup>
                </Col>
              </Row>
              
              <Row className="gx-5">
                <Col md={12}>
                  <Form.Label>Text</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder=""
                      name="text"
                      value={aboutSection.text}
                      onChange={onChangeHandler("text")}
                    />
                  </InputGroup>
                </Col>
              </Row>
              
              
              <Row className="justify-content-end">
                <button
                  type="submit"
                  style={{
                    width: "fit-content",
                    backgroundColor: "red",
                    border: "none",
                    color: "white",
                    padding: "5px 15px",
                    borderRadius: "6px",
                  }}
                >
                  {loading ? <Spinner animation='border' size='sm' /> : 'Submit'}
                </button>
              </Row>
            </Col>
          </Row>
        </Form>
      </>
    </Modal>
    </div>

  );
};



export default EditAboutSectionModal