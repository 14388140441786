import React from "react";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { updateTab } from "../../Redux/Accessors/game.accessors";
// import { ThemeState } from "../../../Context";
const MatchesHeader = ({ name, setName, tabs, selectedTab }) => {
  //   const {mode} = ThemeState()
  return (
    <div className={`points_table_header_main matches_header`}>
      <button>
        <HiOutlineArrowNarrowLeft />
      </button>
      <div>
        {tabs && tabs.map(({ tab }, index) => (
          <button
            key={index}
            onClick={() => {
              updateTab(tab);
            }}
            className={`${selectedTab === tab ? "points_active" : ""}`}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MatchesHeader;
