import React, { useEffect, useRef, useState } from "react";
import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import SearchSuggestion from "../../Layout/Header/SearchSuggestion";
import Games from "../../../Services/Games";

const PlayerSearch = ({ search, setSearch, playerData, setPlayerData }) => {
  const suggestionRef = useRef(null);
  const [players, setPlayers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  const onSearch = async (name) => {
    setSearch(name);
    if (name?.length >= 2) {
      try {
        setIsLoading(true);
        const res = await Games.search(name);
        setPlayers(res?.data?.data);
      } catch (error) {
        setIsLoading(false);
        console.log("Error in searching players");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="search-wrapper mb-4 mb-md-0" ref={suggestionRef}>
      <div
        className="w-100 search_bar p-1 d-flex justify-content-between align-items-center bg-white"
        style={{
          borderRadius: "5px",
          border: "2px solid #A4A4A4",
        }}
      >
        <Form.Control
          style={{
            height: "27px",
          }}
          className="w-100 search-focus"
          type="search"
          placeholder="Search for player totals or results"
          aria-label="Search"
          value={search}
          onChange={(e) => onSearch(e.target.value)}
          onFocus={() => setShowDropdown(true)}
        />
        {!search.length && (
          <img
            src="/images/search.png"
            alt="search"
            width={"20px"}
            height={"20px"}
          />
        )}
      </div>
      {search.length >= 2 && showDropdown && (
        <SearchSuggestion
          players={players}
          setPlayerName={setSearch}
          isLoading={isLoading}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          search={true}
          setPlayerData={setPlayerData}
        />
      )}
    </div>
  );
};

export default PlayerSearch;
