import React from "react";
import "./borderLine.css";
import { ThemeState } from "../../../Context";
const BorderLine = ({ compare }) => {
  const { mode } = ThemeState();
  return (
    <div
      className={`border_line_main ${compare && mode === "Dark" && "col-yellow"}
    ${compare && mode === "Light" && "col-black"}
    ${mode === "Light" && "light_theme"}`}
    >
      {compare && <h3>Compare Your Players For Odds & Totals</h3>}
    </div>
  );
};

export default BorderLine;
