import { apiRequest } from "../Utils/apiRequest";
import { userRoute } from "../Utils/routes";

const User = {
  login: (email, phone, password) => {
    return apiRequest(userRoute.login, "POST", {
      email,
      phone,
      password,
    });
  },

  socialLgoin: (data) => {
    return apiRequest(userRoute.socialLogin, "POST", data);
  },

  signup: (email, phone, password, state) => {
    const requestData = {
      password,
      state,
      ...(phone ? { phone } : { email }),
    };

    return apiRequest(userRoute.signup, "POST", requestData);
  },

  verifyOtp: (data) => {
    return apiRequest(userRoute.verifyOtp, "POST", data);
  },

  sendOtp: (data) => {
    return apiRequest(userRoute.sendOtp, "POST", data);
  },

  update: (data) => {
    return apiRequest(userRoute.update, "PUT", data);
  },
  forgetPassword: (data) => {
    return apiRequest(userRoute.forgetPassword, "PUT", data);
  },
  updatePassword: (data) => {
    return apiRequest(userRoute.updatePassword, "PUT", data);
  },

  getFavouritePlayer: () => {
    return apiRequest(`${userRoute.favourite}`);
  },

  addFavourite: (data) => {
    return apiRequest(`${userRoute.favourite}`, "POST", data);
  },

  removeFavourite: (id) => {
    return apiRequest(`${userRoute.favourite}/${id}`, "DELETE");
  },

  getNotifications: () => {
    return apiRequest(`${userRoute.notification}`);
  },

  getPlayerNotifications: (id) => {
    return apiRequest(`${userRoute.playerNotification}/${id}`);
  },

  getTeamNotifications: (id) => {
    return apiRequest(`${userRoute.teamNotification}/${id}`);
  },

  deleteNotification: (id) => {
    return apiRequest(`${userRoute.deleteNotification}/${id}`, "DELETE");
  },
};

export default User;
