import React from "react";
import { Col } from "react-bootstrap";
import { useSport, useTab } from "../../Redux/Accessors/game.accessors";
import { isShow, overValue, underValue } from "../../Utils/getStats";
import { useState } from "react";
import { useEffect } from "react";
import Modal from "../../Components/Modal/SiteModal";
import dataUpdates from "../../Services/dataUpdate";
import Loader from "../../Common/Loader";
import { useUser } from "../../Redux/Accessors/auth.accessors";

const MatchPlayerStatsBox = ({
  playerStats,
  gameType,
  game,
  fetchSchedulesGames,
  teamId,
  statsLoading = false,
  setPage
}) => {
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { user } = useUser();
  
  // console.log(game, "game");
  useEffect(() => {
    setData({
      gameId: game?.game,
      bettingBetType: game?.bettingBetType,
      playerStatsId: game?._id,
      playerId: game?.player?._id,
      [gameType]: playerStats,
      teamId: teamId,
    });
  }, [show]);
  // console.log(data, "modal data");
  useEffect(() => {
    if (updateSuccess) {
      onCloseModal();
      setData({});
      fetchSchedulesGames();
      setUpdateSuccess(false);
      setPage && setPage(1)
    }
  }, [updateSuccess]);

  const tab = useTab();

  let sport = useSport();
  sport?.toLowerCase();
  // console.log(data, "data");
  const handleModalOpen = () => {
    setShow(true);
  };
  const onCloseModal = () => {
    setShow(false);
  };

  const handleUpdatedData = (gameType, name) => (e) => {
    const inputValue = e.target.value;

    setData((prev) => {
      return {
        ...prev,
        [gameType]: { ...prev[gameType], [name]: inputValue },
      };
    });
  };
  // console.log(data);
  const handleFormSumbit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // console.log(data,"data")
      const res = await dataUpdates.updatePlayerStats(data);
      // console.log(res, "response");
      setUpdateSuccess(true);
      setData({});
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while updating banner:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Col xs={2} md={2} style={{ position: "relative" }}>
        {loading || statsLoading ? (
          <Loader />
        ) : (
          <div className="text-center d-flex align-items-center gap-1">
            {playerStats?.value || playerStats?.over || playerStats?.under ? (
              <div className="text-center">
                <h3>
                  {!isShow(playerStats?.value, tab) &&
                    (playerStats?.value ? playerStats?.value : "N/A")}
                  {!!isShow(playerStats?.value, tab) &&
                    playerStats?.value !== 1.5 &&
                    (overValue(playerStats?.over, sport, tab)
                      ? overValue(playerStats?.over, sport, tab)
                      : "N/A")}
                </h3>
                {!isShow(playerStats?.value, tab) && (
                  <div className="flex d-flex align-items-center justify-content-center text-center">
                    <p className="text-center">
                      O {overValue(playerStats?.over, sport)}
                    </p>
                    {!!underValue(playerStats?.under, sport, tab) &&
                      playerStats?.value &&
                      tab !== "1 + Hits" && (
                        <p style={{ marginLeft: "5px" }}>
                          U {underValue(playerStats?.under, sport, tab)}
                        </p>
                      )}
                  </div>
                )}
                {user?.user?.role === "admin" && (
                  <img
                    src="/images/edit-icon.png"
                    className="match_stats_edit_icon"
                    style={{
                      position: "absolute",
                      right: "3px",
                      top: "3px",
                      cursor: "pointer",
                    }}
                    onClick={handleModalOpen}
                  />
                )}
              </div>
            ) : (
              <div className="flex d-flex align-items-center justify-content-center text-center">
                <p>N/A</p>
                {user?.user?.role === "admin" && (
                  <img
                    src="/images/edit-icon.png"
                    className="match_stats_edit_icon"
                    style={{
                      position: "absolute",
                      right: "3px",
                      top: "3px",
                      cursor: "pointer",
                    }}
                    onClick={handleModalOpen}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </Col>
      <Modal show={show} setShow={setShow} onCloseModal={onCloseModal}>
        <div className="edit_value_modal">
          <div style={{ width: "80%" }}>
            <form style={{ width: "100%" }} onSubmit={handleFormSumbit}>
              <label>Value</label>
              {!isShow(data[gameType]?.value, tab) ? (
                // && data[gameType]?.value
                <input
                  // type="number"
                  value={data[gameType]?.value}
                  style={{ width: "100%" }}
                  onChange={handleUpdatedData(gameType, "value")}
                />
              ) : (
                !!isShow(data[gameType]?.value, tab) &&
                data[gameType]?.value !== 1.5 &&
                overValue(data[gameType]?.over, sport, tab) && (
                  <input
                    // type="number"
                    value={data[gameType]?.over}
                    style={{ width: "100%" }}
                    onChange={handleUpdatedData(gameType, "over")}
                  />
                )
              )}
              {!isShow(data[gameType]?.value, tab) && (
                <div style={{ marginTop: "20px" }}>
                  <label>Over Value (O)</label>
                  <input
                    // type="number"
                    value={data[gameType]?.over}
                    style={{ width: "100%" }}
                    onChange={handleUpdatedData(gameType, "over")}
                  />
                </div>
              )}
              {!isShow(data[gameType]?.value, tab) &&
                !!underValue(data[gameType]?.under, sport, tab) &&
                // data[gameType]?.under &&
                tab !== "1 + Hits" && (
                  <div style={{ marginTop: "20px" }}>
                    <label>Under Value (U)</label>

                    <input
                      // type="number"
                      value={data[gameType]?.under}
                      style={{ width: "100%" }}
                      onChange={handleUpdatedData(gameType, "under")}
                    />
                  </div>
                )}

              <div className="text-end">
                <button type="submit" disabled={loading}>
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MatchPlayerStatsBox;
