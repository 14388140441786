import { dataCompanies } from "../Constants";

export const getTab = (tab) => {
  switch (tab) {
    case "Home Runs":
      return "Total Home Runs";
    case "1 + Hits":
      return "Total Hits";
    case "1 + Runs":
      return "Total Runs";
    case "1 + RBIs":
      return "Total RBIs";
    case "Stolen Bases":
      return "Stolen Bases";
    case "Strikeouts":
      return "Total Pitching Strikeouts";
    case "Earned Runs":
      return "Total Earned Runs Allowed";
    case "Anytime TD":
      return "Anytime TD";
    case "Passing Yds":
      return "Passing Yds";
    case "Passing TD":
      return "Passing TD";
    case "Rushing Yds":
      return "Rushing Yds";
    case "Receptions":
      return "Receptions";
    case "Receiving Yds":
      return "Receiving Yds";
    case "First TD":
      return "First TD";
      case "Points":
      return "Total Points";
    case "Rebounds":
      return "Total Rebounds";
    case "Assists":
      return "Total Assists";
    case "Threes":
      return "Total 3-Pointers Made";
    case "Steals":
      return "Total Steals";
    case "Blocks":
      return "Total Blocks";
    case "Pts + Ast":
      return "Total Points & Assists";
    case "Pts + Rebs":
      return "Total Points & Rebounds";
    case "Pts + Rebs + Ast":
      return "Total Points, Rebounds, & Assists";
    default:
      return "Total Home Runs";
  }
};

export const getStats = (company, tab, stats) => {
  const result = stats?.find(({ bettingBetType }) => bettingBetType === tab);
  return result ? result[company] : null;
};

export const getResult = (tab, stats) => {
  const game = stats?.find(({ bettingBetType }) => bettingBetType === tab);
  return game ? game?.result : 0;
};

export const overValue = (value, sport, tab) => {
  if (!value) {
    return "N/A";
  }
  if (sport === "nba") {
    return value;
  } else {
    // if (tab === "1 + Hits") {
    //   return value < 0 ? value : null;
    // }
    if (tab === "2 + Hits") {
      return value > 0 ? `+${value}` : null;
    }
    return value > 0 ? `+${value}` : value;
  }
};

export const underValue = (value, sport, tab) => {
  if (!value) {
    return "N/A";
  }
  if (sport === "nba") {
    return value;
  } else {
    if (tab === "2 + Hits") {
      return null;
    }
    return value > 0 ? `+${value}` : value;
  }
};

export const isShow = (decimal, tab) => {
  switch (tab) {
    case "Home Runs":
      return true;
    case "1 + Hits":
      if (decimal && decimal >= 1.5) {
        return false;
      } else {
        return true;
      }
    case "1 + Runs":
      return true;
    case "1 + RBIs":
      return true;
    case "Stolen Bases":
      return true;
    case "Anytime TD":
      return true;
    case "First TD":
      return true;
    default:
      return false;
  }
};

export const getNullValues = (selectedTab, playerStats) => {
  const allStatsNull = Object.values(dataCompanies).every((dataCompany) => {
    const stats = getStats(dataCompany, getTab(selectedTab), playerStats);
    return !stats;
  });
  return allStatsNull;
};

export const isOnlyOver = (decimal, tab) => {
  if (!tab) return;

  switch (tab) {
    case "Home Runs":
      return true;
    case "1 + Hits":
      if (decimal && decimal >= 1.5) return false;
      return true;
    case "1 + Runs":
      return true;
    case "1 + RBIs":
      return true;
    case "Stolen Bases":
      return true;
    case "Anytime TD":
      return true;
    case "First TD":
      return true;
    default:
      return false;
  }
};

const checkStatValues = (companySite, tab) => {
  if (!companySite) return true;

  if (isOnlyOver(companySite?.value, tab)) {
    return companySite?.over === null;
  }

  return (
    typeof companySite === "object" &&
    Object.values(companySite).every((attr) => attr === null)
  );
};

export const getDataValues = (stats, tab) => {
  return (
    checkStatValues(stats?.fanduel, tab) &&
    checkStatValues(stats?.draftkings, tab) &&
    checkStatValues(stats?.betmgm, tab) &&
    checkStatValues(stats?.caesars, tab) &&
    checkStatValues(stats?.consensus, tab) &&
    checkStatValues(stats?.pointsbet, tab)
  );
};

export const isResultGreater = (stats) => {
  const result = stats?.result || 0;
  if (
    result > (stats?.fanduel?.value || 0) &&
    result > (stats?.draftkings?.value || 0) &&
    result > (stats?.betmgm?.value || 0) &&
    result > (stats?.caesars?.value || 0) &&
    result > (stats?.consensus?.value || 0) &&
    result > (stats?.pointsbet?.value || 0)
  ) {
    return true;
  } else return false;
};

export const MLB_BATTING_TOGGLES_KEYS = {
  "Home Runs": "Total Home Runs",
  "To Hit Home Runs": "To Hit A Home Run",
  Hits: "Total Hits",
  "To Get A Hit": "To Get A Hit",
  Runs: "Total Runs",
  "To Score A Run": "To Score A Run",
  RBIs: "Total RBIs",
  "To Record an RBI": "To Record an RBI",
  "Total Bases": "Total Bases",
  "Stolen Bases": "Stolen Bases",
  "To Steal A Base": "To Steal A Base",
  "Total Hits": "Total Hits",
  "Total Runs": "Total Runs",
  "Total RBIs": "Total RBIs",
};

export const MLB_PITCHING_TOGGLES_KEYS = {
  Strikeouts: "Total Pitching Strikeouts",
  "Earned Runs": "Total Earned Runs Allowed",
};

// return (
//   companySite &&
//   typeof companySite === "object" &&
//   Object.values(companySite).every((attr) => !attr)
// );
