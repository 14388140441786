import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import {
  updateSport,
  updateTab,
  useSettings,
} from "../../../Redux/Accessors/game.accessors";
import { playerNavigator } from "../../../Utils/navigator";
import { updateSportTab } from "../../../Utils/tabs";
import "./HomeBanner.css";
import Modal from "../../../Components/Modal/SiteModal";

const HomeBanner = () => {
  const settings = useSettings() || {};
  const { mode } = ThemeState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const getSportLogo = (sport) => {
    switch (sport) {
      case "NBA":
        return {
          src: "/images/Logo-NBA.png",
          alt: "nikola jokic prop bets",
        };
      case "MLB":
        return { src: "/images/Logo-MLB.png", alt: "shohei ohtani stats" };
      case "NFL":
        return { src: "/images/Logo-NFL.png", alt: "rb austin ekeler" };
      case "NHL":
        return "/images/Logo-NBA.png";
      default:
        return "/images/Logo-NBA.png";
    }
  };

  const getLogoSize = (sport) => {
    switch (sport) {
      case "NBA":
        return { width: "34px", height: "30px" };
      case "MLB":
        return { width: "30px", height: "30px" };
      case "NFL":
        return { width: "26px", height: "30px" };
      case "NHL":
        return { width: "35px", height: "30px" };
      default:
        return { width: "30px", height: "30px" };
    }
  };
  const onCloseModal = () => {
    setShow(false);
  };

  return (
    <div
      className={`home_banner_main ${mode === "Light" ? "light_theme" : ""}`}
      role="img"
      alt="Top Betting Prediction"
    >
      <Container>
        <Row>
          <Col md={6}>
            <div className="d-flex align-items-start banner_content">
              {mode === "Light" ? (
                <img src="/images/right-arrow-light.png" alt="" />
              ) : (
                <img src="/images/right-arrow.png" alt="" />
              )}
              <h1>
                Your <span>Information Source</span> for Player{" "}
                <span>Prop Bets</span>
              </h1>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex flex-column align-items-md-end banner_btns mt-4 mt-md-0">
              <button onClick={() => setShow(!show)}>GO PREMIUM</button>
              {!!settings &&
                !!settings.players &&
                [...settings?.players]
                  ?.sort((a, b) => a?.firstName.localeCompare(b?.firstName))
                  .map((player, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        updateSport(player?.sport);
                        updateSportTab(player?.sport, updateTab);
                        playerNavigator(player, navigate, updateSport);
                      }}
                      // navigate(`/player/${player?._id}`);
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#4598ff",
                          fontWeight: 500,
                        }}
                      >
                        <span style={{ width: "150px", float: "left" }}>
                          {player?.firstName} {player?.lastName}
                        </span>
                        <span style={{ marginLeft: "10px" }}>
                          <img
                            src={getSportLogo(player?.sport)?.src}
                            alt={`${player?.firstName} ${player?.lastName} - ${
                              getSportLogo(player?.sport)?.alt
                            }`}
                            style={getLogoSize(player?.sport)}
                          />
                        </span>
                      </span>
                    </button>
                  ))}

              {/* <button>Joel Embid ( - 120 )</button>
              <button>Jason Tatum ( - 115 )</button>
              <button>James Harding ( + 50 )</button> */}
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={show} setShow={setShow} onCloseModal={onCloseModal} bg>
        <div className="edit_value_modal coming-soon-modal">
          <img
            src="/images/coming-soon.png"
            style={{ width: "90%", height: "auto" }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default HomeBanner;
