import React, { useState, useEffect } from "react";
import { ThemeState } from "../../Context";
import Games from "../../Services/Games";
import { getTab } from "../../Utils/getStats";
import { updateSport, useTab } from "../../Redux/Accessors/game.accessors";
import StatsBox from "../../Common/StatsBox";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { errorNotify } from "../../Utils/toast";
import CompareLoader from "./CompareLoader";
import { useNavigate } from "react-router-dom";
import { playerNavigator } from "../../Utils/navigator";

const MobileStatsCol = ({ playerId, removeIdFromSport }) => {
  const { mode } = ThemeState();
  const tab = useTab();
  const navigate = useNavigate();

  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPlayersDataToCompare = async () => {
    try {
      setIsLoading(true);
      const res = await Games.getDataToCompare(playerId, getTab(tab));
      if (res?.data?.data?.length) {
        setStats(res?.data?.data[0]);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlayersDataToCompare();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const onRemove = (sport) => {
    removeIdFromSport(sport, playerId);
    errorNotify(`Player removed from ${sport} successfully`);
  };

  return (
    <div>
      <div
        className={`d-flex text-white compare_assists_header_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
        style={{ position: "relative" }}
      >
        <div className="d-flex align-items-center">
          <h3
            className="me-2 text-center"
            style={{ cursor: "pointer" }}
            onClick={() =>
              playerNavigator(
                stats?.playerWithStats?.player,
                navigate,
                updateSport
              )
            }
            // navigate(`/player/${stats?.playerWithStats?.player?._id}`)
          >
            {stats?.playerWithStats?.player?.firstName
              ? stats?.playerWithStats?.player?.firstName +
                " " +
                stats?.playerWithStats?.player?.lastName
              : "Player Name"}{" "}
            <br />
            <p>
              {stats?.playerWithStats?.player?.firstName &&
                stats?.playerWithStats?.player?.position}
            </p>
          </h3>
          <AiOutlineMinusCircle
            className="compare_minus_icon"
            style={{ position: "absolute", cursor: "pointer" }}
            onClick={() => onRemove(stats?.playerWithStats?.player?.sport)}
          />
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {!isLoading ? (
            <StatsBox playerStats={stats?.playerWithStats?.fanduel} />
          ) : (
            <CompareLoader />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {!isLoading ? (
            <StatsBox playerStats={stats?.playerWithStats?.draftkings} />
          ) : (
            <CompareLoader />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {!isLoading ? (
            <StatsBox playerStats={stats?.playerWithStats?.betmgm} />
          ) : (
            <CompareLoader />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {!isLoading ? (
            <StatsBox playerStats={stats?.playerWithStats?.caesars} />
          ) : (
            <CompareLoader />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {!isLoading ? (
            <StatsBox playerStats={stats?.playerWithStats?.consensus} />
          ) : (
            <CompareLoader />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body_data bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {!isLoading ? (
            <StatsBox playerStats={stats?.playerWithStats?.pointsbet} />
          ) : (
            <CompareLoader />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileStatsCol;
