import React, { useEffect, useState } from "react";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import PointsTableHeader from "../../Components/Home/PointsTableHeader/PointsTableHeader";
import MobilePointsTableHeader from "../../Components/Home/PointsTableHeader/MobilePointsTableHeader";
import { Col, Container, Row } from "react-bootstrap";
import CompareTable from "../../Components/Compare/CompareTable";
import "./compare.css";
import ComparePremiumCard from "../../Components/Home/PremiumCard/ComparePremiumCard";
import MobileCompareTable from "../../Components/Compare/MobileCompareTable";
import { ThemeState } from "../../Context";
import AutoCompleteSearch from "../../Components/Layout/AutoCompleteSearch/AutoCompleteSearch";
import BorderLine from "../../Components/Teams/BorderLine/BorderLine";
import { updateSport, useSport } from "../../Redux/Accessors/game.accessors";
import PageWrapper from "../../Common/PageWrapper";
import Header from "../../Components/Layout/Header/Header";
import { updateHeaderTab } from "../../Redux/Accessors/header.accessors";
import { scrollToTop } from "../../Utils/scrollToTop";

const Compare = () => {
  const sport = useSport();
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState("Assists");
  const { mode } = ThemeState();
  const [compares, setCompares] = useState(4);
  const [selectedTab, setSelectedTab] = useState("NFL");
  // eslint-disable-next-line no-unused-vars
  const [compNav, setCompNav] = useState("All");
  const [playerIds, setPlayerIds] = useState({
    NFL: [],
    NBA: [],
    MLB: [],
    NHL: [],
  });

  const tabs = ["NFL", "NBA", "MLB", "NHL"];

  useEffect(() => {
    scrollToTop();
    updateHeaderTab("COMPARE");
    updateSport(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  function addIdToSport(sport, id) {
    setPlayerIds((prevState) => {
      if (!prevState[sport].includes(id)) {
        return {
          ...prevState,
          [sport]: [...prevState[sport], id],
        };
      }
      return prevState; // Return previous state if ID is already present
    });
  }

  function removeIdFromSport(sport, id) {
    setPlayerIds((prevState) => {
      const index = prevState[sport || selectedTab].indexOf(id);
      if (index !== -1) {
        return {
          ...prevState,
          [sport || selectedTab]: [
            ...prevState[sport || selectedTab].slice(0, index),
            ...prevState[sport || selectedTab].slice(index + 1),
          ],
        };
      }
      return prevState; // Return previous state if ID is not found
    });
  }

  return (
    <PageWrapper>
      <div>
        <div className="home_desktop">
          <BorderLine compare />
          <Header isComparePage={true} />
          {/* <CompareBanner compNav={compNav} /> */}
          <Container
            fluid
            className={`px-1 px-md-5 compare_page_content ${
              mode === "Light" && "light_theme"
            }`}
          >
            <div
              className="d-flex align-items-center gap-2 navigation_bar_main"
              style={{ overflow: "auto" }}
            >
              {tabs?.map((tab, index) => (
                <div
                  key={index}
                  className={`${
                    tab === selectedTab && "selected-notification-tab"
                  }`}
                  onClick={() => {
                    setSelectedTab(tab);
                    updateSport(tab);
                  }}
                >
                  <h3>{tab}</h3>
                </div>
              ))}
            </div>
            <div className="justify-content-start">
              <Col md={6} className="">
                <AutoCompleteSearch
                  playerIds={playerIds}
                  gameSport={selectedTab}
                  addIdToSport={(sport, id) => addIdToSport(sport, id)}
                  removeIdFromSport={(sport, id) =>
                    removeIdFromSport(sport, id)
                  }
                />
              </Col>
            </div>
            {selectedTab !== "NHL" && <PointsTableHeader />}
            <CompareTable
              name={name}
              compares={compares}
              setCompares={setCompares}
              playerIds={playerIds[selectedTab]}
              removeIdFromSport={(sport, id) => removeIdFromSport(sport, id)}
            />
            {!!playerIds[selectedTab]?.length && (
              <Row className="justify-content-end">
                {compares > 0 && (
                  <Col md={12}>
                    <ComparePremiumCard
                      name={name}
                      compares={compares}
                      setCompares={setCompares}
                      playerIds={playerIds[selectedTab]}
                    />
                  </Col>
                )}
              </Row>
            )}
          </Container>
          <Footer compare />
        </div>
        <div
          className={`home_mobile compare_page_mobile ${
            mode === "Light" && "light_theme"
          }`}
        >
          <BorderLine compare />
          <MobileHeader bgWhite isComparePage={true} />
          {/* <CompareBanner mobile compNav={compNav} /> */}
          <div
            className="d-flex align-items-center gap-2 navigation_bar_main px-2"
            style={{ overflow: "auto" }}
          >
            {tabs?.map((tab, index) => (
              <div
                key={index}
                className={`${
                  tab === selectedTab && "selected-notification-tab"
                }`}
                onClick={() => {
                  setSelectedTab(tab);
                  updateSport(tab);
                }}
              >
                <h3>{tab}</h3>
              </div>
            ))}
          </div>
          <div className="px-2 my-2">
            <AutoCompleteSearch
              playerIds={playerIds}
              gameSport={selectedTab}
              addIdToSport={(sport, id) => addIdToSport(sport, id)}
              removeIdFromSport={(sport, id) => removeIdFromSport(sport, id)}
            />
          </div>
          <div
            className="mt-3 mt-md-0"
            style={{ position: "sticky", top: 0, left: 0, zIndex: "99999" }}
          >
            {selectedTab !== "NHL" && <MobilePointsTableHeader />}
          </div>
          <MobileCompareTable
            name={name}
            compares={compares}
            setCompares={setCompares}
            playerIds={playerIds[selectedTab]}
            removeIdFromSport={(sport, id) => removeIdFromSport(sport, id)}
          />
          {!!playerIds[selectedTab]?.length && (
            <ComparePremiumCard
              compares={compares}
              name={name}
              playerIds={playerIds[selectedTab]}
            />
          )}
          <MobileFooter bgWhite />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Compare;
