import React from "react";
import Accordion from "react-bootstrap/Accordion";
import PageNumbers from "../../../Common/Pagination";
import { ThemeState } from "../../../Context";
import { useTeamGames } from "../../../Redux/Accessors/previousGames.accessors";
import { getTeamPagePreviousGameTime } from "../../../Utils/dates";
import AccordianBody from "./AccordianBody";
import "./TeamAccordion.css";

const TeamsAccordion = ({
  id,
  page,
  setPage,
  fetchTeamPreviousGames,
  setEnableScroll,
}) => {
  const { mode } = ThemeState();
  const games = useTeamGames();

  const previousGames = games?.data?.previousGames;

  const limit = 5;

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchTeamPreviousGames(skip * limit);
  };

  const handlePrev = () => {
    setEnableScroll(true);

    if (page !== 1) {
      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchTeamPreviousGames(skip * limit);
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(games?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      fetchTeamPreviousGames(skip * limit);
    }
  };

  return (
    <>
      <div className={`team_accordion ${mode === "Light" && "light_theme"}`}>
        {previousGames?.map((game, i) => {
          const teamKey = game?.awayTeam?._id === id ? "awayTeam" : "homeTeam";

          return (
            <Accordion key={i} defaultActiveKey="0">
              <Accordion.Item eventKey={`${i}`}>
                <Accordion.Header
                // onClick={() => setViewMore(false)}
                >
                  <p>
                    <span>{game?.awayTeam?.name}</span> <span>@</span>{" "}
                    <span>{game?.homeTeam?.name}</span>{" "}
                    {getTeamPagePreviousGameTime(game?.time)}
                  </p>
                </Accordion.Header>
                <Accordion.Body className="teams_accordion_body">
                  <AccordianBody game={game} teamId={game[teamKey]?._id} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          );
        })}
      </div>
      {previousGames?.length && (
        <div
          className={`mt-5 players_pagination ${
            mode === "Light" && "light_theme"
          }`}
        >
          <PageNumbers
            page={page}
            setPage={setPage}
            handleSetPage={(pageNum) => handleSetPage(pageNum)}
            handlePrev={handlePrev}
            handleNext={handleNext}
            totalRecords={games?.totalCount}
            postsPerPage={5}
          />
        </div>
      )}
    </>
  );
};

export default TeamsAccordion;
