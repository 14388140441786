import React from "react";
import { ThemeState } from "../../Context";

const Loader = () => {
  const { mode } = ThemeState();

  return (
    <div className="text-center pt-5 pb-5">
      {mode !== "Light" ? (
        <img
          src="/images/loader.gif"
          alt="loader"
          style={{ width: "50px", height: "50px" }}
        />
      ) : (
        <img
          src="/images/dark-loader.gif"
          alt="loader"
          style={{ width: "50px", height: "50px" }}
        />
      )}
    </div>
  );
};

export default Loader;
