// /* global PasswordCredential */

import React, { useEffect, useState, useRef } from "react";
import "./login.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { onSuccess, onError } from "../../Utils/toast";
import {
  getUser,
  saveAuth,
  useUser,
} from "../../Redux/Accessors/auth.accessors";
import { loginSchema } from "../../Schemas";
import User from "../../Services/User";
import { saveToken } from "../../Utils/token";
import OtpModal from "../../Components/Modal/OtpModal";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import Loader from "../../Components/Loader/ButtonLoader";
import {
  removeCredentials,
  saveCredentials,
} from "../../Utils/localStorageCredentials";

const Login = () => {
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [signUpOpt, setSignUpOpt] = useState(
    localStorage.getItem("loginType") || "Email"
  );
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRemember, setIsRemember] = useState(
    JSON.parse(localStorage.getItem("remember")) || false
  );
  const [showPassword, setShowPassword] = useState(false);

  const facebookLoginRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const isSubAdmin = location.pathname?.split("/").includes("sub-admin");
  const loggedInUser = useUser();
  const role = loggedInUser?.user?.user?.role;
  console.log(role, "role");
  const getRole = (user) => {
    const role = user.role;
    return role === "admin"
      ? "super-admin"
      : role === "sub-admin"
      ? "sub-admin"
      : "user";
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const userInfo = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse?.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse?.access_token}`,
            Accept: "application/json",
          },
        }
      );

      const { email, given_name, family_name, picture, verified_email } =
        userInfo?.data;
      const data = {
        email,
        firstName: given_name,
        lastName: family_name,
        profile: picture,
        isVerified: verified_email,
        provider: "google",
      };

      const response = await User.socialLgoin(data);
      const { token, verified } = response?.data?.data;

      if (!verified) {
        localStorage.setItem("tempUser", JSON.stringify(response?.data?.data));
        setShowOtpModal(true);
      } else {
        // console.log(response?.data?.data,"login response")
        saveToken(token);
        saveAuth(response?.data?.data);
        const { user } = response?.data?.data;
        if (user.firstName && user.lastName) {
          onSuccess(`Welcome ${user.firstName} ${user.lastName}!`);
        } else onSuccess("Login Successfully!");
        if (isRemember) {
          saveCredentials(data);
        } else {
          removeCredentials();
          localStorage.setItem("loginType", "Email");
        }
        console.log(loggedInUser, "role1");
        navigate(`/${getRole(user)}/profile`);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const facebookLogin = () => {
    if (facebookLoginRef.current) {
      facebookLoginRef.current.querySelector("button").click();
    }
  };

  const responseFacebook = async (response) => {
    console.log("facebook respose", response);
  };

  const handleRemember = (value) => {
    setIsRemember(value);
    localStorage.setItem("remember", value);
  };

  const togglePassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const email = localStorage.getItem("email");
  const phone = localStorage.getItem("phone");
  const password = localStorage.getItem("password");

  useEffect(() => {
    localStorage.setItem("loginType", "Email");
  }, []);

  return (
    <div className="login_main_container">
      <Formik
        validateOnChange={false}
        initialValues={{
          loginType: signUpOpt || "Email",
          Email: email ? email : "",
          Phone: phone ? phone : "",
          Password: password ? password : "",
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsLoading(true);
          try {
            const res = await User.login(
              signUpOpt === "Email" ? values.Email : "",
              signUpOpt !== "Email" ? values.Phone : "",
              values.Password
            );
            const { token, verified } = res?.data?.data;
            if (!verified) {
              localStorage.setItem("tempUser", JSON.stringify(res?.data?.data));
              setShowOtpModal(true);
            } else {
              saveToken(token);
              saveAuth(res?.data?.data);
              const { user } = res?.data?.data;
              if (user.firstName && user.lastName) {
                onSuccess(`Welcome ${user.firstName} ${user.lastName}!`);
              } else onSuccess("Login Successfully!");
              if (isRemember) {
                saveCredentials(values);
              } else {
                removeCredentials();
                localStorage.setItem("loginType", "Email");
              }
              resetForm();
              console.log(user, "role");
              navigate(`/${getRole(user)}/profile`);
            }
          } catch (error) {
            onError(
              error?.response?.data?.errorResponse?.message || error?.message
            );
          } finally {
            setIsLoading(false);
          }
        }}
        validationSchema={loginSchema}
      >
        {({
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
          values,
          errors,
          ...props
        }) => (
          <form onSubmit={handleSubmit} novalidate>
            <Container>
              <Row>
                <Col md={6} className="pe-3 pe-md-5">
                  <div className="auth_box">
                    <img
                      src="/images/close-btn.png"
                      alt="close"
                      className="close-btn"
                      onClick={() => navigate("/")}
                    />
                    <img
                      src="/images/light_logo.png"
                      style={{ width: "180px", cursor: "pointer" }}
                      alt="logo"
                      onClick={() => navigate("/")}
                    />

                    <div className="d-flex flex-column align-items-center my-3">
                      <h3 style={{ fontWeight: "700" }}>LOG IN</h3>
                      <div className="d-flex align-items-center mt-2 sign_up_options">
                        <button
                          type="button"
                          className={`${signUpOpt === "Email" && "active"}`}
                          onClick={() => {
                            resetForm();
                            setSignUpOpt("Email");
                            setFieldValue("loginType", "Email");
                            localStorage.setItem("loginType", "Email");
                          }}
                        >
                          Email
                        </button>
                        <h3>OR</h3>
                        <button
                          type="button"
                          className={`${signUpOpt === "Phone" && "active"}`}
                          onClick={() => {
                            setSignUpOpt("Phone");
                            setFieldValue("loginType", "Phone");
                            localStorage.setItem("loginType", "Phone");
                          }}
                        >
                          Mobile Number
                        </button>
                      </div>
                    </div>
                    <div className="inputs_container">
                      {signUpOpt === "Email" ? (
                        <div className="block">
                          <input
                            type="text"
                            placeholder="Email"
                            value={values?.Email}
                            onChange={handleChange("Email")}
                          />

                          <p className="error">{errors?.Email}</p>
                        </div>
                      ) : (
                        <div className="block">
                          <div className="phone_number_container">
                            <PhoneInput
                              international
                              defaultCountry="US"
                              limitMaxLength="20"
                              value={values.Phone}
                              onChange={(phone) =>
                                setFieldValue("Phone", phone)
                              }
                            />
                          </div>
                          <p className="error">{errors.Phone}</p>
                        </div>
                      )}

                      <div className="block password-input">
                        <div className="password">
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={values.Password}
                            onChange={handleChange("Password")}
                            aut
                          />
                          {showPassword ? (
                            <BsEyeSlash
                              className="eye-icon"
                              onClick={togglePassword}
                            />
                          ) : (
                            <BsEye
                              className="eye-icon"
                              onClick={togglePassword}
                            />
                          )}
                        </div>
                        <p className="error">{errors.Password}</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex remember-me">
                          <Form.Check
                            type="checkbox"
                            label={`Remember Me`}
                            style={{ width: "auto" }}
                            checked={isRemember}
                            onChange={(e) => handleRemember(e.target.checked)}
                          />
                        </div>
                        <p
                          className="forgot_password"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setType("forget");
                            setShowOtpModal(true);
                          }}
                        >
                          Forget Password
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="login_button"
                        disabled={isLoading}
                      >
                        <div className="d-flex justify-content-center gap-2">
                          Log In
                          {isLoading ? <Loader /> : null}
                        </div>
                      </button>
                    </div>

                    <div className="d-flex flex-column align-items-center mt-4 or-continue-with">
                      <p>
                        <span>Or</span> continue with
                      </p>
                      <div
                        style={{
                          width: "1px",
                          height: "28px",
                          backgroundColor: "#000",
                          margin: "10px 0px",
                        }}
                      ></div>
                      <div className="d-flex align-items-center gap-4 my-2">
                        <img
                          className="social-media-btn"
                          src="/images/google-btn.png"
                          alt="social-icon"
                          onClick={() => {
                            console.log("Initiating Google sign-in...");
                            googleLogin(); // Initiates the Google sign-in process
                          }}
                        />
                        <div ref={facebookLoginRef} className="hidden">
                          <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            cssClass="hidden"
                          />
                        </div>
                        <img
                          className="social-media-btn"
                          src="/images/facebook-btn.png"
                          alt="social-icon"
                          onClick={() => {
                            console.log("Initiating Facebook sign-in...");
                            facebookLogin(); // Initiates the Google sign-in process
                          }}
                        />
                        {/* <img src="/images/twitter-btn.png" alt="social-icon" /> */}
                        {/* <img
                          src="/images/instagram-btn.png"
                          alt="social-icon"
                        /> */}
                      </div>
                      <h3 className="mt-3">
                        Need an account?{" "}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              isSubAdmin ? "/sub-admin/signup" : "/signup"
                            )
                          }
                        >
                          Sign Up
                        </span>
                      </h3>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      </Formik>
      <OtpModal
        show={showOtpModal}
        setShow={setShowOtpModal}
        selectedType={type}
      />
    </div>
  );
};

export default Login;
