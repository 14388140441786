import React, { useState, useEffect } from "react";
import "./Notification.css";
import { RxCross2 } from "react-icons/rx";
import { ThemeState } from "../../../Context";
import { useLocation, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useGameNotifications } from "../../../Redux/Accessors/auth.accessors";
import moment from "moment";
// import Delete from "../../../Common/Delete";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import { playerNavigator } from "../../../Utils/navigator";
import { updateSport } from "../../../Redux/Accessors/game.accessors";
// import { InstagramShareButton } from "react-instagram-share";

const Notifications = ({
  notShowAll,
  widthStyle,
  allNotifications,
  Dark,
  page = "",
  viewMore,
  selectedTab,
  search = "",
  data,
  onUpdate,
  setOnUpdate,
}) => {
  const navigate = useNavigate();
  const { mode } = ThemeState();
  const { pathname } = useLocation();

  const URL = process.env.REACT_APP_TWITTER_URL;

  const gameNotifications = useGameNotifications();

  // eslint-disable-next-line no-unused-vars
  const [notifications, setNotifications] = useState(data);
  const [displayedNotifications, setDisplayedNotifications] =
    useState(notifications);

  useEffect(() => {
    setDisplayedNotifications(data);
  }, [data, selectedTab, notifications, gameNotifications, onUpdate]);

  return (
    <div>
      <div className="notification_main" style={{ width: widthStyle }}>
        <div
          className={` ${
            pathname === "/notifications"
              ? "notification_container"
              : "notification_container_wrap"
          } ${mode === "Light" && !Dark && "light_theme"} ${
            allNotifications && "notifications_page"
          }`}
        >
          {(displayedNotifications || []).length > 0 ? (
            (displayedNotifications || []).map((notification, i) => {
              const words = notification?.message?.split(" ");
              const indexOfNumberToReplace = words.indexOf("to") + 1;
              words[indexOfNumberToReplace] = "***";
              // eslint-disable-next-line no-unused-vars
              const aa =
                "Projection went from 50.5 -115 to  -1 +8 in Rushing Yards";
              // const modifiedString = aa?.replace(
              //   /to\s+-?\d+(\.\d+)?(\s+-?\d+(\.\d+)?)?/g, 'to ***'
              // )
              const modifiedString = notification?.message?.replace(
                /to\s+[-+]?(\d+(\.\d+)?)(\s+[-+]?(\d+(\.\d+)?))?/g,
                (match, group1, _, group2) => {
                  if (group2) {
                    return "to *** ***";
                  } else {
                    return "to ***";
                  }
                }
              );

              const tweetString = modifiedString?.split(" ");
              const shareMessage = `${tweetString?.join(
                " "
              )} @playertotals #Playerprops`;
              const sport = notification?.player?.sport;
              const slug = notification?.player?.slug;
              const shareUrl = `${URL}/${sport}/player/${slug}`;

              return (
                <div
                  key={i}
                  className={`notification_box ${
                    i === 0 ? "yellow_notification" : ""
                  } ${mode === "Light" && !Dark && "light_theme"}`}
                >
                  <div className="close_button d-flex align-items-center gap-1 gap-md-2 mt-2">
                    {allNotifications && (
                      <TwitterShareButton
                        url={shareUrl}
                        title={`${notification?.player.firstName} ${notification?.player.lastName} - #${notification?.team.name} - ${notification?.sport} ${shareMessage}`}
                      >
                        {mode === "Light" ? (
                          <img
                            width="16px"
                            height="16px"
                            src="/images/twitter-lt.png"
                            alt=""
                          />
                        ) : (
                          <img
                            width="16px"
                            height="16px"
                            src="/images/sidebar-twitter-light.png"
                            alt=""
                          />
                        )}
                        {/* <FaTwitter className="notifications_social" /> */}
                      </TwitterShareButton>
                    )}
                    {allNotifications && (
                      <FacebookShareButton
                        url={shareUrl}
                        quote={`${notification?.player.firstName} ${notification?.player.lastName} - #${notification?.team.name} - ${notification?.sport} ${shareMessage}`}
                      >
                        <FaFacebookF className="notifications_social" />
                      </FacebookShareButton>
                      // <FaFacebookF className="notifications_social" />
                    )}
                    {/* {allNotifications && (
                      // <InstagramShareButton
                      //   url={`${URL}/player/${notification?.player?._id}`}
                      //   quote={`${notification?.player.firstName}: ${notification?.message}`}
                      //   image={notification?.imageURL}
                      // >
                      //   <FaInstagram className="notifications_social_last" />
                      // </InstagramShareButton>
                      
                      // <FaInstagram className="notifications_social_last" />
                    )} */}

                    {/* {i === 0 ? (
                      <Alert
                        keyI={i}
                        page={mode !== "Light" && "player"}
                        status="notify"
                        playerId={notification?.player?._id}
                      />
                    ) : (
                      <Alert
                        playerId={notification?.player?._id}
                        status="notify"
                      />
                    )} */}
                    {/* <Delete
                      notificationId={notification?._id}
                      onUpdate={onUpdate}
                      setOnUpdate={setOnUpdate}
                    /> */}
                  </div>
                  <div className="d-flex mb-1 notification_content">
                    <img src="/images/notify-logo.png" alt="logo" />
                    <div className="mt-2 ">
                      <div className="d-flex align-items-center mb-1">
                        <h3>
                          {notification?.player.firstName}{" "}
                          {notification?.player.lastName} -{" "}
                          {notification?.team.name} -{" "}
                          {notification?.player?.sport}
                        </h3>
                      </div>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          playerNavigator(
                            notification?.player,
                            navigate,
                            updateSport
                          )
                        }
                      >
                        {notification?.message}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between notification_time">
                    <div></div>
                    <p>{moment(notification.createdAt).format("llll")}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="h-100"
            >
              <h3
                className={` text-center py-4 ${
                  mode === "Light" && !Dark ? "fw-bold" : "text-white"
                }`}
                style={{ fontSize: "1rem" }}
              >
                No Player Notifications
              </h3>
            </div>
          )}
          {viewMore && (
            <>
              <div
                className={`notification_box ${
                  mode === "Light" && !Dark && "light_theme"
                }`}
              >
                <div className="close_button d-flex align-items-center gap-2 mt-1">
                  {allNotifications && (
                    <>
                      {mode === "Light" ? (
                        <img
                          width="16px"
                          height="16px"
                          src="/images/twitter-lt.png"
                          alt=""
                        />
                      ) : (
                        <img
                          width="16px"
                          height="16px"
                          src="/images/sidebar-twitter-light.png"
                          alt=""
                        />
                      )}
                    </>
                    // <FaTwitter className="notifications_social" />
                  )}
                  {allNotifications && (
                    <FaFacebookF className="notifications_social" />
                  )}
                  {allNotifications && (
                    <FaInstagram className="notifications_social_last" />
                  )}

                  <img
                    src="/images/yellow-star.png"
                    style={{
                      width: allNotifications ? "15px" : "12px",
                      height: allNotifications ? "15px" : "12px",
                    }}
                    alt="logo"
                  />

                  <RxCross2 />
                </div>
                <div className="d-flex mb-1 notification_content">
                  <img src="/images/notify-logo.png" alt="logo" />
                  <div className="mt-2 ">
                    <div className="d-flex align-items-center mb-1">
                      <h3>Marcus Smart - Celtics</h3>
                    </div>
                    <p>
                      FanDuel went from 23.5 to 22.5 in Points + Rebounds +
                      Assists
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between notification_time">
                  <div></div>
                  <p style={{}}>Sat, May 27, 2023 8:25 PM</p>
                </div>
              </div>
              <div
                className={`notification_box ${
                  mode === "Light" && !Dark && "light_theme"
                }`}
              >
                <div className="close_button d-flex align-items-center gap-2 mt-1">
                  {allNotifications && (
                    <>
                      {mode === "Light" ? (
                        <img
                          width="16px"
                          height="16px"
                          src="/images/twitter-lt.png"
                          alt=""
                        />
                      ) : (
                        <img
                          width="16px"
                          height="16px"
                          src="/images/sidebar-twitter-light.png"
                          alt=""
                        />
                      )}
                    </>
                    // <FaTwitter className="notifications_social" />
                  )}
                  {allNotifications && (
                    <FaFacebookF className="notifications_social" />
                  )}
                  {allNotifications && (
                    <FaInstagram className="notifications_social_last" />
                  )}

                  <img
                    src="/images/yellow-star.png"
                    style={{
                      width: allNotifications ? "15px" : "12px",
                      height: allNotifications ? "15px" : "12px",
                    }}
                    alt="logo"
                  />

                  <RxCross2 />
                </div>
                <div className="d-flex mb-1 notification_content">
                  <img src="/images/notify-logo.png" alt="logo" />
                  <div className="mt-2 ">
                    <div className="d-flex align-items-center mb-1">
                      <h3>Marcus Smart - Celtics</h3>
                    </div>
                    <p>
                      FanDuel went from 23.5 to 22.5 in Points + Rebounds +
                      Assists
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between notification_time">
                  <div></div>
                  <p style={{}}>Sat, May 27, 2023 8:25 PM</p>
                </div>
              </div>
            </>
          )}
        </div>
        {page !== "home" && (
          <div>
            {!notShowAll && (
              <h3
                className={`text-center mt-3 ${
                  mode === "Light" && !Dark ? "fw-bold" : "text-white"
                }`}
                style={{ cursor: "pointer", fontSize: "12px" }}
                onClick={() => navigate("/notifications")}
              >
                View All Notifications
              </h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
