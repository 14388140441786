import { useSelector } from "react-redux";
import { ensureMutators } from "../helper";
import previousGames from "../Slices/previousGames.slice";
import { store } from "../store";

// mutators
export const updatePlayerMatches = (games) =>
  store.dispatch(previousGames.actions.updatePlayerMatches(games));

export const updateTeamMatches = (games) =>
  store.dispatch(previousGames.actions.updateTeamMatches(games));

export const updateTeams = (games) =>
  store.dispatch(previousGames.actions.updateTeams(games));

export const updatePosition = (position) =>
  store.dispatch(previousGames.actions.updatePosition(position));

ensureMutators(previousGames.actions, {
  updateTeams,
  updatePlayerMatches,
  updateTeamMatches,
  updatePosition,
});

// selectors
export const useTeams = () => useSelector((state) => state.previousGames.teams);

export const usePlayerGames = () =>
  useSelector((state) => state.previousGames.playerMatches);

export const useTeamGames = () =>
  useSelector((state) => state.previousGames.teamMatches);

export const usePosition = () =>
  useSelector((state) => state.previousGames.position);
