import React from "react";
import Modal from "../../../Components/Modal/SiteModal";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { cloudURL } from "../../../Utils/helper";
import { useSelector } from "react-redux";
import { useState } from "react";
import "../cms.css"

const EditPromosModal = ({
  show,
  setShow,
  promo,
  onChangeHandler,
  handleImageChange,
  uploadedImagePreview,
  onCloseModal,
  submitHandler,
  loading
}) => {
  //   console.log(promo);
  // const {loading}=useSelector((state)=>state.promoUpdate)

  return (
    <div className="edit_promos_modal">
      <Modal
        show={show}
        setShow={setShow}
        style="promo_modal"
        size="lg"
        heading="Edit Promo"
        onCloseModal={onCloseModal}
      >
        <>
          <Form onSubmit={submitHandler}>
            <Row className="justify-content-center edit_promo">
              <Col md={11} className="mt-4">
                <Row className="gx-5">
                  <Col md={6}>
                    <Form.Label>Image</Form.Label>
                    <Form.Group className="mb-3 cms_image_input">
                      <Form.Control
                        type="file"
                        style={{ fontSize: "13px", paddingBottom: "7px" }}
                        name={`image`}
                        onChange={handleImageChange("image")}
                      />
                    </Form.Group>
                    <div className="previewImg_div">
                      <img
                        src={
                          uploadedImagePreview?.image
                            ? uploadedImagePreview?.image
                            : promo?.image?.filename
                            ? `${cloudURL}/${promo.image.filename}`
                            : "/images/empty.jpg"
                        }
                        alt=""
                        style={{}}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Light Mode Image</Form.Label>
                    <Form.Group className="mb-3 cms_image_input">
                      <Form.Control
                        type="file"
                        style={{ fontSize: "13px", paddingBottom: "7px" }}
                        name={`dark_mode_img`}
                        onChange={handleImageChange("dark_mode_img")}
                      />
                    </Form.Group>
                    <div className="previewImg_div">
                      <img
                        src={
                          uploadedImagePreview?.dark_mode_img
                            ? uploadedImagePreview?.dark_mode_img
                            : promo?.dark_mode_img?.filename
                            ? `${cloudURL}/${promo.dark_mode_img.filename}`
                            : "/images/empty.jpg"
                        }
                        alt=""
                        style={{}}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="gx-5">
                  <Col md={6}>
                    <Form.Label>Title</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="title"
                        value={promo.title}
                        onChange={onChangeHandler("title")}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Heading</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="heading"
                        value={promo.heading}
                        onChange={onChangeHandler("heading")}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="gx-5">
                  <Col md={6}>
                    <Form.Label>Text1</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="text1"
                        value={promo.text1}
                        onChange={onChangeHandler("text1")}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Text2</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="text2"
                        value={promo.text2}
                        onChange={onChangeHandler("text2")}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="gx-5">
                  <Col md={12}>
                    <Form.Label>Review Text</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder=""
                        name="review"
                        value={promo.review}
                        onChange={onChangeHandler("review")}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="gx-5">
                  <Col md={8}>
                    <Form.Label>Claim Link</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="claim_link"
                        value={promo.claim_link}
                        onChange={onChangeHandler("claim_link")}
                      />
                    </InputGroup>
                  </Col>
                  {/* <Col md={6}>
                  <Form.Label>facebook Link</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      name="fb_link"
                      value={promo.fb_link}
                      onChange={onChangeHandler("fb_link")}
                    />
                  </InputGroup>
                </Col> */}
                </Row>
                {/* <Row className="gx-5">
                <Col md={6}>
                  <Form.Label>Instagram Link</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder=""
                      name="insta_link"
                      value={promo.insta_link}
                      onChange={onChangeHandler("insta_link")}
                    />
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <Form.Label>Twitter Link</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder={""}
                      name="twitter_link"
                      value={promo.twitter_link}
                      onChange={onChangeHandler("twitter_link")}
                    />
                  </InputGroup>
                </Col>
              </Row> */}
                <Row className="justify-content-end">
                  <button
                    type="submit"
                    style={{
                      width: "fit-content",
                      backgroundColor: "red",
                      border: "none",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "6px",
                    }}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      </Modal>
    </div>
  );
};

export default EditPromosModal;
