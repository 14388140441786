import { createSlice } from "@reduxjs/toolkit";

const previousGameSlice = createSlice({
  name: "previousGames",
  initialState: {
    playerMatches: null,
    teamMatches: null,
    teams: [],
    position: "B",
  },
  reducers: {
    updatePlayerMatches: (state, action) => {
      state.playerMatches = action.payload;
    },

    updateTeamMatches: (state, action) => {
      state.teamMatches = action.payload;
    },

    updateTeams: (state, action) => {
      state.teams = action.payload;
    },

    updatePosition: (state, action) => {
      state.position = action.payload;
    },
  },
});

export default previousGameSlice;
