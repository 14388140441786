import Dashboard from "../User/Dashboard/Dashboard";

import Subscription from "../User/Subscription/Subscription";
import BillingHistory from "../User/BillingHistory/BillingHistory";
import UserSettings from "../User/UserSettings/UserSettings";
import Profile from "../User/Profile/Profile";
import Creations from "./Creation/Creations";
import AllUsers from "./AllUsers/AllUsers";
import NFL from "./DataUpdate/NFL";
import EditAboutUs from "./CMS/EditAboutUs/EditAboutUs";
import EditPromos from "./CMS/EditAboutUs/EditPromos";
import NBA from "./DataUpdate/NBA";
import MLB from "./DataUpdate/MLB";
import NHL from "./DataUpdate/NHL";
import Search from "./DataUpdate/Search/Search";
import UserDetails from "./AllUsers/UserDetails/UserDetails";

export const SuperAdminRoutes = [
  // {
  //   path: "/admin/dashboard",
  //   component: <Dashboard />,
  // },

  {
    path: "/super-admin/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/super-admin/profile",
    component: <Profile />,
  },
  {
    path: "/super-admin/creation",
    component: <Creations />,
  },
  {
    path: "/super-admin/users",
    component: <AllUsers />,
  },
  {
    path: "/super-admin/users/:userId",
    component: <UserDetails />,
  },
  {
    path: "/super-admin/data-update/NFL",
    component: <NFL />,
  },
  {
    path: "/super-admin/data-update/NBA",
    component: <NBA />,
  },
  {
    path: "/super-admin/data-update/MLB",
    component: <MLB />,
  },
  {
    path: "/super-admin/data-update/NHL",
    component: <NHL />,
  },
  {
    path: "/super-admin/data-update/Search",
    component: <Search />,
  },
  {
    path: "/super-admin/cms/edit-about-us",
    component: <EditAboutUs />,
  },
  {
    path: "/super-admin/cms/edit-promos",
    component: <EditPromos />,
  },
];

export const SuperAdminSideBarItems = [
  // {
  //   path: "/user/dashboard",
  //   icon: "/images/dashboard-icon.png",
  //   title: "Dashboard",
  //   isSubNav: false,
  // },
  {
    path: "/super-admin/profile",
    icon: "/images/profile-icon.png",
    title: "Profile",
    isSubNav: false,
  },
  {
    path: "/super-admin/creation",
    icon: "/images/creation.png",
    title: "Sub Admins",
    isSubNav: false,
  },
  {
    path: "/super-admin/users",
    icon: "/images/users.png",
    title: "Total Users",
    isSubNav: false,
  },
  // {
  //   path: "/super-admin/data-update",
  //   icon: "/images/update.png",
  //   title: "Data Update",
  //   isSubNav: true,
  // },
  {
    path: "/super-admin/data-update",
    icon: "/images/update.png",
    title: "Data Update",
    isSubNav: true,
    subNav: [
      {
        path: "/super-admin/data-update/search",
        component: <Search />,
        icon: "",
        title: "SEARCH",
      },
      {
        path: "/super-admin/data-update/NFL",
        component: <NFL />,
        icon: "",
        title: "NFL",
      },
      {
        path: "/super-admin/data-update/NBA",
        component: <NBA />,
        icon: "",
        title: "NBA",
      },
      {
        path: "/super-admin/data-update/MLB",
        component: <MLB />,
        icon: "",
        title: "MLB",
      },
      {
        path: "/super-admin/data-update/NHL",
        component: <NHL />,
        icon: "",
        title: "NHL",
      },
      
    ],
  },
  {
    path: "/super-admin/cms",
    icon: "/images/update.png",
    title: "Pages",
    isSubNav: true,
    subNav: [
      {
        path: "/super-admin/cms/edit-about-us",
        component: <EditAboutUs />,
        icon: "",
        title: "About Us",
      },
      {
        path: "/super-admin/cms/edit-promos",
        component: <EditPromos />,
        icon: "",
        title: "Promos",
      },
    ],
  },
];
