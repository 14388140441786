import React from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import AdvertiseForm from "../../Components/Advertise/AdvertiseForm";
import PageWrapper from "../../Common/PageWrapper";

const Advertise = () => {
  return (
    <PageWrapper>
      <div>
        <div className="home_desktop">
          <Header />
          <AdvertiseForm />
          <Footer />
        </div>
        <div className="home_mobile">
          <MobileHeader />
          <AdvertiseForm />

          <MobileFooter />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Advertise;
