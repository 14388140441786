import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowUpCircleFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import { updateHeaderTab } from "../../../Redux/Accessors/header.accessors";
import {
  updateSport,
  useSettings,
} from "../../../Redux/Accessors/game.accessors";
import { useUser } from "../../../Redux/Accessors/auth.accessors";
import Games from "../../../Services/Games";
import { errorNotify, successNotify } from "../../../Utils/toast";
import { FB, Instagram, Twitter } from "../../../Utils/helper";

const MobileFooter = ({ bgWhite }) => {
  const location = useLocation();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const { mode } = ThemeState();
  const navigate = useNavigate();
  const { user } = useUser();

  const [showScroll, setShowScroll] = useState(false);
  const [email, setEmail] = useState("");

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 1200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 1200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0 });
  };

  const handleNewsLetter = async () => {
    if (!email) {
      errorNotify("Please enter email address.");
      return;
    }

    const formData = {
      email,
      subject: "News Letter",
    };

    const sendMail = await Games.sendMail(formData);

    if (sendMail.data.data?.success) {
      setEmail("");
      successNotify(sendMail?.data.message);
    } else {
      errorNotify("Email not sent! Please try again.");
    }
  };

  window.addEventListener("scroll", checkScrollTop);

  const onUpdateTab = (tab) => {
    window.scrollTo(0, 0);
    updateHeaderTab(tab);
    switch (tab) {
      case "HOME":
        navigate("/");
        updateSport("MLB");
        scrollTop();
        if (location.pathname === "/") window.location.reload();
        return;
      case "ABOUT_US":
      case "COMPARE":
      case "PROMOS":
        navigate(
          tab === "HOME"
            ? "/"
            : tab === "ABOUT_US"
            ? "/about-us"
            : tab === "PROMOS"
            ? "/promos"
            : "/compare"
        );
        tab === "HOME" && updateSport("MLB");
        return;
      case "PREMIUM":
        updateSport(defaultSport);
        navigate("/premium");
        return;
      case "CONTACT_US":
        navigate("/contact-us");
        return;
      case "NFL":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nfl");
        return;
      case "NBA":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nba");
        return;
      case "MLB":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/mlb");
        return;
      case "NHL":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nhl");
        return;
      default:
        navigate("/");
        return;
    }
  };

  const handleNavigate = () => {
    window.location.href = "/";
  };

  const handleAccount = () => {
    if (user) {
      navigate("/user/profile");
    } else {
      navigate("/login");
    }
  };

  return (
    <div
      className={`mobile_footer_container pt-4 px-3 ${
        mode === "Light" && "light_theme"
      }`}
    >
      {/* ${bgWhite && "bgWhite"} */}
      <Container fluid>
        <Row>
          <div className="d-flex align-items-center justify-content-between">
            {mode === "Light" ? (
              <img
                src="/images/light_logo.png"
                alt="betting predictions"
                style={{ width: "170px" }}
                onClick={() => handleNavigate()}
              />
            ) : (
              <img
                src="/images/logo.png"
                alt="betting predictions"
                style={{ width: "170px" }}
                onClick={() => handleNavigate()}
              />
            )}
            {!user && (
              <div className="auth_btn_container">
                <button
                  className="bg_green text-white sign-up"
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </button>
                <button className="login" onClick={() => navigate("/login")}>
                  Log In
                </button>
              </div>
            )}
          </div>
        </Row>
        <Row className="py-3 align-items-start">
          <Col xs={5}>
            <div className="footer-links ">
              <p onClick={() => onUpdateTab("HOME")}>HOME</p>
              <p onClick={() => onUpdateTab("PREMIUM")}>PREMIUM</p>
              <p onClick={() => onUpdateTab("NFL")}>NFL</p>
              <p onClick={() => onUpdateTab("NBA")}> NBA</p>
              <p onClick={() => onUpdateTab("MLB")}>MLB</p>
              <p onClick={() => onUpdateTab("NHL")}>NHL</p>
              <p onClick={() => onUpdateTab("PROMOS")}>PROMOS</p>
              <p onClick={() => onUpdateTab("COMPARE")}>COMPARE</p>
              <p onClick={() => onUpdateTab("ABOUT_US")}>ABOUT US</p>
              <p onClick={() => onUpdateTab("CONTACT_US")}>CONTACT US</p>
            </div>
          </Col>
          <Col xs={7} className="text-center news_letter">
            <h3>NEWSLETTER</h3>
            <div className="py-3">
              <p
                className={`${
                  mode === "Light" ? "fw-bold" : "text-white"
                } mb-2`}
              >
                Player Totals updates and promotions
              </p>
              <div className="news-letter">
                <input
                  placeholder="Email address"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <img
                  src="/images/paper-plane.png"
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={handleNewsLetter}
                />
              </div>

              <h3
                className={`pt-3 pb-2 follow-us-mobile ${
                  mode === "Light" ? "text-black fw-bolder" : "text-white"
                }`}
              >
                FOLLOW US
              </h3>
              <div className="social-connect d-flex justify-content-center">
                <div>
                  <a href={FB} target="_blank"
                  //  rel="noreferrer"
                   >
                    {mode === "Light" ? (
                      <img src="/images/facebook-light.png" alt="" />
                    ) : (
                      <img src="/images/fb.png" alt="" />
                    )}
                  </a>
                </div>
                <div>
                  <a
                    href={Twitter}
                    target="_blank"
                    // rel="noreferrer"
                  >
                    {" "}
                    {mode === "Light" ? (
                      <img src="/images/twitter-light.png" alt="" />
                    ) : (
                      <img src="/images/twitter.png" alt="" />
                    )}{" "}
                  </a>
                </div>
                <div>
                  <a
                    href={Instagram}
                    target="_blank"
                    // rel="noreferrer"
                  >
                    {" "}
                    {mode === "Light" ? (
                      <img src="/images/instagram-light.png" alt="" />
                    ) : (
                      <img src="/images/instagram.png" alt="" />
                    )}{" "}
                  </a>
                </div>
              </div>
              <p
                className={`mt-4 advertise_with_us ${
                  mode === "Light" && "light_theme"
                }`}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/advertise")}
              >
                ADVERTISE WITH US
              </p>
            </div>
          </Col>
        </Row>
        <Row className="disclaimer">
          <p className={`py-2 ${mode === "Light" ? "fw-bold" : "text-white"}`}>
            Disclaimer: This site is for entertainment purposes only, and there
            is no gambling offered on the site. This service is intended for
            adult audiences. No guarantees are made for any specific outcome.
          </p>
        </Row>
        <hr style={{ color: mode === "Light" ? "black" : "white" }} />
        <Row>
          <div className="d-flex justify-content-center copyrights">
            <p className={`${mode === "Light" ? "fw-normal" : "text-white"}`}>
              @{new Date().getUTCFullYear()} Player Totals LLC. All rights
              reserved
            </p>
          </div>
        </Row>
      </Container>
      <BsArrowUpCircleFill
        className={`scrollTop ${mode === "Light" && "light_theme"}`}
        onClick={scrollTop}
        id="mobileScrollToTop"
        style={{ display: showScroll ? "flex" : "none" }}
      />
      <div className="mobile_menu_bar">
        <div className="mobile_menu_items_container">
          <div onClick={() => navigate("/")}>
            <img src="/images/home.png" alt="logo" />
            <h3>HOME</h3>
          </div>
          <div onClick={() => navigate("/premium")}>
            <img src="/images/premium.png" alt="logo" />
            <h3>Premium</h3>
          </div>
          <div onClick={() => navigate("/compare")}>
            <img src="/images/compare.png" alt="logo" />
            <h3>Compare</h3>
          </div>
          <div onClick={() => navigate("/notifications")}>
            <img src="/images/promos.png" alt="logo" />
            <h3>Notification</h3>
          </div>
          <div onClick={() => handleAccount()}>
            <img src="/images/account.png" alt="logo" />
            <h3>Account</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFooter;
