import { apiRequest } from "../Utils/apiRequest";
import { adminRoute } from "../Utils/routes";

const Admin = {
  getAllUsers: () => {
    return apiRequest(adminRoute.getAllUsers);
  },
  getAllSubAdmins: () => {
    return apiRequest(adminRoute.getAllSubAdmins);
  },
  deleteUser: (id) => {
    return apiRequest(`${adminRoute.deleteUser}/${id}`, "DELETE");
  },
  getUser: (id) => {
    return apiRequest(`${adminRoute.getUser}/${id}`);
  },
  startCron: (name) => {
    return apiRequest(`${adminRoute.adminCron}/${name}`);
  },
  updateMatchPremium: (data) => {
    return apiRequest(`${adminRoute.updatePremium}`, "PUT", data);
  },
  updatePlayerPremium: (data) => {
    return apiRequest(`${adminRoute.updatePlayerPremium}`, "PUT", data);
  },
  updateTeamPremium: (data) => {
    return apiRequest(`${adminRoute.updateTeamPremium}`, "PUT", data);
  },
};
export default Admin;
