/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import "../ContactUs/contactForm.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import Recaptcha from "../Layout/Recaptcha/Recaptcha";
import Games from "../../Services/Games";
import { errorNotify, successNotify } from "../../Utils/toast";
import Loader from "../Loader/ButtonLoader";

const AdvertiseForm = () => {
  const [recaptcha, setRecaptcha] = useState();
  const captchaRef = useRef(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!recaptcha) {
      errorNotify("Please verify captcha");
      return;
    }

    setIsLoading(true);
    formData.subject = "Advertise With Us";
    const sendMail = await Games.sendMail(formData);
    if (sendMail.data.data?.success) {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        message: "",
      });
      successNotify(sendMail?.data.message);
      setIsLoading(false);
      setRecaptcha(null);
      captchaRef.current.reset();
    } else {
      errorNotify("Email not sent! Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div className="contact_form_main py-md-4">
      <div className="contact_form_container mx-3">
        <Container fluid>
          <Row className="justify-content-between gx-5">
            <Col md={6} className="mt-md-4">
              <div className="my-md-4 question_or_feedback">
                <h1>Advertise With Us</h1>
                <p>
                  Interested in learning more about our advertising
                  opportunities? Reach out to start working with us today!
                </p>
                <hr />
                <div>
                  <h3>Email</h3>
                  <span className="mt-3">
                    <a href="mailto:info@playertotals.com">
                      <AiOutlineMail /> info@playertotals.com
                    </a>
                  </span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Form autoComplete="off" onSubmit={handleSendMessage}>
                <Row className="my-4 contact_form">
                  <Col md={12}>
                    <div className="input-placeholder">
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                      <div className="placeholder">
                        First Name <span>*</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="input-placeholder">
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                      <div className="placeholder">
                        Last Name <span>*</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="input-placeholder">
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                      <div className="placeholder">
                        Email <span>*</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="input-placeholder">
                      <input
                        type="text"
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleInputChange}
                        required
                      />
                      <div className="placeholder">
                        Company Name <span>*</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="input-placeholder">
                      <textarea
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        rows={3}
                      />
                      <div className="placeholder">
                        Message <span>*</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Recaptcha setRecaptcha={setRecaptcha} ref={captchaRef} />
                  </Col>
                  <Col md={12}>
                    <button
                      type="submit"
                      className="send-message"
                      disabled={isLoading}
                    >
                      <div className="d-flex justify-content-center gap-2">
                        Send Message
                        {isLoading ? <Loader /> : null}
                      </div>
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AdvertiseForm;
