import React, { useState, useEffect } from "react";
import { Container, Dropdown, Nav, Accordion } from "react-bootstrap";
import { AiFillCaretDown } from "react-icons/ai";
import Games from "../../../Services/Games";
import { BiRefresh } from "react-icons/bi";
import { useHeaderTab } from "../../../Redux/Accessors/header.accessors";
import { ThemeState } from "../../../Context";
import { sports } from "../../../Constants";
import DarkLoader from "../../../Common/Loader/DarkLoader";

const NavigationBar = ({
  selectedTab,
  setSelectedTab,
  searchByTeam,
  fetchAllNotifications,
}) => {
  const tabs = ["ALL", "NFL", "NBA", "MLB", "NHL"];

  const [teams, setTeams] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamsList, setTeamsList] = useState([]);

  const headerTab = useHeaderTab() || "HOME";
  const { mode } = ThemeState();

  const fetchTeamsList = async (sport) => {
    try {
      setTeamsList([]);
      setIsLoading(true);
      const res = await Games.teams(sport);
      setTeamsList(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching teams list:", error);
    }
  };

  useEffect(() => {
    fetchTeamsList();
  }, []);

  return (
    <Container fluid className="px-md-0">
      <Dropdown show={teams} onToggle={() => setTeams(!teams)}>
        <div
          className="d-flex align-items-center gap-2 gap-md-3 navigation_bar_main"
          style={{ overflow: "auto" }}
        >
          <button
            className="refresh_btn mobile sticky-refresh"
            onClick={() => {
              setSelectedTab("ALL");
              fetchAllNotifications();
            }}
          >
            <BiRefresh size={25} />
          </button>
          {tabs?.map((tab, index) => (
            <div
              key={index}
              className={`${
                tab === selectedTab && "selected-notification-tab"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              <h3>{tab}</h3>
            </div>
          ))}
          {/* <Dropdown.Toggle className="team_header_link"> */}
          <Nav.Link
            onClick={() => {
              // onUpdateTab("TEAMS");
              setTeams(!teams);
            }}
            className={`d-flex gap-2${
              headerTab === "TEAMS" && "selected-sport"
            }`}
            style={{
              flexDirection: "row",
              alignItems: "center",
              columnGap: 5,
            }}
          >
            <h3 className="team-header">TEAMS</h3> <AiFillCaretDown />
          </Nav.Link>
          {/* </Dropdown.Toggle> */}

          <button
            className="refresh_btn desktop"
            onClick={() => {
              setSelectedTab("ALL");
              fetchAllNotifications();
            }}
          >
            <BiRefresh size={25} />
          </button>
        </div>

        <Dropdown.Menu className="team_accordion_dropdown" align="end">
          <Accordion
            className={`team_header_accordion ${
              mode === "Dark" && "dark_theme"
            } `}
          >
            {sports.map((sport, index) => {
              return (
                <Accordion.Item
                  className={`team_accordion_item ${
                    mode === "Dark" && "dark_theme"
                  } `}
                  key={index}
                  eventKey={index}
                >
                  <Accordion.Header
                    onClick={() => fetchTeamsList(sport)}
                    className="team_accordion_header"
                  >
                    {sport}
                  </Accordion.Header>
                  <Accordion.Body className="team_accordion_body">
                    {isLoading ? (
                      <DarkLoader />
                    ) : teamsList?.length ? (
                      teamsList?.map((team) => (
                        <p style={{ cursor: "pointer" }}>
                          <span style={{ display: "flex", gap: 15 }}>
                            <img
                              className={`${
                                team?.name === "Pirates" && "team_logo_bg"
                              }`}
                              src={team?.wikipediaLogoUrl || "/"}
                              alt={`${team?.city} ${team?.name}`}
                            />
                            <span
                              onClick={() => {
                                searchByTeam(team?._id);
                                setTeams(!teams);
                              }}
                            >
                              {team?.city} {team?.name}
                            </span>
                          </span>
                          <span>{team?.sport}</span>
                        </p>
                      ))
                    ) : (
                      <p style={{ color: "#778899" }}>No Teams Found</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default NavigationBar;
