import { useSelector } from "react-redux";
import { ensureMutators } from "../helper";

import { store } from "../store";
import aboutSlice from "../Slices/about.slice";

//mutators
export const updateAbout = (promos) =>
  store.dispatch(aboutSlice.actions.updateAbout(promos));

ensureMutators(aboutSlice.actions, { updateAbout });


  //selectors
export const useAbout = () => useSelector((state) => state.about);
