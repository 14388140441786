export const saveCredentials = (values, signUpOpt) => {
  localStorage.setItem("email", signUpOpt === "Email" ? values.Email : "");
  localStorage.setItem("phone", signUpOpt !== "Email" ? values.Phone : "");
  localStorage.setItem("password", values.Password);
  localStorage.setItem("remember", true);
};

export const removeCredentials = () => {
  localStorage.setItem("email", "");
  localStorage.setItem("phone", "");
  localStorage.setItem("password", "");
  localStorage.setItem("remember", false);
};
