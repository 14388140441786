import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeState } from "../../Context";
// import { Form } from 'react-bootstrap'
import Modal from "../../Components/Modal/SiteModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Admin from "../../Services/admin";
import { successNotify } from "../../Utils/toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from "@tinymce/tinymce-react";

const PlayerPremium = ({ search, teamData, premiumType, tab, schedule }) => {
  const { mode } = ThemeState();
  console.log(teamData,"teamData")
  const premiumValue =
    tab === "playerPremium" && schedule?.playerPremium &&
    schedule?.playerPremium.filter((premium) => premium.player === teamData._id)
      
  console.log(premiumValue, "premiumValue");
  //   const premiumValue = !tab
  //     ? (teamData.premium && teamData?.premium[valueType]
  //       ? teamData?.premium[valueType]
  //       : "")
  //     : (teamData?.premium
  //     ? teamData?.premium
  //     : "");
  //   const [value, setValue] = useState(premiumValue);
  const [value, setValue] = useState(premiumValue ? premiumValue[0]?.premium :null);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);
  const log = async () => {
    // console.log(editorRef.current.getContent(),"editor context")
    try {
      setLoading(true);

      await Admin.updatePlayerPremium({
        gameId:schedule._id,
        playerId: teamData._id,
        premium: editorRef.current.getContent(),
      });

      // if (editorRef.current) {
      //   setValue(editorRef.current.getContent());
      // }

      setLoading(false);
      successNotify("Premium text updated successfully!");
    } catch (error) {
      console.error("An error occurred while updating premium data:", error);
      setLoading(false);
    }
  };
  // console.log(value, "value");
  // const onSubmitPremium = async () => {
  //   try {
  //     setLoading(true);
  //     await Admin.updateMatchPremium({
  //       gameId: teamData._id,
  //       [premiumType]: value,
  //     });

  //     setLoading(false);
  //     successNotify("Premium text updated successfully!");
  //   } catch (error) {
  //     console.error("An error occurred while updating premium data:", error);
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <Container>
        <Row>
          <Col md={9} className="p-0 mb-4 match_premium_main">
            <div className={`premium_card_box_main matches_premium`}>
              <div
                className={`premium_card_box ${
                  mode === "Light" && "light_theme"
                }`}
              >
                {search && (
                  <div className="premium_search_box mb-1">
                    <input placeholder="Search for player totals or results" />
                    <img
                      src={"/images/search.png"}
                      alt="Search for player totals or results"
                    />
                  </div>
                )}
                <div
                  className={`d-flex align-items-center justify-content-between py-2 px-2 premium_menu_box ${
                    mode === "Light" && "light_theme"
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <>
                      <h6
                        className={`${
                          mode === "Light" ? "text-white" : "col-yellow"
                        }`}
                      >
                        {teamData?.slug}
                        {/* {!tab ? teamData[valueType]?.city} {teamData[valueType]?.name} :
                    tab=== "team" ? teamData?.slug : 
                    teamData?.f
                  } */}

                        {/* {teamData[valueType]?.city} {teamData[valueType]?.name} */}
                      </h6>
                    </>
                  </div>
                </div>

                <>
                  {/* <div className="premium_card_header bg_red w-100 text-white">
                    <h3>PREMIUM</h3>
                  </div> */}
                  <div className={` ${mode === "Light" && "light_theme"}`}>
                    <div>
                      {/* <div className="d-flex justify-content-between align-items-center">
                      <CKEditor
                    editor={ ClassicEditor }
                    data={value}
                    
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        setValue(data)
                        // console.log( { event, editor, data } );
                    } }
                    
                />
                      </div> */}
                      <div>
                        <Editor
                          apiKey="e0q00rc8te6ghw8t7t8d2yqa8bxq1z7rsp4erbg7fun5i0r2"
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={value}
                          init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                              "emoticons",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor emoticons link | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help ",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                        <div className="d-flex justify-content-end mt-2">
                          <button
                            className="text-right premium_submit_btn"
                            onClick={log}
                            disabled={loading}
                          >
                            SUBMIT
                          </button>
                        </div>
                        {/* <button onClick={log}>Submit</button> */}
                      </div>
                      {/* <div className="pt-2 mb-3">
                  <ul>
                    <li>74% hit rate away</li>
                    <li>83% hit rate without James Harden</li>
                  </ul>
                </div> */}
                    </div>
                    {/* <hr style={{ color: "white" }} /> */}
                  </div>
                </>
              </div>
            </div>
            {/* <div className="d-flex justify-content-end mt-2">
              <button
                className="text-right premium_submit_btn"
                onClick={() => onSubmitPremium()}
                disabled={loading}
              >
                SUBMIT
              </button>
            </div> */}
          </Col>
        </Row>
      </Container>
      \
    </>
  );
};

export default PlayerPremium;
