import React from "react";
import { useState } from "react";
import { Accordion, Col, Container, Dropdown, Nav, Row } from "react-bootstrap";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import AboutTitle from "./AboutTitle";
import { logoutAuth, useUser } from "../../Redux/Accessors/auth.accessors";
import { updateSport } from "../../Redux/Accessors/game.accessors";
import { updateHeaderTab } from "../../Redux/Accessors/header.accessors";
import Games from "../../Services/Games";
import { AiFillCaretDown } from "react-icons/ai";
import { defaultSport, sports } from "../../Constants";
import DarkLoader from "../../Common/Loader/DarkLoader";
import { ThemeState } from "../../Context";
import { onSuccess } from "../../Utils/toast";
import { teamNavigator } from "../../Utils/navigator";
import { FB, Instagram, Twitter, cloudURL } from "../../Utils/helper";

const AboutBanner = ({ mobile,banner }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { mode } = ThemeState();

  const [teams, setTeams] = useState(false);

  const [teamsList, setTeamsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onUpdateTab = (tab) => {
    updateHeaderTab(tab);
    switch (tab) {
      case "HOME":
        navigate("/");
        break;
      case "ABOUT_US":
        navigate("/about-us");
        break;
      case "PROMOS":
        navigate("/promos");
        break;
      case "COMPARE":
        navigate("/compare");
        break;
        case "PREMIUM":
          updateSport(defaultSport);
          navigate("/premium");
          return;
      case "NFL":
      case "NBA":
      case "MLB":
      case "NHL":
        updateSport(tab);
        navigate("/");
        break;
      default:
        navigate("/");
    }
  };

  const fetchTeamsList = async (sport) => {
    try {
      setTeamsList([]);
      setIsLoading(true);
      const res = await Games.teams(sport);
      setTeamsList(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching teams list:", error);
    }
  };

  const logout = () => {
    logoutAuth();
    navigate("/login");
  };

  return (
    <div className="about_banner_section">
      <div className="about_banner_main" style={{backgroundImage:`url(${cloudURL}/${banner?.bannerImg?.filename})`}}>
        {!mobile && (
          <>
            <AboutTitle title={banner?.title}/>
            <Container fluid style={{ height: "100%", position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "6px",
                  top: 0,
                  height: "100%",
                  backgroundColor: "black",
                  width: "168px",
                }}
              >
                <Row className="justify-content-end">
                  <Col
                    md={2}
                    style={{
                      backgroundColor: "#000",
                      opacity: 1,
                      width: "180px",
                    }}
                    className="px-4 pt-5 pb-2"
                  >
                    <img
                      src="/images/logo.png"
                      style={{ width: "120px", cursor: "pointer" }}
                      alt="logo"
                      onClick={() => navigate("/")}
                    />
                  </Col>
                </Row>
                {/* </Container> */}
                {/* <Container className="ps-5"> */}
                <Row
                  className={`${
                    !!user ? "justify-content-evenly" : "justify-content-end"
                  }`}
                >
                  <Col
                    md={!!user ? 6 : 2}
                    style={{
                      backgroundColor: "#000",
                      opacity: 1,
                      width: !!user ? "50%" : "180px",
                    }}
                    className={`about-links ${!!user ? "" : "ps-4 pt-3"}`}
                  >
                    {!user && (
                      <div className="auth_btn_container flex-column">
                        <button
                          className="bg_green text-white sign-up"
                          onClick={() => navigate("/signup")}
                        >
                          Sign Up
                        </button>
                        <button
                          className="login"
                          onClick={() => navigate("/login")}
                        >
                          Log In
                        </button>
                      </div>
                    )}
                    <Link to="/" onClick={() => onUpdateTab("HOME")}>
                      <h6>Home</h6>
                    </Link>
                    <Link to="/premium" onClick={() => onUpdateTab("PREMIUM")}>
                      <h6>PREMIUM</h6>
                    </Link>
                    <Link to="/" onClick={() => onUpdateTab("NFL")}>
                      <h6>NFL</h6>
                    </Link>
                    <Link to="/" onClick={() => onUpdateTab("NBA")}>
                      <h6>NBA</h6>
                    </Link>
                    <Link to="/" onClick={() => onUpdateTab("MLB")}>
                      <h6>MLB</h6>
                    </Link>
                    <Link to="/" onClick={() => onUpdateTab("NHL")}>
                      <h6>NHL</h6>
                    </Link>
                    <Link to="/promos" onClick={() => onUpdateTab("PROMOS")}>
                      <h6>PROMOS</h6>
                    </Link>
                    <Link to="/compare" onClick={() => onUpdateTab("COMPARE")}>
                      <h6>COMPARE</h6>
                    </Link>

                    {/* <Link
                      to=""
                      onClick={() => setTeams(!teams)}
                      style={{ position: "relative" }}
                    >
                      <h6>TEAMS</h6>
                      {teams && (
                        <div className="team_header_dropdown header_dropdown sidebar_dropdown about_dropdown">
                          <p>
                            <img src="/images/Atlanta.png" alt="" /> Atlanta
                            Hawks
                          </p>
                          <p>
                            <img src="/images/boston.png" alt="" /> Boston
                            Celtics
                          </p>
                          <p>
                            <img src="/images/brooklyn.png" alt="" /> Brooklyn
                            Nets
                          </p>
                          <p>
                            <img src="/images/charlos.png" alt="" /> Charlotte
                            Hornets
                          </p>
                          <p>
                            <img src="/images/chicago.png" alt="" /> Chicago
                            Bulls
                          </p>
                          <p>
                            <img src="/images/cleve.webp" alt="" /> Cleveland
                            Cavaliers
                          </p>
                          <p>
                            <img src="/images/dallas.png" alt="" /> Dallas
                            Mavericks
                          </p>
                        </div>
                      )}
                    </Link> */}
                    <Dropdown>
                      <Dropdown.Toggle className="about-teams">
                        <Nav.Link
                          onClick={() => {
                            // onUpdateTab("TEAMS");
                            setTeams(!teams);
                          }}
                        >
                          TEAMS <AiFillCaretDown />
                        </Nav.Link>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="team_accordion_dropdown mt-4"
                        align="end"
                      >
                        <Accordion
                          className={`team_header_accordion ${
                            mode === "Dark" && "dark_theme"
                          } `}
                        >
                          {sports.map((sport, index) => {
                            return (
                              <Accordion.Item
                                className={`team_accordion_item ${
                                  mode === "Dark" && "dark_theme"
                                } `}
                                key={index}
                                eventKey={index}
                              >
                                <Accordion.Header
                                  onClick={() => fetchTeamsList(sport)}
                                  className="team_accordion_header"
                                >
                                  {sport}
                                </Accordion.Header>
                                <Accordion.Body className="team_accordion_body">
                                  {isLoading ? (
                                    <DarkLoader />
                                  ) : teamsList?.length ? (
                                    teamsList?.map((team) => (
                                      <p style={{ cursor: "pointer" }}>
                                        <span
                                          style={{ display: "flex", gap: 15 }}
                                        >
                                          <img
                                            src={team?.wikipediaLogoUrl || "/"}
                                            alt={`${team?.city} ${team?.name}`}
                                          />
                                          <span
                                            onClick={() =>
                                              teamNavigator(team, navigate, updateSport)
                                            }
                                          >
                                            {team?.city} {team?.name}
                                          </span>
                                        </span>
                                        <span>{team?.sport}</span>
                                      </p>
                                    ))
                                  ) : (
                                    <p style={{ color: "#778899" }}>
                                      No Teams Found
                                    </p>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div
                      className={`d-flex justify-content-between align-items-center mt-3`}
                      style={{ marginBottom: "20px" }}
                    >
                      <div className={`d-flex gap-3 social_about_icons`}>
                        <a
                          href={FB}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaFacebookF />
                        </a>
                        <a
                          href={Twitter}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {mode === "Light" ? (
                            <img
                              width="16px"
                              height="16px"
                              src="/images/sidebar-twitter-light.png"
                              alt=""
                            />
                          ) : (
                            <img
                              width="16px"
                              height="16px"
                              src="/images/sidebar-twitter-light.png"
                              alt=""
                            />
                          )}{" "}
                          {/* <BsTwitter /> */}
                        </a>
                        <a
                          href={Instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BsInstagram />
                        </a>
                      </div>
                      {/* <div>
                  {mode ==="Dark" ? (
                      <img
                        src="/images/Sun.png"
                        style={{
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => setMode("Light")}
                      />
                    ) : (
                      <img
                        src="/images/Moon.png"
                        style={{
                          height: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => setMode("Dark")}
                      />
                    )}
                  </div> */}
                    </div>
                  </Col>
                  <Col md={4}>
                    {!!user && (
                      <div className="d-flex align-items-center">
                            <Dropdown>
                              <Dropdown.Toggle id="profile-dropdown">
                                <img
                                  src={
                                    user?.user?.provider === "google" ||
                                    user?.user?.provider === "facebook"
                                      ? user?.user?.profile
                                      : `/images/${
                                          user?.user?.profile || "profile.png"
                                        }`
                                  }
                                  alt=""
                                  className="profile_icon"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                className="profile-dropdown-menu"
                                style={{
                                  marginLeft: "0px",
                                  backgroundColor: "#feb91a",
                                  width: "240px",
                                  marginTop:"40px"
                                }}
                              >
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/dashboard-1.png" />
                                  <h6>Dashboard</h6>
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  href="/"
                                  className="profile-dropdown-item"
                                >
                                  Account Setting
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/key.png" />

                                  <h6>Change Password</h6>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    logout();
                                    onSuccess("Logout Successfully!");
                                  }}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/logout.png" />

                                  <h6 style={{ color: "red" }}>Logout</h6>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
          </>
        )}
      </div>
    </div>
  );
};

export default AboutBanner;
