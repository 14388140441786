import React, { useEffect, useState } from "react";
import Modal from "../../../Components/Modal/SiteModal";
import { Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { cloudURL } from "../../../Utils/helper";
import Abouts from "../../../Services/about";
import { updateAbout } from "../../../Redux/Accessors/about.accessors";
import { errorNotify } from "../../../Utils/toast";
// import { useDispatch, useSelector } from "react-redux";
// import { addAboutSection, addPromo, getAllPromos } from "../../../Redux/Actions/CMS";
// import { errorNotify, successNotify } from "../../../Utils/toast";

const AddAboutSectionModal = ({ show, setShow, success, setSuccess }) => {
  // const dispatch = useDispatch()
  // const {loading,success}=useSelector((state)=>state.aboutCreate)
  const [createLoading, setCreateLoading] = useState(false);
  // const [success,setSuccess]=useState(false);
  // useEffect(async()=>{
  //     if(success){
  //       const res = await Abouts.about();
  //   const data = res?.data?.data || [];

  //   updateAbout(data);
  //   setSuccess(false)
  //         onCloseModal()
  //     }
  // },[success])
  const [images, setImages] = useState({
    image: "",
    imagePreview: "",
  });

  const handleImageChange = () => (e) => {
    setImages({
      image: e.target.files[0],
      imagePreview: URL.createObjectURL(e.target.files[0]),
    });
  };
  const [aboutSection, setAboutSection] = useState({
    heading: "",
    text: "",
  });
  const onChangeHandler = (name) => (e) => {
    setAboutSection((prev) => {
      return { ...prev, [name]: e.target.value };
    });
  };
  const onCloseModal = () => {
    setShow(false);
    setAboutSection({
      image: "",
      heading: "",
      text: "",
    });
    setImages({ image: "", imagePreview: "" });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setCreateLoading(true);
      console.log(images?.image, "image");
      const formData = new FormData();
      if (images.image !== "") {
        formData.append("image", images.image);
      }
      formData.append("heading", aboutSection.heading);
      formData.append("text", aboutSection.text);
      // console.log(images.image, aboutSection.heading, aboutSection.text);
      // dispatch(addAboutSection(formData))
      const res = await Abouts.createAbout(formData);
      console.log(res?.data?.data, "dataaa");
      setSuccess(true);
      onCloseModal();
    } catch (error) {
      setCreateLoading(false);
      errorNotify(error?.response?.data?.errorResponse?.message)
      
    } finally {
      setCreateLoading(false);
    }
  };
  return (
    <div>
      <Modal
        show={show}
        setShow={setShow}
        style="promo_modal"
        size="md"
        heading="Add About Section"
        onCloseModal={onCloseModal}
      >
        <>
          <Form onSubmit={submitHandler}>
            <Row className="justify-content-center add_promo">
              <Col md={11} className="mt-4">
                <Row className="gx-5">
                  <Col md={12}>
                    <Form.Label>Preview Image</Form.Label>
                    <Form.Group className="mb-3 cms_image_input">
                      <Form.Control
                        type="file"
                        style={{ fontSize: "13px", paddingBottom: "7px" }}
                        name={`image`}
                        onChange={handleImageChange()}
                      />
                    </Form.Group>
                    <div className="previewImg_div">
                      <img
                        src={
                          images?.imagePreview
                            ? images.imagePreview
                            : "/images/empty.jpg"
                        }
                        alt=""
                        style={{}}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="gx-5">
                  <Col md={12}>
                    <Form.Label>Heading</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="heading"
                        value={aboutSection.heading}
                        onChange={onChangeHandler("heading")}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row className="gx-5">
                  <Col md={12}>
                    <Form.Label>Text</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder=""
                        name="review"
                        value={aboutSection.text}
                        onChange={onChangeHandler("text")}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row className="justify-content-end">
                  <button
                    type="submit"
                    style={{
                      width: "fit-content",
                      backgroundColor: "red",
                      border: "none",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "6px",
                    }}
                  >
                    {createLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      </Modal>
    </div>
  );
};

export default AddAboutSectionModal;
