import React, { useEffect, useState } from 'react'
import AllUsersTable from './AllUsersTable'
import AllUsersTableMobile from './AllUsersTableMobile'
import Admin from '../../../Services/admin';
import { updateUsers, useUsers } from '../../../Redux/Accessors/auth.accessors';
import Loader from '../../../Utils/Loader';

const AllUsers = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUsers();
  }, []);
  // fetch all promos
  const users = useUsers();
  console.log(users, "promos");
  const fetchUsers = async () => {
    // console.log("promos");
    try {
      setLoading(true);
      const res = await Admin.getAllUsers();
      const data = res?.data?.data || [];
      console.log(data, "res");

      updateUsers(data);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="all_users">
    <h1>Total Users</h1>
      <div className="layout_page_section billing_section">
      {loading ? <Loader /> :<AllUsersTable users={users} fetchUsers={fetchUsers}/>}

      </div>
      {/* <div className="layout_page_section billing_section home_mobile">
          <AllUsersTableMobile />

      </div> */}
    </div>
  )
}

export default AllUsers