import React from "react";
import { Pagination } from "react-bootstrap";

const PageNumbers = ({
  page,
  setPage,
  handlePrev,
  handleNext,
  totalRecords,
  handleSetPage,
  postsPerPage = 10,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRecords / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const getPaginationRange = (currentPage) => {
    console.log('totalRecords', totalRecords);
    const totalPages = Math.ceil(totalRecords / postsPerPage);
    const visiblePages = 5;
    const halfVisible = Math.floor(visiblePages / 2);
    let startPage = currentPage - halfVisible;
    let endPage = currentPage + halfVisible;

    if (startPage <= 0) {
      startPage = 1;
      endPage = visiblePages;
    } else if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - visiblePages + 1;
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = getPaginationRange(page);

  return (
    <div>
      <Pagination size="lg">
        <Pagination.Prev
          onClick={() => handlePrev()}
          disabled={page === startPage}
        />
        {pageNumbers?.map((number, index) => {
          if (number >= startPage && number <= endPage) {
            return (
              <Pagination.Item
                key={index}
                active={page === number}
                onClick={() => handleSetPage(number)}
              >
                {number}
              </Pagination.Item>
            );
          }
          return null;
        })}
        <Pagination.Next
          onClick={() => handleNext()}
          disabled={page === endPage}
        />
      </Pagination>
    </div>
  );
};

export default PageNumbers;
