import React from "react";
import { useState } from "react";
import { ThemeState } from "../../../Context";
import Games from "../../../Services/Games";
import { Accordion } from "react-bootstrap";
import { sports } from "../../../Constants";
import DarkLoader from "../../../Common/Loader/DarkLoader";

const TeamSearch = ({ setTeamData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mode, setMode } = ThemeState();
  const [teamsList, setTeamsList] = useState([]);

  const fetchTeamsList = async (sport) => {
    try {
      setTeamsList([]);
      setIsLoading(true);
      const res = await Games.teams(sport);
      setTeamsList(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching teams list:", error);
    }
  };
  const handleTeamSelect = (team) => {
    setTeamData(team);
  };
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionSelect = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };

  return (
    <div className="position-relative">
      <Accordion
        className={`team_header_accordion position-absolute ${
          mode === "Dark" && "dark_theme"
        } `}
        activeKey={activeKey}
        onSelect={handleAccordionSelect}
      >
        {sports.map((sport, index) => {
          return (
            <Accordion.Item
              className={`team_accordion_item ${
                mode === "Dark" && "dark_theme"
              } `}
              key={index}
              eventKey={index}
            >
              <Accordion.Header
                onClick={() => fetchTeamsList(sport)}
                className="team_accordion_header"
              >
                {sport}
              </Accordion.Header>
              <Accordion.Body
                className="team_accordion_body"
                onClick={() => setActiveKey(null)}
              >
                {isLoading ? (
                  <DarkLoader />
                ) : teamsList?.length ? (
                  teamsList?.map((team) => (
                    <p style={{ cursor: "pointer" }}>
                      <span style={{ display: "flex", gap: 15 }}>
                        <img
                          className={`${
                            team?.name === "Pirates" && "team_logo_bg"
                          }`}
                          src={team?.wikipediaLogoUrl || "/"}
                          alt={
                            team?.logoAltText || `${team?.city} ${team?.name}`
                          }
                        />
                        <span
                          onClick={() => handleTeamSelect(team)}
                          // onClick={() => {
                          //   updateHeaderTab(team?.sport);
                          //   teamNavigator(
                          //     team,
                          //     navigate,
                          //     updateSport
                          //   );
                          //   updateSport(team?.sport);
                          // }}
                        >
                          {team?.city} {team?.name}
                        </span>
                      </span>
                      <span>{team?.sport}</span>
                    </p>
                  ))
                ) : (
                  <p style={{ color: "#778899" }}>No Teams Found</p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </div>
  );
};

export default TeamSearch;
