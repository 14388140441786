import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import headerSlice from "./Slices/header.slice";
import authSlice from "./Slices/auth.slice";
import gameSlice from "./Slices/game.slice";
import previousGameSlice from "./Slices/previousGames.slice";
import promoSlice from "./Slices/promos.slice";
import aboutSlice from "./Slices/about.slice";
import dataUpdateSlice from "./Slices/dataUpdate.slice";
import subAdminSlice from "./Slices/subAdmin.slice";

const mainReducer = combineReducers({
  heaeder: headerSlice.reducer,
  auth: authSlice.reducer,
  games: gameSlice.reducer,
  previousGames: previousGameSlice.reducer,
  promos: promoSlice.reducer,
  about: aboutSlice.reducer,
  dataUpdate: dataUpdateSlice.reducer,
  subAdmin: subAdminSlice.reducer,
});

const persistConfig = {
  key: "base",
  storage,
};

const persistedReducer = persistReducer(persistConfig, mainReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    });
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
