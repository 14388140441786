import * as yup from "yup";

export const loginSchema = yup.object().shape({
  loginType: yup.string(),
  Email: yup
    .string()
    .email()
    .when("loginType", (loginType, schema) => {
      return loginType === "Email" || loginType[0] === "Email"
        ? schema.email().required("Email is required")
        : schema;
    }),
  Phone: yup.string().when("loginType", (loginType, schema) => {
    return loginType === "Phone" || loginType[0] === "Phone"
      ? schema
          .min(7, "Too short")
          .max(15, "Too long")
          .required("Phone number is required")
      : schema;
  }),
  Password: yup.string().required("Password is required"),
});

export const updateNameSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
});

export const updatePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .min(8, "New password must be at least 8 characters")
    .required("New password is required")
    .test(
      "regex",
      "A combination of uppercase letters, lowercase letters, numbers and symbols.",
      (val) => {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$_-]).{8,}$/.test(val);
      }
    ),
  confirmNewPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Password should be same."),
});

export const otpSchema = yup.object().shape({
  otp: yup
    .string()
    .required("Please provide the code.")
    .min(4, "OTP must be exactly 4 characters long.")
    .max(4, "OTP must be exactly 4 characters long."),
});

export const forgetSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .when("loginType", (loginType, schema) => {
      return loginType === "Email" || loginType[0] === "Email"
        ? schema.email().required("Email is required")
        : schema;
    }),
  phone: yup.string().when("loginType", (loginType, schema) => {
    return loginType === "Phone" || loginType[0] === "Phone"
      ? schema
          .min(7, "Too short")
          .max(15, "Too long")
          .required("Phone number is required")
      : schema;
  }),
});

export const forgetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, "New Password must be at least 8 characters")
    .required("New Password is required")
    .test(
      "regex",
      "A combination of uppercase letters, lowercase letters, numbers and symbols.",
      (val) => {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$_-]).{8,}$/.test(val);
      }
    ),
  confirmNewPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Confirm Password should be same."),
});

export const signupSchema = yup.object().shape({
  loginType: yup.string(),
  Email: yup
    .string()
    .email()
    .when("loginType", (loginType, schema) => {
      return loginType === "Email" || loginType[0] === "Email"
        ? schema.email().required("Email is required")
        : schema;
    }),
  Phone: yup.string().when("loginType", (loginType, schema) => {
    return loginType === "Phone" || loginType[0] === "Phone"
      ? schema
          .min(7, "Too short")
          .max(15, "Too long")
          .required("Phone number is required")
      : schema;
  }),
  Password: yup
    .string()
    .min(8)
    .required("Password is required")
    .test(
      "regex",
      "A combination of uppercase letters, lowercase letters, numbers and symbols.",
      (val) => {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$_-]).{8,}$/.test(val);
      }
    ),
  ConfirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("Password")], "Password should be same."),
  State: yup.string().required("State is required"),
});
