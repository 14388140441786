import React from "react";

import BillingTable from "./BillingTable";
import BillingTableMobile from "./BillingTableMobile";

const BillingHistory = () => {
  return (
    <>
      <h1>Billing History</h1>
      <div className="layout_page_section billing_section home_desktop">
          <BillingTable />

      </div>
      <div className="layout_page_section billing_section home_mobile">
          <BillingTableMobile />

      </div>
    </>
  );
};

export default BillingHistory;
