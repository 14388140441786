import React, { useEffect } from "react";
import "./AboutUs.css";
import AboutBanner from "../../Components/AboutUs/AboutBanner";
import AboutContent from "../../Components/AboutUs/AboutContent";
import Footer from "../../Components/Layout/Footer/Footer";
import AboutTitle from "../../Components/AboutUs/AboutTitle";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import PageWrapper from "../../Common/PageWrapper";
import { useState } from "react";
import Loader from "../../Utils/Loader";
import { updateAbout, useAbout } from "../../Redux/Accessors/about.accessors";
import Abouts from "../../Services/about";

const AboutUs = () => {
  const [loading, setLoading] = useState(false);
  const {about} = useAbout();
  const fetchAbout = async () => {
    try {
      setLoading(true);
      const res = await Abouts.about();
      const data = res?.data?.data || [];

      updateAbout(data);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching about-us:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAbout();
  }, []);
  return (
    <PageWrapper>

    <div style={{ overflowX: "none", backgroundColor: "white" }}>
      <div className="home_desktop">
        {loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <AboutBanner banner={about?.bannerSection} />

            <AboutContent content={about?.aboutSections} />
          </>
        )}
        <Footer />
      </div>
      <div className={`home_mobile`}>
        <AboutTitle title={about?.bannerSection?.title} />
        <MobileHeader />
        {loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <AboutBanner mobile banner={about?.bannerSection} />
            <AboutContent content={about?.aboutSections} />
          </>
        )}
        <MobileFooter />
      </div>
    </div>
    </PageWrapper>

  );
};

export default AboutUs;
