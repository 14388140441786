import React from "react";
import Header from "../../Components/User/Header/Header";
import { userSideBarItems } from "../User/Routes";
import { Outlet } from "react-router-dom";
import { useUser } from "../../Redux/Accessors/auth.accessors";
const UserLayout = () => {
  const {user} = useUser()
    // console.log(user,"user")
  // let userAuth = JSON.parse(localStorage.getItem("user"));

  return (
    <React.Fragment>
       {
                user?.user.role !== "admin" || user?.user.role !== "sub-admin" ? 
      <Header sideBarItems={userSideBarItems}>
        <Outlet />
      </Header>
      : ""}
    </React.Fragment>
  );
};

export default UserLayout;
