import React, { useState, useEffect, useRef } from "react";
import "./notifications.css";
import NavigationBar from "../../Components/Layout/Notification/NavigationBar";
import Notifications from "../../Components/Layout/Notification/Notifications";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import { ThemeState } from "../../Context";
import Header from "../../Components/Layout/Header/Header";
import { updateNotifications } from "../../Redux/Accessors/auth.accessors";
import Notification from "../../Services/Notification";
import PageNumbers from "../../Common/Pagination";
import Games from "../../Services/Games";
import NotificationSearch from "./NotificationSearch";
import Loader from "../../Common/Loader";
import { updateHeaderTab } from "../../Redux/Accessors/header.accessors";
import PageWrapper from "../../Common/PageWrapper";
import { useSport } from "../../Redux/Accessors/game.accessors";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { PAGE_HEADER_TABS } from "../../Constants";

const NotificationsPage = () => {
  const sport = useSport();
  const audioRef = useRef(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const { mode } = ThemeState();
  const [viewMore, setViewMore] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ALL");
  const [search, setSearch] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [onUpdate, setOnUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [playerId, setPlayerId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [enableScroll, setEnableScroll] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [notificationData, setNotificationData] = useState({});
  const [isListening, setIsListening] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const limit = 20;

  const fetchAllNotifications = async (data) => {
    const { skip, playerId, teamId, pageCount = page } = data || { skip: 0 };
    const matchKey = `${sport}_${pageCount}`;

    try {
      window.scrollTo(0, 0);
      setIsLoading(true);
      const res = await Notification.getAllNotifications(
        selectedTab,
        skip || 0,
        limit,
        playerId,
        teamId
      );
      setNotifications(res?.data);
      updateNotifications(res?.data);
      setNotificationData((prevState) => ({
        ...prevState,
        [matchKey]: res.data,
      }));
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching schedules:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    fetchAllNotifications();
    updateHeaderTab(PAGE_HEADER_TABS.NOTIFICATIONS);

    const intervalId = setInterval(fetchAllNotifications, 5 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdate, selectedTab]);

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchAllNotifications({
      skip: skip * limit,
      playerId,
      teamId,
      pageCount: page,
    });
  };

  const handlePrev = () => {
    setEnableScroll(true);

    if (page !== 1) {
      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchAllNotifications({ skip: skip * limit, pageCount: newPage });
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(notifications?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      console.log("skip", skip, "playerId", playerId, "teamId", teamId);
      fetchAllNotifications({
        skip: skip * limit,
        playerId,
        teamId,
        pageCount: newPage,
      });
    }
  };

  const onSearch = async (name) => {
    setSearch(name);
    if (name?.length >= 4) {
      try {
        const res = await Games.search(name);
        setPlayers(res?.data?.data);
      } catch (error) {
        console.log("Error in searching players");
      }
    }
  };

  const searchByPlayer = async (id) => {
    if (id) {
      setPlayerId(id);
      setTeamId(null);
      fetchAllNotifications({ playerId: id });
    }
  };

  const searchByTeam = async (id) => {
    if (id) {
      setTeamId(id);
      setPlayerId(null);
      fetchAllNotifications({ teamId: id });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isListening) {
      onSearch(transcript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  const handleAudio = () => {
    audioRef?.current?.play();
  };

  const handleSpeech = (status) => {
    resetTranscript();
    setIsListening(!isListening);

    try {
      if (status === "start") {
        handleAudio();
        SpeechRecognition.startListening({ continuous: false });

        const stopTimeout = setTimeout(() => {
          console.log("stopTimeout");
          SpeechRecognition.stopListening();
          setIsListening(false);
        }, 5000); // 5000 milliseconds = 5 seconds

        SpeechRecognition.onresult = (event) => {
          clearTimeout(stopTimeout); // Clear the timeout if recognition produces results
        };
      } else {
        SpeechRecognition.stopListening();

        resetTranscript();
      }
    } catch (error) {
      console.log("Error in speach recognization", error);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn`t support speech recognition.</span>;
  }

  return (
    <PageWrapper enableScroll={enableScroll} setEnableScroll={setEnableScroll}>
      <div
        className={`notifications_main ${mode === "Light" && "light_theme"}`}
      >
        <div className="home_desktop">
          <Header />
        </div>
        <div className="home_mobile">
          <MobileHeader />
        </div>
        <Container className=" px-md-5 mt-3">
          <Row className="justify-content-between px-0 px-md-0 align-items-end mb-3">
            <Col md={7} className="">
              <NavigationBar
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                searchByTeam={searchByTeam}
                fetchAllNotifications={fetchAllNotifications}
              />
            </Col>
            <Col md={5} className="">
              <div className="search-wrapper">
                <div className="premium_search_box  mb-2">
                  <input
                    placeholder=" Search Player Notifications "
                    className="ps-2"
                    value={search}
                    onChange={(e) => onSearch(e.target.value)}
                    onFocus={() => setShowDropdown(true)}
                    style={{
                      background: "rgb(33, 33, 33)",
                      border: "1px solid rgb(77,77,77)",
                      height: "40px",
                      color: "white",
                    }}
                    alt="logo"
                  />

                  <div className="notification-mic-wrapper">
                    <div className="mic-wrapper">
                      <img
                        src={"/images/search-white.png"}
                        style={{ color: "white !important", top: "10px" }}
                        className="text-white notification-search"
                        alt="logo"
                      />
                    </div>
                    <div
                      className="mic-wrapper"
                      onClick={() =>
                        handleSpeech(isListening ? "stop" : "start")
                      }
                    >
                      <img
                        className="mic notification-mic mt-1"
                        src={
                          !isListening
                            ? "/images/white-mic.png"
                            : "/images/mic-animation.gif"
                        }
                        alt="mic"
                      />
                    </div>
                  </div>
                </div>
                {search?.length >= 4 && showDropdown && (
                  <NotificationSearch
                    players={players}
                    setPlayerName={setSearch}
                    searchByPlayer={searchByPlayer}
                    showDropdown={showDropdown}
                    setShowDropdown={setShowDropdown}
                  />
                )}
              </div>
            </Col>
          </Row>
          <div
            style={{
              minHeight: "350px",
            }}
          >
            {!isLoading ? (
              <Notifications
                allNotifications
                notShowAll
                viewMore={viewMore}
                setViewMore={setViewMore}
                showViewMore
                selectedTab={selectedTab}
                search={search}
                data={notifications.data}
                onUpdate={onUpdate}
                setOnUpdate={setOnUpdate}
              />
            ) : (
              <Loader />
            )}
          </div>
          {!!notifications?.data?.length && !isLoading && (
            <div
              className={`mt-5 players_pagination ${
                mode === "Light" && "light_theme"
              }`}
            >
              <PageNumbers
                page={page}
                setPage={setPage}
                handleSetPage={(pageNum) => handleSetPage(pageNum)}
                handlePrev={handlePrev}
                handleNext={handleNext}
                postsPerPage={100}
                totalRecords={notifications?.totalCount}
              />
            </div>
          )}
        </Container>
        <div className="home_desktop">
          <Footer />
        </div>
        <div className="home_mobile">
          <MobileFooter />
        </div>
      </div>
      <audio ref={audioRef} src="./images/beep.mp3" />
    </PageWrapper>
  );
};

export default NotificationsPage;
