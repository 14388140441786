export const requestMicrophoneAccess = async () => {
  try {
    // Check if permission has already been granted
    if (navigator.permissions) {
      const permissionStatus = await navigator.permissions.query({
        name: "microphone",
      });
      if (permissionStatus.state === "granted") {
        // Microphone access is already granted, no need to request it again
        return;
      }
    }

    // If permission hasn't been granted, request it
    let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    // You have permission, you can use the stream for something like Web Audio API or close it.
    stream.getTracks().forEach((track) => track.stop());
  } catch (error) {
    console.error("Microphone permission error:", error);
  }
};
