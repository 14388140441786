import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import "./promos.css";
import PromosContent from "../../Components/Promos/PromosContent";
import { ThemeState } from "../../Context";
import PageWrapper from "../../Common/PageWrapper";
// import { promos } from "../../Utils/helper";
import { updatePromos, usePromos } from "../../Redux/Accessors/promo.accessors";
import ExistingPromos from "../../Services/promos";

const Promos = () => {
  const { mode } = ThemeState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPromos();
  }, []);
  // fetch all promos
  const {promos} = usePromos();
  console.log(promos, "promos");
  const fetchPromos = async () => {
    // console.log("promos");
    try {
      setLoading(true);
      const res = await ExistingPromos.promos();
      // console.log(res?.data?.data, "res");
      const data = res?.data?.data || [];

      updatePromos(data);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching promos:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper>
      <div>
        <div className="home_desktop">
          <Header />
          <PromosContent mode={mode} promosContent={promos?.promos} loading={loading} />
          <Footer />
        </div>
        <div className={`home_mobile`}>
          <MobileHeader />
          <PromosContent
            mode={mode}
            mobile
            promosContent={promos?.promos}
            loading={loading}
          />
          <MobileFooter />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Promos;
