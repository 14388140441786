import React from "react";
import DataUpdate from "./DataUpdate";

const NHL = () => {
  return (
    <div className="data_update">
      <div className=" data_update_header_container">
        <h1>NHL Schedule</h1>
      </div>
      <div className="layout_page_section px-3 ">
        <DataUpdate game="NHL" />
      </div>
    </div>
  );
};

export default NHL;
