import React from "react";
import { Col, Row } from "react-bootstrap";
import { BsChevronRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import TextMessage from "../../../Common/TextMessage";
import { NO_CONTENT_MESSAGE } from "../../../Constants";
import { ThemeState } from "../../../Context";
import { updateSport } from "../../../Redux/Accessors/game.accessors";
import { useTeamGames } from "../../../Redux/Accessors/previousGames.accessors";
import { getTeamPageScheduleTime } from "../../../Utils/dates";
import { teamNavigator } from "../../../Utils/navigator";

const TeamsTable = ({
  premiumDetails,
  setPremiumDetails,
  id,
  teamKey,
  setTeamKey,
}) => {
  const { mode } = ThemeState();
  const games = useTeamGames();
  const navigate = useNavigate();

  const schedule = games?.data?.scheduledGame;

  const hasData = schedule && Object.keys(schedule).length > 0;

  const getSignValue = (value) => {
    if (!value) return "";
    if (value === 0 || value < 0) return value;
    return `+${value}`;
  };
  console.log(schedule, "schedule");
  setTeamKey &&
    setTeamKey(schedule?.awayTeam?._id === id ? "awayTeam" : "homeTeam");

  setPremiumDetails && setPremiumDetails(schedule);

  return (
    <>
      {hasData ? (
        <div className="ps-3 ps-md-0">
          <div
            className={`teams_table_main ${mode === "Light" && "light_theme"}`}
          >
            <div className={`teams_table ${mode === "Light" && "light_theme"}`}>
              <Row className="gx-0">
                <Col xs={5} md={7}>
                  <div className="team_name"></div>
                  <div className="team_name" style={{ position: "relative" }}>
                    <img
                      src={schedule?.awayTeam?.wikipediaLogoUrl}
                      alt={schedule?.awayTeam?.logoAltText || ""}
                    />
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        teamNavigator(schedule?.awayTeam, navigate, updateSport)
                      }
                    >
                      {schedule?.awayTeam?.city} {schedule?.awayTeam?.name}
                    </h3>
                    <div className="team_vs">@</div>
                  </div>
                  <div className="team_name">
                    <img
                      src={schedule?.homeTeam?.wikipediaLogoUrl}
                      alt={schedule?.homeTeam?.logoAltText || ""}
                    />
                    <h3
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        teamNavigator(schedule?.homeTeam, navigate, updateSport)
                      }
                    >
                      {schedule?.homeTeam?.city} {schedule?.homeTeam?.name}{" "}
                    </h3>
                  </div>
                </Col>
                <Col xs={7} md={5}>
                  <Row className="table_info gx-0">
                    <Col xs={4}>
                      <h3>Spread</h3>
                    </Col>
                    <Col xs={4}>
                      <h3>Total</h3>
                    </Col>
                    <Col xs={4}>
                      <h3>Money</h3>
                    </Col>
                  </Row>
                  <Row className="table_info gx-0">
                    <Col xs={4} md={4}>
                      <div>
                        <h3>
                          {/* {schedule?.awayPointSpread > 0
                          ? `+${schedule?.awayPointSpread}`
                          : schedule?.awayPointSpread} */}
                          {getSignValue(schedule?.awayPointSpread)}
                        </h3>
                        <p>{schedule?.awayPointSpreadPayout}</p>
                      </div>
                    </Col>
                    <Col xs={4}>
                      {!!schedule && (
                        <div className="text-center">
                          <h3>{schedule?.overUnder}</h3>
                          <p> {schedule?.underPayout}</p>
                        </div>
                      )}
                    </Col>
                    <Col xs={4}>
                      <h3>
                        {/* {schedule?.awayMoneyLine > 0
                          ? `+${schedule?.awayMoneyLine}`
                          : schedule?.awayMoneyLine} */}
                        {getSignValue(schedule?.awayMoneyLine)}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="table_info gx-0">
                    <Col xs={4} md={4}>
                      {
                        <div>
                          <h3>
                            {/* {schedule?.homePointSpread > 0
                              ? `+${schedule?.homePointSpread}`
                              : schedule?.homePointSpread} */}
                            {getSignValue(schedule?.homePointSpread)}
                          </h3>
                          <p>{schedule?.homePointSpreadPayout}</p>
                        </div>
                      }
                    </Col>
                    <Col xs={4}>
                      {!!schedule?.underPayout && (
                        <div className="text-center">
                          <h3>{schedule?.overUnder}</h3>
                          <p>{schedule?.underPayout}</p>
                        </div>
                      )}
                    </Col>
                    <Col xs={4}>
                      <h3>
                        {/* {schedule?.homeMoneyLine > 0
                          ? `+${schedule?.homeMoneyLine}`
                          : schedule?.homeMoneyLine} */}
                        {getSignValue(schedule?.homeMoneyLine)}
                      </h3>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="justify-content-between align-items-center d-flex text-white match_details">
                <h3>{getTeamPageScheduleTime(schedule?.dateTimeUTC)}</h3>
                <Link
                  to="/"
                  state={{ data: schedule?.externalId }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className="d-flex align-items-center"
                    // onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  >
                    <h3
                      className={`${
                        mode === "Light" ? "text-white" : "col-blue"
                      }`}
                    >
                      Player Totals
                    </h3>
                    <BsChevronRight
                      style={{
                        marginLeft: "10px",
                        color: mode === "Light" ? "#fff" : "#60a5fa",
                      }}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center mt-5">
          <TextMessage message={NO_CONTENT_MESSAGE} />
        </div>
      )}
    </>
  );
};

export default TeamsTable;
