import React, { useState } from "react";
import "./PremiumCard.css";
import { BiChevronDown } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../../Context";
import { useTab } from "../../../Redux/Accessors/game.accessors";
import PremiumData from "./PremiumData";

const LabelCols = ({ mode, tab }) => {
  return (
    <Col xs={12} className="">
      <div className="compare_list_data compare_labels">
        <div
          style={{ border: "none" }}
          className={`compare_premium_box_header ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div
            sm={12}
            className=" w-100 d-flex justify-content-start align-items-center"
          >
            <p className="compare_selected_tab">{tab}</p>
          </div>
        </div>
      </div>
      <div className="compare_list_data compare_labels">
        <Row className="list_body">
          <Col
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <p>Avg Finish</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="compare_list_data compare_labels">
        <Row className="list_body">
          <Col sm={12}>
            <div>
              <p>Avg Line</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="compare_list_data compare_labels">
        <Row className="list_body">
          <Col sm={12}>
            <div>
              <p>% Finish</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="compare_list_data compare_labels">
        <Row className="list_body">
          <Col sm={12}>
            <div>
              <p>Projection</p>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const ComparePremiumCard = ({
  viewMore,
  onlyOne,
  player,
  search,
  onlyList,
  teamName,
  teamLogo,
  name,
  compares,
  playerIds,
}) => {
  const { mode } = ThemeState();
  const tab = useTab();
  const [premiumListTab, setPremiumListTab] = useState(5);

  return (
    <div className={`premium_card_box_main`}>
      <div className={`premium_card_box ${mode === "Light" && "light_theme"}`}>
        <div className="desktop_search">
          {search && (
            <div className="premium_search_box mb-1">
              <input placeholder="Search for player totals or results" />
              <img src={"/images/search.png"} alt="" />
            </div>
          )}
        </div>
        <div className="mobile_search">
          {search && (
            <div className="premium_search_box">
              <img src="/images/search.png" alt="" />
              <input placeholder="Search for player totals or results" />
              <img src="/images/mic.png" alt="" />
            </div>
          )}
        </div>

        <>
          <div className="compare_premium_card_header bg_green w-100 text-white list_head ">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "8px" }}
            >
              <h3 style={{ fontSize: "13px" }}>
                PREMIUM <BiChevronDown />
              </h3>
              <h3
                className={`${premiumListTab === 5 ? "active" : ""}`}
                onClick={() => setPremiumListTab(5)}
              >
                Last 5{" "}
              </h3>
              <h3
                className={`${premiumListTab === 10 ? "active" : ""}`}
                onClick={() => setPremiumListTab(10)}
              >
                Last 10{" "}
              </h3>
              <h3
                className={`${premiumListTab === "season" ? "active" : ""}`}
                onClick={() => setPremiumListTab("season")}
              >
                Season{" "}
              </h3>
            </div>
          </div>

          <div
            className={`compare_list_box ${mode === "Light" && "light_theme"}`}
          >
            <Row>
              <Col xs={3} md={1}>
                <LabelCols mode={mode} tab={tab} />
              </Col>
              <Col xs={9} md={11}>
                <Row>
                  {playerIds?.map((playerId, index) => (
                    <PremiumData
                      mode={mode}
                      playerId={playerId}
                      matches={premiumListTab}
                      length={playerIds?.length}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </>
      </div>
    </div>
  );
};

export default ComparePremiumCard;
