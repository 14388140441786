import { apiRequest } from "../Utils/apiRequest";
import {dataUpdateRoute} from "../Utils/routes"
const dataUpdates = {
  updatePlayerStats: (data) => {
    console.log(data,"api call data")
    return apiRequest(dataUpdateRoute.updatePlayerStats, "PUT", data, true);
  },
};

export default dataUpdates;
