import React from "react";
import { Modal } from "react-bootstrap";
import "./SiteModal.css";
import { IoMdCloseCircleOutline } from "react-icons/io";

const SiteModal = ({
  title,
  show,
  size,
  children,
  onCloseModal,
  style,
  heading,
  addAdmin,
  setShow,
  bg
}) => {
  return (
    <Modal
      show={show}
      size={size}
      backdrop="static"
      className={style ? style : `site_modal ${addAdmin && "admin_modal"}`}
      onHide={() => setShow(false)}
      // style={{backgroundColor:bg?"#feb91a" :""}}
    >
      <Modal.Body>
        <div className={"review_header"}>
          {heading && <h4>{heading}</h4>}

          <IoMdCloseCircleOutline onClick={onCloseModal} />
        </div>

        {children}
      </Modal.Body>
    </Modal>
  );
};
export default SiteModal;
