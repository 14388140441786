import React, { useEffect } from "react";
import "./TeamsDetails.css";
import { Col, Container, Row } from "react-bootstrap";
import TeamHeader from "./TeamHeader";
import PremiumCard from "../../Home/PremiumCard/PremiumCard";
import TeamsTable from "./TeamsTable";
import PointsTableHeader from "../../Home/PointsTableHeader/PointsTableHeader";
import TeamsSideBar from "./TeamsSideBar";
import TeamsAccordion from "./TeamsAccordion";
import Notifications from "../../Layout/Notification/Notifications";
import { ThemeState } from "../../../Context";
import { useTeamGames } from "../../../Redux/Accessors/previousGames.accessors";
import TextMessage from "../../../Common/TextMessage";
import { useSport } from "../../../Redux/Accessors/game.accessors";
import { useState } from "react";
import { TEAMS_DETAILS } from "../../../Constants";

const TeamsDetails = ({
  page,
  setPage,
  fetchTeamPreviousGames,
  notificationsData,
  onUpdate,
  setOnUpdate,
  setEnableScroll,
  id,
  tabTitle = "",
  premiumData,
  setPremiumData,
  teamKey,
  setTeamKey,
  teamData,
}) => {
  const { mode } = ThemeState();
  const sport = useSport();
  const games = useTeamGames();
  const previousGames = games?.data?.previousGames;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(premiumData, "premiumDetails");

  let team;

  if (tabTitle) {
    // console.log(tabTitle);
    const string = tabTitle.replace(/[^a-zA-Z0-9\s]/g, ``);
    const words = string.split(" ");
    const title = words.slice(0, 3).join("_").toUpperCase();
    team = title;
  }
  return (
    <div className={`teams_details_main ${mode === "Light" && "light_theme"}`}>
      <Container fluid>
        <Row className="gx-0">
          <Col md={9}>
            <div className="pe-0 pe-md-4">
              <TeamHeader id={id} teamDetail={TEAMS_DETAILS[team]} />
              <TeamsTable
                premiumDetails={premiumData}
                setPremiumDetails={setPremiumData}
                teamKey={teamKey}
                setTeamKey={setTeamKey}
                id={id}
              />
              <PointsTableHeader gameSport={sport} id={id} />
              {previousGames?.length ? (
                <>
                  <TeamsAccordion
                    id={id}
                    page={page}
                    setPage={setPage}
                    fetchTeamPreviousGames={(skip) =>
                      fetchTeamPreviousGames(skip)
                    }
                    setEnableScroll={setEnableScroll}
                  />
                </>
              ) : (
                <div className="text-center mt-5">
                  <TextMessage message={"No Previous Game"} />
                </div>
              )}
            </div>
          </Col>
          <Col md={3}>
            <Notifications
              page="team"
              data={notificationsData}
              onUpdate={onUpdate}
              setOnUpdate={setOnUpdate}
            />
            <div
              style={{
                marginTop: "20px",
              }}
            >
              <PremiumCard
                onlyOne
                onlyList
                search
                id={id}
                // tab="team" teamData={teamData}
                premium={
                  premiumData?.premium && premiumData?.premium[teamKey]
                    ? premiumData?.premium[teamKey]
                    : null
                }
              />
            </div>
            <div className="mt-3">
              <TeamsSideBar />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamsDetails;
