import { GoogleOAuthProvider } from "@react-oauth/google";
import moment from "moment";
import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { ThemeState } from "./Context";
import AboutUs from "./Pages/AboutUs/AboutUs";
import { AdminRoutes } from "./Pages/Admin/Routes/Routes";
import Advertise from "./Pages/Advertise/Advertise";
import Compare from "./Pages/Compare/Compare";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import NotificationsPage from "./Pages/Notifications/NotificationsPage";
import Players from "./Pages/Players/Players";
import Premium from "./Pages/Premium/Premium";
import Promos from "./Pages/Promos/Promos";
import AdminLayout from "./Pages/ProtectedRoutes/AdminLayout";
import UserLayout from "./Pages/ProtectedRoutes/UserLayout";
import RssFeed from "./Pages/RssFeed";
import SignUp from "./Pages/Signup/SignUp";
import Teams from "./Pages/Teams/Teams";
import Dashboard from "./Pages/User/Dashboard/Dashboard";
import { userRoutes } from "./Pages/User/Routes";
import { useThemeMode } from "./Redux/Accessors/auth.accessors";
import {
  updateSettings,
  updateSlide,
  updateSport,
  useDate,
} from "./Redux/Accessors/game.accessors";
import Games from "./Services/Games";
import { SuperAdminRoutes } from "./Pages/SuperAdmin/Routes";
import SuperAdminLayout from "./Pages/ProtectedRoutes/SuperAdminLayout";

function App() {
  const { mode, setMode } = ThemeState();
  const themeMode = useThemeMode() || "Dark";
  const existingDate = useDate();

  function ScrollToTop() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      if (!hash) {
        window.scrollTo(0, 0);
      }
    }, [pathname, hash]);
    return null;
  }

  const settings = async () => {
    const res = await Games.settings();

    updateSettings(res?.data?.data);
    updateSport(res?.data?.data?.defaultSport);
  };

  useEffect(() => {
    settings();
  }, []);
  const superAdminLayout = (
    <Route path={"/super-admin"} element={<SuperAdminLayout />}>
      {SuperAdminRoutes.map((item) => (
        <Route key={item.path} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const adminLayout = (
    <Route path={"/sub-admin"} element={<AdminLayout />}>
      {AdminRoutes.map((item) => (
        <Route key={item.path} path={item.path} element={item.component} />
      ))}
    </Route>
  );
  const userLayout = (
    <Route path={"/user"} element={<UserLayout />}>
      {userRoutes.map((item) => (
        <Route key={item.path} path={item.path} element={item.component} />
      ))}
    </Route>
  );
  

  useEffect(() => {
    window.scrollTo(0, 0);

    if (existingDate) {
      const isSameDate = moment(existingDate).isSame(moment(), "day");
      if (!isSameDate) {
        updateSlide(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMode(themeMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className={`App ${mode === "Light" && "light_theme"}`}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:sport" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/:sport/team/:slug" element={<Teams />} />
            <Route path="/:sport/player/:slug" element={<Players />} />
            <Route path="/premium" element={<Premium />} />
            <Route path="/premium/:sport" element={<Premium />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sub-admin/signup" element={<SignUp />} />
            <Route path="/sub-admin/login" element={<Login />} />
            <Route path="/admin/signup" element={<SignUp />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/compare" element={<Compare />} />
            <Route path="/promos" element={<Promos />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/advertise" element={<Advertise />} />
            <Route path="/feed" element={<RssFeed />} />
            {userLayout}
            {adminLayout}
          {superAdminLayout}

            <Route path="/user/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
