import React from "react";
import { ThemeState } from "../../Context";
import MobileSiteLogos from "./MobileSiteLogos";
import MobileStatsCol from "./MobileStatsCol";

const MobileCompareTable = ({
  viewMore,
  setViewMore,
  name,
  teamName,
  teamLogo,
  compares,
  setCompares,
  playerIds,
  removeIdFromSport,
}) => {
  const { mode } = ThemeState();
  // eslint-disable-next-line no-unused-vars
  return (
    <>
      {playerIds?.length ? (
        <div className="ps-3 py-2">
          <div
            className={`compare_match_stats_main bg_grey ${
              mode === "Light" && "light_theme"
            }`}
          >
            <div className="d-flex">
              <MobileSiteLogos />
              <div className="d-flex" style={{ overflow: "auto" }}>
                {playerIds?.map((playerId, index) => (
                  <MobileStatsCol
                    key={`${playerId}_${index}`}
                    playerId={playerId}
                    length={playerIds?.length}
                    removeIdFromSport={(sport, id) =>
                      removeIdFromSport(sport, id)
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3
          style={{
            color: "white",
            textAlign: "center",
            padding: "40px 0px",
            fontSize: "1rem",
          }}
        >
          Search Players to Compare
        </h3>
      )}
    </>
  );
};

export default MobileCompareTable;
