import React, { useState, useEffect } from "react";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import StatsBox from "../../../Common/StatsBox";
import Games from "../../../Services/Games";
import { getTab, isResultGreater } from "../../../Utils/getStats";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import { getMonthAndDay } from "../../../Utils/dates";
import { teamNavigator } from "../../../Utils/navigator";

const GameStatsMobile = ({ id, game, team, player, playerDetail }) => {
  const tab = useTab();
  const navigate = useNavigate();
  const { mode } = ThemeState();
  const [gameStats, setGameStats] = useState(null);

  const myTeam = game?.homeTeamPlayers?.find(({ _id }) => _id === id)
    ? "homeTeam"
    : "awayTeam";

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.statsByPlayer(data);
      setGameStats(res.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    fetchTeamPlayerStats({
      gameId: game?._id,
      playerId: id,
      bettingBetType: getTab(tab),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  return (
    <div
      className={`player_mobile_assists_body_2 ${
        mode === "Light" && "light_theme"
      }`}
    >
      <div>
        <div className="d-flex flex-column justify-content-center h-100">
          <p>Date {getMonthAndDay(game?.time)}</p>
          <p
            onClick={() =>
              teamNavigator(
                game[myTeam === "homeTeam" ? "awayTeam" : "homeTeam"],
                navigate, updateSport
              )
            }
          >
            Opp:{" "}
            <span>
              {myTeam !== "homeTeam" && "@"}{" "}
              {game[myTeam === "homeTeam" ? "awayTeam" : "homeTeam"]?.name}
            </span>
          </p>
        </div>
        <div
          className="d-flex align-items-center h-100"
          style={
            isResultGreater(gameStats)
              ? { color: "#5caa00" }
              : { color: "#FF003A" }
          }
        >
          <p
            className={
              mode === "Light" && isResultGreater(game) && "text-black"
            }
          >
            {gameStats?.result || 0}
          </p>
          {isResultGreater(gameStats) ? (
            <HiOutlineArrowNarrowUp />
          ) : (
            <HiOutlineArrowNarrowDown />
          )}
        </div>
      </div>
      <div>
        <StatsBox playerStats={gameStats?.fanduel} />
      </div>
      <div>
        <StatsBox playerStats={gameStats?.draftkings} />
      </div>
      <div>
        <StatsBox playerStats={gameStats?.betmgm} />
      </div>
      <div>
        <StatsBox playerStats={gameStats?.caesars} />
      </div>
      <div>
        <StatsBox playerStats={gameStats?.consensus} />
      </div>
      <div>
        <StatsBox playerStats={gameStats?.pointsbet} />
      </div>
    </div>
  );
};

export default GameStatsMobile;
