import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./PlayersDetails.css";
import PlayerHeader from "./PlayerHeader";
import PointsTableHeader from "../../Home/PointsTableHeader/PointsTableHeader";
import PlayerAssistTable from "../PlayerAssistTable";
import PremiumCard from "../../Home/PremiumCard/PremiumCard";
import TeamsSideBar from "../../Teams/TeamsDetails/TeamsSideBar";
import Notifications from "../../Layout/Notification/Notifications";
import { ThemeState } from "../../../Context";
import { usePlayerGames } from "../../../Redux/Accessors/previousGames.accessors";
import Games from "../../../Services/Games";
import SearchSuggestion from "../../Layout/Header/SearchSuggestion";

const PlayersDetails = ({
  id,
  page,
  setPage,
  position,
  fetchPlayerPreviousGames,
  notificationsData,
  onUpdate,
  setOnUpdate,
  loading,
  setEnableScroll,
  teamKey,
}) => {
  const { mode } = ThemeState();
  const games = usePlayerGames();
  const suggestionRef = useRef(null);

  const [search, setSearch] = useState("");
  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const schedule = games?.data?.scheduledGame || {};
  const playerDetails = games?.data?.playerDetails;

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  const onSearch = async (name) => {
    setSearch(name);
    if (name?.length >= 4) {
      try {
        setIsLoading(true);
        const res = await Games.search(name);
        setPlayers(res?.data?.data);
      } catch (error) {
        setIsLoading(false);
        console.log("Error in searching players");
      } finally {
        setIsLoading(false);
      }
    }
  };
  console.log(schedule, "schedule");
  return (
    <div
      className={`players_details_main ${mode === "Light" && "light_theme"}`}
    >
      <Container fluid>
        <Row className="gx-0">
          <Col md={9}>
            <div className="pe-0 pe-md-4">
              <PlayerHeader
                isLoading={loading}
                player={playerDetails}
                team={playerDetails?.team}
              />
              <PointsTableHeader position={position} id={id} />
              <PlayerAssistTable
                id={id}
                page={page}
                setPage={setPage}
                fetchPlayerPreviousGames={fetchPlayerPreviousGames}
                setEnableScroll={setEnableScroll}
                playerDetails={playerDetails}
              />
            </div>
          </Col>
          <Col md={3}>
            <Notifications
              page="player"
              data={notificationsData}
              onUpdate={onUpdate}
              setOnUpdate={setOnUpdate}
            />
            <div></div>
            <div
              style={{
                marginTop: "15px",
              }}
            >
              <div className="search-wrapper" ref={suggestionRef}>
                <div
                  className="w-100 search_bar p-1 d-flex justify-content-between align-items-center bg-white"
                  style={{
                    borderRadius: "5px",
                    border: "2px solid #A4A4A4",
                  }}
                >
                  <Form.Control
                    style={{
                      height: "27px",
                    }}
                    className="w-100 search-focus"
                    type="search"
                    placeholder="Search for player totals or results"
                    aria-label="Search"
                    value={search}
                    onChange={(e) => onSearch(e.target.value)}
                    onFocus={() => setShowDropdown(true)}
                  />
                  {!search.length && (
                    <img
                      src="/images/search.png"
                      alt="search"
                      width={"20px"}
                      height={"20px"}
                    />
                  )}
                </div>
                {search.length >= 4 && showDropdown && (
                  <SearchSuggestion
                    players={players}
                    setPlayerName={setSearch}
                    isLoading={isLoading}
                    showDropdown={showDropdown}
                    setShowDropdown={setShowDropdown}
                  />
                )}
              </div>
              <div style={{ marginTop: "35px" }}>
                <PremiumCard
                  onlyOne
                  id={id}
                  players={[]}
                  playerDetails={playerDetails}
                  schedule={schedule}
                  premium={schedule?.premium && schedule?.premium[teamKey]
                    ? schedule?.premium[teamKey]
                    : null
                  }
                  tab="playerPremium"
                />
              </div>
            </div>
            <div className="mt-2">
              <TeamsSideBar />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PlayersDetails;
