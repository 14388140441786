import React from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const ViewMore = ({ viewMore, setViewMore }) => {
  return (
    <button className="color-white" onClick={() => setViewMore(!viewMore)}>
      {!viewMore ? "View More" : "View Less"}
      {!viewMore ? <BiChevronDown /> : <BiChevronUp />}
    </button>
  );
};

export default ViewMore;
