/* eslint-disable no-unused-vars */
import { googleLogout } from "@react-oauth/google";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Container,
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { AiFillCaretDown } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import DarkLoader from "../../../Common/Loader/DarkLoader";
import { sports } from "../../../Constants";
import { ThemeState } from "../../../Context";
import {
  logoutAuth,
  updateThemeMode,
  useUser,
} from "../../../Redux/Accessors/auth.accessors";
import {
  updateSport,
  useSettings,
  useSport,
} from "../../../Redux/Accessors/game.accessors";
import {
  updateHeaderTab,
  useHeaderTab,
} from "../../../Redux/Accessors/header.accessors";
import Games from "../../../Services/Games";
import { teamNavigator } from "../../../Utils/navigator";
import { onSuccess } from "../../../Utils/toast";
import "./Header.css";
import SearchSuggestion from "./SearchSuggestion";

const Header = ({ premium, isComparePage = false }) => {
  const sport = useSport();
  const navigate = useNavigate();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const location = useLocation();
  const { user } = useUser();
  const headerTab = useHeaderTab() || "HOME";
  const suggestionRef = useRef(null);

  const { mode, setMode } = ThemeState();
  const [teams, setTeams] = useState(false);
  const [search, setSearch] = useState("");
  const [players, setPlayers] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const fetchTeamsList = async (sport) => {
    try {
      setTeamsList([]);
      setIsLoading(true);
      const res = await Games.teams(sport);
      setTeamsList(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching teams list:", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const logout = () => {
    googleLogout();
    logoutAuth();
    navigate("/login");
  };

  const onUpdateTab = (tab) => {
    updateHeaderTab(tab);
    switch (tab) {
      case "HOME":
        navigate("/");
        updateSport(defaultSport);
        break;
      case "ABOUT_US":
      case "COMPARE":
      case "PROMOS":
        navigate(
          tab === "HOME"
            ? "/"
            : tab === "ABOUT_US"
            ? "/about-us"
            : tab === "PROMOS"
            ? "/promos"
            : "/compare"
        );
        tab === "HOME" && updateSport(defaultSport);
        return;
      case "PREMIUM":
        updateSport(defaultSport);
        navigate("/premium");
        return;
      case "NFL":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/nfl")
          : navigate("/nfl");
        // location.pathname !== "/premium" && navigate("/nfl");
        return;
      case "NBA":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/nba")
          : navigate("/nba");
        // location.pathname !== "/premium" && navigate("/nba");
        return;
      case "MLB":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/mlb")
          : navigate("/mlb");
        // location.pathname !== "/premium" && navigate("/mlb");
        return;
      case "NHL":
        updateSport(tab);
        location.pathname === "/premium" ||
        location.pathname === `/premium/${sport.toLowerCase()}`
          ? navigate("/premium/nhl")
          : navigate("/nhl");
        // location.pathname !== "/premium" && navigate("/nhl");
        return;
      // updateSport(tab);
      // location.pathname !== "/premium" && navigate("/");
      // return;
      default:
        navigate("/");
        return;
    }
  };

  const onSearch = async (name) => {
    setSearch(name);
    if (name?.length >= 4) {
      try {
        setIsNotificationLoading(true);
        const res = await Games.search(name);
        setPlayers(res?.data?.data);
      } catch (error) {
        setIsNotificationLoading(false);
        console.log("Error in searching players");
      } finally {
        setIsNotificationLoading(false);
      }
    }
  };

  const onDayMode = (mode) => {
    setMode(mode);
    updateThemeMode(mode);
  };

  const onNightMode = (mode) => {
    setMode(mode);
    updateThemeMode(mode);
  };

  const handleNavigate = () => {
    window.location.href = "/";
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`${premium ? "premium_header" : ""} ${
          mode === "Light" ? "light_theme" : ""
        }`}
      >
        <Container fluid>
          <div></div>
          <Navbar.Brand href="">
            {!premium ? (
              mode === "Dark" ? (
                <img
                  src="/images/logo.png"
                  alt="betting predictions"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onUpdateTab("HOME");
                    handleNavigate();
                  }}
                />
              ) : (
                <img
                  src="/images/light_logo.png"
                  alt="betting predictions"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    onUpdateTab("HOME");
                    handleNavigate();
                  }}
                />
              )
            ) : (
              <img
                src="/images/premium-logo.svg"
                alt="logo"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onUpdateTab("HOME");
                  handleNavigate();
                }}
              />
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-column w-100">
                {!premium && (
                  <div className="ms-auto d-flex my-4 align-items-center">
                    {mode === "Dark" ? (
                      <img
                        src="/images/theme.png"
                        alt="theme-icon"
                        style={{ height: "26px", cursor: "pointer" }}
                        onClick={() => onDayMode("Light")}
                      />
                    ) : (
                      <img
                        src="/images/theme-2.png"
                        alt="theme-icon"
                        style={{ height: "26px", cursor: "pointer" }}
                        onClick={() => onNightMode("Dark")}
                      />
                    )}
                    {!user && (
                      <div
                        style={{
                          width: "1px",
                          height: "30px",
                          backgroundColor: "rgba(128, 128, 128, 0.46863)",
                          marginLeft: "35px",
                        }}
                      />
                    )}

                    {/* HOME page */}
                    <>
                      {!!user ? (
                        <>
                          {/* commented dropdown here */}

                          <div className="d-flex align-items-center">
                            <Dropdown>
                              <Dropdown.Toggle id="profile-dropdown">
                                <img
                                  src={
                                    user?.user?.provider === "google" ||
                                    user?.user?.provider === "facebook"
                                      ? user?.user?.profile
                                      : `/images/${
                                          user?.user?.profile || "profile.png"
                                        }`
                                  }
                                  alt=""
                                  className="profile_icon"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                className="profile-dropdown-menu"
                                style={{
                                  marginLeft: "-192px",
                                  backgroundColor: "#feb91a",
                                  width: "250px",
                                }}
                              >
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/dashboard-1.png" />
                                  <h6>Dashboard</h6>
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  href="/"
                                  className="profile-dropdown-item"
                                >
                                  Account Setting
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/key.png" />

                                  <h6>Change Password</h6>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    logout();
                                    onSuccess("Logout Successfully!");
                                  }}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/logout.png" />

                                  <h6 style={{ color: "red" }}>Logout</h6>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="auth_btn_container">
                            <button
                              className="bg_green text-white sign-up"
                              onClick={() => navigate("/signup")}
                            >
                              Sign Up
                            </button>
                            <button
                              className="login"
                              onClick={() => navigate("/login")}
                            >
                              Log In
                            </button>
                          </div>
                        </>
                      )}
                    </>
                    {/*  */}
                  </div>
                )}
              </div>
              <div
                className={`d-flex w-100 ${!premium ? "mb-3" : ""} 
                justify-content-between
                 align-items-center `}
              >
                <div className="w-100 d-flex justify-content-center">
                  <Nav
                    className=" my-2 my-lg-0"
                    style={{ position: "relative" }}
                  >
                    <Nav.Link
                      onClick={() => onUpdateTab("HOME")}
                      className={`${headerTab === "HOME" && "selected-sport"}`}
                    >
                      HOME
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => onUpdateTab("PREMIUM")}
                      className={`${
                        headerTab === "PREMIUM" && "selected-sport"
                      }`}
                    >
                      PREMIUM
                    </Nav.Link>
                    <Nav.Link
                      href=""
                      className={`${headerTab === "NFL" && "selected-sport"} 
                    ${sport === "NFL" && "selected"}
                    `}
                      onClick={() => onUpdateTab("NFL")}
                    >
                      NFL
                    </Nav.Link>
                    <Nav.Link
                      href=""
                      className={`${headerTab === "NBA" && "selected-sport"}
                    ${sport === "NBA" && "selected"}
                    `}
                      onClick={() => onUpdateTab("NBA")}
                    >
                      NBA
                    </Nav.Link>
                    <Nav.Link
                      href=""
                      className={`${headerTab === "MLB" && "selected-sport"}
                    ${sport === "MLB" && "selected"}
                    `}
                      onClick={() => onUpdateTab("MLB")}
                    >
                      MLB
                    </Nav.Link>
                    <Nav.Link
                      href=""
                      className={`${headerTab === "NHL" && "selected-sport"}
                    ${sport === "NHL" && "selected"}
                    `}
                      onClick={() => onUpdateTab("NHL")}
                    >
                      NHL
                    </Nav.Link>
                    {!premium && (
                      <Nav.Link
                        href=""
                        className={`${
                          headerTab === "PROMOS" && "selected-sport"
                        }`}
                        onClick={() => onUpdateTab("PROMOS")}
                      >
                        PROMOS
                      </Nav.Link>
                    )}
                    <Nav.Link
                      onClick={() => onUpdateTab("COMPARE")}
                      className={`${
                        headerTab === "COMPARE" && "selected-sport"
                      }`}
                    >
                      COMPARE
                    </Nav.Link>

                    {!premium && (
                      <Nav.Link
                        onClick={() => onUpdateTab("ABOUT_US")}
                        className={`${
                          headerTab === "ABOUT_US" && "selected-sport"
                        }`}
                      >
                        About Us
                      </Nav.Link>
                    )}
                    {premium && (
                      <Nav.Link
                        onClick={() => onUpdateTab("ABOUT_US")}
                        className={`${
                          headerTab === "ABOUT_US" && "selected-sport"
                        }`}
                      >
                        About Us
                      </Nav.Link>
                    )}

                    <Dropdown>
                      <Dropdown.Toggle className="team_header_link">
                        <Nav.Link
                          onClick={() => {
                            // onUpdateTab("TEAMS");
                            setTeams(!teams);
                          }}
                          className={`${
                            headerTab === "TEAMS" && "selected-sport"
                          }`}
                        >
                          TEAMS <AiFillCaretDown />
                        </Nav.Link>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="team_accordion_dropdown"
                        align="end"
                      >
                        <Accordion
                          className={`team_header_accordion ${
                            mode === "Dark" && "dark_theme"
                          } `}
                        >
                          {sports.map((sport, index) => {
                            return (
                              <Accordion.Item
                                className={`team_accordion_item ${
                                  mode === "Dark" && "dark_theme"
                                } `}
                                key={index}
                                eventKey={index}
                              >
                                <Accordion.Header
                                  onClick={() => fetchTeamsList(sport)}
                                  className="team_accordion_header"
                                >
                                  {sport}
                                </Accordion.Header>
                                <Accordion.Body className="team_accordion_body">
                                  {isLoading ? (
                                    <DarkLoader />
                                  ) : teamsList?.length ? (
                                    teamsList?.map((team) => (
                                      <p style={{ cursor: "pointer" }}>
                                        <span
                                          style={{ display: "flex", gap: 15 }}
                                        >
                                          <img
                                            className={`${
                                              team?.name === "Pirates" &&
                                              "team_logo_bg"
                                            }`}
                                            src={team?.wikipediaLogoUrl || "/"}
                                            alt={
                                              team?.logoAltText ||
                                              `${team?.city} ${team?.name}`
                                            }
                                          />
                                          <span
                                            onClick={() => {
                                              updateHeaderTab(team?.sport);
                                              teamNavigator(
                                                team,
                                                navigate,
                                                updateSport
                                              );
                                              updateSport(team?.sport);
                                            }}
                                          >
                                            {team?.city} {team?.name}
                                          </span>
                                        </span>
                                        <span>{team?.sport}</span>
                                      </p>
                                    ))
                                  ) : (
                                    <p style={{ color: "#778899" }}>
                                      No Teams Found
                                    </p>
                                  )}
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      </Dropdown.Menu>
                    </Dropdown>

                    {premium && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-35px",
                          right: "14px",
                        }}
                      >
                        {!isComparePage && (
                          <Form className="d-flex" ref={suggestionRef}>
                            <div className="search-wrapper">
                              <div className="search_bar">
                                <Form.Control
                                  className="search-focus"
                                  type="search"
                                  placeholder="Search for player totals or results"
                                  aria-label="Search"
                                  value={search}
                                  onChange={(e) => onSearch(e.target.value)}
                                  onFocus={() => setShowDropdown(true)}
                                />
                                {!search.length && (
                                  <img src="/images/search.png" alt="search" />
                                )}
                              </div>
                              {search.length >= 4 && showDropdown && (
                                <SearchSuggestion
                                  players={players}
                                  setPlayerName={setSearch}
                                  isLoading={isNotificationLoading}
                                  showDropdown={showDropdown}
                                  setShowDropdown={setShowDropdown}
                                />
                              )}
                            </div>
                          </Form>
                        )}
                      </div>
                    )}
                  </Nav>
                </div>
                {!premium && (
                  <>
                    {!isComparePage && (
                      <Form className="d-flex ml-2" ref={suggestionRef}>
                        <div className="search-wrapper">
                          <div className="search_bar">
                            <Form.Control
                              className="search-focus"
                              type="search"
                              placeholder="Search for player totals or results"
                              aria-label="Search"
                              value={search}
                              onChange={(e) => onSearch(e.target.value)}
                              onFocus={() => setShowDropdown(true)}
                            />
                            {!search.length && (
                              <img src="/images/search.png" alt="search" />
                            )}
                          </div>
                          {search.length >= 4 && showDropdown && (
                            <SearchSuggestion
                              players={players}
                              setPlayerName={setSearch}
                              isLoading={isNotificationLoading}
                              showDropdown={showDropdown}
                              setShowDropdown={setShowDropdown}
                            />
                          )}
                        </div>
                      </Form>
                    )}
                  </>
                )}
                {premium && (
                  <div
                    style={{
                      paddingRight: "10px",
                    }}
                  >
                    {mode === "Dark" ? (
                      <img
                        src="/images/Sun.png"
                        alt="theme"
                        style={{
                          height: "24px",
                          cursor: "pointer",
                          marginLeft: "20px",
                        }}
                        onClick={() => onDayMode("Light")}
                      />
                    ) : (
                      <img
                        src="/images/Moon.png"
                        alt="theme"
                        style={{
                          height: "24px",
                          cursor: "pointer",
                          marginLeft: "20px",
                        }}
                        onClick={() => onNightMode("Dark")}
                      />
                    )}
                  </div>
                )}
                {/* {user && ( */}
                {premium && (
                  <>
                    {/* commented dropdown second */}

                    {!!user && (
                      <div className="d-flex align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle id="profile-dropdown">
                          <img
                            src={
                              user?.user?.provider === "google" ||
                              user?.user?.provider === "facebook"
                                ? user?.user?.profile
                                : `/images/${
                                    user?.user?.profile || "profile.png"
                                  }`
                            }
                            alt=""
                            className="profile_icon"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="profile-dropdown-menu"
                          style={{
                            marginLeft: "-165px",
                            backgroundColor: "#feb91a",
                            width: "240px",
                            // marginTop:"40px"
                          }}
                        >
                          <Dropdown.Item
                            href={`/${
                              user?.user?.role === "admin"
                                ? "super-admin"
                                : user?.user?.role
                            }/profile`}
                            className="profile-dropdown-item"
                          >
                            <img src="/images/dashboard-1.png" />
                            <h6>Dashboard</h6>
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            href="/"
                            className="profile-dropdown-item"
                          >
                            Account Setting
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            href={`/${
                              user?.user?.role === "admin"
                                ? "super-admin"
                                : user?.user?.role
                            }/profile`}
                            className="profile-dropdown-item"
                          >
                            <img src="/images/key.png" />

                            <h6>Change Password</h6>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              logout();
                              onSuccess("Logout Successfully!");
                            }}
                            className="profile-dropdown-item"
                          >
                            <img src="/images/logout.png" />

                            <h6 style={{ color: "red" }}>Logout</h6>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
