import React from "react";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../Context";
import { useTab } from "../../Redux/Accessors/game.accessors";

const SiteLogos = () => {
  const { mode } = ThemeState();
  const tab = useTab();

  return (
    <>
      <div
        className={`compare_table_header bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <Row>
          <Col md={12} className="header_left_section">
            <h4
              className={`text-center  ${
                mode === "Light" ? "col-green" : "col-yellow"
              }`}
            >
              {tab}
            </h4>
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center body_left_section align-items-center"
          >
            <img
              src="/images/fanduel.png"
              style={{ width: "65px", height: "auto" }}
              alt="fanduel sportsbook"
            />
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center body_left_section align-items-center"
          >
            {mode === "Light" ? (
              <img
                src="/images/draft-light.png"
                style={{ width: "80px", height: "auto" }}
                alt=""
              />
            ) : (
              <img
                src="/images/draft.png"
                style={{ width: "80px", height: "auto" }}
                alt="draftkings sportsbook"
              />
            )}
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center body_left_section align-items-center"
          >
            <img
              src="/images/betmgm.png"
              style={{ width: "70px", height: "auto" }}
              alt="betmgm sportsbook"
            />
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center body_left_section align-items-center"
          >
            {mode === "Light" ? (
              <img
                src="/images/caesars-light.png"
                style={{ width: "80px", height: "34px" }}
                alt=""
              />
            ) : (
              <img
                src="/images/caesars.png"
                style={{ width: "90px", height: "34px" }}
                alt="caesars sportbook"
              />
            )}
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center body_left_section align-items-center"
          >
            <img
              src="/images/bet365_img.png"
              style={{ width: "80px", height: "auto" }}
              alt="bet365 bonus code"
            />
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Row>
          <Col
            md={12}
            className="d-flex justify-content-center body_left_section align-items-center"
          >
            {mode === "Light" ? (
              <img
                src="/images/pointsbet-light.png"
                style={{ width: "80px", height: "auto" }}
                alt=""
              />
            ) : (
              <img
                src="/images/pointsbet.png"
                style={{ width: "80px", height: "auto" }}
                alt="pointsbet sportsbook"
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SiteLogos;
