import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllPromos, promoDelete, updatePromo } from "../../../../Redux/Actions/CMS";
import { cloudURL } from "../../../../Utils/helper";
// import { errorNotify, successNotify } from "../../../../Utils/toast";
import Loader from "../../../../Utils/Loader";
import Promos from "../../../../Services/promos";
import {
  updatePromos,
  usePromos,
} from "../../../../Redux/Accessors/promo.accessors";
import EditPromosModal from "../../../Admin/EditPromos/EditPromosModal";
import AddPromoModal from "../../../Admin/EditPromos/AddPromoModal";
import { successNotify } from "../../../../Utils/toast";
// import { promos } from "../../../../Utils/helper";

const EditPromos = () => {
  //   const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [show, setShow] = useState(false);
  // const [promos, setPromos] = useState([]);
  // const FetchedPromos = usePromos();
  // console.log(FetchedPromos, "FetchedPromos");
  const [addPromoShow, setAddPromoShow] = useState(false);
  const [uploadedImages, setUploadedImages] = useState({
    image: "",
    dark_mode_img: "",
  });
  const [uploadedImagePreview, setUploadedImagePreview] = useState({
    image: "",
    dark_mode_img: "",
  });

  const [promo, setPromo] = useState({
    promoId: "",
    image: "",
    dark_mode_img: "",
    title: "",
    heading: "",
    text1: "",
    text2: "",
    review: "",
    claim_link: "",
    // fb_link: "",
    // insta_link: "",
    // twitter_link: "",
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createSuccess,setCreateSuccess] = useState(false);
  const [editSuccess,setEditSuccess] = useState(false);
  const [deleteSuccess,setDeleteSuccess] = useState(false);

  useEffect(()=>{
    if(createSuccess){
      window.scrollTo(0, 0);
      successNotify("Promo Created Successfully!")
      fetchPromos();
      setCreateSuccess(false)
    }
    if(editSuccess){
      window.scrollTo(0, 0);
      successNotify("Promo Edited Successfully!")
      onCloseModal();
      fetchPromos();
      setEditSuccess(false)
    }
    if(deleteSuccess){
      window.scrollTo(0, 0);
      successNotify("Promo Deleted Successfully!")
      fetchPromos();
      setDeleteSuccess(false)
    }
  },[createSuccess,editSuccess,deleteSuccess])
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPromos();
  }, []);

  // fetch all promos
  const {promos} = usePromos();
  // console.log(promos, "promos");
  const fetchPromos = async () => {
    // console.log("promos");
    try {
      setLoading(true);
      const res = await Promos.promos();
      // console.log(res?.data?.data, "res");
      const data = res?.data?.data || [];
      console.log(data)

      updatePromos(data);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching promos:", error);
    } finally {
      setLoading(false);
    }
  };

  const promoEditHandler = (e, p) => {
    e.preventDefault();
    setPromo({
      promoId: p._id,
      image: p.image,
      dark_mode_img: p.dark_mode_img,
      title: p.title,
      heading: p.heading,
      text1: p.text1,
      text2: p.text2,
      review: p.review,
      claim_link: p.claim_link,
      // fb_link: p.fb_link,
      // insta_link: p.insta_link,
      // twitter_link: p.twitter_link,
    });
    setShow(true);
  };
  const onChangeHandler = (name) => (e) => {
    setPromo((prev) => {
      return { ...prev, [name]: e.target.value };
    });
  };
  const handleImageChange = (name) => (e) => {
    setUploadedImages((prev) => {
      return { ...prev, [name]: e.target.files[0] };
    });
    setUploadedImagePreview((prev) => {
      return { ...prev, [name]: URL.createObjectURL(e.target.files[0]) };
    });
  };
  //   // console.log(uploadedImages);
  //   // console.log(uploadedImagePreview);
  const onCloseModal = () => {
    setShow(false);
    setPromo({
      promoId: "",
      image: "",
      title: "",
      heading: "",
      text1: "",
      text2: "",
      review: "",
      claim_link: "",
      // fb_link: "",
      // insta_link: "",
      // twitter_link: "",
    });
    setUploadedImages({ image: "", dark_mode_img: "" });
    setUploadedImagePreview({ image: "", dark_mode_img: "" });
  };

  const submitHandler = async(e) => {
    e.preventDefault();
    try {
    setEditLoading(true)
    const formData = new FormData();
    formData.append("promoId", promo.promoId);
    formData.append("title", promo.title);
    formData.append("heading", promo.heading);
    formData.append("text1", promo.text1);
    formData.append("text2", promo.text2);
    formData.append("review", promo.review);
    formData.append("claim_link", promo.claim_link);
    if (uploadedImages.image !== "") {
      formData.append("image", uploadedImages.image);
    }
    if (uploadedImages.dark_mode_img !== "") {
      formData.append("dark_mode_img", uploadedImages.dark_mode_img);
    }
    await Promos.updatePromo(formData)
    setEditSuccess(true)
  } catch (error) {
    setEditLoading(false);
    console.error("An error occurred while updating banner:", error);
  } finally {
    setEditLoading(false);
  }
  };
  const promoDeleteHandler = async(e, id) => {
    e.preventDefault();
    try{
      setDeleteLoading(true)
    // console.log(a);
    //   dispatch(aboutDelete(a?._id));
    const res= await Promos.deletePromo(id)
    console.log(res?.data)
    setDeleteSuccess(true)
  } catch (error) {
    setDeleteLoading(false);
    console.error("An error occurred while deleting Section:", error);
  } finally {
    setDeleteLoading(false);
  }
  };
  return (
    <>
      <h1>Promos</h1>
      <div className="layout_page_section ">
        {loading ? (
          <Loader />
        ) : (
          <Container className="mb-3">
            <Row className="justify-content-center edit_promos">
              <Col md={11}>
                <Row>
                  <Col
                    md={12}
                    className="my-3 d-flex justify-content-between align-items-center"
                  >
                    <h2
                      style={{
                        fontWeight: "700",
                        color: "#fff",
                        fontSize: "35px",
                      }}
                    >
                      {" "}
                      Promos
                    </h2>
                    <button
                      className="add_more"
                      onClick={() => setAddPromoShow(true)}
                    >
                      Add More Promos
                    </button>
                  </Col>
                </Row>
              </Col>
              {promos &&
                promos?.promos?.map((p, i) => (
                  <Col md={11} className="mt-4" key={i}>
                    <Row className="gx-5">
                      <Col md={12}>
                        <Row className="align-items-center">
                          <Col md={6}>
                            <h4 className="text-white">Promo {i + 1}</h4>
                          </Col>
                          <Col
                            md={6}
                            className="d-flex justify-content-end gap-3"
                          >
                            <button
                              className="edit_btn"
                              onClick={(e) => promoEditHandler(e, p)}
                            >
                              Edit
                            </button>
                            <button
                              className="delete_btn"
                              onClick={(e) => promoDeleteHandler(e, p?._id)}
                            >
                              {deleteLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : (
                                "Delete"
                              )}
                            </button>
                          </Col>
                        </Row>
                        <hr />
                      </Col>
                    </Row>
                    <Row className="gx-5">
                      <Col md={6}>
                        <Form.Label className="text-white">Image</Form.Label>
                        {/* <Form.Group className="mb-3 cms_image_input">
                  <Form.Control
                    type="file"
                    style={{ fontSize: "13px", paddingBottom: "7px" }}
                    name={`image`}
                  />
                </Form.Group> */}
                        <div className="previewImg_div">
                          <img
                            src={
                              p?.image?.filename
                                ? `${cloudURL}/${p.image.filename}`
                                : "/images/empty.jpg"
                            }
                            alt=""
                            style={{}}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Form.Label className="text-white">
                          Light Mode Image
                        </Form.Label>
                        {/* <Form.Group className="mb-3 cms_image_input">
                  <Form.Control
                    type="file"
                    style={{ fontSize: "13px", paddingBottom: "7px" }}
                    name={`dark_mode_img`}
                  />
                </Form.Group> */}
                        <div className="previewImg_div">
                          <img
                            src={
                              p?.dark_mode_img?.filename
                                ? `${cloudURL}/${p.dark_mode_img.filename}`
                                : "/images/empty.jpg"
                            }
                            alt=""
                            style={{}}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="gx-5">
                      <Col md={6}>
                        <Form.Label className="text-white">Title</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder=""
                            name="title"
                            value={p.title}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                      <Col md={6}>
                        <Form.Label className="text-white">Heading</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder=""
                            name="heading"
                            value={p.heading}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="gx-5">
                      <Col md={6}>
                        <Form.Label className="text-white">Text1</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder=""
                            name="text1"
                            value={p.text1}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                      <Col md={6}>
                        <Form.Label className="text-white">Text2</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder=""
                            name="text2"
                            value={p.text2}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="gx-5">
                      <Col md={12}>
                        <Form.Label className="text-white">
                          Review Text
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder=""
                            name="text1"
                            value={p.review}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="gx-5">
                      <Col md={8}>
                        <Form.Label className="text-white">
                          Claim Link
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder=""
                            name="claim_link"
                            value={p.claim_link}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                      {/* <Col md={6}>
                    <Form.Label>facebook Link</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="fb_link"
                        value={p.fb_link}
                        readOnly
                      />
                    </InputGroup>
                  </Col> */}
                    </Row>
                    {/* <Row className="gx-5">
                  <Col md={6}>
                    <Form.Label>Instagram Link</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="text1"
                        value={p.insta_link}
                        readOnly
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label>Twitter Link</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder={p.twitter_link ? "Enter Twitter Link" : ""}
                        name="text2"
                        value={p.twitter_link}
                        readOnly
                      />
                    </InputGroup>
                  </Col>
                </Row> */}
                  </Col>
                ))}
            </Row>
          </Container>
        )}
      </div>

      <EditPromosModal
        show={show}
        setShow={setShow}
        promo={promo}
        onChangeHandler={onChangeHandler}
        handleImageChange={handleImageChange}
        uploadedImagePreview={uploadedImagePreview}
        onCloseModal={onCloseModal}
        submitHandler={submitHandler}
        loading={editLoading}
      />
      <AddPromoModal
        show={addPromoShow}
        setShow={setAddPromoShow}
        success={createSuccess}
        setSuccess={setCreateSuccess}
        // onCloseModal={onCloseAddPromoModal}
        // submitHandler={addPromoSubmitHandler}
      />
    </>
  );
};

export default EditPromos;
