import React, { useState, useEffect } from "react";
import { ThemeState } from "../../../Context";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { useNavigate, useParams } from "react-router-dom";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import StatsBox from "../../../Common/StatsBox";
import {
  getDataValues,
  getTab,
  isResultGreater,
} from "../../../Utils/getStats";
import ViewMore from "../../../Common/ViewMore";
import Games from "../../../Services/Games";
import Info from "../../../Common/Info";
import Alert from "../../../Common/Alert";
import { playerNavigator } from "../../../Utils/navigator";

const AccordianBodyMobile = ({ game, teamId }) => {
  const { id } = useParams();
  const tab = useTab();
  const { mode } = ThemeState();
  const navigate = useNavigate();

  const [playerStats, setPlayerStats] = useState(null);
  const [viewMore, setViewMore] = useState(false);

  // const handleNavigate = (id) => {
  //   navigate(`/player/${id}`);
  // };

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.playerStats(data);
      setPlayerStats(res.data.data);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    fetchTeamPlayerStats({
      gameId: game?._id,
      teamId,
      bettingBetType: getTab(tab),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  const filteredTeamData =
    playerStats?.filter((game) => !getDataValues(game, tab)) || [];

  return (
    <div>
      <div
        className={`mobile_match_stats_main bg_grey team_table_acc ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div style={{ overflow: "auto", margin: "0px 0px 0px 0px" }}>
          <div
            className={`d-flex  mobile_assists_header bg_grey team_table_acc  ${
              mode === "Light" && "light_theme"
            }`}
          >
            <div className="d-flex justify-content-between">
              <h3
                className={`${mode === "Light" ? "col-green" : "col-yellow"}`}
              >
                {tab}
              </h3>
              <h3
                className={`${mode === "Light" ? "text-black" : "text-white"}`}
                style={{ fontSize: "1rem" }}
              >
                Results
              </h3>
            </div>
            <div>
              <img src="/images/fanduel.png" alt="fanduel sportsbook" />
            </div>
            <div>
              {mode === "Light" ? (
                <img src="/images/draft-light.png" alt="logo" />
              ) : (
                <img src="/images/draft.png" alt="draftkings sportsbook" />
              )}
            </div>
            <div>
              <img src="/images/betmgm.png" alt="betmgm sportsbook" />
            </div>
            <div>
              {mode === "Light" ? (
                <img
                  src="/images/caesars-light.png"
                  style={{ height: "25px" }}
                  alt="logo"
                />
              ) : (
                <img
                  src="/images/caesars.png"
                  style={{ height: "25px" }}
                  alt="caesars sportbook"
                />
              )}
            </div>
            <div>
              <img
                src="/images/bet365_img.png"
                style={{ height: "30px" }}
                alt="bet365 bonus code"
              />
            </div>
            <div>
              {mode === "Light" ? (
                <img
                  src="/images/pointsbet-light.png"
                  style={{ height: "55px" }}
                  alt="logo"
                />
              ) : (
                <img
                  src="/images/pointsbet.png"
                  style={{ height: "55px" }}
                  alt="pointsbet sportsbook"
                />
              )}
            </div>
          </div>
          {filteredTeamData &&
            filteredTeamData
              ?.slice(0, viewMore ? filteredTeamData?.length : 3)
              .map((game, index) => {
                const allStatsNull = getDataValues(game, tab);

                if (allStatsNull) {
                  return null;
                }

                return (
                  <div
                    key={index}
                    className="mobile_assists_body bg_grey team_table_acc"
                  >
                    <div>
                      <div>
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            playerNavigator(game?.player, navigate, updateSport)
                          }
                          className="text-white"
                        >
                          {game?.player?.firstName} {game?.player?.lastName}
                        </h3>
                        <div className="d-flex gap-3 align-items-center">
                          <Alert playerId={game?.player?._id} />
                          <Info />
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={
                          isResultGreater(game)
                            ? {
                                color: "#5caa00",
                              }
                            : {
                                color: "#FF003A",
                              }
                        }
                      >
                        <p
                          className={
                            mode === "Light" &&
                            isResultGreater(game) &&
                            "text-black"
                          }
                        >
                          {game?.result || 0}
                        </p>
                        {isResultGreater(game) ? (
                          <HiOutlineArrowNarrowUp />
                        ) : (
                          <HiOutlineArrowNarrowDown />
                        )}
                      </div>
                    </div>
                    <div>
                      <StatsBox playerStats={game?.fanduel} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.draftkings} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.betmgm} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.caesars} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.consensus} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.pointsbet} />
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
      <div
        className={`d-flex justify-content-center py-3 view_more_btn ${
          mode === "Light" && "light_theme"
        }`}
      >
        <ViewMore viewMore={viewMore} setViewMore={setViewMore} />
      </div>
    </div>
  );
};

export default AccordianBodyMobile;
