import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BillingTable = () => {
  return (
    <>
      <div className="billing_table_header">
        <Container fluid>
          <Row>
            <Col xs={3}>
              <h3 className="text-start">Date</h3>
            </Col>
            <Col xs={9} className="text-center">
              <Row>
                <Col xs={3}>
                  <h3>Transaction</h3>
                </Col>
                <Col xs={3}>
                  <h3>Amount</h3>
                </Col>
                <Col xs={3}>
                  <h3>Status</h3>
                </Col>
                <Col xs={3}>
                  <h3>Actions</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="billing_table_body">
        <Container fluid>
          <Row>
            <Col xs={3} className="d-flex align-items-center">
              <h3 className="text-start">Feb 27, 2020</h3>
            </Col>
            <Col xs={9} className="text-center">
              <Row>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>Invoice</h3>
                </Col>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>$ 0.00</h3>
                </Col>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>Unpaid</h3>
                </Col>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <button>Pay</button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="billing_table_body">
        <Container fluid>
          <Row>
            <Col xs={3} className="d-flex align-items-center">
              <h3 className="text-start">Feb 27, 2020</h3>
            </Col>
            <Col xs={9} className="text-center">
              <Row>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>Invoice</h3>
                </Col>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>$ 0.00</h3>
                </Col>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h3>Unpaid</h3>
                </Col>
                <Col
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <button>Pay</button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BillingTable;
