import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext();
const ThemeProvider = ({ children }) => {
  const [mode, setMode] = useState("Dark");
  return (
    <ThemeContext.Provider value={{ mode, setMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
export const ThemeState = () => {
  return useContext(ThemeContext);
};

export default ThemeProvider;
