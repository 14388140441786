import CreationTable from "./CreationTable";
import "../superAdmin.css";
import { useEffect, useState } from "react";
import AddSubAdminModal from "./AddSubAdminModal";
import CreationMobileTable from "./CreationMobileTable";
import Admin from "../../../Services/admin";
import {
  updateSubAdmins,
  useSubAdmins,
} from "../../../Redux/Accessors/auth.accessors";
import Loader from "../../../Utils/Loader";

const Creations = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchSubAdmins();
  }, []);
  // fetch all promos
  const subAdmins = useSubAdmins();
  console.log(subAdmins, "subAdmins");
  const fetchSubAdmins = async () => {
    // console.log("promos");
    try {
      setLoading(true);
      const res = await Admin.getAllSubAdmins();
      const data = res?.data?.data || [];
      console.log(data, "res");

      updateSubAdmins(data);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching subAdmins:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="creation">
      <h1>Sub Admin Creation & Permission Set</h1>
      <div className="layout_page_section billing_section">
        <button className="add_sub_admin_btn" onClick={() => setShow(true)}>
          <img src="/images/add-admin.png" />
          ADD SUB-ADMIN
        </button>
        {loading ? <Loader /> : <CreationTable subAdmins={subAdmins} fetchSubAdmins={fetchSubAdmins}/>}
      </div>
      {/* <div className="layout_page_section billing_section home_mobile">
        <button
          className="add_sub_admin_btn"
          style={{ left: "20px" }}
          onClick={() => setShow(true)}
        >
          <img src="/images/add-admin.png" />
          ADD SUB-ADMIN
        </button>

        <CreationMobileTable />
      </div> */}
      <AddSubAdminModal show={show} setShow={setShow} fetchSubAdmins={fetchSubAdmins}/>
    </div>
  );
};

export default Creations;
