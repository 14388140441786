/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

const AutocompleteSuggestion = ({
  players,
  setPlayerName,
  isLoading = false,
  showDropdown,
  setShowDropdown,
  playerIds,
  handleOnSelect,
}) => {
  const suggestionRef = useRef(null);

  useEffect(() => {
    if (players.length) setShowDropdown(true);
  }, [players]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  return (
    <div className="suggestion-hr">
      <div className="w-100 autocomplete-suggestion-wrapper">
        {!isLoading ? (
          players?.map((player, index) => (
            <div key={index} onClick={() => handleOnSelect(player)}>
              <div>
                {playerIds[player?.sport]?.includes(player?._id) ? (
                  <AiOutlineMinusCircle
                    size="80px"
                    style={{
                      color: "green",
                      marginRight: "15px",
                      width: "30px",
                      height: "30px",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <AiOutlinePlusCircle
                    size="80px"
                    style={{
                      color: "green",
                      marginRight: "15px",
                      width: "30px",
                      height: "30px",
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  />
                )}
                <p>
                  {player?.firstName} {player?.lastName}
                </p>
              </div>
              <p>{player?.sport}</p>
            </div>
          ))
        ) : (
          <div className="text-center pt-3 pb-3">
            <img
              src="/images/dark-loader.gif"
              alt="loader"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AutocompleteSuggestion;
