import React from "react";
import PageNumbers from "../../Common/Pagination";
import TextMessage from "../../Common/TextMessage";
import { NO_CONTENT_MESSAGE } from "../../Constants";
import { ThemeState } from "../../Context";
import { useTab } from "../../Redux/Accessors/game.accessors";
import { usePlayerGames } from "../../Redux/Accessors/previousGames.accessors";
import { scrollToTop } from "../../Utils/scrollToTop";
import GameStatsMobile from "./PlayersDetails/GameStatsMobile";
import ScheduleStatsMobile from "./PlayersDetails/ScheduleStatsMobile";
import "./playerMobileAssists.css";

const PlayerMobileAssistsTable = ({
  id,
  page,
  setPage,
  fetchPlayerPreviousGames,
  viewMore,
  setViewMore,
  setEnableScroll,
  playerDetails,
}) => {
  const tab = useTab();
  const { mode } = ThemeState();
  const games = usePlayerGames();

  const schedule = games?.data?.scheduledGame || {};
  const previousGames = games?.data?.previousGames;
  const hasData = Object.keys(schedule).length > 0;

  const limit = 5;

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchPlayerPreviousGames(skip * limit);
  };

  const handlePrev = () => {
    if (page !== 1) {
      setEnableScroll(true);

      scrollToTop();
      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchPlayerPreviousGames(skip * limit);
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(games?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      fetchPlayerPreviousGames(skip * limit);
    }
  };

  const team =
    previousGames?.length &&
    previousGames[0]?.homeTeamPlayers?.find((player) => player?._id === id)
      ? "homeTeam"
      : "awayTeam";
  const player =
    previousGames?.length &&
    previousGames[0]?.homeTeamPlayers?.find((player) => player?._id === id)
      ? "homeTeamPlayers"
      : "awayTeamPlayers";
  const playerDetail =
    previousGames?.length &&
    previousGames[0]?.homeTeamPlayers?.find((player) => player?._id === id);

  return (
    <div className="ps-3 py-2">
      {hasData ? (
        <ScheduleStatsMobile
          id={id}
          playerDetail={playerDetail}
          schedule={schedule}
          player={player}
          team={team}
        />
      ) : (
        <TextMessage message={NO_CONTENT_MESSAGE} />
      )}

      {previousGames?.length ? (
        previousGames?.map((game, index) => {
          return (
            <div
              key={index}
              className={`player_match_stats_main_2 my-3 ${
                mode === "Light" && "light_theme"
              }`}
            >
              <div style={{ overflow: "auto" }}>
                <div
                  className={`d-flex text-white player_assists_header_2 ${
                    mode === "Light" && "light_theme"
                  }`}
                >
                  <div className="d-flex justify-content-between">
                    <h3
                      className={`${
                        mode === "Light" ? "col-green" : "col-yellow"
                      }`}
                    >
                      {tab}
                    </h3>
                    <h3
                      className={`${
                        mode === "Light" ? "text-black" : "text-white"
                      }`}
                    >
                      Results
                    </h3>
                  </div>
                  <div>
                    <img src="/images/fanduel.png" alt="fanduel sportsbook" />
                  </div>
                  <div>
                    {mode === "Light" ? (
                      <img src="/images/draft-light.png" alt="logo" />
                    ) : (
                      <img
                        src="/images/draft.png"
                        alt="draftkings sportsbook"
                      />
                    )}
                  </div>
                  <div>
                    <img src="/images/betmgm.png" alt="betmgm sportsbook" />
                  </div>
                  <div>
                    {mode === "Light" ? (
                      <img
                        src="/images/caesars-light.png"
                        style={{ height: "25px" }}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src="/images/caesars.png"
                        style={{ height: "25px" }}
                        alt="caesars sportbook"
                      />
                    )}
                  </div>
                  <div>
                    <img
                      src="/images/bet365_img.png"
                      style={{ height: "30px" }}
                      alt="bet365 bonus code"
                    />
                  </div>
                  <div>
                    {mode === "Light" ? (
                      <img
                        src="/images/pointsbet-light.png"
                        style={{ height: "55px" }}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src="/images/pointsbet.png"
                        style={{ height: "55px" }}
                        alt="pointsbet sportsbook"
                      />
                    )}
                  </div>
                </div>
                <GameStatsMobile
                  id={id}
                  game={game}
                  team={team}
                  player={player}
                  playerDetail={playerDetail}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center mt-5">
          <TextMessage message={"No Previous Game"} />
        </div>
      )}
      {!!games?.totalCount > 0 && (
        <div
          className={`mt-5 players_pagination ${
            mode === "Light" && "light_theme"
          }`}
        >
          <PageNumbers
            page={page}
            setPage={setPage}
            handleSetPage={(pageNum) => handleSetPage(pageNum)}
            handlePrev={handlePrev}
            handleNext={handleNext}
            totalRecords={games?.totalCount}
            postsPerPage={5}
          />
        </div>
      )}
    </div>
  );
};

export default PlayerMobileAssistsTable;
