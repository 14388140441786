/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { FaFacebookF } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import {
  logoutAuth,
  updateNotifications,
  updateThemeMode,
  useUser,
} from "../../../Redux/Accessors/auth.accessors";
import {
  updateSport,
  useSettings,
  useSport,
} from "../../../Redux/Accessors/game.accessors";
import { updateHeaderTab } from "../../../Redux/Accessors/header.accessors";
import Notification from "../../../Services/Notification";
import { Accordion, Dropdown } from "react-bootstrap";
import Games from "../../../Services/Games";
import DarkLoader from "../../../Common/Loader/DarkLoader";
import { sports } from "../../../Constants";
import { AiFillCaretDown } from "react-icons/ai";
import { onSuccess } from "../../../Utils/toast";
import { teamNavigator } from "../../../Utils/navigator";

const TeamsSideBar = () => {
  const { user } = useUser();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const sport = useSport() || defaultSport;
  const navigate = useNavigate();
  const { mode, setMode } = ThemeState();
  const location = useLocation();
  const [teams, setTeams] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [onUpdate, setOnUpdate] = useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTeamsList = async (sport) => {
    try {
      setTeamsList([]);
      const res = await Games.teams(sport);
      setTeamsList(res?.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching teams list:", error);
    }
  };

  const fetchUserNotifications = async () => {
    try {
      const res = await Notification.getNotifications(sport, 0, 50);
      updateNotifications(res?.data);
      setNotificationsData(res?.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching schedules:", error);
    }
  };

  useEffect(() => {
    fetchUserNotifications();
    const intervalId = setInterval(fetchUserNotifications, 5 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdate]);

  const onDayMode = (mode) => {
    setMode(mode);
    updateThemeMode(mode);
  };

  const onNightMode = (mode) => {
    setMode(mode);
    updateThemeMode(mode);
  };

  const logout = () => {
    logoutAuth();
    navigate("/login");
  };

  const onUpdateTab = (tab) => {
    updateHeaderTab(tab);
    switch (tab) {
      case "HOME":
        navigate("/");
        break;
      case "ABOUT_US":
        navigate("/about-us");
        break;
      case "PROMOS":
        navigate("/promos");
        break;
      case "COMPARE":
        navigate("/compare");
        break;
      case "PREMIUM":
        updateSport(defaultSport);
        navigate("/premium");
        return;
      case "CONTACT_US":
        navigate("/contact-us");
        break;
      case "NFL":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nfl");
        return;
      case "NBA":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nba");
        return;
      case "MLB":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/mlb");
        return;
      case "NHL":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nhl");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <div
      className={`teams_sidebar_main ${mode === "Light" && "light_theme"}`}
      style={{ position: "relative" }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <Link to="/">
          {mode === "Light" ? (
            <img src="/images/light_logo.png" alt="" className="logo_img" />
          ) : (
            <img
              src="/images/logo.png"
              alt="betting predictions"
              className="logo_img"
            />
          )}
        </Link>
        {!user ? (
          <div className="auth_btn_container">
            <button
              className="bg_green text-white sign-up"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </button>
            <button className="login" onClick={() => navigate("/login")}>
              Log In
            </button>
          </div>
        ) : (
          <div className="d-flex align-items-center">
                            <Dropdown>
                              <Dropdown.Toggle id="profile-dropdown">
                                <img
                                  src={
                                    user?.user?.provider === "google" ||
                                    user?.user?.provider === "facebook"
                                      ? user?.user?.profile
                                      : `/images/${
                                          user?.user?.profile || "profile.png"
                                        }`
                                  }
                                  alt=""
                                  className="profile_icon"
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                className="profile-dropdown-menu"
                                style={{
                                  marginLeft: "-192px",
                                  backgroundColor: "#feb91a",
                                  width: "250px",
                                  marginTop:"40px"
                                }}
                              >
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/dashboard-1.png" />
                                  <h6>Dashboard</h6>
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  href="/"
                                  className="profile-dropdown-item"
                                >
                                  Account Setting
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  href={`/${
                                    user?.user?.role === "admin"
                                      ? "super-admin"
                                      : user?.user?.role
                                  }/profile`}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/key.png" />

                                  <h6>Change Password</h6>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    logout();
                                    onSuccess("Logout Successfully!");
                                  }}
                                  className="profile-dropdown-item"
                                >
                                  <img src="/images/logout.png" />

                                  <h6 style={{ color: "red" }}>Logout</h6>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
        )}
      </div>
      <div className="d-flex justify-content-end align-items-center mb-0">
        <div className="d-flex align-items-center">
          {mode === "Dark" ? (
            <img
              src="/images/theme.png"
              style={{ height: "22px", width: "50px", cursor: "pointer" }}
              onClick={() => onDayMode("Light")}
              alt="mode"
            />
          ) : (
            <img
              src="/images/theme-2.png"
              style={{ height: "22px", width: "50px", cursor: "pointer" }}
              onClick={() => onNightMode("Dark")}
              alt="mode"
            />
          )}

          {/* <Dropdown>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                position: "relative",
              }}
            >
              <DropdownToggle
                type="button"
                style={{
                  background: "transparent",
                  border: "none",
                }}
                className="position-relative notification-btn"
              >
                <FaBell
                  style={{
                    color: mode === "Light" ? "black" : "white",
                    fontSize: "18px",
                  }}
                />

                {notifications?.totalCount > 0 && !!user && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill notification_value">
                    {notifications?.totalCount}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                )}
              </DropdownToggle>
              {!!user && (
                <Dropdown.Menu
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  align="end"
                  className="Noti_wrapper"
                >
                  <Notifications
                    notShowAll
                    widthStyle={"350px"}
                    data={notificationsData}
                    onUpdate={onUpdate}
                    setOnUpdate={setOnUpdate}
                  />
                </Dropdown.Menu>
              )}
            </div>
          </Dropdown> */}
        </div>
      </div>
      <div style={{ marginTop: "-20px" }}></div>

      <Link to="/" onClick={() => onUpdateTab("HOME")}>
        <h3>Home</h3>
      </Link>
      <Link to="/premium" onClick={() => onUpdateTab("PREMIUM")}>
        <h3>PREMIUM</h3>
      </Link>
      <Link to="/nfl" onClick={() => onUpdateTab("NFL")}>
        <h3>NFL</h3>
      </Link>
      <Link to="/nba" onClick={() => onUpdateTab("NBA")}>
        <h3>NBA</h3>
      </Link>
      <Link to="/mlb" onClick={() => onUpdateTab("MLB")}>
        <h3>MLB</h3>
      </Link>
      <Link to="/nhl" onClick={() => onUpdateTab("NHL")}>
        <h3>NHL</h3>
      </Link>
      <Link to="/promos" onClick={() => onUpdateTab("PROMOS")}>
        <h3>PROMOS</h3>
      </Link>
      <Link to="/compare" onClick={() => onUpdateTab("COMPARE")}>
        <h3>COMPARE</h3>
      </Link>
      <Link to="/about-us" onClick={() => onUpdateTab("ABOUT_US")}>
        <h3>About Us</h3>
      </Link>
      {/* <Link to="/contact-us" onClick={() => onUpdateTab("CONTACT_US")}>
        <h3>CONTACT US</h3>
      </Link> */}

      <Dropdown>
        <Dropdown.Toggle className="team_header_link">
          <Link
            to=""
            onClick={() => setTeams(!teams)}
            style={{ position: "relative" }}
          >
            <h3>
              TEAMS{" "}
              <span>
                <AiFillCaretDown />
              </span>
            </h3>
          </Link>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="team_accordion_dropdown"
          align="end"
          style={{
            marginTop: "22px",
          }}
        >
          <Accordion
            className={`team_header_accordion ${
              mode === "Dark" && "dark_theme"
            } `}
          >
            {sports.map((sport, index) => {
              return (
                <Accordion.Item
                  key={index}
                  eventKey={index}
                  className={`team_accordion_item ${
                    mode === "Dark" && "dark_theme"
                  } `}
                >
                  <Accordion.Header
                    onClick={() => fetchTeamsList(sport)}
                    className="team_accordion_header"
                  >
                    {sport}
                  </Accordion.Header>
                  <Accordion.Body className="team_accordion_body">
                    {isLoading ? (
                      <DarkLoader />
                    ) : teamsList?.length ? (
                      teamsList?.map((team) => (
                        <p style={{ cursor: "pointer" }}>
                          <span style={{ display: "flex", gap: 15 }}>
                            <img
                              className={`${
                                team?.name === "Pirates" && "team_logo_bg"
                              }`}
                              src={team?.wikipediaLogoUrl || "/"}
                              alt={`${team?.city} ${team?.name}`}
                            />
                            <span
                              onClick={() => {
                                teamNavigator(team, navigate, updateSport);
                                updateSport(team?.sport);
                              }}
                            >
                              {team?.city} {team?.name}
                            </span>
                          </span>
                          <span>{team?.sport}</span>
                        </p>
                      ))
                    ) : (
                      <p style={{ color: "#778899" }}>No Teams Found</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          {/* {teamsList?.map((team) => (
            <p style={{ cursor: "pointer" }}>
              <span style={{ display: "flex", gap: 15 }}>
                <img
                  src={team?.wikipediaLogoUrl || "/"}
                  alt={`${team?.city} ${team?.name}`}
                />
                <span onClick={() => navigate(`/team/${team?._id}`)}>
                  {team?.city} {team?.name}
                </span>
              </span>
              <span>{team?.sport}</span>
            </p>
          ))} */}
        </Dropdown.Menu>
      </Dropdown>

      <div
        className={`d-flex flex-column gap-3 sidebar_social ${
          mode === "Light" && "light_theme"
        }`}
      >
        <a href="https://www.facebook.com/profile.php?id=61552815775133" target="_blank" rel="noreferrer">
          <FaFacebookF />
        </a>
        <a
          href="https://twitter.com/playertotals"
          target="_blank"
          rel="noreferrer"
        >
          <div>
            {mode === "Light" ? (
              <img
                width="16px"
                height="16px"
                src="/images/twitter-lt.png"
                alt=""
              />
            ) : (
              <img
                width="16px"
                height="16px"
                src="/images/sidebar-twitter-light.png"
                alt=""
              />
            )}
          </div>
          {/* <BsTwitter /> */}
        </a>
        <a
          href="https://www.instagram.com/playerprops"
          target="_blank"
          rel="noreferrer"
        >
          <BsInstagram />
        </a>
      </div>
    </div>
  );
};
export default TeamsSideBar;
