import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import "../../../Admin/cms.css";
// import {
//   aboutDelete,
//   getAboutUs,
//   updateAbout,
// } from "../../../../Redux/Actions/CMS";
// import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Utils/Loader";
import { cloudURL } from "../../../../Utils/helper";
// import { about } from "../../../../Utils/helper";
// import { errorNotify, successNotify } from "../../../../Utils/toast";
import EditAboutSectionModal from "../../../Admin/EditAboutUs/EditAboutSectionModal";
import AddAboutSectionModal from "../../../Admin/EditAboutUs/AddAboutSectionModal";
import EditBannerSectionModal from "../../../Admin/EditAboutUs/EditBannerSectionModal";
import Abouts from "../../../../Services/about";
import { updateAbout, useAbout } from "../../../../Redux/Accessors/about.accessors";
import { successNotify } from "../../../../Utils/toast";

const EditAboutUs = () => {

  const [show, setShow] = useState(false);
  const [addSectionShow, setAddSectionShow] = useState(false);
  const [editSectionShow, setEditSectionShow] = useState(false);

  const [bannerContent, setBannerContent] = useState({
    BannerImg: "",
    title: "",
  });
  const [uploadedImages, setUploadedImages] = useState({
    bannerImg: "",
    BannerImgPreview: "",
  });
  const [sectionContent, setSectionContent] = useState({
    sectionId: "",
    image: "",
    heading: "",
    text: "",
  });
  const [sectionUploadedImages, setSectionUploadedImages] = useState({
    image: "",
    imagePreview: "",
  });
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [createSuccess,setCreateSuccess] = useState(false);
  const [editSuccess,setEditSuccess] = useState(false);
  const [deleteSuccess,setDeleteSuccess] = useState(false);

  
  const {about} = useAbout();
  console.log(about)
  const fetchAbout = async () => {
    try {
      setLoading(true);
      const res = await Abouts.about();
      const data = res?.data?.data || [];

      updateAbout(data);
    } catch (error) {
      setLoading(false);
      console.error("An error occurred while fetching about-us:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAbout();
  }, []);
  useEffect(()=>{
    if(createSuccess){
      window.scrollTo(0, 0);
      successNotify("About Section Created Successfully!")
      fetchAbout();
      setCreateSuccess(false)
    }
    if(editSuccess){
      window.scrollTo(0, 0);
      successNotify("About Edited Successfully!")
      onCloseModal();
      sectionCloseModal();
      fetchAbout();
      setEditSuccess(false)
    }
    if(deleteSuccess){
      window.scrollTo(0, 0);
      successNotify("About Section Deleted Successfully!")
      fetchAbout();
      setDeleteSuccess(false)
    }
  },[createSuccess,editSuccess,deleteSuccess])

  const bannerEditHandler = (e) => {
    e.preventDefault();
    setBannerContent({
      bannerImg: about?.bannerSection?.bannerImg,
      title: about?.bannerSection?.title,
    });
    setShow(true);
  };
  const sectionEditHandler = (e, a) => {
    e.preventDefault();
    setSectionContent({
      sectionId: a?._id,
      image: a?.image,
      heading: a?.heading,
      text: a?.text,
    });
    setEditSectionShow(true);
  };
  const handleImageChange = () => (e) => {
    setUploadedImages({
      bannerImg: e.target.files[0],
      BannerImgPreview: URL.createObjectURL(e.target.files[0]),
    });
  };
  const handleSectionImageChange = () => (e) => {
    setSectionUploadedImages({
      image: e.target.files[0],
      imagePreview: URL.createObjectURL(e.target.files[0]),
    });
  };
  const onChangeHandler = (name) => (e) => {
    setBannerContent((prev) => {
      return { ...prev, [name]: e.target.value };
    });
  };
  const sectionChangeHandler = (name) => (e) => {
    setSectionContent((prev) => {
      return { ...prev, [name]: e.target.value };
    });
  };
  const onCloseModal = () => {
    console.log("a")
    setShow(false);
    setBannerContent({
      BannerImg: "",
      title: "",
    });
    setUploadedImages({
      bannerImg: "",
      BannerImgPreview: "",
    });
  };
  const sectionCloseModal = () => {
    setEditSectionShow(false);
    setSectionContent({
      sectionId: "",
      image: "",
      heading: "",
      text: "",
    });
    setSectionUploadedImages({
      image: "",
      imagePreview: "",
    });
  };
  const bannerSubmitHandler = async(e) => {
    e.preventDefault();
    try {
    setBannerLoading(true)
    const formData = new FormData();
    formData.append("title", bannerContent.title);
    if (uploadedImages.bannerImg !== "") {
      formData.append("banner_img", uploadedImages.bannerImg);
    }
    await Abouts.updateAbout(formData)
    
    setEditSuccess(true)
  } catch (error) {
    setBannerLoading(false);
    console.error("An error occurred while updating banner:", error);
  } finally {
    setBannerLoading(false);
  }
  };
  const editSectionSubmitHandler = async(e) => {
    e.preventDefault();
    try{
      setSectionLoading(true)
    const formData = new FormData();
    formData.append("sectionId", sectionContent.sectionId);
    formData.append("heading", sectionContent.heading);
    formData.append("text", sectionContent.text);
    if (sectionUploadedImages.image !== "") {
      formData.append("image", sectionUploadedImages.image);
    }
    await Abouts.updateAbout(formData)
    setEditSuccess(true)
  } catch (error) {
    setSectionLoading(false);
    console.error("An error occurred while updating Section:", error);
  } finally {
    setSectionLoading(false);
  }
  };
  const aboutDeleteHandler = async(e, a) => {
    e.preventDefault();
    try{
      setDeleteLoading(true)

    const res= await Abouts.deleteAbout(a?._id)
    console.log(res?.data)
    setDeleteSuccess(true)
  } catch (error) {
    setDeleteLoading(false);
    console.error("An error occurred while deleting Section:", error);
  } finally {
    setDeleteLoading(false);
  }
  };
  return (
    <>
      <h1>About Us</h1>
      <div className="layout_page_section ">
        {loading ? (
          <Loader />
        ) : (
          <Container className="mb-3">
            <Row className="justify-content-center edit_promos ">
              <Col md={11}>
                <Row>
                  <Col
                    md={12}
                    className="my-3 d-flex justify-content-between align-items-center"
                  >
                    <h2
                      style={{
                        fontWeight: "700",
                        color: "#fff",
                        fontSize: "35px",
                      }}
                    >
                      {" "}
                      About Us
                    </h2>
                  </Col>
                </Row>
              </Col>
              <Col md={11}>
                <Row className="gx-5">
                  <Col md={6}>
                    <h4 className="text-white">Banner Section </h4>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end gap-3">
                    <button
                      className="edit_btn"
                      onClick={(e) => bannerEditHandler(e)}
                    >
                      Edit
                    </button>
                  </Col>
                </Row>
                <hr />

                <Row className="gx-5">
                  <Col md={6}>
                    <Form.Label className="text-white">Banner Image</Form.Label>
                    {/* <Form.Group className="mb-3 cms_image_input">
                  <Form.Control
                    type="file"
                    style={{ fontSize: "13px", paddingBottom: "7px" }}
                    name={`image`}
                  />
                </Form.Group> */}
                    <div className="previewImg_div">
                      <img
                        src={
                          about?.bannerSection?.bannerImg
                            ? `${cloudURL}/${about?.bannerSection?.bannerImg.filename}`
                            : "/images/empty.jpg"
                        }
                        alt=""
                        style={{}}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Label className="text-white">Title</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder=""
                        name="title"
                        value={about?.bannerSection?.title}
                        readOnly
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row></Row>
              </Col>
              <Col md={11}>
                <Row>
                  <Col
                    md={12}
                    className="my-3 d-flex justify-content-between align-items-center"
                  >
                    <h4
                      style={{
                        fontWeight: "700",
                        color: "#fff",
                        fontSize: "30px",
                      }}
                    >
                      {" "}
                      About Sections
                    </h4>
                    <button
                      className="add_more"
                      onClick={() => setAddSectionShow(true)}
                    >
                      Add More Sections
                    </button>
                  </Col>
                </Row>
              </Col>
              {about?.aboutSections &&
                about?.aboutSections.map((a, i) => (
                  <Col md={11} className="mt-4" key={i}>
                    <Row className="gx-5">
                      <Col md={12}>
                        <Row className="align-items-center">
                          <Col md={6}>
                            <h4 className="text-white">Section {i + 1}</h4>
                          </Col>
                          <Col
                            md={6}
                            className="d-flex justify-content-end gap-3"
                          >
                            <button
                              className="edit_btn"
                              onClick={(e) => sectionEditHandler(e, a)}
                            >
                              Edit
                            </button>
                            <button
                              className="delete_btn"
                              onClick={(e) => aboutDeleteHandler(e, a)}
                            >
                               {deleteLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : ( 
                                "Delete"
                               )} 
                            </button>
                          </Col>
                        </Row>
                        <hr />
                      </Col>
                    </Row>

                    <Row className="gx-5">
                      <Col md={6}>
                        <Form.Label className="text-white">Image</Form.Label>

                        <div className="previewImg_div">
                          <img
                            src={
                              a?.image?.filename
                                ? `${cloudURL}/${a.image.filename}`
                                : "/images/empty.jpg"
                            }
                            alt=""
                            style={{}}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <Form.Label className="text-white">Heading</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Enter About Section Heading"
                            name="heading"
                            value={a?.heading}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="gx-5">
                      <Col md={12}>
                        <Form.Label className="text-white">Text</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Enter About Section Text"
                            name="text"
                            value={a.text}
                            readOnly
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                ))}
            </Row>
          </Container>
        )}
      </div>
      <EditBannerSectionModal
        show={show}
        setShow={setShow}
        banner={bannerContent}
        onCloseModal={onCloseModal}
        onChangeHandler={onChangeHandler}
        handleImageChange={handleImageChange}
        uploadedImages={uploadedImages}
        submitHandler={bannerSubmitHandler}
        loading={bannerLoading}
      />
      <AddAboutSectionModal
        show={addSectionShow}
        setShow={setAddSectionShow}
        size="lg"
        success={createSuccess}
        setSuccess={setCreateSuccess}
      />

      <EditAboutSectionModal
        show={editSectionShow}
        setShow={setEditSectionShow}
        aboutSection={sectionContent}
        onChangeHandler={sectionChangeHandler}
        handleImageChange={handleSectionImageChange}
        sectionUploadedImages={sectionUploadedImages}
        onCloseModal={sectionCloseModal}
        submitHandler={editSectionSubmitHandler}
        loading={sectionLoading}
      />
    </>
  );
};

export default EditAboutUs;
