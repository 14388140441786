import { useSelector } from "react-redux";
import { ensureMutators } from "../helper";
import gameSlice from "../Slices/game.slice";
import { store } from "../store";

// mutators
export const updateSchedule = (scheduleGames) =>
  store.dispatch(gameSlice.actions.updateSchedule(scheduleGames));

export const updateSport = (sport) =>
  store.dispatch(gameSlice.actions.updateSport(sport));

export const updateTab = (tab) =>
  store.dispatch(gameSlice.actions.updateTab(tab));

export const updatePremiumTab = (premium) =>
  store.dispatch(gameSlice.actions.updatePremiumTab(premium));

export const updateMLBTab = (tab) =>
  store.dispatch(gameSlice.actions.updateMLBTab(tab));

export const updateSlide = (slide) =>
  store.dispatch(gameSlice.actions.updateSlide(slide));

export const updateDate = (date) =>
  store.dispatch(gameSlice.actions.updateDate(date));

export const updateSettings = (data) =>
  store.dispatch(gameSlice.actions.updateSettings(data));

ensureMutators(gameSlice.actions, {
  updateSchedule,
  updateSport,
  updatePremiumTab,
  updateTab,
  updateMLBTab,
  updateSlide,
  updateDate,
  updateSettings,
});

// selectors
export const useGame = () => useSelector((state) => state.games);

export const useSchedules = () =>
  useSelector((state) => state.games.scheduleGames);

export const useSport = () => useSelector((state) => state.games.sport);

export const useTab = () => useSelector((state) => state.games.tab);

export const usePremiumTab = () =>
  useSelector((state) => state.games.premiumTab);

export const useMLBTab = () => useSelector((state) => state.games.mlbTab);

export const useSlide = () => useSelector((state) => state.games.slide);

export const useDate = () => useSelector((state) => state.games.currentDate);

export const useSettings = () => useSelector((state) => state.games.settings);
