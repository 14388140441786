import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { getDataValues, getTab } from "../../Utils/getStats";
import { updateSport, useTab } from "../../Redux/Accessors/game.accessors";
import Games from "../../Services/Games";
import { getScheduleTime } from "../../Utils/dates";
import Loader from "../../Common/Loader";
import { playerNavigator } from "../../Utils/navigator";
import { useNavigate } from "react-router-dom";
import MatchPlayerStatsBox from "./MatchPlayerStatsBox";
import ViewMore from "../../Common/ViewMore";
import MatchesTeamsData from "./MatchesTeamsData";
import MatchesPremiumCard from "./MatchesPremium";

const MatchesAccordianItem = ({
  i,
  mode,
  match,
  sport,
  fetchSchedulesGames,
}) => {
  const tab = useTab();

  const [viewMore, setViewMore] = useState(false);
  const [viewMoreB, setViewMoreB] = useState(false);
  const [isPlayerDataLoading, setIsPlayerDataLoading] = useState(false);
  const [homeTeamData, setHomeTeamData] = useState(null);
  const [awayTeamData, setAwayTeamData] = useState(null);
  const [homeTeamId, setHomeTeamId] = useState("");
  const [awayTeamId, setAwayTeamId] = useState("");
  const [show, setShow] = useState("Matches");
  //   console.log(homeTeamData, "home team data");
  //   console.log(awayTeamData, "away team data");

  const fetchTeamPlayerStats = async (key, data) => {
    setHomeTeamData(null);
    setAwayTeamData(null);

    try {
      setIsPlayerDataLoading(true);
      const res = await Games.playerStats(data);
      //   console.log(res?.data?.data, "players data");
      if (key === "HOME") {
        setHomeTeamData(res.data.data);
      } else {
        setAwayTeamData(res.data.data);
      }
    } catch (error) {
      setHomeTeamData(null);
      setAwayTeamData(null);
      setIsPlayerDataLoading(false);
      console.error("An error occurred while fetching player stats:", error);
    } finally {
      setIsPlayerDataLoading(false);
    }
  };
  useEffect(() => {
    if (
      match?._id &&
      match?.homeTeam?._id &&
      match?.awayTeam?._id &&
      match?.sport === sport
    ) {
      setHomeTeamId(match?.homeTeam?._id);
      setAwayTeamId(match?.awayTeam?._id);
      fetchTeamPlayerStats("HOME", {
        gameId: match?._id,
        teamId: match?.homeTeam?._id,
        bettingBetType: getTab(tab),
      });

      fetchTeamPlayerStats("AWAY", {
        gameId: match?._id,
        teamId: match?.awayTeam?._id,
        bettingBetType: getTab(tab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, tab, match]);
  // console.log(homeTeamData,"homeTeamData")
  // console.log(awayTeamData,"awayTeamData")
console.log(match,"match")
  return (
    <>
      <Accordion.Item eventKey={`${i}`}>
        <Accordion.Header onClick={() => setViewMore(false)}>
          <p>
            <span>
              {match?.homeTeam?.city} {match?.homeTeam?.name}
            </span>{" "}
            <span>@</span>{" "}
            <span>
              {match?.awayTeam?.city} {match?.awayTeam?.name}
            </span>{" "}
            {getScheduleTime(match?.dateTimeUTC)}
            EST
          </p>
        </Accordion.Header>
        <Accordion.Body className="pb-0">
          <div className="data_update_header_container d-flex justify-content-end w-full">
            <div>
              <button
                className={`${show === "Matches" && "active"}`}
                onClick={() => setShow("Matches")}
              >
                Matches
              </button>
              <button
                className={`${show === "Premium" && "active"}`}
                onClick={() => setShow("Premium")}
              >
                Premium
              </button>
            </div>
          </div>
          {show === "Matches" ? (
            <>
              <MatchesTeamsData
                loading={isPlayerDataLoading}
                setTeamData={setHomeTeamData}
                teamData={homeTeamData}
                tab={tab}
                viewMore={viewMore}
                setViewMore={setViewMore}
                mode={mode}
                fetchSchedulesGames={fetchSchedulesGames}
                teamId={homeTeamId}
              />
              <MatchesTeamsData
                loading={isPlayerDataLoading}
                teamData={awayTeamData}
                setTeamData={setAwayTeamData}
                tab={tab}
                viewMore={viewMoreB}
                setViewMore={setViewMoreB}
                mode={mode}
                fetchSchedulesGames={fetchSchedulesGames}
                teamId={awayTeamId}
              />
            </>
          ) : (
            <>
              <MatchesPremiumCard
                name={tab}
                viewMore
                onlyList
                // setTeamData={setHomeTeamData}
                teamData={match}
                premiumType="premiumHome"
              />
              <MatchesPremiumCard
                name={tab}
                viewMore
                onlyList
                teamData={match}
                premiumType="premiumAway"
              />
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default MatchesAccordianItem;
