import React from "react";
import SearchTeamAccordianItem from "./SearchTeamAccordianItem";
import { Accordion } from "react-bootstrap";
import { ThemeState } from "../../../Context";
import { getTeamPagePreviousGameTime } from "../../../Utils/dates";

const SearchTeamAccordian = ({ id, games, fetchTeamPreviousGames,setPage }) => {
  const { mode } = ThemeState();
  console.log(games);

  return (
    <>
      <div
        className={`team_accordion matches_accordian ${
          mode === "Light" && "light_theme"
        }`}
      >
          {games &&
            games.map(function (game, i) {
              const teamkey =
                game?.awayTeam?._id === id ? "awayTeam" : "homeTeam";

              return (
                  <Accordion key={i} defaultActiveKey="0">
                <Accordion.Item eventKey={`${i}`}>
                  <Accordion.Header
                  // onClick={() => setViewMore(false)}
                  >
                    <p>
                      <span>{game?.awayTeam?.name}</span> <span>@</span>{" "}
                      <span>{game?.homeTeam?.name}</span>{" "}
                      {getTeamPagePreviousGameTime(game?.time)}
                    </p>
                  </Accordion.Header>
                <Accordion.Body className="teams_accordion_body">

                  <SearchTeamAccordianItem
                    id={id}
                    game={game}
                    fetchSchedulesGames={fetchTeamPreviousGames}
                    teamId={game[teamkey]?._id}
                    key={i}
                    setPage={setPage}

                  />
                </Accordion.Body>

                </Accordion.Item>
        </Accordion>
              );
            })}
      </div>
    </>
  );
};

export default SearchTeamAccordian;
