// import Dashboard from "../../User/Dashboard/Dashboard";

// import Subscription from "../User/Subscription/Subscription";
// import BillingHistory from "../User/BillingHistory/BillingHistory";
// import UserSettings from "../User/UserSettings/UserSettings";
import Profile from "../../User/Profile/Profile";
// import Creations from "../../SuperAdmin/Creation/Creations";
// import AllUsers from "../../SuperAdmin/AllUsers/AllUsers";
import NFL from "../../SuperAdmin/DataUpdate/NFL";
// import EditAboutUs from "../../SuperAdmin/CMS/EditAboutUs/EditAboutUs";
// import EditPromos from "../../SuperAdmin/CMS/EditAboutUs/EditPromos";
import NBA from "../../SuperAdmin/DataUpdate/NBA";
import MLB from "../../SuperAdmin/DataUpdate/MLB";
import NHL from "../../SuperAdmin/DataUpdate/NHL";
import Search from "../../SuperAdmin/DataUpdate/Search/Search";

export const AdminRoutes = [
  // {
  //   path: "/admin/dashboard",
  //   component: <Dashboard />,
  // },

  // {
  //   path: "/sub-admin/dashboard",
  //   component: <Dashboard />,
  // },
  {
    path: "/sub-admin/profile",
    component: <Profile />,
  },
  // {
  //   path: "/sub-admin/creation",
  //   component: <Creations />,
  // },
  // {
  //   path: "/sub-admin/users",
  //   component: <AllUsers />,
  // },
  {
    path: "/sub-admin/data-update/NFL",
    component: <NFL />,
  },
  {
    path: "/sub-admin/data-update/NBA",
    component: <NBA />,
  },
  {
    path: "/sub-admin/data-update/MLB",
    component: <MLB />,
  },
  {
    path: "/sub-admin/data-update/NHL",
    component: <NHL />,
  },
  {
    path: "/sub-admin/data-update/Search",
    component: <Search />,
  },
  // {
  //   path: "/sub-admin/cms/edit-about-us",
  //   component: <EditAboutUs />,
  // },
  // {
  //   path: "/sub-admin/cms/edit-promos",
  //   component: <EditPromos />,
  // },
];

export const AdminSideBarItems = [
  // {
  //   path: "/user/dashboard",
  //   icon: "/images/dashboard-icon.png",
  //   title: "Dashboard",
  //   isSubNav: false,
  // },
  {
    path: "/sub-admin/profile",
    icon: "/images/profile-icon.png",
    title: "Profile",
    isSubNav: false,
  },
  // {
  //   path: "/sub-admin/creation",
  //   icon: "/images/creation.png",
  //   title: "Creation",
  //   isSubNav: false,
  // },
  // {
  //   path: "/sub-admin/users",
  //   icon: "/images/users.png",
  //   title: "Total Users",
  //   isSubNav: false,
  // },
  // {
  //   path: "/sub-admin/data-update",
  //   icon: "/images/update.png",
  //   title: "Data Update",
  //   isSubNav: true,
  // },
  {
    path: "/sub-admin/data-update",
    icon: "/images/update.png",
    title: "Data Update",
    isSubNav: true,
    subNav: [
      {
        path: "/sub-admin/data-update/search",
        component: <Search />,
        icon: "",
        title: "SEARCH",
      },
      {
        path: "/sub-admin/data-update/NFL",
        component: <NFL />,
        icon: "",
        title: "NFL",
      },
      {
        path: "/sub-admin/data-update/NBA",
        component: <NBA />,
        icon: "",
        title: "NBA",
      },
      {
        path: "/sub-admin/data-update/MLB",
        component: <MLB />,
        icon: "",
        title: "MLB",
      },
      {
        path: "/sub-admin/data-update/NHL",
        component: <NHL />,
        icon: "",
        title: "NHL",
      },
      
    ],
  },
  // {
  //   path: "/sub-admin/cms",
  //   icon: "/images/update.png",
  //   title: "Pages",
  //   isSubNav: true,
  //   subNav: [
  //     {
  //       path: "/sub-admin/cms/edit-about-us",
  //       component: <EditAboutUs />,
  //       icon: "",
  //       title: "About Us",
  //     },
  //     {
  //       path: "/sub-admin/cms/edit-promos",
  //       component: <EditPromos />,
  //       icon: "",
  //       title: "Promos",
  //     },
  //   ],
  // },
];
