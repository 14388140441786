import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Info from "../../../Common/Info";
import Loader from "../../../Common/Loader";
import StatsBox from "../../../Common/StatsBox";
import ViewMore from "../../../Common/ViewMore";
import { NO_CONTENT_MESSAGE } from "../../../Constants";
import { ThemeState } from "../../../Context";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { getDataValues } from "../../../Utils/getStats";
import { playerNavigator, teamNavigator } from "../../../Utils/navigator";
import "./MatchStats.css";

const MatchStats = ({ isLoading, viewMore, setViewMore, team, teamData }) => {
  const selectedTab = useTab();
  const { mode } = ThemeState();
  const navigate = useNavigate();

  const filteredTeamData =
    teamData?.filter((game) => !getDataValues(game, selectedTab)) || [];

  return (
    <div>
      <div className="w-100">
        <div
          className={`match_stats_main ${mode === "Light" && "light_theme"}`}
        >
          <div className="match_stats_header">
            <div onClick={() => teamNavigator(team, navigate, updateSport)}>
              <img
                src={team?.wikipediaLogoUrl}
                style={{ width: "35px", height: "35px" }}
                alt="team-logo"
              />
              <h3
                className={`${
                  mode === "Light" ? "text-white" : "text-white"
                } ps-2 cursor-pointer team_name`}
              >
                {team?.city} {team?.name}
              </h3>
            </div>
          </div>
          <>
            <div className="mt-2 assets_header">
              <Row>
                <Col md={3}>
                  <h3
                    className={`ps-4 py-2 ${
                      mode === "Light" ? "col-green fw-bolder" : "col-yellow"
                    }`}
                  >
                    {selectedTab}
                  </h3>
                </Col>
                <Col md={9}>
                  <Row className="align-items-center assets_images">
                    <Col md={2}>
                      <img src="/images/fanduel.png" alt="fanduel sportsbook" />
                    </Col>
                    <Col md={2}>
                      {mode === "Light" ? (
                        <img
                          src="/images/draft-light.png"
                          style={{ height: "34px" }}
                          alt="logo"
                        />
                      ) : (
                        <img
                          src="/images/draft.png"
                          style={{ height: "34px" }}
                          alt="draftkings sportsbook"
                        />
                      )}
                    </Col>
                    <Col md={2}>
                      <img
                        src="/images/betmgm.png"
                        style={{ height: "40px" }}
                        alt="betmgm sportsbook"
                      />
                    </Col>
                    <Col md={2}>
                      {mode === "Light" ? (
                        <img
                          src="/images/caesars-light.png"
                          style={{ height: "24px" }}
                          alt="logo"
                        />
                      ) : (
                        <img
                          src="/images/caesars.png"
                          style={{ height: "24px" }}
                          alt="caesars sportbook"
                        />
                      )}
                    </Col>
                    <Col md={2}>
                      <img
                        src="/images/bet365_img.png"
                        style={{ height: "22px" }}
                        // src="/images/bwin_img.png" style={{ height: "22px" }}
                        alt="bet365 bonus code"
                      />
                    </Col>
                    <Col md={2}>
                      {mode === "Light" ? (
                        <img
                          src="/images/pointsbet-light.png"
                          style={{ height: "46px" }}
                          alt="logo"
                        />
                      ) : (
                        <img
                          src="/images/pointsbet.png"
                          style={{ height: "46px" }}
                          alt="pointsbet sportsbook"
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {isLoading ? (
              <Loader />
            ) : !!filteredTeamData?.length ? (
              filteredTeamData
                ?.slice(0, viewMore ? filteredTeamData?.length : 3)
                .map((game, index) => {
                  const allStatsNull = getDataValues(game, selectedTab);

                  if (allStatsNull) {
                    return null;
                  }

                  return (
                    <div key={index} className="assets_body">
                      <Row>
                        <Col
                          md={3}
                          className="d-flex flex-column justify-content-center ps-3"
                        >
                          <h3
                            onClick={() =>
                              playerNavigator(
                                game?.player,
                                navigate,
                                updateSport
                              )
                            }
                          >
                            {game?.player?.firstName} {game?.player?.lastName}
                          </h3>
                          <div className="d-flex gap-3 align-items-center">
                            <Alert playerId={game?.player?._id} />
                            <Info />
                          </div>
                        </Col>
                        <Col md={9}>
                          <Row className="align-items-center assets_images">
                            <Col md={2}>
                              <StatsBox playerStats={game?.fanduel} />
                            </Col>
                            <Col md={2}>
                              <StatsBox playerStats={game?.draftkings} />
                            </Col>
                            <Col md={2}>
                              <StatsBox playerStats={game?.betmgm} />
                            </Col>
                            <Col md={2}>
                              <StatsBox playerStats={game?.caesars} />
                            </Col>
                            <Col md={2}>
                              <StatsBox playerStats={game?.consensus} />
                            </Col>
                            <Col md={2}>
                              <StatsBox playerStats={game?.pointsbet} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  );
                })
            ) : (
              <div
                className={`text-center p-2 ${
                  mode === "Light" ? "text-dark" : "text-white"
                }`}
              >
                {NO_CONTENT_MESSAGE}
              </div>
            )}
          </>
        </div>
        <div
          className={`d-flex justify-content-center mt-4 view_more_btn ${
            mode === "Light" && "light_theme"
          }`}
        >
          <ViewMore viewMore={viewMore} setViewMore={setViewMore} />
        </div>
      </div>
    </div>
  );
};

export default MatchStats;
