import React from "react";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../Context";
import StatsCol from "./StatsCol";
import SiteLogos from "./SiteLogos";

const CompareTable = ({
  viewMore,
  setViewMore,
  name,
  setName,
  teamName,
  teamLogo,
  compares,
  setCompares,
  playerIds,
  removeIdFromSport,
}) => {
  const { mode } = ThemeState();

  return (
    <>
      {playerIds?.length ? (
        <div
          className={`compare_table_main bg_grey ${
            mode === "Light" && "light_theme"
          }`}
        >
          <Row className="compare_table">
            <Col md={1}>
              <SiteLogos />
            </Col>
            <Col md={11}>
              <div className="d-flex">
                {playerIds?.map((playerId, index) => (
                  <StatsCol
                    key={`${playerId}_${index}`}
                    playerId={playerId}
                    length={playerIds?.length}
                    removeIdFromSport={(sport, id) =>
                      removeIdFromSport(sport, id)
                    }
                  />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <h3
          style={{
            color: "white",
            textAlign: "center",
            padding: "40px 0px",
            fontSize: "1rem",
          }}
        >
          Search Players to Compare
        </h3>
      )}
    </>
  );
};

export default CompareTable;
