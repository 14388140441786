import { useSelector } from "react-redux";
import { ensureMutators } from "../helper";
import headerSlice from "../Slices/header.slice";
import { store } from "../store";

// mutators
export const updateHeaderTab = (tab) =>
  store.dispatch(headerSlice.actions.updateHeaderTab(tab));

ensureMutators(headerSlice.actions, { updateHeaderTab });


// selectors
export const useHeaderTab = () =>
  useSelector((state) => state.heaeder.headerTab);
