import { useSelector } from "react-redux";
import { ensureMutators } from "../helper";

import promoSlice from "../Slices/promos.slice"; 
import { store } from "../store";

//mutators
export const updatePromos = (promos) =>
  store.dispatch(promoSlice.actions.updatePromos(promos));

ensureMutators(promoSlice.actions, { updatePromos });


  //selectors
export const usePromos = () => useSelector((state) => state.promos);
