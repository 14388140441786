import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import PlayerSearch from "../../../../Components/DataUpdate/SearchByPlayer/PlayerSearch";
import TeamSearch from "../../../../Components/DataUpdate/SearchByTeam/TeamSearch";
import SearchedPlayerData from "../../../../Components/DataUpdate/SearchByPlayer/SearchedPlayerData";
import SearchedTeamData from "../../../../Components/DataUpdate/SearchByTeam/SearchedTeamData";

const Search = () => {
  const [search, setSearch] = useState("");

  const [selectedItem, setSelectedItem] = useState("PLAYER"); // Set the default selected
  const [playerData, setPlayerData] = useState();
  const [teamData, setTeamData] = useState();

  const handleSelect = (item) => {
    setSelectedItem(item);
  };
  // console.log(teamData, "teamData");
  return (
    <div className="data_update">
      <div className=" data_update_header_container">
        <h1>Search</h1>
      </div>
      <div className="layout_page_section px-3 ">
        <div className="d-flex flex-column flex-md-row align-items-start gap-3 mt-4 player_team_search">
          <DropdownButton
            id="dropdown-item-button"
            title={selectedItem}
            onSelect={handleSelect}
            variant="warning"
          >
            <Dropdown.Item eventKey="PLAYER">PLAYER</Dropdown.Item>
            <Dropdown.Item eventKey="TEAM">TEAM</Dropdown.Item>
          </DropdownButton>
          {selectedItem === "PLAYER" ? (
            <PlayerSearch
              search={search}
              setSearch={setSearch}
              playerData={playerData}
              setPlayerData={setPlayerData}
            />
          ) : (
            <TeamSearch setTeamData={setTeamData} />
          )}
        </div>
        {selectedItem === "PLAYER" ? (
          <>
            {playerData && playerData !== undefined && (
              <SearchedPlayerData playerData={playerData} />
            )}
          </>
        ) : (
          <>
            {teamData && teamData !== undefined && (
              <SearchedTeamData teamData={teamData} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Search;
