import React from "react";
import { useEffect } from "react";
import {
  updateSport,
  updateTab,
  useTab,
} from "../../../Redux/Accessors/game.accessors";
import { useState } from "react";
import MatchesHeader from "../MatchesHeader";
import {
  MLB_BATTING_TABS,
  MLB_PITCHING_TABS,
  NBA_TABS,
  NFL_TABS,
  SPORTS,
} from "../../../Constants";
import Games from "../../../Services/Games";
import {
  updateTeamMatches,
  useTeamGames,
  useTeams,
} from "../../../Redux/Accessors/previousGames.accessors";
import SearchTeamAccordian from "./SearchTeamAccordian";
import TextMessage from "../../../Common/TextMessage";
import MatchesPremiumCard from "../MatchesPremium";
import PageNumbers from "../../../Common/Pagination";
import Loader from "../../../Common/Loader";

const SearchedTeamData = ({ teamData }) => {
  const [tabs, setTabs] = useState();
  const [selectedTabMLB, setSelectedTabMLB] = useState("Batting");
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const matches = useTeamGames();
  const [games, setGames] = useState(matches || []);
  const [show, setShow] = useState("Matches");
  const [page, setPage] = useState(1);
  const [enableScroll, setEnableScroll] = useState(false);

  const selectedTab = useTab();

  useEffect(() => {
    updateGameTab(teamData?.sport);
    if (teamData?.sport === SPORTS.NBA) {
      setTabs(NBA_TABS);
      return;
    }
    if (teamData?.sport === SPORTS.MLB) {
      if (selectedTabMLB === "Pitching") {
        // setSelectedTabMLB("Pitching");
        // updateMLBTab("Pitching");
        setTabs(MLB_PITCHING_TABS);
        return;
      } else {
        // setSelectedTabMLB("Batting");
        // updateMLBTab("Batting");
        setTabs(MLB_BATTING_TABS);
        return;
      }
    }
    if (teamData?.sport === SPORTS.NFL) {
      setTabs(NFL_TABS);
      return;
    }
  }, [teamData, selectedTabMLB]);
  const updateGameTab = (sport) => {
    switch (sport) {
      case "NBA":
        updateTab("Points");
        break;
      case "MLB":
        if (selectedTabMLB === "Batting") {
          updateTab("Home Runs");
        } else {
          updateTab("Strikeouts");
        }
        break;
      case "NFL":
        updateTab("Anytime TD");
        break;
      case "NHL":
        updateTab("Points");
        break;
      default:
        updateTab("Home Runs");
    }
  };
  const previousGames = games?.data?.previousGames;

  console.log(games, "games");
  console.log(previousGames, "prevGames");
  const fetchTeamBySlug = async () => {
    try {
      const res = await Games.getTeamBySlug(teamData?.slug);
      setId(res?.data?.data?._id);
      updateSport(res?.data?.data?.sport);
    } catch (error) {
      console.error("An error occurred while fetching team details:", error);
    }
  };

  const fetchTeamPreviousGames = async (skip = 0) => {
    try {
      setIsLoading(true);
      const res = await Games.teamGames(teamData?.slug, skip, teamData?.sport);
      setGames(res?.data);
      updateTeamMatches(res?.data);
      window.scrollTo(0, 0);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching player games:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamBySlug();
    fetchTeamPreviousGames();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamData, tabs, selectedTabMLB]);

  const limit = 5;

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchTeamPreviousGames(skip * limit);
  };

  const handlePrev = () => {
    setEnableScroll(true);

    if (page !== 1) {
      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchTeamPreviousGames(skip * limit);
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(games?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      fetchTeamPreviousGames(skip * limit);
    }
  };

  return (
    <>
      <div className="mb-4 search_team_data">
        {/* <div className="data_update_header_container d-flex justify-content-end w-full">
          <div>
            <button
              className={`${show === "Matches" && "active"}`}
              onClick={() => setShow("Matches")}
            >
              Matches
            </button>
            <button
              className={`${show === "Premium" && "active"}`}
              onClick={() => setShow("Premium")}
            >
              Premium
            </button>
          </div>
        </div> */}
        {show === "Matches" ? (
          <>
            {teamData?.sport === "MLB" && (
              <div className="d-flex mt-4 batting_and_pitching">
                <button
                  className={selectedTabMLB === "Batting" && "active"}
                  onClick={() => setSelectedTabMLB("Batting")}
                >
                  Batting
                </button>
                <button
                  className={selectedTabMLB === "Pitching" && "active"}
                  onClick={() => setSelectedTabMLB("Pitching")}
                >
                  Pitching
                </button>
              </div>
            )}
            <MatchesHeader tabs={tabs} selectedTab={selectedTab} />
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {previousGames?.length ? (
                  <>
                    <SearchTeamAccordian
                      id={id}
                      games={previousGames}
                      fetchTeamPreviousGames={fetchTeamPreviousGames}
                      setPage={setPage}

                    />
                  </>
                ) : (
                  <div className="text-center mt-5">
                    <TextMessage message={"No Previous Game"} />
                  </div>
                )}
                {previousGames?.length && (
                  <div className={`mt-5 players_pagination `}>
                    <PageNumbers
                      page={page}
                      setPage={setPage}
                      handleSetPage={(pageNum) => handleSetPage(pageNum)}
                      handlePrev={handlePrev}
                      handleNext={handleNext}
                      totalRecords={games?.totalCount}
                      postsPerPage={5}
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <MatchesPremiumCard
              name={selectedTab}
              viewMore
              onlyList
              // setTeamData={setHomeTeamData}
              teamData={teamData}
              premiumType="premiumHome"
              tab="team"
            />
          </>
        )}
      </div>
    </>
  );
};

export default SearchedTeamData;
