export const updateSportTab = (sport, updateTab, tab) => {
  switch (sport) {
    case "NBA":
      updateTab(tab || "Points");
      break;
    case "MLB":
      updateTab(tab || "Home Runs");
      break;
    case "NFL":
      updateTab(tab || "Anytime TD");
      break;
    case "NHL":
      updateTab(tab || "Points");
      break;
    default:
      updateTab(tab || "Home Runs");
  }
};

export const getSportTab = (sport) => {
  switch (sport) {
    case "NBA":
      return "Points";
    case "MLB":
      return "Home Runs";
    case "NFL":
      return "Anytime TD";
    case "NHL":
      return "Points";
    default:
      return "Home Runs";
  }
};
