import { useSelector } from "react-redux";
import { ensureMutators } from "../helper";
import authSlice from "../Slices/auth.slice";
import { store } from "../store";

// mutators
export const saveAuth = (loginResponse) =>
  store.dispatch(authSlice.actions.saveAuth(loginResponse));

export const updateThemeMode = (mode) =>
  store.dispatch(authSlice.actions.updateThemeMode(mode));

export const updateUser = (updateResponse) =>
  store.dispatch(authSlice.actions.updateUser(updateResponse));

export const updateFavourite = (favouritePlayer) =>
  store.dispatch(authSlice.actions.updateFavourite(favouritePlayer));

export const updateNotifications = (notifications) =>
  store.dispatch(authSlice.actions.updateNotifications(notifications));

export const updatePlayerNotifications = (notifications) =>
  store.dispatch(authSlice.actions.updatePlayerNotifications(notifications));

export const updateTeamNotifications = (notifications) =>
  store.dispatch(authSlice.actions.updateTeamNotifications(notifications));

export const updateGameNotifications = (notifications) =>
  store.dispatch(authSlice.actions.updateGameNotifications(notifications));

export const updateIcon = (icon) =>
  store.dispatch(authSlice.actions.updateIcon(icon));

export const logoutAuth = () => store.dispatch(authSlice.actions.logoutAuth());

export const updateUsers = (users) =>
  store.dispatch(authSlice.actions.updateUsers(users));
  
export const updateSubAdmins = (subAdmins) =>
  store.dispatch(authSlice.actions.updateSubAdmins(subAdmins));
export const updateAdminUser = (user) =>
  store.dispatch(authSlice.actions.updateAdminUser(user));

ensureMutators(authSlice.actions, {
  saveAuth,
  updateThemeMode,
  updateIcon,
  logoutAuth,
  updateUser,
  updateFavourite,
  updateNotifications,
  updateGameNotifications,
  updateTeamNotifications,
  updatePlayerNotifications,
});

// getters
export const getUser = () => store.getState().auth.user;

// getters
export const getIcon = () => store.getState().auth.icon;

// selectors
export const useUser = () => useSelector((state) => state.auth);
export const useUsers = () => useSelector((state) => state.auth.users);
export const useSubAdmins = () => useSelector((state) => state.auth.subAdmins);
export const useAdminUser = () => useSelector((state) => state.auth.adminUser);

export const useThemeMode = () => useSelector((state) => state.auth.themeMode);

export const useFavourite = () => useSelector((state) => state.auth.favourites);

export const useNotifications = () =>
  useSelector((state) => state.auth.notifications);

export const usePlayerNotifications = () =>
  useSelector((state) => state.auth.playerNotifications);

export const useTeamNotifications = () =>
  useSelector((state) => state.auth.teamNotifications);

export const useGameNotifications = () =>
  useSelector((state) => state.auth.gameNotifications);
