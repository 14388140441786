import React from "react";
import "./OurMobileApp.css";
import { Col, Container, Row } from "react-bootstrap";
import { ThemeState } from "../../../Context";
import { useReactPWAInstall } from "react-pwa-install";
import AppLogo from "../../../assets/light_logo.png";

const OurMobileAppResponsive = () => {
  const { mode } = ThemeState();
  const { pwaInstall, supported } = useReactPWAInstall();

  const handleClick = () => {
    if (!supported) {
      alert("Your browser does not support PWA");
      return;
    }
    pwaInstall({
      title: "Install Player Totals Web App",
      logo: AppLogo,
      description: (
        <p>
          <strong>Real time Odds and Past Performances on every Player</strong>,{" "}
          <br />
          We provide real-time odds and past performances for every player
          across various sports. Our player pages cover all sports and offer
          current player prop odds for upcoming games, encompassing statistics
          from six sports books. Additionally, our platform presents past
          results, including over/under performances from previous games. This
          enables users to determine whether players have exceeded or fallen
          short of their selected prop bets. Our page provides premium
          statistics for the last five to ten games, as well as entire season
          statistics. You also may receive notifications for any line changes
          pertaining to the selected player. To access a specific player's
          information, simply search for their name and you will be directed to
          their dedicated page.,
        </p>
      ),
    })
      .then(() => {
        console.log("then");
        alert("App installed successfully or instructions for install shown");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <div
      className={`our_mobile_app_main_responsive ${
        mode === "Light" && "light_theme"
      }`}
    >
      <Container>
        <Row className="justify-content-center align-items-center pt-3">
          <Col md={6} className="d-flex" style={{ position: "relative" }}>
            <div>
              <h3>
                Install Our Web <br />
                Application
              </h3>
              <button
                className="bg_green text-white"
                onClick={() => handleClick()}
              >
                INSTALL NOW
              </button>
            </div>
            <div className="image_container">
              <img
                src="/images/mobile-app-2.png"
                style={{ height: "224px" }}
                alt="Latest Betting Strategies"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurMobileAppResponsive;
