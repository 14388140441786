import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageNumbers from "../../Common/Pagination";
import TextMessage from "../../Common/TextMessage";
import { NO_CONTENT_MESSAGE } from "../../Constants";
import { ThemeState } from "../../Context";
import { updateSport, useTab } from "../../Redux/Accessors/game.accessors";
import { usePlayerGames } from "../../Redux/Accessors/previousGames.accessors";
import { getGameTime } from "../../Utils/dates";
import { teamNavigator } from "../../Utils/navigator";
import GameStats from "./PlayersDetails/GameStats";
import ScheduleStats from "./PlayersDetails/ScheduleStats";

const PlayerAssistTable = ({
  id,
  page,
  setPage,
  fetchPlayerPreviousGames,
  setEnableScroll,
  playerDetails,
}) => {
  const tab = useTab();
  const { mode } = ThemeState();
  const games = usePlayerGames();
  const navigate = useNavigate();

  const schedule = games?.data?.scheduledGame || {};
  const previousGames = games?.data?.previousGames;
  const hasData = Object.keys(schedule).length > 0;

  const limit = 5;

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchPlayerPreviousGames(skip * limit);
  };

  const handlePrev = () => {
    if (page !== 1) {
      setEnableScroll(true);

      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchPlayerPreviousGames(skip * limit);
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(games?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      fetchPlayerPreviousGames(skip * limit);
    }
  };

  const team = schedule?.homeTeamPlayers?.find((player) => player?._id === id)
    ? "homeTeam"
    : "awayTeam";
  const player = schedule?.homeTeamPlayers?.find((player) => player?._id === id)
    ? "homeTeamPlayers"
    : "awayTeamPlayers";
  const playerDetail = (schedule[player] || [])?.find(
    (player) => player?._id === id
  );

  return (
    <>
      {hasData ? (
        <div
          className={`player_stats_main bg_grey ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div
            className={`player_assets_header bg_grey ${
              mode === "Light" && "light_theme"
            }`}
          >
            {/* Company Logo */}
            <Row>
              <Col md={3} className="header_left_section">
                <h3
                  className={`ps-4 py-2  ${
                    mode === "Light" ? "col-green" : "col-yellow"
                  }`}
                >
                  {tab}
                </h3>
              </Col>
              <Col md={9} className="header_right_section">
                <Row className="align-items-center assets_images">
                  <Col md={2}>
                    <img src="/images/fanduel.png" alt="fanduel sportsbook" />
                  </Col>
                  <Col md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/draft-light.png"
                        style={{ height: "34px" }}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src="/images/draft.png"
                        style={{ height: "34px" }}
                        alt="draftkings sportsbook"
                      />
                    )}
                  </Col>
                  <Col md={2}>
                    <img
                      src="/images/betmgm.png"
                      style={{ height: "40px" }}
                      alt="betmgm sportsbook"
                    />
                  </Col>
                  <Col md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/caesars-light.png"
                        style={{ height: "20px" }}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src="/images/caesars.png"
                        style={{ height: "20px" }}
                        alt="caesars sportbook"
                      />
                    )}
                  </Col>
                  <Col md={2}>
                    <img
                      src="/images/bet365_img.png"
                      style={{ height: "22px" }}
                      // src="/images/bwin_img.png" style={{ height: "22px" }}
                      alt="bet365 bonus code"
                    />
                  </Col>
                  <Col md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/pointsbet-light.png"
                        style={{ height: "46px" }}
                        alt="logo"
                      />
                    ) : (
                      <img
                        src="/images/pointsbet.png"
                        style={{ height: "46px" }}
                        alt="pointsbet sportsbook"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className={`player_assets_body ${
              mode === "Light" && "light_theme"
            }`}
          >
            <ScheduleStats
              id={id}
              playerDetail={playerDetail}
              schedule={schedule}
              player={player}
              team={team}
            />
          </div>
          <div
            className={`player_assets_footer ${
              mode === "Light" && "light_theme"
            }`}
          >
            <Row>
              <Col md={6}>
                <p
                  className="cursor-status"
                  onClick={() =>
                    teamNavigator(
                      schedule[team === "homeTeam" ? "awayTeam" : "homeTeam"],
                      navigate,
                      updateSport
                    )
                  }
                >
                  Opp:{" "}
                  <span>
                    {team !== "homeTeam" && "@"}{" "}
                    {
                      schedule[team === "homeTeam" ? "awayTeam" : "homeTeam"]
                        ?.name
                    }
                  </span>
                </p>
              </Col>
              <Col md={6}>
                {/* <img
                src="/images/calendar.png"
                style={{ width: "15px", height: "15px", marginRight: "5px" }}
              /> */}
                <p>
                  Date: <span>{}</span>
                  {getGameTime(schedule?.dateTimeUTC)}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        
        <TextMessage message={NO_CONTENT_MESSAGE} />
      )}

      {previousGames?.length ? (
        previousGames?.map((game, index) => {
          const player = game?.homeTeamPlayers?.find(
            (player) => player?._id === id
          )
            ? "homeTeamPlayers"
            : "awayTeamPlayers";

          return (
            <div
              key={index}
              className={`player_stats_main mt-4 ${
                mode === "Light" && "light_theme"
              }`}
            >
              <div
                className={`player_assets_header_2 ${
                  mode === "Light" && "light_theme"
                }`}
              >
                <Row>
                  <Col md={4} className="header_left_section">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3
                        className={`ps-4 py-2 ${
                          mode === "Light"
                            ? "col-green fw-bolder"
                            : "col-yellow"
                        }`}
                      >
                        {tab}
                      </h3>
                      <h3
                        className={`pe-4 py-2 ${
                          mode === "Light" ? "text-black fw-bold" : "text-white"
                        }`}
                      >
                        Results
                      </h3>
                    </div>
                  </Col>
                  <Col md={8} className="header_right_section">
                    <Row className="align-items-center assets_images">
                      <Col md={2}>
                        <img
                          src="/images/fanduel.png"
                          alt="fanduel sportsbook"
                        />
                      </Col>
                      <Col md={2}>
                        {mode === "Light" ? (
                          <img
                            src="/images/draft-light.png"
                            style={{ height: "34px" }}
                            alt="company-logo"
                          />
                        ) : (
                          <img
                            src="/images/draft.png"
                            style={{ height: "34px" }}
                            alt="draftkings sportsbook"
                          />
                        )}
                      </Col>
                      <Col md={2}>
                        <img
                          src="/images/betmgm.png"
                          style={{ height: "40px" }}
                          alt="betmgm sportsbook"
                        />
                      </Col>
                      <Col md={2}>
                        {mode === "Light" ? (
                          <img
                            src="/images/caesars-light.png"
                            style={{ height: "20px" }}
                            alt="company-logo"
                          />
                        ) : (
                          <img
                            src="/images/caesars.png"
                            style={{ height: "20px" }}
                            alt="caesars sportbook"
                          />
                        )}
                      </Col>
                      <Col md={2}>
                        <img
                          src="/images/bet365_img.png"
                          style={{ height: "22px" }}
                          // src="/images/bwin_img.png" style={{ height: "22px" }}
                          alt="bet365 bonus code"
                        />
                      </Col>
                      <Col md={2}>
                        {mode === "Light" ? (
                          <img
                            src="/images/pointsbet-light.png"
                            style={{ height: "46px" }}
                            alt="company-logo"
                          />
                        ) : (
                          <img
                            src="/images/pointsbet.png"
                            style={{ height: "46px" }}
                            alt="pointsbet sportsbook"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div
                className={`player_assets_body_2 ${
                  mode === "Light" && "light_theme"
                }`}
              >
                <GameStats
                  id={id}
                  game={game}
                  player={player}
                  playerDetails={playerDetails}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="mt-5">
          <TextMessage message={"No Previous Game"} />
        </div>
      )}
      {!!games?.totalCount > 0 && (
        <div
          className={`mt-5 players_pagination ${
            mode === "Light" && "light_theme"
          }`}
        >
          <PageNumbers
            page={page}
            setPage={setPage}
            handleSetPage={(pageNum) => handleSetPage(pageNum)}
            handlePrev={handlePrev}
            handleNext={handleNext}
            totalRecords={games?.totalCount}
            postsPerPage={5}
          />
        </div>
      )}
    </>
  );
};

export default PlayerAssistTable;
