import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./Footer.css";
import { BsArrowUpCircleFill } from "react-icons/bs";
import { ThemeState } from "../../../Context";
import { updateHeaderTab } from "../../../Redux/Accessors/header.accessors";
import {
  updateSport,
  useSettings,
} from "../../../Redux/Accessors/game.accessors";
import { useUser } from "../../../Redux/Accessors/auth.accessors";
import Games from "../../../Services/Games";
import { errorNotify, successNotify } from "../../../Utils/toast";
import { FB, Twitter } from "../../../Utils/helper";

const Footer = ({ compare }) => {
  const location = useLocation();
  const { mode } = ThemeState();
  const { user } = useUser();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;

  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false);
  const [email, setEmail] = useState("");

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 1200) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 1200) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollTop();
  }, []);

  window.addEventListener("scroll", checkScrollTop);

  const onUpdateTab = (tab) => {
    window.scrollTo(0, 0);
    updateHeaderTab(tab);
    switch (tab) {
      case "HOME":
        navigate("/");
        updateSport("MLB");
        scrollTop();
        if (location.pathname === "/") window.location.reload();
        return;
      case "CONTACT_US":
        navigate("/contact-us");
        return;
      case "ABOUT_US":
      case "COMPARE":
      case "PROMOS":
        navigate(
          tab === "HOME"
            ? "/"
            : tab === "ABOUT_US"
            ? "/about-us"
            : tab === "PROMOS"
            ? "/promos"
            : "/compare"
        );
        tab === "HOME" && updateSport("MLB");
        return;
      case "PREMIUM":
        updateSport(defaultSport);
        navigate("/premium");
        return;
      case "NFL":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nfl");
        return;
      case "NBA":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nba");
        return;
      case "MLB":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/mlb");
        return;
      case "NHL":
        updateSport(tab);
        location.pathname !== "/premium" && navigate("/nhl");
        return;
      default:
        navigate("/");
        return;
    }
  };

  const handleNewsLetter = async () => {
    if (!email) {
      errorNotify("Please enter email address.");
      return;
    }

    const formData = {
      email,
      subject: "News Letter",
    };

    const sendMail = await Games.sendMail(formData);

    if (sendMail.data.data?.success) {
      setEmail("");
      successNotify(sendMail?.data.message);
    } else {
      errorNotify("Email not sent! Please try again.");
    }
  };

  const handleNavigate = () => {
    window.location.href = "/";
  };

  return (
    <>
      <div className={`footer-container ${mode === "Light" && "light_theme"}`}>
        {/* ${
          compare && "compare_footer"
        } */}
        <Container className="pt-5">
          <Row>
            <Col md={4}>
              <div className="footer-about">
                {mode === "Light" ? (
                  <img
                    src="/images/light_logo.png"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavigate()}
                  />
                ) : (
                  <img
                    src="/images/logo.png"
                    alt="betting predictions"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavigate()}
                  />
                )}
                <p
                  className={`py-4 ${
                    mode === "Light" ? "fw-normal text-black" : "text-white"
                  } fs-14`}
                >
                  Disclaimer: This site is for entertainment purposes only, and
                  there is no gambling offered on the site. This service is
                  intended for adult audiences. No guarantees are made for any
                  specific outcome.
                </p>
              </div>
            </Col>
            <Col md={2}>
              <h3
                className={` ${mode === "Light" ? "fw-bold text-black" : ""}`}
              >
                QUICK LINKS
              </h3>
              <div className="py-3 footer-links ">
                <p onClick={() => onUpdateTab("HOME")}>HOME</p>
                <p onClick={() => onUpdateTab("NFL")}>NFL</p>
                <p onClick={() => onUpdateTab("NBA")}> NBA</p>
                <p onClick={() => onUpdateTab("MLB")}>MLB</p>
                <p onClick={() => onUpdateTab("NHL")}>NHL</p>
                <p onClick={() => onUpdateTab("COMPARE")}>COMPARE</p>
                <p onClick={() => onUpdateTab("PREMIUM")}>PREMIUM</p>
              </div>
            </Col>
            <Col md={3}>
              <h3
                className={` ${mode === "Light" ? "fw-bold text-black" : ""}`}
              >
              <a href={`/sitemap.xml`}>
                SITEMAP
                </a>
              </h3>
              <div className="py-3 footer-links ">
                {/* <p>ADVERTISE WITH US</p> */}
                <p onClick={() => onUpdateTab("CONTACT_US")}>CONTACT US</p>
                <p onClick={() => onUpdateTab("ABOUT_US")}>ABOUT US</p>
                <p onClick={() => onUpdateTab("PROMOS")}>PROMOS</p>
                {!user && <p onClick={() => navigate("/signup")}>SIGN UP</p>}
                {!user && <p onClick={() => navigate("/login")}>LOG IN</p>}
              </div>
            </Col>
            <Col md={3}>
              <h3
                className={` ${mode === "Light" ? "fw-bold text-black" : ""}`}
              >
                NEWSLETTER
              </h3>
              <div className="py-3">
                <p
                  className={` ${
                    mode === "Light" ? "fw-normal text-black" : "text-white"
                  } mb-2`}
                >
                  Player Totals updates and promotions
                </p>
                <div className="news-letter">
                  <input
                    placeholder="Email address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <img
                    src="/images/paper-plane.png"
                    style={{ cursor: "pointer" }}
                    alt=""
                    onClick={handleNewsLetter}
                  />
                </div>

                <h3
                  className={`pt-3 pb-2 ${
                    mode === "Light" ? "text-black fw-bolder" : "text-white"
                  }`}
                >
                  FOLLOW US
                </h3>
                <div className="social-connect d-flex">
                  <div>
                    <a href={FB} target="_blank" rel="noreferrer">
                      {mode === "Light" ? (
                        <img src="/images/facebook-light.png" alt="" />
                      ) : (
                        <img src="/images/fb.png" alt="" />
                      )}
                    </a>
                  </div>
                  <div>
                    <a
                      href={Twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {mode === "Light" ? (
                        <img src="/images/twitter-light.png" alt="" />
                      ) : (
                        <img src="/images/twitter.png" alt="" />
                      )}{" "}
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/playerprops"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {mode === "Light" ? (
                        <img src="/images/instagram-light.png" alt="" />
                      ) : (
                        <img src="/images/instagram.png" alt="" />
                      )}{" "}
                    </a>
                  </div>
                </div>
                <p
                  className={`mt-4 advertise_with_us ${
                    mode === "Light" ? "light_theme" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/advertise")}
                >
                  ADVERTISE WITH US
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <hr
          style={{
            color:
              mode === "Light" && !compare
                ? "#fff"
                : mode === "Light" && compare
                ? "#000"
                : "#ffffff52",
          }}
        />
        <Container>
          <Row>
            <div className="d-flex justify-content-center pb-3 copyrights">
              <p
                className={`${
                  mode === "Light" ? "fw-bold text-black" : "text-white"
                }`}
              >
                @{new Date().getUTCFullYear()} Player Totals LLC. All rights
                reserved
              </p>
            </div>
          </Row>
        </Container>
      </div>
      <div>
        <BsArrowUpCircleFill
          className={`scrollTop ${mode === "Light" && "light_theme"}`}
          onClick={scrollTop}
          id="scrollToTop"
          style={{ display: showScroll ? "flex" : "none" }}
        />
      </div>
    </>
  );
};

export default Footer;
