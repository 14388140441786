import React from 'react'
import Modal from "../SiteModal"
import { Spinner } from 'react-bootstrap'

const DeleteModal = ({show,setShow,onDeleteSubmit,loading}) => {
  const onCloseModal=()=>{
    setShow(false)
  }
  return (
    <>
    <Modal
        show={show}
        setShow={setShow}
        onCloseModal={onCloseModal}
      >
        <div className="edit_value_modal">
          <div style={{ width: "90%" }}>
              <h6 className='text-center text-white' style={{fontSize:"18px"}}>Are you sure you want to delete this user?</h6>
              <div className="text-end d-flex gap-2 justify-content-end">
                <button style={{padding:"6px 15px",backgroundColor:"#e91c24"}} type="button" disabled={loading} onClick={()=>onCloseModal()}>
                  Cancel
                </button>
                <button style={{padding:"6px 15px"}} type="submit" disabled={loading} onClick={onDeleteSubmit}>
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "SUBMIT"
                  )}
                </button>

              </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeleteModal