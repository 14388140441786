import React, { useState, useEffect } from "react";
import BorderLine from "../../Components/Players/BorderLine";
import CouponsSlider from "../../Components/Home/CouponsSlider/CouponsSlider";
import PlayersDetails from "../../Components/Players/PlayersDetails/PlayersDetails";
import Footer from "../../Components/Layout/Footer/Footer";
import { ThemeState } from "../../Context";
import { useParams } from "react-router-dom";
import Games from "../../Services/Games";
import {
  updatePlayerMatches,
  updatePosition,
  usePlayerGames,
} from "../../Redux/Accessors/previousGames.accessors";
import Loader from "../../Common/Loader";
import {
  updateSport,
  updateTab,
  useSport,
  useTab,
} from "../../Redux/Accessors/game.accessors";
import PlayerHeader from "../../Components/Players/PlayersDetails/PlayerHeader";
import Notifications from "../../Components/Layout/Notification/Notifications";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import MobilePointsTableHeader from "../../Components/Home/PointsTableHeader/MobilePointsTableHeader";
import PlayerMobileAssistsTable from "../../Components/Players/PlayerMobileAssistsTable";
import MobilePremiumCard from "../../Components/Home/PremiumCard/MobilePremiumCard";
import GoPremium from "../../Components/Home/PremiumCard/GoPremium";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import User from "../../Services/User";
import {
  updateFavourite,
  updateGameNotifications,
} from "../../Redux/Accessors/auth.accessors";
import Notification from "../../Services/Notification";
import { updateSportTab } from "../../Utils/tabs";
import PageWrapper from "../../Common/PageWrapper";
import { updateHeaderTab } from "../../Redux/Accessors/header.accessors";

const Players = () => {
  const { slug, sport: playerSport } = useParams();
  console.log(slug, "slug");
  console.log(playerSport, "sport");
  const sport = useSport();
  const { mode } = ThemeState();
  const playerGames = usePlayerGames();
  const tab = useTab();

  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [games, setGames] = useState(playerGames || null);
  const [isFavoriteFetched, setIsFavoriteFetched] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [onUpdate, setOnUpdate] = useState(false);
  const [enableScroll, setEnableScroll] = useState(false);
  const [teamKey, setTeamKey] = useState("");

  const schedule = games?.data?.scheduledGame || {};
  const playerDetails = games?.data?.playerDetails;

  // const teamKey = schedule?.awayTeam?._id === id ? "awayTeam" : "homeTeam";
  // console.log(teamKey,"teamKey")
  const fetchPlayerBySlug = async () => {
    try {
      const res = await Games.getPlayerBySlug(slug);
      setId(res?.data?.data?._id);
      updateSport(res?.data?.data?.sport);
      setTeamKey(
        schedule?.awayTeam?._id === res?.data?.data?.team
          ? "awayTeam"
          : "homeTeam"
      );
      // console.log(schedule?.awayTeam?._id, res?.data?.data?.team);
    } catch (error) {
      console.error("An error occurred while fetching player details:", error);
    }
  };

  const fetchPlayerPreviousGames = async (skip = 0) => {
    try {
      window.scrollTo(0, 0);
      setIsLoading(true);
      const res = await Games.playerGames(slug, skip, playerSport);
      updatePlayerMatches(res?.data);
      setGames(res?.data);
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching player games:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFavouritePlayer = async () => {
    try {
      const res = await User.getFavouritePlayer();
      updateFavourite(res?.data?.data);
    } catch (error) {
      console.log("Error in getting favourite player", error);
    } finally {
      setIsFavoriteFetched(true);
    }
  };

  const fetchPlayerNotifications = async () => {
    try {
      const res = await Notification.getPlayerNotifications(
        id,
        playerSport,
        0,
        50
      );
      updateGameNotifications(res?.data?.data);
      setNotificationsData(res?.data?.data);
    } catch (error) {
      console.error(
        "An error occurred while fetching player notifications:",
        error
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateHeaderTab("PLAYER");
  }, []);

  useEffect(() => {
    fetchPlayerPreviousGames();
    fetchPlayerBySlug();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, playerSport, id, slug]);

  useEffect(() => {
    getFavouritePlayer();
    if (playerDetails) {
      updateSport(playerDetails?.sport);
      updateSportTab(playerDetails?.sport, updateTab, tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, playerSport, id, slug]);

  useEffect(() => {
    if (!isFavoriteFetched) return;
    fetchPlayerNotifications();

    const intervalId = setInterval(fetchPlayerNotifications, 5 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug, isFavoriteFetched, sport, playerSport, onUpdate]);

  useEffect(() => {
    updatePosition(playerDetails?.position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, slug]);

  return (
    <PageWrapper
      enableScroll={enableScroll}
      setEnableScroll={setEnableScroll}
      id={id}
      tabTitle={
        !!playerDetails &&
        `${playerDetails?.firstName} ${playerDetails?.lastName}`
      }
    >
      <div className="home_desktop">
        <BorderLine />
        <CouponsSlider />
        <div style={{ minHeight: "400px" }}>
          {!isLoading ? (
            <PlayersDetails
              id={id}
              page={page}
              setPage={setPage}
              loading={isLoading}
              position={playerDetails?.position}
              notificationsData={notificationsData}
              onUpdate={onUpdate}
              setOnUpdate={setOnUpdate}
              fetchPlayerPreviousGames={(skip) =>
                fetchPlayerPreviousGames(skip)
              }
              setEnableScroll={setEnableScroll}
              teamKey={teamKey}
            />
          ) : (
            <div
              style={{
                minHeight: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
        <Footer />
      </div>
      <div className="home_mobile">
        <BorderLine />
        <MobileHeader bgWhite />
        <CouponsSlider />
        {!isLoading ? (
          <>
            <div
              className={`players_details_main ${
                mode === "Light" && "light_theme"
              }`}
            >
              <PlayerHeader
                isLoading={isLoading}
                player={playerDetails}
                team={playerDetails?.team}
              />
            </div>
            <MobilePointsTableHeader
              position={playerDetails?.position}
              id={id}
            />

            <PlayerMobileAssistsTable
              id={id}
              page={page}
              setPage={setPage}
              fetchPlayerPreviousGames={(skip) =>
                fetchPlayerPreviousGames(skip)
              }
              setEnableScroll={setEnableScroll}
              playerDetails={playerDetails}
            />
          </>
        ) : (
          <Loader />
        )}
        <Notifications
          page="player"
          data={notificationsData}
          onUpdate={onUpdate}
          setOnUpdate={setOnUpdate}
        />
        <MobilePremiumCard
          onlyOne
          search
          id={id}
          team={playerDetails?.team}
          players={[]}
          playerDetails={playerDetails}
          schedule={schedule}
          tab="playerPremium"
        />
        <GoPremium />
        <MobileFooter />
      </div>
    </PageWrapper>
  );
};

export default Players;
