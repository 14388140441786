import React, { useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import Slider from "react-slick";
import { ThemeState } from "../../../Context";
import { getHourMonth, getScheduleTime } from "../../../Utils/dates";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { updateSport, useSport } from "../../../Redux/Accessors/game.accessors";
import {
  updateHeaderTab,
  useHeaderTab,
} from "../../../Redux/Accessors/header.accessors";
import moment from "moment";
import { teamNavigator } from "../../../Utils/navigator";
import { NO_CONTENT_MESSAGE } from "../../../Constants";

const sportsMenu = ["NFL", "NBA", "MLB", "NHL"];

const MobileMatchSlider = ({
  slide,
  handleSlider,
  scheduleGames,
  premium,
  isPremiumPage,
  week,
}) => {
  const { mode } = ThemeState();
  const sport = useSport();
  const navigate = useNavigate();
  const location = useLocation();
  const headerTab = useHeaderTab();

  const gamesWithDate = {};

  if (sport === "NFL") {
    scheduleGames?.forEach((game, index) => {
      const dateKey = moment(game.dateTimeUTC).format("D, MMMM, dddd, YYYY");
      if (!gamesWithDate[dateKey]) {
        gamesWithDate[dateKey] = [];
      }
      gamesWithDate[dateKey].push({ ...game, id: index });
      // gamesWithDate[dateKey] = [...gamesWithDate[dateKey], game];
    });
  }

  // eslint-disable-next-line no-unused-vars
  const [selectedSport, setselectedSport] = useState(sport);
  const [isSportShow, setisSportShow] = useState(false);
  const [show, setShow] = useState(false);

  const sliderRef = useRef(null);

  function CustomPrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;

    return (
      <div
        className={`arrow_container  ${className}`}
        onClick={() => {
          onClick();
          handleSlider(currentSlide - 1);
        }}
      >
        <div
          className={`custom_slick_prev`}
          style={{
            ...style,
          }}
        />
      </div>
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <div
        className={`arrow_container ${className}`}
        onClick={() => {
          onClick();
          handleSlider(currentSlide + 1);
        }}
      >
        <div
          className={`custom_slick_next`}
          style={{
            ...style,
          }}
        />
      </div>
    );
  }

  const handleMatchClick = (index) => {
    console.log("indexx", index);
    handleSlider(index); // Update the slide value
    sliderRef.current.slickGoTo(index); // Move the carousel to the selected slide
    setShow(false);
  };

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slide,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const getSportLogo = (sport) => {
    switch (sport) {
      case "NBA":
        return "/images/NBA.png";
      case "MLB":
        return "/images/mlb-slider-logo.png";
      case "NFL":
        return "/images/nfl-slider-logo.png";
      case "NHL":
        return "/images/NBA.png";
      default:
        return "/images/NBA.png";
    }
  };

  const getSignValue = (value) => {
    if (!value) return "";
    if (value === 0 || value < 0) return value;
    return `+${value}`;
  };

  const isShowPregameOds = (sport) => {
    if (sport === "MLB") return false;
    return true;
  };

  return (
    <div
      className={`mobile_match_slider_main ${premium ? "premium" : ""} ${
        mode === "Light" && "light_theme"
      }`}
    >
      <Dropdown show={show} onToggle={() => setShow(!show)}>
        <div
          className={`mobile_match_slider ${premium ? "premium" : ""} ${
            mode === "Light" && "light_theme"
          }`}
        >
          <Slider
            ref={sliderRef}
            {...settings}
            aria-hidden="false"
            afterChange={(currentSlide) => {
              handleMatchClick(currentSlide);
            }}
          >
            {scheduleGames?.length ? (
              scheduleGames.map((game, index) => (
                <div key={index}>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <span
                      className="d-flex align-items-center"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => setisSportShow(!isSportShow)}
                    >
                      <img
                        style={{ maxWidth: "130px" }}
                        src={getSportLogo(sport)}
                        alt="logo"
                      />
                      <BiChevronDown size={25} color="#feb91a" />
                    </span>
                  </div>
                  {/* <img
                    src={getSportLogo(game?.sport)}
                    style={{ width: "130px" }}
                    alt="logo"
                  /> */}
                  <div className="mobile_teams_container">
                    <div
                      className={`${
                        mode === "Light" ? "text-white" : "text-white"
                      }`}
                    >
                      <h3
                        onClick={() =>
                          teamNavigator(game?.awayTeam, navigate, updateSport)
                        }
                      >
                        {game?.awayTeam?.city}
                      </h3>
                      <h3
                        onClick={() =>
                          teamNavigator(game?.awayTeam, navigate, updateSport)
                        }
                      >
                        {game?.awayTeam?.name}
                      </h3>
                      <div>
                        <img
                          src={game?.awayTeam?.wikipediaLogoUrl}
                          style={{ width: "55px", height: "55px" }}
                          alt="logo"
                        />
                      </div>

                      <p>
                        {!!isShowPregameOds(game?.sport) && getSignValue("")}{" "}
                        {getSignValue(
                          game?.sport === "NFL"
                            ? game?.awayPointSpread
                            : game?.awayMoneyLine
                        )}
                      </p>
                    </div>
                    <div className="">
                      <h3
                        className={`${
                          mode === "Light" ? "text-white" : "text-white"
                        } pb-1`}
                      >
                        @
                      </h3>
                      <p>
                        {!!isShowPregameOds(game?.sport) && getSignValue("")}
                      </p>
                      {game?.overUnder && <p>O/U {game?.overUnder}</p>}
                    </div>
                    <div
                      className={`${
                        mode === "Light" ? "text-white" : "text-white"
                      }`}
                    >
                      <h3
                        onClick={() =>
                          teamNavigator(game?.homeTeam, navigate, updateSport)
                        }
                      >
                        {game?.homeTeam?.city}
                      </h3>
                      <h3
                        onClick={() =>
                          teamNavigator(game?.homeTeam, navigate, updateSport)
                        }
                      >
                        {game?.homeTeam?.name}
                      </h3>
                      <div>
                        <img
                          src={game?.homeTeam?.wikipediaLogoUrl}
                          style={{ width: "55px", height: "55px" }}
                          alt="icon"
                        />
                      </div>
                      <p>
                        {!!isShowPregameOds(game?.sport) && getSignValue("")}{" "}
                        {getSignValue(
                          game?.sport === "NFL"
                            ? game?.homePointSpread
                            : game?.homeMoneyLine
                        )}
                      </p>
                    </div>
                  </div>
                  <div style={{ padding: "0px 20px" }}>
                    <hr
                      style={{ color: mode === "Light" ? "white" : "white" }}
                    />
                    <div className="match_schedule text-center">
                      <h3>{getScheduleTime(game?.dateTimeUTC)}</h3>
                      {!isPremiumPage && (
                        <div className="d-flex align-items-end justify-content-center">
                          <Dropdown.Toggle className="match_schedule text-center">
                            <div className="d-flex align-items-center">
                              <p>
                                {sport === "NFL"
                                  ? `Week ${week}`
                                  : "Complete Schedule"}
                              </p>
                              <BiChevronDown />
                            </div>
                          </Dropdown.Toggle>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-white">{NO_CONTENT_MESSAGE}</p>
            )}
          </Slider>
        </div>

        {!!isSportShow && (
          <div className="home_scheduler_sports_dropdown">
            {sportsMenu?.map((sport, index) => (
              <p
                key={index}
                onClick={() => {
                  setselectedSport(sport);
                  updateSport(sport);
                  updateHeaderTab(sport);
                  if (
                    location.pathname === "/premium" ||
                    location.pathname === `/premium/${headerTab.toLowerCase()}`
                  ) {
                    navigate(`/premium/${sport.toLowerCase()}`);
                  } else {
                    navigate(`/${sport.toLowerCase()}`);
                  }
                  setisSportShow(false);
                }}
              >
                {sport}
              </p>
            ))}
          </div>
        )}

        <Dropdown.Menu
          className="main_dropdown home_scheduler_dropdown"
          align="end"
        >
          {sport === "NFL" ? (
            <div className="nfl-schedules">
              {Object.entries(gamesWithDate).map(([dateKey, scheduleGames]) => (
                <div key={dateKey}>
                  <p className="nfl-weeks">{dateKey}</p>
                  {scheduleGames?.length &&
                    scheduleGames?.map((game) => {
                      return (
                        <div
                          className="mlb-schedules"
                          key={game?.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleMatchClick(game?.id)}
                        >
                          <span>
                            <div
                              className={`${
                                game?.awayTeam?.name === "Pirates" &&
                                "team_logo_bg"
                              }`}
                            >
                              <img
                                src={game?.awayTeam?.wikipediaLogoUrl}
                                alt="logo"
                              />
                            </div>
                          </span>
                          <span>{game?.awayTeam?.name}</span>

                          <span>@</span>
                          <span>
                            <div
                              className={`${
                                game?.homeTeam?.name === "Pirates" &&
                                "team_logo_bg"
                              }`}
                            >
                              <img
                                src={game?.homeTeam?.wikipediaLogoUrl}
                                alt="logo"
                              />
                            </div>
                          </span>
                          <span>{game?.homeTeam?.name}</span>
                          <span>{getHourMonth(game?.dateTimeUTC)}</span>
                        </div>
                      );
                    })}
                </div>
              ))}
            </div>
          ) : (
            <div>
              {scheduleGames?.length &&
                scheduleGames?.map((game, index) => (
                  <div
                    className="mlb-schedules"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMatchClick(index)}
                  >
                    <span>
                      <div
                        className={`${
                          game?.awayTeam?.name === "Pirates" && "team_logo_bg"
                        }`}
                      >
                        <img
                          src={game?.awayTeam?.wikipediaLogoUrl}
                          alt="logo"
                        />
                      </div>
                    </span>
                    <span>{game?.awayTeam?.name}</span>

                    <span>@</span>
                    <span>
                      <div
                        className={`${
                          game?.homeTeam?.name === "Pirates" && "team_logo_bg"
                        }`}
                      >
                        <img
                          src={game?.homeTeam?.wikipediaLogoUrl}
                          alt="logo"
                        />
                      </div>
                    </span>
                    <span>{game?.homeTeam?.name}</span>
                    <span>{getHourMonth(game?.dateTimeUTC)}</span>
                  </div>
                ))}
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MobileMatchSlider;
