import moment from "moment";
import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BiChevronDown } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ThemeState } from "../../../Context";
import { updateSport, useSport } from "../../../Redux/Accessors/game.accessors";
import {
  updateHeaderTab,
  useHeaderTab,
} from "../../../Redux/Accessors/header.accessors";
import { getHourMonth, getScheduleTime } from "../../../Utils/dates";
import { teamNavigator } from "../../../Utils/navigator";
import "./MatchSlider.css";
import { NO_CONTENT_MESSAGE } from "../../../Constants";

const sportsMenu = ["NFL", "NBA", "MLB", "NHL"];

const MatchSlider = ({
  slide,
  handleSlider,
  scheduleGames,
  premium,
  isPremiumPage,
  week,
}) => {
  const { mode } = ThemeState();
  const navigate = useNavigate();
  const location = useLocation();
  const headerTab = useHeaderTab();
  const sport = useSport();
  const gamesWithDate = {};

  if (sport === "NFL") {
    scheduleGames?.forEach((game, index) => {
      const dateKey = moment(game.dateTimeUTC).format("dddd, MMMM, D, YYYY");
      if (!gamesWithDate[dateKey]) {
        gamesWithDate[dateKey] = [];
      }
      gamesWithDate[dateKey].push({ ...game, id: index });
      // gamesWithDate[dateKey] = [...gamesWithDate[dateKey], game];
    });
  }

  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedSport, setselectedSport] = useState(sport);
  const [isSportShow, setisSportShow] = useState(false);

  const sliderRef = useRef(null);

  function CustomPrevArrow(props) {
    const { className, style, onClick, currentSlide } = props;

    return (
      <div
        className={`${className} custom_slick_prev`}
        style={{
          ...style,
        }}
        onClick={() => {
          onClick();
          handleSlider(currentSlide);
        }}
      />
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick, currentSlide } = props;
    return (
      <div
        className={`${className} custom_slick_next`}
        style={{
          ...style,
        }}
        onClick={() => {
          onClick();
          handleSlider(currentSlide);
        }}
      />
    );
  }

  const handleMatchClick = (index) => {
    handleSlider(index); // Update the slide value
    sliderRef.current.slickGoTo(index); // Move the carousel to the selected slide
    setShow(false);
  };

  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: slide,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const getSportLogo = (sport) => {
    switch (sport) {
      case "NBA":
        return "/images/NBA.png";
      case "MLB":
        return "/images/mlb-slider-logo.png";
      case "NFL":
        return "/images/nfl-slider-logo.png";
      case "NHL":
        return "/images/NBA.png";
      default:
        return "/images/NBA.png";
    }
  };

  const getSignValue = (value) => {
    if (!value) return "";
    if (value === 0 || value < 0) return value;
    return `+${value}`;
  };

  const isShowPregameOds = (sport) => {
    if (sport === "MLB") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={`match_slider_main ${mode === "Light" && "light_theme"}`}>
      <Dropdown show={show} onToggle={() => setShow(!show)}>
        <div
          className={`match_slider ${premium ? "premium" : ""} ${
            mode === "Light" && "light_theme"
          }`}
        >
          <Slider
            ref={sliderRef}
            {...settings}
            aria-hidden="false"
            afterChange={(currentSlide) => {
              handleMatchClick(currentSlide);
            }}
          >
            {scheduleGames?.length ? (
              scheduleGames?.map((game, index) => (
                <div key={index} className="d-flex align-items-center">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div
                      className="league_name"
                      style={{
                        position: "relative",
                      }}
                    >
                      <span
                        className="d-flex align-items-center"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => setisSportShow(!isSportShow)}
                      >
                        <img
                          style={{ maxWidth: "120px" }}
                          src={getSportLogo(sport)}
                          alt="logo"
                        />
                        <BiChevronDown size={25} color="#feb91a" />
                      </span>
                    </div>
                    <div
                      className={`teams_container ${
                        mode === "Light" && "light_theme"
                      }`}
                    >
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          teamNavigator(game?.awayTeam, navigate, updateSport)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="me-5">
                          <h3>{game?.awayTeam?.city}</h3>
                          <h3>{game?.awayTeam?.name}</h3>
                          <p>
                            {!!isShowPregameOds(game?.sport) &&
                              getSignValue("")}{" "}
                            {getSignValue(
                              game?.sport === "NFL"
                                ? game?.awayPointSpread
                                : game?.awayPointSpread
                            )}
                          </p>
                        </div>
                        <div>
                          <img
                            src={game?.awayTeam?.wikipediaLogoUrl}
                            alt="logo"
                          />
                        </div>
                      </div>
                      <div className=" px-1 vs_container">
                        <h3>@</h3>
                        <p>
                          {!!isShowPregameOds(game?.sport) && getSignValue("")}
                        </p>
                        {game?.overUnder && <p>O/U {game?.overUnder}</p>}
                      </div>
                      <div
                        className="d-flex align-items-center"
                        onClick={() =>
                          teamNavigator(game?.homeTeam, navigate, updateSport)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="me-4">
                          <img
                            src={game?.homeTeam?.wikipediaLogoUrl}
                            alt="logo"
                          />
                        </div>
                        <div>
                          <h3>{game?.homeTeam?.city}</h3>
                          <h3>{game?.homeTeam?.name}</h3>
                          <p>
                            {!!isShowPregameOds(game?.sport) &&
                              getSignValue("")}{" "}
                            {getSignValue(
                              game?.sport === "NFL"
                                ? game?.homePointSpread
                                : game?.homePointSpread
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="ms-2 match_schedule text-center text-white">
                      <h3>{getScheduleTime(game?.dateTimeUTC)}</h3>
                      {!isPremiumPage && (
                        <div className="d-flex align-items-end justify-content-center">
                          <Dropdown.Toggle className="match_schedule text-center">
                            <div className="d-flex align-items-center">
                              <p>
                                {sport === "NFL"
                                  ? `Week ${week}`
                                  : "Complete Schedule"}
                              </p>
                              <BiChevronDown />
                            </div>
                          </Dropdown.Toggle>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : headerTab !== "NHL" ? (
              <p className="text-center text-white"> {NO_CONTENT_MESSAGE} </p>
            ):
            <p className="text-center text-white">NHL Coming Soon... </p>

            }
          </Slider>
        </div>
        {!!isSportShow && (
          <div className="home_scheduler_sports_dropdown">
            {sportsMenu?.map((sport, index) => (
              <p
                key={index}
                onClick={() => {
                  setselectedSport(sport);
                  updateSport(sport);
                  updateHeaderTab(sport);
                  setisSportShow(false);
                  if (
                    location.pathname === "/premium" ||
                    location.pathname === `/premium/${headerTab.toLowerCase()}`
                  ) {
                    navigate(`/premium/${sport.toLowerCase()}`);
                  } else {
                    navigate(`/${sport.toLowerCase()}`);
                  }
                }}
              >
                {sport}
              </p>
            ))}
          </div>
        )}
        <Dropdown.Menu
          className="main_dropdown home_scheduler_dropdown"
          align="end"
        >
          {sport === "NFL" ? (
            <div className="nfl-schedules">
              {Object.entries(gamesWithDate).map(([dateKey, scheduleGames]) => (
                <div key={dateKey}>
                  <p className="nfl-weeks">{dateKey}</p>
                  {scheduleGames?.length &&
                    scheduleGames?.map((game) => (
                      <div
                        className="mlb-schedules"
                        key={game?.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleMatchClick(game?.id)}
                      >
                        <span>
                          <div
                            className={`${
                              game?.awayTeam?.name === "Pirates" &&
                              "team_logo_bg"
                            }`}
                          >
                            <img
                              src={game?.awayTeam?.wikipediaLogoUrl}
                              alt="logo"
                            />
                          </div>
                        </span>
                        <span>{game?.awayTeam?.name}</span>

                        <span>@</span>
                        <span>
                          <div
                            className={`${
                              game?.homeTeam?.name === "Pirates" &&
                              "team_logo_bg"
                            }`}
                          >
                            <img
                              src={game?.homeTeam?.wikipediaLogoUrl}
                              alt="logo"
                            />
                          </div>
                        </span>
                        <span>{game?.homeTeam?.name}</span>
                        <span>{getHourMonth(game?.dateTimeUTC)}</span>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          ) : (
            <div>
              {scheduleGames?.length &&
                scheduleGames?.map((game, index) => (
                  <div
                    className="mlb-schedules"
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMatchClick(index)}
                  >
                    <span>
                      <div
                        className={`${
                          game?.awayTeam?.name === "Pirates" && "team_logo_bg"
                        }`}
                      >
                        <img
                          src={game?.awayTeam?.wikipediaLogoUrl}
                          alt="logo"
                        />
                      </div>
                    </span>
                    <span>{game?.awayTeam?.name}</span>

                    <span>@</span>
                    <span>
                      <div
                        className={`${
                          game?.homeTeam?.name === "Pirates" && "team_logo_bg"
                        }`}
                      >
                        <img
                          src={game?.homeTeam?.wikipediaLogoUrl}
                          alt="logo"
                        />
                      </div>
                    </span>
                    <span>{game?.homeTeam?.name}</span>
                    <span>{getHourMonth(game?.dateTimeUTC)}</span>
                  </div>
                ))}
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MatchSlider;
