import { useEffect, useState } from 'react';

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    }

    // Run once to initialise value
    handleResize();

    // Update on window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile, isDesktop: !isMobile };
}

export default useDeviceDetect;
