import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ThemeProvider from "./Context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/store";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ReactPWAInstallProvider from "react-pwa-install";
import { ScrollToTop } from "./Common/ScrollTop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ReactPWAInstallProvider enableLogging>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <ScrollToTop />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </ReactPWAInstallProvider>
  // </React.StrictMode>
);

reportWebVitals();
// eslint-disable-next-line no-undef
serviceWorkerRegistration.register();
