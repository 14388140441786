import { Formik } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { forgetPasswordSchema, forgetSchema, otpSchema } from "../../Schemas";
import { saveAuth } from "../../Redux/Accessors/auth.accessors";
import User from "../../Services/User";
import { onError, onSuccess } from "../../Utils/toast";
import { useNavigate } from "react-router-dom";
import { saveToken } from "../../Utils/token";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "./OtpModal.css";

const OtpModal = ({ show, setShow, selectedType }) => {
  const [type, setType] = useState("otp");
  const navigate = useNavigate();
  const tempUser = localStorage.getItem("tempUser");
  const loginType = localStorage.getItem("loginType");
  const [isLoading, setIsLoading] = useState(false);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);

  const Loader = () => {
    return (
      <img
        src="/images/loader.gif"
        alt="loader"
        style={{ width: "25px", height: "25px" }}
      />
    );
  };

  const verifyOtp = async (values) => {
    setIsLoading(true);
    try {
      const parsedUser = JSON.parse(tempUser);
      const data = {
        email: loginType === "Email" ? parsedUser.user.email : "",
        phone: loginType === "Phone" ? parsedUser.user.phone : "",
        otp: values.otp,
      };

      await User.verifyOtp(data);
      if (selectedType === "forget") {
        setType("password");
      } else {
        saveToken(parsedUser?.token);
        saveAuth(parsedUser);
        navigate("/user/profile");
      }
      if (parsedUser.user.firstName && parsedUser.user.lastName) {
        onSuccess(
          `Welcome ${parsedUser.user.firstName} ${parsedUser.user.lastName}!`
        );
      } else onSuccess("Otp Successfully Verified!");
    } catch (error) {
      onError(error?.response?.data?.errorResponse?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const sendOtp = async (values) => {
    setSendOtpLoading(true);
    try {
      const parsedTempUser = JSON.parse(tempUser);
      const parsedUser = values ? values : { ...parsedTempUser.user };
      const data = {
        email: loginType === "Email" ? parsedUser.email : "",
        phone: loginType === "Phone" ? parsedUser.phone : "",
      };
      await User.sendOtp(data);
      if (type === "forget") {
        localStorage.setItem("tempUser", JSON.stringify({ user: values }));
        setType("otp");
      }
      onSuccess("Otp send Successfully!");
    } catch (error) {
      onError(error?.response?.data?.errorResponse?.message || error.message);
    } finally {
      setSendOtpLoading(false);
    }
  };

  const updatePassword = async (values) => {
    setIsLoading(true);
    try {
      const parsedUser = JSON.parse(tempUser);
      const data = {
        email: loginType === "Email" ? parsedUser.user.email : "",
        phone: loginType === "Phone" ? parsedUser.user.phone : "",
        ...values,
      };
      await User.forgetPassword(data);
      setShow(false);
      onSuccess("Password Successfully Updated!");
    } catch (error) {
      onError(error?.response?.data?.errorResponse?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedType) setType(selectedType);
  }, [selectedType]);

  return (
    <Modal show={show} onHide={setShow} size="md" centered>
      <div className="profile_form_section otp-modal-body">
        <div className="close-btn">
          <img
            src="/images/close-btn.png"
            alt="close"
            style={{ width: 25, height: 25 }}
            onClick={() => setShow(false)}
          />
        </div>
        {type === "forget" && (
          <Formik
            initialValues={{
              email: "",
              phone: "",
              loginType: loginType,
            }}
            onSubmit={async (values) => {
              await sendOtp(values);
            }}
            validationSchema={forgetSchema}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
              values,
              errors,
              ...props
            }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    {loginType === "Email" ? (
                      <>
                        <label className="text-white">Enter email</label>
                        <input
                          type="text"
                          name="email"
                          className="otp-modal-input"
                          placeholder="Email"
                          disabled={sendOtpLoading}
                          value={values?.email}
                          onChange={handleChange}
                        />
                        <p className="error">{errors?.email}</p>
                      </>
                    ) : (
                      <>
                        <label className="text-white">Enter phone</label>
                        <PhoneInput
                          international
                          defaultCountry="US"
                          limitMaxLength="20"
                          className="otp-modal-input"
                          disabled={sendOtpLoading}
                          value={values.phone}
                          onChange={(phone) => setFieldValue("phone", phone)}
                        />
                        <p className="error">{errors?.phone}</p>
                      </>
                    )}
                  </Col>

                  <Col md={12} className="d-flex justify-content-end">
                    <button
                      disabled={sendOtpLoading}
                      className="save_btn"
                      type="submit"
                    >
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        Next
                        {sendOtpLoading ? <Loader /> : null}
                      </div>
                    </button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        )}
        {type === "otp" && (
          <Formik
            initialValues={{
              otp: "",
            }}
            onSubmit={async (values, { resetForm }) => {
              await verifyOtp(values);
            }}
            validationSchema={otpSchema}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
              values,
              errors,
              ...props
            }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <label className="text-white">
                      Enter OTP you received on email/text
                    </label>
                    <input
                      type="number"
                      name="otp"
                      placeholder="Otp"
                      className="otp-modal-input"
                      disabled={sendOtpLoading || isLoading}
                      min={0}
                      value={values?.otp}
                      onChange={handleChange}
                    />
                    <p className="error">{errors?.otp}</p>
                  </Col>

                  <Col md={12} className="d-flex justify-content-end">
                    <button
                      className="save_btn me-2"
                      type="button"
                      disabled={sendOtpLoading}
                      onClick={() => {
                        sendOtp();
                        resetForm();
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        Resend
                        {sendOtpLoading ? <Loader /> : null}
                      </div>
                    </button>
                    <button
                      className="save_btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        Verify
                        {isLoading ? <Loader /> : null}
                      </div>
                    </button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        )}
        {type === "password" && (
          <Formik
            initialValues={{
              newPassword: "",
              confirmNewPassword: "",
            }}
            onSubmit={async (values) => {
              await updatePassword(values);
            }}
            validationSchema={forgetPasswordSchema}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
              values,
              errors,
              ...props
            }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <label className="text-white">Enter new password</label>
                    <input
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      className="otp-modal-input"
                      disabled={isLoading}
                      value={values.newPassword}
                      onChange={handleChange}
                    />
                    <p className="error">{errors.newPassword}</p>
                  </Col>
                  <Col md={12}>
                    <label className="text-white">Enter confirm password</label>
                    <input
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Confirm New Password"
                      disabled={isLoading}
                      className="otp-modal-input"
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                    />
                    <p className="error">{errors.confirmNewPassword}</p>
                  </Col>
                  <Col md={12} className="d-flex justify-content-end">
                    <button
                      className="save_btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        Update
                        {isLoading ? <Loader /> : null}
                      </div>
                    </button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

export default OtpModal;
