import React from "react";
import "../user.css";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Formik } from "formik";
import { updateNameSchema, updatePasswordSchema } from "../../../Schemas";
import { onError, onSuccess } from "../../../Utils/toast";
import User from "../../../Services/User";
import { saveAuth, useUser } from "../../../Redux/Accessors/auth.accessors";
import { avatars } from "../../../Constants";

const Profile = () => {
  const { user } = useUser();

  const updateUser = async (values) => {
    try {
      const response = await User.update(values);
      saveAuth({ ...user, user: { ...user.user, ...response.data.data } });
      onSuccess("Updated Successfully!");
    } catch (error) {
      onError(error?.response?.data?.errorResponse?.message || error?.message);
    }
  };

  const updatePassword = async (values) => {
    try {
      await User.updatePassword(values);
      onSuccess("Password Updated Successfully!");
    } catch (error) {
      onError(error?.response?.data?.errorResponse?.message || error?.message);
    }
  };

  return (
    <>
      <h1>Profile</h1>
      <div className="layout_page_section">
        <div className="profile_img_section py-4 px-2 px-md-4 ">
          <div className="profile_img_container">
            <img
              src={
                user?.user?.provider === "google" ||
                user?.user?.provider === "facebook"
                  ? user?.user?.profile
                  : `/images/${user?.user?.profile || "avatar.png"}`
              }
              alt="profile-pic"
            />
          </div>
          <div className="d-flex align-items-center profile_upload_btn">
            <Dropdown>
              <Dropdown.Toggle className="avatar-dropdown">
                <label
                  htmlFor="file-input"
                  className="d-flex align-items-center cursor-pointer"
                >
                  <img src="/images/camera.png" alt="profile-pic" />
                  <h4>Upload Photo</h4>
                </label>
              </Dropdown.Toggle>
              <Dropdown.Menu className="avatar-dropdown-menu">
                {avatars.map((url) => {
                  return (
                    <Dropdown.Item
                      className="avatar-dropdown-item"
                      onClick={() => updateUser({ profile: url })}
                    >
                      <img src={`/images/${url}`} alt="profile-pic" />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <hr style={{ color: "rgb(77,77,77)", margin: 0 }} />
        <div className="profile_form_section py-3 px-2 px-md-4">
          <Row>
            <Col md={8}>
              <Formik
                initialValues={{
                  firstName: user?.user?.firstName || "",
                  lastName: user?.user?.lastName || "",
                  email: user?.user?.email || "",
                  phone: user?.user?.phone || "",
                }}
                onSubmit={async (values) => {
                  await updateUser(values);
                }}
                validationSchema={updateNameSchema}
              >
                {({
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
                  values,
                  errors,
                  ...props
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <input
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          value={values?.firstName}
                          onChange={handleChange}
                        />
                        <p className="error">{errors?.firstName}</p>
                      </Col>
                      <Col md={6}>
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          value={values?.lastName}
                          onChange={handleChange}
                        />
                        <p className="error">{errors?.lastName}</p>
                      </Col>

                      <Col md={12} className="d-flex justify-content-end">
                        <button className="save_btn" type="submit">
                          Save Changes
                        </button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
        {user?.user?.provider === "google" ||
        user?.user?.provider === "facebook" ? (
          <div></div>
        ) : (
          <div className="profile_form_section py-3 px-2 px-md-4">
            <Row>
              <Col md={8}>
                <Formik
                  validateOnChange={false}
                  initialValues={{
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                  }}
                  onSubmit={async (values, { resetForm }) => {
                    await updatePassword(values);
                    resetForm();
                  }}
                  validationSchema={updatePasswordSchema}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                    values,
                    errors,
                    ...props
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6}>
                          <input
                            type="password"
                            placeholder="Old Password"
                            name="oldPassword"
                            value={values?.oldPassword}
                            onChange={handleChange}
                          />
                          <p className="error">{errors?.oldPassword}</p>
                        </Col>
                        <Col md={6}>
                          <input
                            type="password"
                            placeholder="New Password"
                            name="newPassword"
                            value={values?.newPassword}
                            onChange={handleChange}
                          />
                          <p className="error">{errors?.newPassword}</p>
                        </Col>
                        <Col md={12}>
                          <input
                            type="password"
                            placeholder="Confirm New Password"
                            name="confirmNewPassword"
                            value={values?.confirmNewPassword}
                            onChange={handleChange}
                          />
                          <p className="error">{errors?.confirmNewPassword}</p>
                        </Col>
                        <Col md={12} className="d-flex justify-content-end">
                          <button className="change_pswd_btn" type="submit">
                            Change Password
                          </button>
                        </Col>
                      </Row>
                    </form>
                  )}
                </Formik>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
