import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MatchSlider from "../MatchSlider/MatchSlider";
import PointsTableHeader from "../PointsTableHeader/PointsTableHeader";
import PremiumCard from "../PremiumCard/PremiumCard";
import MatchStats from "../MatchStats/MatchStats";
import Notifications from "../../Layout/Notification/Notifications";
import { ThemeState } from "../../../Context";
import "./HomeMatches.css";
import Loader from "../../../Common/Loader";
import { useNavigate } from "react-router-dom";

const HomeMatches = ({
  scheduleGames,
  isLoading,
  homeTeamData,
  awayTeamData,
  slide,
  handleSlider,
  data,
  isNotificationLoading,
  onUpdate,
  setOnUpdate,
  isPremiumPage,
  week,
}) => {
  const { mode } = ThemeState();
  const navigate = useNavigate();

  const [viewMoreGameA, setViewMoreGameA] = useState(false);
  const [viewMoreGameB, setViewMoreGameB] = useState(false);
  console.log(scheduleGames[slide], "schedule game");

  return (
    <div className={`home_matches_main ${mode === "Light" && "light_theme"}`}>
      <Container fluid>
        <Row className="gx-0">
          <Col md={9}>
            <div className="pe-4">
              <MatchSlider
                slide={slide}
                handleSlider={(val) => handleSlider(val)}
                scheduleGames={scheduleGames}
                isPremiumPage={isPremiumPage}
                week={week}
              />
              {scheduleGames?.length ? <PointsTableHeader /> : null}
            </div>
          </Col>
          <Col md={3}>
            {!isNotificationLoading ? (
              <>
                <Notifications
                  page="home"
                  data={data}
                  onUpdate={onUpdate}
                  setOnUpdate={setOnUpdate}
                />
                <h3
                  className={`text-center d-flex justify-content-center align-items-center ${
                    mode === "Light" ? "fw-bold" : "text-white"
                  }`}
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    height: "90px",
                  }}
                  onClick={() => navigate("/notifications")}
                >
                  View All Notifications
                </h3>
              </>
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
        {!!scheduleGames?.length && (
          <>
            <Row className="gx-0">
              <Col md={9}>
                <div className="pe-4">
                  <MatchStats
                    isLoading={isLoading}
                    viewMore={viewMoreGameA}
                    setViewMore={setViewMoreGameA}
                    team={scheduleGames[isPremiumPage ? 0 : slide]?.awayTeam}
                    players={awayTeamData}
                    teamData={awayTeamData}
                  />
                </div>
              </Col>
              <Col md={3}>
                <PremiumCard
                  isStatsLoading={isLoading}
                  viewMore={viewMoreGameA}
                  setViewMore={setViewMoreGameA}
                  players={awayTeamData}
                  onlyOne
                  premium={scheduleGames[slide]?.premium?.awayTeam}

                />
              </Col>
            </Row>
            <Row className="gx-0 mt-5">
              <Col md={9}>
                <div className="pe-4">
                  <MatchStats
                    isLoading={isLoading}
                    viewMore={viewMoreGameB}
                    setViewMore={setViewMoreGameB}
                    team={scheduleGames[isPremiumPage ? 0 : slide]?.homeTeam}
                    players={homeTeamData}
                    teamData={homeTeamData}
                  />
                </div>
              </Col>
              <Col md={3}>
                <PremiumCard
                  isStatsLoading={isLoading}
                  viewMore={viewMoreGameB}
                  setViewMore={setViewMoreGameB}
                  players={homeTeamData}
                  onlyOne
                  premium={scheduleGames[slide]?.premium?.homeTeam}
                  list
                />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default HomeMatches;
