import { toast } from "react-toastify";

export const successNotify = (message) => {
  toast.success(
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* insert your icon here */}
      <span style={{ fontWeight: "bold", color: "#fff" }}>
        {message || 'Player Notification Added!'}
      </span>{" "}
      <img
        src="/images/bell-icon.png"
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "6px",
        }}
        alt=""
      />
    </div>,
    {
      icon: ({ theme, type }) => (
        <img
          src="/images/notify-logo.png"
          style={{ width: "40px", paddingRight: "3px" }}
          alt=""
        />
      ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
};

export const errorNotify = (message) => {
  toast.error(
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* insert your icon here */}
      <span style={{ fontWeight: "bold", color: "#fff" }}>
        {message || "Player Notification Removed!"}
      </span>{" "}
      <img
        src="/images/bell-removed.png"
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "6px",
        }}
        alt=""
      />
    </div>,
    {
      icon: ({ theme, type }) => (
        <img
          src="/images/notify-logo.png"
          style={{ width: "40px", paddingRight: "3px" }}
          alt=""
        />
      ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
};

export const infoNotify = (navigate) => {
  toast.success(
    <div className="info_notify_content_div">
      {/* insert your icon here */}
      <span style={{ fontWeight: "bold", color: "#fff" }}>
        Log in to get player Notifications:
      </span>{" "}
      <div>
        <button
          className="bg_green text-white sign-up toast-signup"
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
        <button
          className="login toast-login"
          onClick={() => navigate("/login")}
        >
          Log In
        </button>
      </div>
    </div>,
    {
      icon: ({ theme, type }) => (
        <img
          src="/images/notify-logo.png"
          style={{ width: "40px", paddingRight: "3px" }}
          alt=""
        />
      ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
};

export const onSuccess = (message) => {
  toast.success(
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* insert your icon here */}
      <span style={{ fontWeight: "bold", color: "#fff" }}>{message}</span>{" "}
      <img
        src="/images/bell-icon.png"
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "6px",
        }}
        alt=""
      />
    </div>,
    {
      icon: ({ theme, type }) => (
        <img
          src="/images/notify-logo.png"
          style={{ width: "40px", paddingRight: "3px" }}
          alt=""
        />
      ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
};

export const onError = (message) => {
  toast.error(
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* insert your icon here */}
      <span style={{ fontWeight: "bold", color: "#fff" }}>{message}</span>{" "}
      <img
        src="/images/bell-removed.png"
        style={{
          width: "40px",
          height: "40px",
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "6px",
        }}
        alt=""
      />
    </div>,
    {
      icon: ({ theme, type }) => (
        <img
          src="/images/notify-logo.png"
          style={{ width: "40px", paddingRight: "3px" }}
          alt=""
        />
      ),
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
};
