import React from "react";

const BillingTableMobile = () => {
  return (
    <div className="mobile_billing_table">
      <div className="mobile_billing_table_header">
        <h3>Date</h3>
        <h3>Transaction</h3>
        <h3>Amount</h3>
        <h3>Status</h3>
        <h3>Actions</h3>
      </div>
      <div className="mobile_billing_table_body">
        <h3 className="text-start">Feb 27, 2020</h3>
        <h3>Invoice</h3>
        <h3>$ 0.00</h3>
        <h3>Unpaid</h3>
        <div>
          <button>Pay</button>
        </div>
      </div>
    </div>
  );
};

export default BillingTableMobile;
