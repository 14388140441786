import React from "react";

const DarkLoader = () => {
  return (
    <div className="text-center">
      <img
        src="/images/dark-loader.gif"
        alt="loader"
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  );
};

export default DarkLoader;
