import React, { useState, useEffect, useRef } from "react";
import "./AutoCompleteSearch.css";
import { ThemeState } from "../../../Context";
import Games from "../../../Services/Games";
import { errorNotify, successNotify } from "../../../Utils/toast";
import { Form } from "react-bootstrap";
import AutocompleteSuggestion from "./AutocompleteSuggestion";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillMicFill } from "react-icons/bs";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const AutoCompleteSearch = ({
  data,
  departmentData,
  setDepartmentData,
  title,
  playerIds,
  gameSport,
  addIdToSport,
  removeIdFromSport,
}) => {
  const { mode } = ThemeState();
  const suggestionRef = useRef(null);
  const audioRef = useRef(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [players, setPlayers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestionRef?.current &&
        !suggestionRef?.current.contains(event.target)
      ) {
        setShowDropdown(false); // Close dropdown when clicked outside
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
  }, [suggestionRef]);

  useEffect(() => {
    if (isListening) {
      onSearch(transcript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  const onSearch = async (name) => {
    setSearch(name);
    if (name?.length >= 4) {
      try {
        setIsLoading(true);
        const res = await Games.search(name, gameSport);
        const playersWithFullName = res?.data?.data.map((player) => ({
          ...player,
          fullName: `${player.firstName} ${player.lastName}`,
        }));

        setPlayers(playersWithFullName);
      } catch (error) {
        setIsLoading(false);
        console.log("Error in searching players", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOnSelect = async (item) => {
    setSearch("");

    if (playerIds[item?.sport].length === 4) {
      errorNotify(`Remove any player to add more in ${item?.sport}`);
      return;
    }

    if (playerIds[item?.sport]?.includes(item?._id)) {
      removeIdFromSport(item?.sport, item?._id);
      successNotify(`Player removed from ${item?.sport} successfully`);
    } else {
      addIdToSport(item?.sport, item?._id);
      successNotify(`Player added to ${item?.sport} successfully`);
    }
  };

  const handleAudio = () => {
    audioRef?.current?.play();
  };

  const handleSpeech = (status) => {
    resetTranscript();
    setIsListening(!isListening);

    try {
      if (status === "start") {
        handleAudio();
        SpeechRecognition.startListening({ continuous: false });

        const stopTimeout = setTimeout(() => {
          console.log("stopTimeout");
          SpeechRecognition.stopListening();
          setIsListening(false);
        }, 5000); // 5000 milliseconds = 5 seconds

        SpeechRecognition.onresult = (event) => {
          clearTimeout(stopTimeout); // Clear the timeout if recognition produces results
        };
      } else {
        SpeechRecognition.stopListening();

        resetTranscript();
      }
    } catch (error) {
      console.log("Error in speach recognization", error);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn`t support speech recognition.</span>;
  }

  return (
    <div className={`autoComplete ${mode === "Light" && "light_theme"}`}>
      <Form className="d-flex ml-2" ref={suggestionRef}>
        <div className="autoComplete-search-wrapper">
          <div className="autoComplete_search_bar">
            <Form.Control
              className="autoComplete_search"
              type="search"
              placeholder="Search Players to Compare"
              aria-label="Search"
              value={search}
              onChange={(e) => onSearch(e.target.value)}
              onFocus={() => setShowDropdown(true)}
            />
            {!search.length &&
              (!isListening ? (
                <div
                  className="compare_close h-100 d-flex align-items-center justify-content-center"
                  style={{ width: "25px" }}
                  onClick={() => handleSpeech(isListening ? "stop" : "start")}
                >
                  <BsFillMicFill
                    size={15}
                    style={{ width: "18px", height: "18px" }}
                  />
                </div>
              ) : (
                <img
                  className="mic"
                  src="/images/mic-animation.gif"
                  alt="mic"
                  width="20px"
                  height="20px"
                  style={{ marginRight: "10px" }}
                />
              ))}
            {!!search.length && (
              <AiFillCloseCircle
                size={20}
                className="compare_close"
                onClick={() => setSearch("")}
              />
            )}
          </div>

          {search.length >= 4 && showDropdown && (
            <AutocompleteSuggestion
              players={players}
              setPlayerName={setSearch}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              playerIds={playerIds}
              handleOnSelect={(player) => handleOnSelect(player)}
            />
          )}
        </div>
      </Form>
      <audio ref={audioRef} src="./images/beep.mp3" />
    </div>
  );
};

export default AutoCompleteSearch;
