import React, { useState, useEffect } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Games from "../../../Services/Games";
import { useSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { getTab, isShow, overValue } from "../../../Utils/getStats";
import { ThemeState } from "../../../Context";

const Loader = () => {
  const { mode } = ThemeState();

  return (
    <>
      {mode !== "Light" ? (
        <img
          src="/images/loader.gif"
          alt="loader"
          style={{ width: "18px", height: "18px" }}
        />
      ) : (
        <img
          src="/images/dark-loader.gif"
          alt="loader"
          style={{ width: "18px", height: "18px" }}
        />
      )}
    </>
  );
};

const PremiumRow = ({ playerId, matches, projection }) => {
  const { mode } = ThemeState();
  const tab = useTab();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let sport = useSport();

  sport?.toLowerCase();
  // console.log(tab)
  // console.log(getTab(tab))

  const fetchPremiumStats = async () => {
    try {
      setIsLoading(true);
      const res = await Games.premiumStats(
        matches,
        playerId,
        getTab(tab),
        sport
      );
      setStats(res?.data?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error in getting premium stats");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPremiumStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, matches, playerId]);

  const calculatePercentages = (over, under) => {
    let total = over + under;
    if (total === 0) {
      return { over: 0, under: 0 };
    }

    return {
      over,
      under,
    };
  };

  const displayLineStats = (stats) => {
    const specialTabs = [
      "Passing Yds",
      "Passing TD",
      "Rushing Yds",
      "Receptions",
      "Receiving Yds",
    ];

    if (specialTabs.includes(tab)) {
      return stats;
    } else {
      return stats ;
    }
  };

  const displayFinishStats = (stats) => {
    const specialTabs = ["First TD"];

    if (specialTabs.includes(tab)) {
      return stats > 0 ? `${stats}` : stats;
    } else {
      return stats;
    }
  };

  const timestampTooltip = (text = "") => (
    <Tooltip className={`tooltip_new ${mode === "Light" && "light_theme"}`}>
      <span>{text}</span>
    </Tooltip>
  );

  return (
    <Row className="list_body premium_list_body  gx-0 p-0">
      <OverlayTrigger
        placement="top"
        overlay={timestampTooltip(
          "Average Finish for Player Prop & Filter Selected"
        )}
      >
        <Col xs={3}>
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <p>{displayFinishStats(stats?.avgFinish)}</p>
            )}
          </div>
          {/* <p>{stats?.avgFinish}</p>} */}
        </Col>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        overlay={timestampTooltip(
          "Average Line from Multiple Sportsbooks for Player Prop & Filter Selected"
        )}
      >
        <Col xs={3}>
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <p>{displayLineStats(stats?.avgLine)}</p>
              // <p>{stats?.avgLine > 0 ? `+${stats?.avgLine}` : stats?.avgLine}</p>
            )}
          </div>
        </Col>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        overlay={timestampTooltip(
          "Percentage of Under/Over Finish for the Player Prop & Filter selected"
        )}
      >
        <Col xs={3}>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <h3 className="percentage-over">
                O {
                  calculatePercentages(
                    stats?.finish?.over,
                    stats?.finish?.under
                  )?.over
                }%
              </h3>
              <p className="premium_under_value">
                U {
                  calculatePercentages(
                    stats?.finish?.over,
                    stats?.finish?.under
                  )?.under
                }%
              </p>
            </div>
          )}
        </Col>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top"
        overlay={timestampTooltip(
          "Player Totals Prop Projection for their Upcoming game"
        )}
      >
        <Col xs={3} className="premium_projection_b">
          <div>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <p
                  className={`premium_projection${
                    mode === "Light" && "light_theme"
                  }`}
                >
                  {!isShow(projection?.value, tab) &&
                    projection?.value &&
                    projection?.value}
                </p>
                {/* {projection?.value}</p> */}
                <p>{overValue(projection?.over, sport)}</p>
              </div>
            )}
          </div>
        </Col>
      </OverlayTrigger>
    </Row>
  );
};

export default PremiumRow;
