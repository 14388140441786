import { createSlice } from "@reduxjs/toolkit";

const aboutSlice = createSlice({
    name: "promos",
    initialState: {
      about: {},
      // loading:false,
      // success:true
    },
    reducers: {
        updateAbout: (state, action) => {
            state.about = action.payload;
          },
    }
})
export default aboutSlice;

// let initialState = {
//   loading: false,
//   promos: {},
//   error: null,
//   success: false,
// };


// const aboutSlice = createSlice({
//   name: "promos",
//   initialState,
//   reducers: {
//     clearErrors: (state, action) => {
//       state.error = null;
//     },
//   },
//   extraReducers: {
//     [login.pending]: (state, action) => {
//       state.loading = true;
//     },
//     [login.fulfilled]: (state, {payload}) => {
//       state.loading = false;
//       if (payload.success === false) {
//         state.success = false;
//         state.error = payload.message;
//       } else {
//         state.success = true;
//         state.error = null;
//         state.promos = payload.data;
//       }
//     },
//     [login.rejected]: (state, { payload }) => {
//       state.error = payload.message;
//     },
//   },
// });
// export const { clearErrors } = LoginSlice.actions;

// export default LoginSlice.reducer;