import { apiRequest } from "../Utils/apiRequest";
import {
  gameRoute,
  playerRoute,
  teamRoute,
  compareRoute,
  sendMailToUs,
} from "../Utils/routes";

const Games = {
  settings: () => {
    return apiRequest(gameRoute.settings);
  },

  schedules: (sport) => {
    return apiRequest(
      `${gameRoute.schedulesGames}?sport=${sport?.toLowerCase()}`
    );
  },

  playerStats: (data) => {
    return apiRequest(gameRoute.playerStats, "POST", data);
  },

  statsByPlayer: (data) => {
    return apiRequest(gameRoute.statsByPlayer, "POST", data);
  },

  premiumStats: (matches = 5, playerId, tab, sport = null) => {
    // console.log(tab,"tab")
    const link = `${
      gameRoute.playerPremiumStats
    }?matches=${matches}&player=${playerId}&tab=${encodeURIComponent(
      tab
    )}&sport=${sport}`;
    console.log(link, "link");
    return apiRequest(link);
  },

  playerGames: (slug, skip = 0, sport) => {
    return apiRequest(
      `${playerRoute.stats}/${slug}?skip=${skip}&limit=${5}&sport=${sport}`
    );
  },

  search: (name, sport) => {
    let url = `${playerRoute.search}?search=${name}`;

    if (sport) {
      url += `&sport=${sport}`;
    }

    return apiRequest(url);
  },

  teamGames: (slug, skip = 0, sport) => {
    return apiRequest(
      `${teamRoute.stats}/${slug}?skip=${skip}&limit=${5}&sport=${sport}`
    );
  },

  teams: (sport) => {
    return apiRequest(`${teamRoute.teams}/${sport}`);
  },

  addPlayerForCompare: (data) => {
    return apiRequest(compareRoute.addPlayerForCompare, "POST", data);
  },

  getDataToCompare: (player, tab) => {
    return apiRequest(
      `${compareRoute.getDataToCompare}?tab=${encodeURIComponent(
        tab
      )}&player=${player}`
    );
  },

  allTeams: () => {
    return apiRequest(teamRoute.teams);
  },

  sendMail: (data) => {
    return apiRequest(sendMailToUs.sendMail, "POST", data);
  },

  singleTeam: (id) => {
    return apiRequest(`${teamRoute.team}/${id}`);
  },

  getPlayerBySlug: (slug) => {
    return apiRequest(`${playerRoute.playerDetails}?slug=${slug}`);
  },

  getTeamBySlug: (slug) => {
    return apiRequest(`${teamRoute.teamDetails}?slug=${slug}`);
  },
};

export default Games;
