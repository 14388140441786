import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../../Context";
import Games from "../../../Services/Games";
import {
  updateSport,
  useSport,
  useTab,
} from "../../../Redux/Accessors/game.accessors";
import { getTab } from "../../../Utils/getStats";
import { useNavigate } from "react-router-dom";
import { playerNavigator } from "../../../Utils/navigator";

const Loader = () => {
  return (
    <img
      src="/images/loader.gif"
      alt="loader"
      style={{ width: "18px", height: "18px" }}
    />
  );
};

const PremiumData = ({ length, playerId, matches }) => {
  const { mode } = ThemeState();
  const tab = useTab();
  const sport = useSport();
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPremiumStats = async () => {
    try {
      setIsLoading(true);
      const res = await Games.premiumStats(
        matches,
        playerId,
        getTab(tab),
        sport
      );
      setStats(res?.data?.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error in getting premium stats");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPremiumStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, matches, playerId]);

  const calculatePercentages = (over, under) => {
    let total = over + under;
    if (total === 0) {
      return { over: 0, under: 0 };
    }

    return {
      over,
      under,
    };
  };

  return (
    <Col
      xs={
        length === 1
          ? 12
          : length === 2
          ? 6
          : length === 3
          ? 4
          : length === 4
          ? 3
          : 12
      }
      md={
        length === 1
          ? 12
          : length === 2
          ? 6
          : length === 3
          ? 4
          : length === 4
          ? 3
          : 12
      }
    >
      <div className="compare_list_data">
        <Row
          style={{ border: "none" }}
          className={`compare_premium_box_header ${
            mode === "Light" && "light_theme"
          }`}
        >
          <Col>
            <h4
              style={{ cursor: "pointer" }}
              onClick={() =>
                playerNavigator(stats?.player, navigate, updateSport)
              }
            >
              {stats?.player?.firstName} {stats?.player?.lastName}
              <br />
              <>{stats?.player?.firstName && stats?.player?.position}</>
            </h4>
          </Col>
        </Row>
      </div>
      <div className="compare_list_data block_boundaries">
        <Row className="list_body">
          <Col xs={12}>
            <div>
              <p>{isLoading ? <Loader /> : stats?.avgFinish}</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="compare_list_data block_boundaries">
        <Row className="list_body">
          <Col xs={12}>
            <div>
              {isLoading ? (
                <Loader />
              ) : (
                <p>
                  {stats?.avgLine > 0 ? `+${stats?.avgLine}` : stats?.avgLine}
                </p>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div className="compare_list_data block_boundaries">
        <Row className="list_body">
          <Col xs={12}>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <h5>
                  O-
                  {
                    calculatePercentages(
                      stats?.finish?.over,
                      stats?.finish?.under
                    )?.over
                  }
                  %
                </h5>
                <h3>
                  U-
                  {
                    calculatePercentages(
                      stats?.finish?.over,
                      stats?.finish?.under
                    )?.under
                  }
                  %
                </h3>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="compare_list_data block_boundaries">
        <Row className="list_body">
          <Col xs={12}>
            <div>
              <p>{isLoading ? <Loader /> : 0}</p>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default PremiumData;
