import React, { useState } from "react";
import Modal from "../../../Components/Modal/SiteModal";
import { Container, Row, Spinner } from "react-bootstrap";
import SubAdmin from "../../../Services/subAdmin";
import { errorNotify } from "../../../Utils/toast";
const AddSubAdminModal = ({ show, setShow,fetchSubAdmins }) => {
  const [loading,setLoading] = useState(false)
  const [subAdminData,setSubAdminData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    password:""
  });
  const [success,setSuccess] = useState(false);

    const handleModalClose=()=>{
        setShow(false)
    }
    const handleChange = (e)=>{
      setSubAdminData((prev)=>{return {...prev,[e.target.name]:e.target.value}})
    }
    const handleSubmit=async(e)=>{
      try {
        e.preventDefault();
        setLoading(true);
        await SubAdmin.createSubAdmin(subAdminData);
      setSuccess(true);
      setShow(false)
      setSubAdminData({});
      fetchSubAdmins()

      }
     catch (error) {
      setLoading(false);
      errorNotify(error?.response?.data?.errorResponse?.message)

    } finally {
      setLoading(false);
    }
    }
  return (
    <div>
      <Modal show={show} setShow={setShow} addAdmin onCloseModal={handleModalClose}>
        <Container>
            <form className="add_admin_form" onSubmit={handleSubmit}>

          <Row>
            <label className="text-white">First Name</label>
            <input name="firstName" placeholder="enter first name" value={subAdminData.firstName} onChange={handleChange} required/>
          </Row>
          <Row>
            <label className="text-white">Last Name</label>
            <input name="lastName" placeholder="enter last name" value={subAdminData.lastName} onChange={handleChange} required/>
          </Row>
          <Row>
            <label>Phone</label>
            <input name="phone" placeholder="enter phone number" value={subAdminData.phone} onChange={handleChange} required/>
          </Row>
          <Row>
            <label>Email</label>
            <input name="email" placeholder="enter email address" value={subAdminData.email} onChange={handleChange} required/>
          </Row>
          <Row>
            <label>Password</label>
            <input type="password" name="password" placeholder="enter password" value={subAdminData.password} onChange={handleChange} required/>
          </Row>
          <Row className="justify-content-end">
            <button type="submit">{loading ? <Spinner animation="border" size="sm"/> :"SUBMIT"}</button>
          </Row>
          
          </form>

        </Container>
      </Modal>
    </div>
  );
};

export default AddSubAdminModal;
