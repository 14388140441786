import React from "react";

const UserSettings = () => {
  return (
    <>
      <h1>Settings</h1>
      <div className="layout_page_section"></div>
    </>
  );
};

export default UserSettings;
