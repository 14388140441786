import React from "react";
import DataUpdate from "./DataUpdate";

const NBA = () => {
  return (
    <div className="data_update">
      <div className=" data_update_header_container">
        <h1>NBA Schedule</h1>
      </div>
      <div className="layout_page_section  px-3 ">
        <DataUpdate game="NBA" />
      </div>
    </div>
  );
};

export default NBA;
