import { apiRequest } from "../Utils/apiRequest";
import { notificationRoute } from "../Utils/routes";

const Notification = {
  getNotifications: (sport, skip, limit) => {
    return apiRequest(
      `${notificationRoute.notification}?skip=${skip}&limit=${limit}&sport=${sport}`
    );
  },

  getGameNotifications: (id, sport, skip, limit) => {
    return apiRequest(
      `${notificationRoute.gameNotificatios}/${id}?skip=${skip}&limit=${limit}&sport=${sport}`
    );
  },

  getPlayerNotifications: (slug, sport, skip, limit) => {
    return apiRequest(
      `${notificationRoute.playerNotification}/${slug}?skip=${skip}&limit=${limit}&sport=${sport}`
    );
  },

  getTeamNotifications: (id, sport, skip, limit) => {
    return apiRequest(
      `${notificationRoute.teamNotification}/${id}?skip=${skip}&limit=${limit}&sport=${sport}`
    );
  },

  deleteNotification: (id) => {
    return apiRequest(
      `${notificationRoute.deleteNotification}/${id}`,
      "DELETE"
    );
  },

  getAllNotifications: (sport, skip = 0, limit, playerId, teamId) => {
    let url = `${notificationRoute.getAllNotifications}?skip=${skip}&limit=${limit}`;

    if (sport !== "ALL") {
      url += `&sport=${sport}`;
    }

    if (playerId) {
      url += `&player=${playerId}`;
    }

    if (teamId) {
      url += `&team=${teamId}`;
    }

    return apiRequest(url);
  },
};

export default Notification;
