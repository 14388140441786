import React from "react";
import { ThemeState } from "../../Context";
import { useTab } from "../../Redux/Accessors/game.accessors";

const MobileSiteLogos = () => {
  const { mode } = ThemeState();
  const tab = useTab();

  return (
    <div>
      <div
        className={`d-flex text-white compare_assists_header bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          <h3 className={`${mode === "Light" ? "col-green" : "col-yellow"}`}>
            {tab}
          </h3>
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          <img src="/images/fanduel.png" alt="fanduel sportsbook" />
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {mode === "Light" ? (
            <img src="/images/draft-light.png" alt="" />
          ) : (
            <img src="/images/draft.png" alt="draftkings sportsbook" />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          <img src="/images/betmgm.png" alt="betmgm sportsbook" />
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {mode === "Light" ? (
            <img src="/images/caesars-light.png" alt="" />
          ) : (
            <img src="/images/caesars.png" alt="caesars sportbook" />
          )}
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          <img src="/images/bet365_img.png" alt="bet365 bonus code" />
        </div>
      </div>
      <div
        className={`compare_mobile_assists_body bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div>
          {mode === "Light" ? (
            <img
              src="/images/pointsbet-light.png"
              style={{ height: "55px" }}
              alt=""
            />
          ) : (
            <img
              src="/images/pointsbet.png"
              style={{ height: "55px" }}
              alt="pointsbet sportsbook"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileSiteLogos;
