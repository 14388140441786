import BillingHistory from "./BillingHistory/BillingHistory";
import Dashboard from "./Dashboard/Dashboard";
import Profile from "./Profile/Profile";
import Subscription from "./Subscription/Subscription";
import UserSettings from "./UserSettings/UserSettings";

export const userRoutes = [
  {
    path: "/user/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/user/profile",
    component: <Profile />,
  },
  {
    path: "/user/subscription",
    component: <Subscription />,
  },
  {
    path: "/user/billing-history",
    component: <BillingHistory />,
  },
  {
    path: "/user/settings",
    component: <UserSettings />,
  },
];

export const userSideBarItems = [
  {
    path: "/user/profile",
    icon: "/images/profile-icon.png",
    title: "Profile",
    isSubNav: false,
  },
  {
    path: "/user/subscription",
    icon: "/images/subscribe-icon.png",
    title: "Subscription",
    isSubNav: false,
  },
  {
    path: "/user/billing-history",
    icon: "/images/billing-icon.png",
    title: "Billing History",
    isSubNav: false,
  },
];
