import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutAuth, useUser } from "../../../Redux/Accessors/auth.accessors";
import { googleLogout } from "@react-oauth/google";
import { onSuccess, successNotify } from "../../../Utils/toast";
import MobileSidebar from "./MobileSideBar";
import "./header.css";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
import { Collapse } from "@mui/material";
import { apiRequest } from "../../../Utils/apiRequest";
import { subAdminRoute } from "../../../Utils/routes";
import Modal from "../../Modal/SiteModal";
import Admin from "../../../Services/admin";

// import ProfileImg from "/images/profileImg.jpeg"
// import ProfileImg from ""

function Header({ sideBarItems, children }) {
  const { user } = useUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const navbarRef = useRef();
  const [subNav, setSubNav] = useState(false);
  const [openRow, setOpenRow] = useState({ name: null, close: false });
  const [cronModal, setCronModal] = useState(false);

  const classes = (path) => {
    if (path === pathname) {
      return "nav_active";
    }
    return "";
  };
  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/user/dashboard" ||
      pathname === "/user/settings" ||
      pathname === "/user/profile" ||
      pathname === "/user/subscription" ||
      pathname === "/user/billing-history" ||
      pathname === "/super-admin/profile" ||
      pathname === "/super-admin/creation" ||
      pathname === "/super-admin/users" ||
      pathname === "/super-admin/cms/edit-about-us" ||
      pathname === "/super-admin/cms/edit-promos" ||
      pathname === "/super-admin/data-update/NFL" ||
      pathname === "/super-admin/data-update/NBA" ||
      pathname === "/super-admin/data-update/MLB" ||
      pathname === "/super-admin/data-update/NHL" ||
      pathname === "/super-admin/data-update/search" ||
      pathname === "/admin/profile" ||
      pathname === "/admin/users" ||
      pathname === "/admin/cms/edit-about-us" ||
      pathname === "/admin/cms/edit-promos" ||
      pathname === "/admin/data-update/NFL" ||
      pathname === "/admin/data-update/NBA" ||
      pathname === "/admin/data-update/MLB" ||
      pathname === "/admin/data-update/NHL" ||
      pathname === "/admin/data-update/search"
    )
      navbarRef.current.style.width = "0%";
  }, [pathname]);
  const NavHandler = () => {
    if (navbarRef.current.style.width === "100%")
      navbarRef.current.style.width = "0%";
    else navbarRef.current.style.width = "100%";
  };

  const logout = () => {
    googleLogout();
    logoutAuth();
    navigate("/login");
  };

  useEffect(() => {
    if (!user) navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const handleRowsCollapse = (name) => {
    setSubNav(!subNav);
    setOpenRow({ name: name, close: !openRow.close });
  };
  const handleCron = async (e) => {
    e.preventDefault();
    setCronModal(true);
    
  };
  const onCloseModal = () => {
    setCronModal(false);
  };
  const handleCronSubmit=async(e,name)=>{
    e.preventDefault()
    await Admin.startCron(name);
    successNotify("Cronjob has been started");
    setCronModal(false);


    // await apiRequest(subAdminRoute.adminCron);
  }
  return (
    <>
      <MobileSidebar
        navbarRef={navbarRef}
        NavHandler={NavHandler}
        sideBarItems={sideBarItems}
      />
      <div className="user_layout">
        <div className="user_header">
          <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container fluid>
              <Navbar.Brand>
                <Link to="/">
                  <img src="/images/logo.png" alt="betting predictions" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={NavHandler}
              />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto">
                  <div className="avatar_container">
                    <div className="d-flex align-items-center login_nav">
                      <img
                        src={
                          user?.user?.provider === "google" ||
                          user?.user?.provider === "facebook"
                            ? user?.user?.profile
                            : `/images/${user?.user?.profile || "avatar.png"}`
                        }
                        alt=""
                      />

                      <NavDropdown
                        title={`${user?.user?.firstName || ""} ${
                          user?.user?.lastName || ""
                        }`}
                        id="basic-nav-dropdown"
                      >
                        <NavDropdown.Item
                          href="#"
                          style={{ marginLeft: 10 }}
                          className="user-dropdown-item"
                          onClick={() => navigate("/")}
                        >
                          <img src="/images/visit.png" />
                          <h6 style={{ color: "red !important" }}>Home Page</h6>
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item href="/">Logout</NavDropdown.Item> */}
                      </NavDropdown>
                    </div>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="layout_content_section">
          <div className="layout_content_sidebar_section">
            <div className="user_sidebar">
              <ul className="nav_list">
                {sideBarItems?.map((item, index) => {
                  if (!item.isSubNav) {
                    return (
                      <li key={index} className={`${classes(item.path)}`}>
                        <Link to={item.path}>
                          <img src={item.icon} />
                          {/* <img src={item.icon.type} alt="icon" /> */}
                          <span>{item.title}</span>
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li key={item.path} className={`${classes(item.path)}`}>
                        {/* <div
                          className="collapse_div"
                          
                        > */}
                        <Link
                          to={"#"}
                          onClick={() => handleRowsCollapse(item.title)}
                        >
                          <img src={item.icon} />
                          <span>{item.title}</span>
                          {item?.title === openRow.name &&
                          openRow.close === true ? (
                            <IoMdArrowDropdown className={"subNav"} />
                          ) : (
                            <IoMdArrowDropright className={"subNav"} />
                          )}
                        </Link>
                        {/* </div> */}
                        {item.subNav ? (
                          <Collapse
                            in={
                              item?.title === openRow.name &&
                              openRow.close === true
                            }
                            unmountOnExit
                          >
                            <React.Fragment>
                              {item.subNav.map((subItem, index) => (
                                <li
                                  className={`${classes(
                                    subItem.path
                                  )} sub_active ml-4`}
                                  key={index}
                                >
                                  <div className="sub_nav">
                                    <Link
                                      to={subItem.path}
                                      style={{
                                        paddingTop: "10px",
                                        paddingLeft: "20px",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      {/* <img src={subItem?.icon} /> */}
                                      <span>{subItem.title}</span>
                                    </Link>
                                  </div>
                                </li>
                              ))}
                            </React.Fragment>
                          </Collapse>
                        ) : null}
                      </li>
                    );
                  }
                })}
              </ul>
              <ul style={{ position: "absolute", bottom: "10px", left: "0px" }}>
                {user?.user?.role === "admin" && (
                  <li className="sidebar_trigger_btn">
                    <button onClick={(e) => handleCron(e)}>CRON TRIGGER</button>
                  </li>
                )}
                <li
                  className="sidebar_logout_btn"
                  onClick={() => {
                    logout();
                    onSuccess("Logout Successfully!");
                  }}
                >
                  <button style={{ width: "100%" }}>Logout</button>
                </li>
              </ul>
            </div>
          </div>
          <div className="layout_content">{children}</div>
        </div>
      </div>
      <Modal
        show={cronModal}
        setShow={setCronModal}
        onCloseModal={onCloseModal}
      >
        <div className="edit_value_modal cron_modal">
          <div style={{ width: "70%" }}>
            <div className="d-flex flex-column gap-2 w-full align-items-center">
              <button onClick={(e)=>handleCronSubmit(e,"scheduledGames")}>Scheduled Games</button>
              <button onClick={(e)=>handleCronSubmit(e,"updateScheduledGamesStats")}>Update Scheduled Games Stats</button>
              <button onClick={(e)=>handleCronSubmit(e,"previousStats")}>Previous Stats</button>
              <button onClick={(e)=>handleCronSubmit(e,"teamAndPlayers")}>Team And Players</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Header;
