import React from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import ContactForm from "../../Components/ContactUs/ContactForm";
import PageWrapper from "../../Common/PageWrapper";

const ContactUs = () => {
  return (
    <PageWrapper>
      <div>
        <div className="home_desktop">
          <Header />
          <ContactForm />
          <Footer />
        </div>
        <div className="home_mobile">
          <MobileHeader />
          <ContactForm />

          <MobileFooter />
        </div>
      </div>
    </PageWrapper>
  );
};

export default ContactUs;
