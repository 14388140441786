import React, { useEffect, useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import { updateSport } from "../../../Redux/Accessors/game.accessors";
import { useTeams } from "../../../Redux/Accessors/previousGames.accessors";
import { teamNavigator } from "../../../Utils/navigator";

const TeamHeader = ({ id, teamDetail }) => {
  const teams = useTeams();
  const { mode } = ThemeState();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const [show, setShow] = useState(false);

  const team = teams?.find(({ _id }) => _id === id);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  const showHandler = () => {
    setShow(!show);
  };

  return (
    <div className={`team_header_main ${mode === "Light" && "light_theme"}`}>
      <div className={`header_container ${mode === "Light" && "light_theme"}`}>
        <h1>{teamDetail?.h1}</h1>
        <p>{teamDetail?.p}</p>
      </div>
      <div className={`team_header ${mode === "Light" && "light_theme"}`}>
        <div style={{ position: "relative" }} ref={wrapperRef}>
          <div className={`${team?.name === "Pirates" && "team_logo_bg"}`}>
            <img src={team?.wikipediaLogoUrl} alt={team?.logoAltText || ""} />
          </div>
          <h2>
            {team?.city} {team?.name}
          </h2>
          <BiChevronDown style={{ cursor: "pointer" }} onClick={showHandler} />

          {show && (
            <div className="team_header_dropdown">
              {[...teams]
                ?.sort((a, b) => a?.city.localeCompare(b?.city))
                ?.map((item, index) => (
                  <p
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => teamNavigator(item, navigate, updateSport)}
                  >
                    <span>
                      <img
                        src={item?.wikipediaLogoUrl}
                        alt={item?.logoAltText || ""}
                        className={`${
                          item?.name === "Pirates" && "team_logo_bg"
                        }`}
                      />{" "}
                      {item?.city} {item?.name}
                    </span>
                    <span></span>
                  </p>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamHeader;
