import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Loader from "../../Utils/Loader";
import { cloudURL } from "../../Utils/helper";

const PromosContent = ({ mode, mobile,promosContent, loading }) => {
  const promos = [
    {
      id: 0,
      promoImg: "/images/fanduel.png",
      promoTitle: "FanDuel",
      promoHeading: "Bet $5, Get $100 in Bonus Bets - Guaranteed!",
      promoSub1: "Available in your state! New users only.",
      promoSub2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      promoReview:
        "On the heels of DraftKings, FanDuel was one of the first online sportsbooks to go to market in the United States. They also have the same background as DraftKings, starting as a daily fantasy sports platform. FanDuel offers numerous player props for every sport with a focus on the main sports and leagues in North America. While they do serve 19 states, they are quick to limit high rollers; however the majority of users will find they are more than well served on FanDuel.",
    },
    {
      id: 1,
      promoImg:
        mode === "Light" ? "/images/draft-light.png" : "/images/draft.png",
      promoTitle: "DraftKings",
      promoHeading: "Get a First Bet Reset up to $250 Back in Bonus Bets!",
      promoSub1: "Available in your state! New users only.",
      promoSub2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      promoReview:
        "DraftKings was one of the first online sportsbooks to launch in the United States when they opened up their platform to New Jersey residents in August of 2018. Since then they have launched in 21 states, the most of any sportsbook. The company was initially a daily fantasy sports platform but quickly added a sportsbook when the law to legalize it in the U.S. went into effect. Due to all of this they are one of the best sportsbooks at creating unique and loads of player prop bets for their users.",
    },
    {
      id: 2,
      promoImg: "/images/betmgm.png",
      promoTitle: "BetMGM",
      promoHeading:
        "Get up to $1,000 Back in Bonus Bets if Your First Bet Doesn't Win!",
      promoSub1:
        "New users NJ, PA, IN, IA, WV, CO, TN, IL, VA, MI, AZ LA, NY, WY, MD, OH, MA only.",
      promoSub2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      promoReview:
        "Most people recognize the MGM name from their brick and mortar casinos but they were fast to realize the potential of an online sportsbook and thus made BetMGM that serves 19 states. BetMGM is known for their robust offering of live-betting options that include live-player props. Users boast about the favorable odds they can get, especially when live betting which has become increasingly popular. Due to this your account may be quick to be temporarily locked when on a winning betting run.",
    },
    {
      id: 3,
      promoImg:
        mode === "Light" ? "/images/caesars-light.png" : "/images/caesars.png",
      promoTitle: "Caesars",
      promoHeading: "Bet $20, Get $100 in Bonus Bets!",
      promoSub1: "New users in NJ only",
      promoSub2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      promoReview:
        "Caesars is a brand largely built on the name of their casino as they are the largest retail sportsbook in the United States. Although they were quick to launch an online sportsbook after decades of success running brick and mortar casinos and sportsbooks. Caesars knows what they are doing and will have sharp odds on their player props for those who want fair and consistent props. Users won’t be wowed by their simple sportsbook interface but it is easy to use and offers a great rewards program.",
    },
    {
      id: 4,
      promoImg: "/images/bet365_img.png",
      promoTitle: "Bet365",
      promoHeading: "Bet $1+ on Any Market, Get $200 in Bonus Bets!",
      promoSub1: "New users NJ, IA, CO, VA, OH only",
      promoSub2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      promoReview:
        "If you are in one of the five states that Bet365 is legal in you can take advantage of a generous welcome bonus. They are also one of the largest sportsbooks in the world but are somewhat limited in the United States right now. Users will find that the player prop options may be more limited than some of the newer sportsbooks but the odds can commonly be better for bettors on Bet365.",
    },
    {
      id: 5,
      promoImg:
        mode === "Light"
          ? "/images/pointsbet-light.png"
          : "/images/pointsbet.png",
      promoTitle: "PointsBet",
      promoHeading: "Get up to $500 in Bonus Bets!",
      promoSub1: "New users IL, IN, KS, LA, MD, MI, NJ, NY, PA, VA, WV only.",
      promoSub2: "Must be 21+. Gambling problem? Call or text 1-800-GAMBLER",
      promoReview:
        "PointsBet is in 14 U.S. states which gives it fairly good coverage of the legal sports betting states. They are known for having their own in-house betting odds so users will find that their lines can be vastly different from other sportsbooks, particularly with their player props. Be sure to take advantage of their promotions quickly as they may disappear soon after. PointsBet has intriguing odds for player prop bettors and those sports betting deal seekers.",
    },
  ];
  const [readReview, setReadReview] = useState({ index: -1, read: false });
  const handleOpenReview = (i) => {
    setReadReview({ index: i, read: true });
  };
  const handleCloseReview = (i) => {
    setReadReview({ index: -1, read: false });
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div
          className={`promo_content_main ${mode === "Light" && "light_theme"}`}
        >
          <Container>
            <Row className="justify-content-center">
              {promosContent &&
                promosContent.map((p, i) => (
                  <Col md={10}>
                    <div
                      className={`promo_card ${
                        mode === "Light" && "light_theme"
                      }`}
                    >
                      <Row
                        className="align-items-start"
                        style={{ position: "relative" }}
                      >
                        <Col md={2}>
                          {mode !== "Light" ? (
                            <img src={`${cloudURL}/${p.image.filename}`} />
                          ) : mode === "Light" &&
                            p.dark_mode_img.filename === "" ? (
                            <img src={`${cloudURL}/${p.image.filename}`} />
                          ) : (
                            <img
                              src={`${cloudURL}/${p.dark_mode_img.filename}`}
                            />
                          )}
                          {mobile && (
                            <div
                              style={{
                                position: "absolute",
                                right: "15px",
                                top: "5px",
                              }}
                            >
                              <div className="d-flex align-items-center mt-1">
                                <div className="d-flex align-items-center gap-3">
                                  <FaFacebookF style={{ fontSize: "16px" }} />
                                  <BsTwitter style={{ fontSize: "16px" }} />
                                  <BsInstagram style={{ fontSize: "16px" }} />
                                </div>
                              </div>
                              <div className="mt-4">
                                {readReview.index !== p._id ? (
                                  <span onClick={() => handleOpenReview(p._id)}>
                                    Site Review
                                  </span>
                                ) : (
                                  <span onClick={() => handleCloseReview(i)}>
                                    Hide Review
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md={7} className="pb-md-3">
                          <h6>{p.title}</h6>
                          <h4>{p.heading}</h4>
                          <p>{p.text1}</p>
                          <p>{p.text2}</p>

                          {readReview.read === true &&
                            readReview.index === p._id && (
                              <>
                                <hr
                                  style={{
                                    color: mode === "Light" ? "black" : "white",
                                    opacity: 1,
                                    margin: "5px 0px",
                                  }}
                                />
                                <p className="pt-2 review-text">{p.review}</p>
                              </>
                            )}
                        </Col>
                        {!mobile ? (
                          <Col
                            md={3}
                            className="d-flex flex-column justify-content-center align-items-center"
                          >
                            <div
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{
                                position: "absolute",
                                top:
                                  readReview.read === true &&
                                  readReview.index === p._id
                                    ? "35%"
                                    : "18%",
                              }}
                            >
                              <a href={p?.claim_link} target="_blank" rel="noreferrer"><button>CLAIM BONUS</button></a>
                              {readReview.index !== p._id ? (
                                <span onClick={() => handleOpenReview(p._id)}>
                                  Site Review
                                </span>
                              ) : (
                                <span onClick={() => handleCloseReview()}>
                                  Hide Review
                                </span>
                              )}
                              <div className="d-flex align-items-center mt-2">
                                <div className="d-flex align-items-center gap-3">
                                  <FaFacebookF style={{ fontSize: "16px" }} />
                                  <BsTwitter style={{ fontSize: "16px" }} />
                                  <BsInstagram style={{ fontSize: "16px" }} />
                                </div>
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col
                            md={3}
                            className="d-flex flex-column justify-content-center "
                          >
                            {!mobile &&
                              (readReview.index !== p._id ? (
                                <span
                                  className="pt-1"
                                  onClick={() => handleOpenReview(p._id)}
                                >
                                  Site Review
                                </span>
                              ) : (
                                <span onClick={() => handleCloseReview(i)}>
                                  Hide Review
                                </span>
                              ))}
                            <a href={p?.claim_link} target="_blank" rel="noreferrer"><button>CLAIM BONUS</button></a>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Col>
                ))}
              
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default PromosContent;
