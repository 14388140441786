import { createSlice } from "@reduxjs/toolkit";

const dataUpdateSlice = createSlice({
  name: "dataUpdate",
  initialState: {
    playerStats: {},
  },
  reducers: {
    updatePlayerStats: (state, action) => {
      state.playerStats = action.payload;
    },
  },
});
export default dataUpdateSlice;
