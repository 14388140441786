import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Col, Pagination, Row } from "react-bootstrap";
import { ThemeState } from "../../Context";
import Modal from "../../Components/Modal/SiteModal";

import MatchesAccordianItem from "./MatchesAccordianItem";

const MatchesAccordion = ({matches, sport,loading,fetchSchedulesGames }) => {
  const [page, setPage] = useState(1);
  const { mode } = ThemeState();


  const handleNext = () => {
    if (page !== 4) {
      setPage(page + 1);
    }
  };
  const handlePrev = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };
  

  return (
    <>
      <div
        className={`team_accordion matches_accordian ${
          mode === "Light" && "light_theme"
        }`}
      >
        <Accordion defaultActiveKey="0">
          {matches &&
            matches.map(function (match, i) {
              return (
                <MatchesAccordianItem
                  i={i}
                  mode={mode}
                  sport={sport}
                  match={match}
                  loading={loading}
                  fetchSchedulesGames={fetchSchedulesGames}
                  key={i}
                />
                
              );
            })}
        </Accordion>
      </div>
      {/* <div
        className={`mt-5 players_pagination ${
          mode === "Light" && "light_theme"
        }`}
      >
        <Pagination size="lg">
          <Pagination.Prev onClick={() => handlePrev()} />
          <Pagination.Item
            active={page === 1 ? true : false}
            onClick={() => setPage(1)}
          >
            {1}
          </Pagination.Item>
          <Pagination.Item
            active={page === 2 ? true : false}
            onClick={() => setPage(2)}
          >
            {2}
          </Pagination.Item>
          <Pagination.Item
            active={page === 3 ? true : false}
            onClick={() => setPage(3)}
          >
            {3}
          </Pagination.Item>
          <Pagination.Item
            active={page === 4 ? true : false}
            onClick={() => setPage(4)}
          >
            {4}
          </Pagination.Item>
          <Pagination.Next onClick={() => handleNext()} />
        </Pagination>
      </div> */}
      
    </>
  );
};

export default MatchesAccordion;
