import React from "react";

const AboutTitle = ({mobile,title}) => {
  return (
    <>
      {/* <div style={{ width: "100%" }} ClassName="about_title">
        <img
          src="/images/about-title.png"
          style={{
            width: "100vw",
            position: "absolute",
            zIndex: "99999",
            left: 0,
            top: "15px",
            maxWidth: "100%",
          }}
        />
      </div> */}

      <div className="about_title_main">
        <h6 className="text-center about_title_text">
          {title}
        </h6>
      </div>
    </>
  );
};

export default AboutTitle;
