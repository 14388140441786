import React from 'react'
import "./GoPremium.css"
const GoPremium = ({color}) => {
  return (
    <div className='go_premium_main'>
        <button className={`${color === "red" ? "bg_red":""}`}>GO PREMIUM</button>
    </div>
  )
}

export default GoPremium