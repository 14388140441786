import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { ThemeState } from "../../Context";
import Games from "../../Services/Games";
import { getTab } from "../../Utils/getStats";
import { updateSport, useTab } from "../../Redux/Accessors/game.accessors";
import StatsBox from "../../Common/StatsBox";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { errorNotify } from "../../Utils/toast";
import CompareLoader from "./CompareLoader";
import { useNavigate } from "react-router-dom";
import { playerNavigator } from "../../Utils/navigator";

const StatsCol = ({ playerId, length = 12, removeIdFromSport }) => {
  const { mode } = ThemeState();
  const tab = useTab();
  const navigate = useNavigate();

  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPlayersDataToCompare = async () => {
    try {
      setIsLoading(true);
      const res = await Games.getDataToCompare(playerId, getTab(tab));
      if (res?.data?.data?.length) {
        setStats(res?.data?.data[0]);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlayersDataToCompare();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const onRemove = (sport) => {
    removeIdFromSport(sport, playerId);
    errorNotify(`Player removed from ${sport} successfully`);
  };

  return (
    <Col
      md={
        length === 1
          ? 12
          : length === 2
          ? 6
          : length === 3
          ? 4
          : length === 4
          ? 3
          : 12
      }
    >
      <div
        className={`compare_table_header bg_grey ${
          mode === "Light" && "light_theme"
        }`}
      >
        <Row>
          <Col className="header_left_section" style={{ position: "relative" }}>
            <h4
              className={`text-center ${
                mode === "Light" ? "col-green" : "col-yellow"
              }`}
              style={{ cursor: "pointer" }}
            >
              <span
                onClick={() =>
                  playerNavigator(
                    stats?.playerWithStats?.player,
                    navigate,
                    updateSport
                  )
                }
              >
                {stats?.playerWithStats?.player?.firstName
                  ? stats?.playerWithStats?.player?.firstName +
                    " " +
                    stats?.playerWithStats?.player?.lastName
                  : "Player Name"}{" "}
              </span>
              <br />
              <p>
                {stats?.playerWithStats?.player?.firstName &&
                  stats?.playerWithStats?.player?.position}
              </p>
              {
                <AiOutlineMinusCircle
                  className="compare_minus_icon"
                  style={{ position: "absolute", cursor: "pointer" }}
                  onClick={() =>
                    onRemove(stats?.playerWithStats?.player?.sport)
                  }
                />
              }
            </h4>
          </Col>
        </Row>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Col className="body_right_section m-0 p-0">
          <Row>
            <div className="compare-box text-center">
              {!isLoading ? (
                <StatsBox playerStats={stats?.playerWithStats?.fanduel} />
              ) : (
                <CompareLoader />
              )}
            </div>
          </Row>
        </Col>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Col className="body_right_section m-0 p-0">
          <Row>
            <div className="compare-box text-center">
              {!isLoading ? (
                <div>
                  <StatsBox playerStats={stats?.playerWithStats?.draftkings} />
                </div>
              ) : (
                <CompareLoader />
              )}
            </div>
          </Row>
        </Col>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Col className="body_right_section m-0 p-0">
          <Row>
            <div className="compare-box text-center">
              {!isLoading ? (
                <StatsBox playerStats={stats?.playerWithStats?.betmgm} />
              ) : (
                <CompareLoader />
              )}
            </div>
          </Row>
        </Col>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Col className="body_right_section m-0 p-0">
          <Row>
            <div className="compare-box text-center">
              {!isLoading ? (
                <StatsBox playerStats={stats?.playerWithStats?.caesars} />
              ) : (
                <CompareLoader />
              )}
            </div>
          </Row>
        </Col>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Col className="body_right_section m-0 p-0">
          <Row>
            <div className="compare-box text-center">
              {!isLoading ? (
                <StatsBox playerStats={stats?.playerWithStats?.consensus} />
              ) : (
                <CompareLoader />
              )}
            </div>
          </Row>
        </Col>
      </div>
      <div
        className={`compare_table_body ${mode === "Light" && "light_theme"}`}
      >
        <Col className="body_right_section m-0 p-0">
          <Row>
            <div className="compare-box text-center">
              {!isLoading ? (
                <StatsBox playerStats={stats?.playerWithStats?.pointsbet} />
              ) : (
                <CompareLoader />
              )}
            </div>
          </Row>
        </Col>
      </div>
    </Col>
  );
};

export default StatsCol;
