import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { META_TAGS, PAGE_HEADER_TABS } from "../../Constants";
import { useHeaderTab } from "../../Redux/Accessors/header.accessors";

export default function PageWrapper({
  children,
  enableScroll = false,
  setEnableScroll,
  id,
  tabTitle = "",
}) {
  const ref = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const [lastPathname, setLastPathname] = useState(null);
  const { pathname, hash } = useLocation();
  const [lastId, setLastId] = useState(null);
  const headerTab = useHeaderTab();

  function ScrollToTop() {
    useEffect(() => {
      if (!hasMounted) {
        setHasMounted(true);
        return; // Skip the initial scroll on the first render
      }

      if (lastPathname !== pathname || lastId !== id || enableScroll) {
        window.scrollTo(0, 0);
        ref.current.scrollTo(0, 0);
        setLastPathname(pathname);
        setLastId(id);
        if (typeof setEnableScroll === "function") {
          setEnableScroll(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, hash, hasMounted, lastPathname, id]);

    return null;
  }

  let newTabTitle;

  if (tabTitle) {
    // console.log(tabTitle);
    const string = tabTitle.replace(/[^a-zA-Z0-9\s]/g, ``);
    const words = string.split(" ");
    const title = words.slice(0, 3).join("_").toUpperCase();
    newTabTitle = title;
  }

  return (
    <div ref={ref}>
      <ScrollToTop />
      <Helmet>
        <meta charSet="utf-8" />
        <title data-rh="true">
          {headerTab === PAGE_HEADER_TABS.HOME
            ? `${META_TAGS[headerTab]?.title}`
            : pathname === "/advertise"
            ? META_TAGS["ADVERTISE"].title
            : tabTitle && headerTab === PAGE_HEADER_TABS.PLAYER
            ? `${tabTitle} - ${META_TAGS[headerTab]?.title}`
            : tabTitle && headerTab === PAGE_HEADER_TABS.TEAM
            ? `${
                META_TAGS[newTabTitle]?.title
                  ? META_TAGS[newTabTitle]?.title
                  : "Player Totals"
              }`
            : META_TAGS[headerTab]?.title || "Player Totals"}{" "}
        </title>
        <meta
          data-rh="true"
          name="description"
          content={
            pathname === "/adertise"
              ? META_TAGS["ADVERTISE"].description
              : tabTitle && headerTab === PAGE_HEADER_TABS.TEAM
              ? META_TAGS[newTabTitle]?.description
              : META_TAGS[headerTab]?.description
          }
        ></meta>
        <meta
          data-rh="true"
          property="og:description"
          content="Discover Winning Prop Bets for NBA, NHL, MLB, and NFL! Get an Edge with Our Expertly Simulated Free Prop Picks. Bet Smarter Today!"
        ></meta>
        <meta property="og:site_name" content="Player Totals"></meta>
      </Helmet>
      {children}
    </div>
  );
}
