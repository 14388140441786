import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTab } from "../../../Redux/Accessors/game.accessors";
import StatsBox from "../../../Common/StatsBox";
import Games from "../../../Services/Games";
import { getTab } from "../../../Utils/getStats";
import Alert from "../../../Common/Alert";
import Info from "../../../Common/Info";
import { usePlayerGames } from "../../../Redux/Accessors/previousGames.accessors";

const ScheduleStats = ({ id, playerDetail, schedule, player, team }) => {
  const tab = useTab();
  const games = usePlayerGames();

  const [playerStats, setPlayerStats] = useState(null);
  const gameStats = playerStats?.find(({ player }) => player?._id === id);
  const playerInfo = games?.data?.playerDetails;

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.playerStats(data);
      setPlayerStats(res?.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTeamPlayerStats({
        gameId: schedule?._id,
        teamId: schedule[team]?._id,
        bettingBetType: getTab(tab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  return (
    <Row>
      <Col
        md={3}
        className="d-flex flex-column justify-content-center ps-4 gap-2 body_left_section"
      >
        <h3>
          {playerInfo?.firstName} {playerInfo?.lastName}
        </h3>
        <div className="d-flex gap-3 align-items-center">
          <Alert playerId={playerInfo?._id} />
          <Info />
        </div>
      </Col>
      <Col md={9} className="body_right_section">
        <Row className="align-items-center assets_images">
          <Col md={2}>
            <StatsBox playerStats={gameStats?.fanduel} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.draftkings} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.betmgm} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.caesars} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.consensus} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.pointsbet} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ScheduleStats;
