import { createSlice } from "@reduxjs/toolkit";

const gameSlice = createSlice({
  name: "game",
  initialState: {
    scheduleGames: [],
    tab: "Points",
    sport: process.env.REACT_APP_DEFAULT_SPORT || "NFL",
    mlbTab: "Batting",
    slide: 0,
    currentDate: "",
    premiumTab: 5,
    settings: null,
  },
  reducers: {
    updateSchedule: (state, action) => {
      state.scheduleGames = action.payload;
    },
    updateSport: (state, action) => {
      state.sport = action.payload;
    },
    updateTab: (state, action) => {
      state.tab = action.payload;
    },
    updatePremiumTab: (state, action) => {
      state.premiumTab = action.payload;
    },
    updateMLBTab: (state, action) => {
      state.mlbTab = action.payload;
    },
    updateSlide: (state, action) => {
      state.slide = action.payload;
    },
    updateDate: (state, action) => {
      state.currentDate = action.payload;
    },
    updateSettings: (state, action) => {
      state.settings = action.payload;
    },
  },
});

export default gameSlice;
