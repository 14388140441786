import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  updateSport,
  updateTab,
  useSport,
  useTab,
} from "../../../Redux/Accessors/game.accessors";
import Games from "../../../Services/Games";
import {
  updatePlayerMatches,
  usePlayerGames,
} from "../../../Redux/Accessors/previousGames.accessors";
import MatchesHeader from "../MatchesHeader";
import {
  MLB_BATTING_TABS,
  MLB_PITCHING_TABS,
  NBA_TABS,
  NFL_TABS,
  SPORTS,
} from "../../../Constants";
import SearchPlayerAccordian from "./SearchPlayerAccordian";
import Loader from "../../../Common/Loader";
import MatchesPremiumCard from "../MatchesPremium";
import PageNumbers from "../../../Common/Pagination";
import PlayerPremium from "../PlayerPremium";
// import { ThemeState } from "../../../Context";
// const { mode } = ThemeState();

const SearchedPlayerData = ({ playerData }) => {
  // console.log(playerData,"playerData")
  const [id, setId] = useState("");
  const playerGames = usePlayerGames();
  const [games, setGames] = useState(playerGames || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlayerDataLoading, setIsPlayerDataLoading] = useState(false);

  const [tabs, setTabs] = useState();
  const [selectedTabMLB, setSelectedTabMLB] = useState("Batting");
  const [show, setShow] = useState("Matches");
  const [page, setPage] = useState(1);
  const [enableScroll, setEnableScroll] = useState(false);

  console.log("playerGames", playerGames);
  const playerDetails = playerGames?.data?.playerDetails;

  useEffect(() => {
    fetchPlayerBySlug();
    fetchPlayerPreviousGames();
  }, [playerData]);
  useEffect(() => {
    updateGameTab(playerData?.sport);
    if (playerData?.sport === SPORTS.NBA) {
      setTabs(NBA_TABS);
      return;
    }
    if (playerData?.sport === SPORTS.MLB) {
      if (selectedTabMLB === "Pitching") {
        // setSelectedTabMLB("Pitching");
        // updateMLBTab("Pitching");
        setTabs(MLB_PITCHING_TABS);
        return;
      } else {
        // setSelectedTabMLB("Batting");
        // updateMLBTab("Batting");
        setTabs(MLB_BATTING_TABS);
        return;
      }
    }
    if (playerData?.sport === SPORTS.NFL) {
      setTabs(NFL_TABS);
      return;
    }
  }, [playerData, selectedTabMLB]);

  const fetchPlayerBySlug = async () => {
    try {
      const res = await Games.getPlayerBySlug(playerData?.slug);
      setId(res?.data?.data?._id);
      updateSport(res?.data?.data?.sport);
    } catch (error) {
      console.error("An error occurred while fetching player details:", error);
    }
  };
  const fetchPlayerPreviousGames = async (skip = 0) => {
    try {
      setIsLoading(true);
      const res = await Games.playerGames(
        playerData?.slug,
        skip,
        playerData?.sport
      );
      updatePlayerMatches(res?.data);
      setGames(res?.data);
      setIsLoading(false);
      setShow("Matches");
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching player games:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const selectedTab = useTab();
  // console.log(pathname);
  // console.log(week,"week")
  // console.log(selectedTab);
  const updateGameTab = (sport) => {
    switch (sport) {
      case "NBA":
        updateTab("Points");
        break;
      case "MLB":
        if (selectedTabMLB === "Batting") {
          updateTab("Home Runs");
        } else {
          updateTab("Strikeouts");
        }
        break;
      case "NFL":
        updateTab("Anytime TD");
        break;
      case "NHL":
        updateTab("Points");
        break;
      default:
        updateTab("Home Runs");
    }
  };
  const schedule = games?.data?.scheduledGame || {};
  const hasData = Object.keys(schedule).length > 0;

  const team = schedule?.homeTeamPlayers?.find((player) => player?._id === id)
    ? "homeTeam"
    : "awayTeam";
  const player = schedule?.homeTeamPlayers?.find((player) => player?._id === id)
    ? "homeTeamPlayers"
    : "awayTeamPlayers";
  const playerDetail = (schedule[player] || [])?.find(
    (player) => player?._id === id
  );
  const limit = 5;

  const handleSetPage = (page) => {
    setEnableScroll(true);

    const skip = page - 1;
    setPage(page);
    fetchPlayerPreviousGames(skip * limit);
  };

  const handlePrev = () => {
    if (page !== 1) {
      setEnableScroll(true);

      const newPage = page - 1;
      const skip = newPage - 1;

      setPage(newPage);
      fetchPlayerPreviousGames(skip * limit);
    }
  };

  const handleNext = () => {
    if (page !== Math.ceil(games?.totalCount / limit)) {
      setEnableScroll(true);

      const newPage = page + 1;
      const skip = newPage - 1;
      setPage(newPage);
      fetchPlayerPreviousGames(skip * limit);
    }
  };
  console.log(schedule, "schedule");
  return (
    <div className="mb-4">
      {hasData && (
        <div className="data_update_header_container d-flex justify-content-end w-full">
          <div>
            <button
              className={`${show === "Matches" && "active"}`}
              onClick={() => setShow("Matches")}
            >
              Matches
            </button>
            <button
              className={`${show === "Premium" && "active"}`}
              onClick={() => setShow("Premium")}
            >
              Premium
            </button>
          </div>
        </div>
      )}

      {show === "Matches" ? (
        <>
          {playerData?.sport === "MLB" && (
            <div className="d-flex mt-4 batting_and_pitching">
              <button
                className={selectedTabMLB === "Batting" && "active"}
                onClick={() => setSelectedTabMLB("Batting")}
              >
                Batting
              </button>
              <button
                className={selectedTabMLB === "Pitching" && "active"}
                onClick={() => setSelectedTabMLB("Pitching")}
              >
                Pitching
              </button>
            </div>
          )}
          <MatchesHeader tabs={tabs} selectedTab={selectedTab} />
          {isPlayerDataLoading || isLoading ? (
            <Loader />
          ) : (
            <>

              <SearchPlayerAccordian
                games={playerGames}
                id={id}
                tab={selectedTab}
                playerDetails={playerDetails}
                fetchPlayerPreviousGames={fetchPlayerPreviousGames}
                loading={isLoading}
                isPlayerDataLoading={isPlayerDataLoading}
                setIsPlayerDataLoading={setIsPlayerDataLoading}
                setPage={setPage}
                schedule={schedule}
              />
            </>
          )}

          {!!playerGames?.totalCount > 0 && (
            <div className={`mt-5 players_pagination `}>
              <PageNumbers
                page={page}
                setPage={setPage}
                handleSetPage={(pageNum) => handleSetPage(pageNum)}
                handlePrev={handlePrev}
                handleNext={handleNext}
                totalRecords={playerGames?.totalCount}
                postsPerPage={5}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <PlayerPremium
            name={selectedTab}
            viewMore
            onlyList
            // setTeamData={setHomeTeamData}
            schedule={schedule}
            teamData={playerDetails}
            tab="playerPremium"

          />
        </>
      )}
    </div>
  );
};

export default SearchedPlayerData;
