/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ThemeState } from "../../Context";
import { errorNotify, successNotify } from "../../Utils/toast";
import { updateFavourite, useUser } from "../../Redux/Accessors/auth.accessors";
import User from "../../Services/User";

const Alert = ({ playerId, page = "general", status = "", keyI = 1 }) => {
  const { mode } = ThemeState();
  const { user, favourites } = useUser();

  const favouritePlayer = (favourites || [])?.find(
    (favourite) => favourite?.playerId === playerId
  );

  const [added, setAdded] = useState(favouritePlayer ? true : false);

  const showToastify = () => {
    if (!user) {
      return errorNotify("Log in to add or remove notifications");
    }
    if (!added) {
      addNotification();
    } else {
      removeNotification();
    }
  };

  const addNotification = async () => {
    try {
      const res = await User.addFavourite({
        playerId,
      });
      console.log("res-addRemoveNotification", res);
      getFavouritePlayer();
      setAdded(true);
      successNotify("Player Notification Added!");
    } catch (error) {
      console.log("Error in adding favourite player", error);
    }
  };

  const removeNotification = async () => {
    try {
      const res = await User.removeFavourite(playerId);
      console.log("res-addRemoveNotification", res);
      getFavouritePlayer();
      setAdded(false);
      errorNotify("Player Notification Removed!");
    } catch (error) {
      console.log("Error in removing favourite player", error);
    }
  };

  const getFavouritePlayer = async () => {
    try {
      const res = await User.getFavouritePlayer();
      updateFavourite(res?.data?.data);
    } catch (error) {
      console.log("Error in getting favourite player", error);
    }
  };

  return (
    <></>
    // <div style={{ cursor: "pointer" }}>
    //   {page !== "player" && keyI === 0 ? (
    //     <>
    //       {mode === "Light" ? (
    //         <img
    //           src={`${
    //             added ? "/images/black-filled.png" : "/images/green-star.png"
    //           }`}
    //           onClick={showToastify}
    //           alt="star"
    //           style={
    //             status === "notify"
    //               ? {
    //                   width: "12px",
    //                   height: "12px",
    //                 }
    //               : {}
    //           }
    //         />
    //       ) : (
    //         <img
    //           src={`${
    //             added ? "/images/black-filled.png" : "/images/yellow-star.png"
    //           }`}
    //           onClick={showToastify}
    //           alt="star"
    //           style={
    //             status === "notify"
    //               ? {
    //                   width: "12px",
    //                   height: "12px",
    //                 }
    //               : {}
    //           }
    //         />
    //       )}
    //     </>
    //   ) : page !== "player" && keyI !== 0 ? (
    //     <>
    //       {mode === "Light" ? (
    //         <img
    //           src={`${
    //             added ? "/images/yellow-filled.png" : "/images/green-star.png"
    //           }`}
    //           onClick={showToastify}
    //           alt="star"
    //           style={
    //             status === "notify"
    //               ? {
    //                   width: "12px",
    //                   height: "12px",
    //                 }
    //               : {}
    //           }
    //         />
    //       ) : (
    //         <img
    //           src={`${
    //             added ? "/images/yellow-filled.png" : "/images/yellow-star.png"
    //           }`}
    //           onClick={showToastify}
    //           alt="star"
    //           style={
    //             status === "notify"
    //               ? {
    //                   width: "12px",
    //                   height: "12px",
    //                 }
    //               : {}
    //           }
    //         />
    //       )}
    //     </>
    //   ) : status === "notify" ? (
    //     <>
    //       {mode === "Light" ? (
    //         <img
    //           src={`${
    //             added ? "/images/yellow-filled.png" : "/images/yellow-star.png"
    //           }`}
    //           alt="star"
    //           onClick={showToastify}
    //           style={{
    //             width: "12px",
    //             height: "12px",
    //           }}
    //         />
    //       ) : (
    //         <img
    //           src={`${
    //             added ? "/images/black-filled.png" : "/images/black-star.png"
    //           }`}
    //           alt="star"
    //           onClick={showToastify}
    //           style={{
    //             width: "12px",
    //             height: "12px",
    //           }}
    //         />
    //       )}
    //     </>
    //   ) : (
    //     <>
    //       {mode === "Light" ? (
    //         <img
    //           src={`${
    //             added ? "/images/yellow-filled.png" : "/images/yellow-star.png"
    //           }`}
    //           alt="star"
    //           onClick={showToastify}
    //         />
    //       ) : (
    //         <img
    //           src={`${
    //             added ? "/images/black-filled.png" : "/images/black-star.png"
    //           }`}
    //           alt="star"
    //           onClick={showToastify}
    //         />
    //       )}
    //     </>
    //   )}
    // </div>
  );
};

export default Alert;
