import React from "react";

const Dashboard = () => {
  return (
    <>
      <h1>Dashboard</h1>
      <div className="layout_page_section"></div>
    </>
  );
};

export default Dashboard;
