import { createSlice } from "@reduxjs/toolkit";

const headerSlice = createSlice({
  name: "header",
  initialState: {
    headerTab: 'HOME',
  },
  reducers: {
    updateHeaderTab: (state, action) => {
      state.headerTab = action.payload;
    },
  },
});

export default headerSlice;
