import React from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../../Common/Alert";
import Info from "../../../Common/Info";
import Loader from "../../../Common/Loader";
import StatsBox from "../../../Common/StatsBox";
import ViewMore from "../../../Common/ViewMore";
import { NO_CONTENT_MESSAGE } from "../../../Constants";
import { ThemeState } from "../../../Context";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { getDataValues } from "../../../Utils/getStats";
import { playerNavigator, teamNavigator } from "../../../Utils/navigator";

const MobileMatchStats = ({
  isLoading,
  viewMore,
  setViewMore,
  team,
  teamData,
}) => {
  const selectedTab = useTab();
  const { mode } = ThemeState();
  const navigate = useNavigate();

  // const handleNavigate = (id) => {
  //   navigate(`/player/${id}`);
  // };

  // const handleTeamNavigate = (id) => {
  //   navigate(`/team/${id}`);
  // };

  const filteredTeamData =
    teamData?.filter((game) => !getDataValues(game, selectedTab)) || [];

  return (
    <div className="ps-3 py-2">
      <div
        className={`mobile_match_stats_main ${
          mode === "Light" && "light_theme"
        }`}
      >
        <div className="mobile_match_stats_header">
          <div>
            <img
              src={team?.wikipediaLogoUrl}
              style={{ width: "35px", height: "35px" }}
              alt="logo"
            />
            <h3
              onClick={() => teamNavigator(team, navigate, updateSport)}
              className={`${
                mode === "Light" ? "text-white" : "text-white"
              } ps-3 cursor-pointer team_name`}
            >
              {team?.city} {team?.name}
            </h3>
          </div>
        </div>
        <div style={{ overflow: "auto", margin: "5px 0px 0px 0px" }}>
          <div className="d-flex text-white mobile_assists_header">
            <div>
              <h3
                className={`${mode === "Light" ? "col-green" : "col-yellow"}`}
              >
                {selectedTab}
              </h3>
            </div>
            <div>
              <img src="/images/fanduel.png" alt="fanduel sportsbook" />
            </div>
            <div>
              {mode === "Light" ? (
                <img src="/images/draft-light.png" alt="site-logo" />
              ) : (
                <img src="/images/draft.png" alt="draftkings sportsbook" />
              )}
            </div>
            <div>
              <img src="/images/betmgm.png" alt="betmgm sportsbook" />
            </div>
            <div>
              {mode === "Light" ? (
                <img
                  src="/images/caesars-light.png"
                  style={{ height: "25px" }}
                  alt="site-logo"
                />
              ) : (
                <img
                  src="/images/caesars.png"
                  style={{ height: "25px" }}
                  alt="caesars sportbook"
                />
              )}
            </div>
            <div>
              <img
                src="/images/bet365_img.png"
                style={{ height: "35px" }}
                alt="bet365 bonus code"
              />
            </div>
            <div>
              {mode === "Light" ? (
                <img
                  src="/images/pointsbet-light.png"
                  style={{ height: "55px" }}
                  alt="site-logo"
                />
              ) : (
                <img
                  src="/images/pointsbet.png"
                  style={{ height: "55px" }}
                  alt="pointsbet sportsbook"
                />
              )}
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : !!filteredTeamData?.length ? (
            filteredTeamData
              ?.slice(0, viewMore ? filteredTeamData?.length : 3)
              .map((game, index) => {
                const allStatsNull = getDataValues(game, selectedTab);

                if (allStatsNull) {
                  return null;
                }

                return (
                  <div key={index} className="mobile_assists_body">
                    <div>
                      <h3
                        className="text-white"
                        onClick={() =>
                          playerNavigator(game?.player, navigate, updateSport)
                        }
                      >
                        {game?.player?.firstName} {game?.player?.lastName}
                      </h3>
                      <div className="d-flex gap-3 align-items-center">
                        <Alert playerId={game?.player?._id} />
                        <Info />
                      </div>
                    </div>
                    <div>
                      <StatsBox playerStats={game?.fanduel} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.draftkings} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.betmgm} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.caesars} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.consensus} />
                    </div>
                    <div>
                      <StatsBox playerStats={game?.pointsbet} />
                    </div>
                  </div>
                );
              })
          ) : (
            <div
              className={`text-center p-2 ${
                mode === "Light" ? "text-dark" : "text-white"
              }`}
            >
              {NO_CONTENT_MESSAGE}
            </div>
          )}
        </div>
      </div>
      <div
        className={`d-flex justify-content-center mt-4 mb-2 view_more_btn ${
          mode === "Light" && "light_theme"
        }`}
      >
        <ViewMore viewMore={viewMore} setViewMore={setViewMore} />
      </div>
    </div>
  );
};

export default MobileMatchStats;
