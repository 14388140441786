const API_URL = process.env.REACT_APP_API_URL;
// "http://192.168.50.25:8080";

export const userRoute = {
  login: `${API_URL}/api/user/login`,
  signup: `${API_URL}/api/user/signup`,
  verifyOtp: `${API_URL}/api/user/verify-otp`,
  update: `${API_URL}/api/user/`,
  favourite: `${API_URL}/api/user/favourite`,
  notification: `${API_URL}/api/notification`,
  playerNotification: `${API_URL}/api/notification/player`,
  teamNotification: `${API_URL}/api/notification/team`,
  deleteNotification: `${API_URL}/api/notification`,
  updatePassword: `${API_URL}/api/user/reset-password`,
  sendOtp: `${API_URL}/api/user/send-otp`,
  forgetPassword: `${API_URL}/api/user/forget-password`,
  socialLogin: `${API_URL}/api/user/social-login`,
};

export const notificationRoute = {
  notification: `${API_URL}/api/notification`,
  playerNotification: `${API_URL}/api/notification/player`,
  teamNotification: `${API_URL}/api/notification/team`,
  gameNotificatios: `${API_URL}/api/notification/game`,
  deleteNotification: `${API_URL}/api/notification`,
  getAllNotifications: `${API_URL}/api/notification/all`,
};

export const gameRoute = {
  settings: `${API_URL}/api/settings`,
  schedulesGames: `${API_URL}/api/game/scheduled`,
  playerStats: `${API_URL}/api/player/stats`,
  statsByPlayer: `${API_URL}/api/player/stats-by-player`,
  playerPremiumStats: `${API_URL}/api/premium`,
};

export const playerRoute = {
  playerDetails: `${API_URL}/api/player/player-by-slug`,
  stats: `${API_URL}/api/player/previous-games`,
  search: `${API_URL}/api/player/search`,
};

export const teamRoute = {
  teamDetails: `${API_URL}/api/team/team-by-slug`,
  stats: `${API_URL}/api/team/previous-games`,
  teams: `${API_URL}/api/team`,
  team: `${API_URL}/api/team/team-by-id`,
};

export const compareRoute = {
  addPlayerForCompare: `${API_URL}/api/compare`,
  getDataToCompare: `${API_URL}/api/compare`,
};

export const sendMailToUs = {
  sendMail: `${API_URL}/api/contact-us`,
};
export const promosRoute = {
  getAllPromos: `${API_URL}/api/promo/get`,
  createPromos: `${API_URL}/api/promo/create`,
  updatePromos: `${API_URL}/api/promo/update`,
  deletePromo: `${API_URL}/api/promo/delete`,
};
export const aboutRoute = {
  getAboutUs: `${API_URL}/api/page/get`,
  createAbout: `${API_URL}/api/page/create`,
  updateAbout: `${API_URL}/api/page/update`,
  deleteAbout: `${API_URL}/api/page/delete/section`,
};
export const adminRoute={
  getAllUsers:`${API_URL}/api/admin/user/user/list`,
  getAllSubAdmins:`${API_URL}/api/admin/user/sub-admin/list`,
  deleteUser:`${API_URL}/api/admin/user`,
  getUser:`${API_URL}/api/admin/user`,
  adminCron:`${API_URL}/api/admin/cron/run`,
  updatePremium:`${API_URL}/api/player-stats/updatePremium`,
  updatePlayerPremium:`${API_URL}/api/player-stats/updatePlayerPremium`,
  updateTeamPremium:`${API_URL}/api/player-stats/updateTeamPremium`

}
export const subAdminRoute={
  getSubAdmins: `${API_URL}/api/admin/user`,
  createSubAdmin: `${API_URL}/api/admin/user`,
}
export const dataUpdateRoute = {
  updatePlayerStats: `${API_URL}/api/player-stats/update`,
};
