/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Loader from "../../Common/Loader";
import {
  updateSport,
  useSettings,
  useSport,
  useTab,
} from "../../Redux/Accessors/game.accessors";
import Games from "../../Services/Games";
import { getTab } from "../../Utils/getStats";
import Notification from "../../Services/Notification";
import MobileMatchSlider from "../../Components/Home/MatchSlider/MobileMatchSlider";
import MobilePointsTableHeader from "../../Components/Home/PointsTableHeader/MobilePointsTableHeader";
import Notifications from "../../Components/Layout/Notification/Notifications";
import MobilePremiumCard from "../../Components/Home/PremiumCard/MobilePremiumCard";

const PremiumMatchesMobile = ({
  slideIndex,
  schedule,
  isLoading,
  isPremiumPage,
}) => {
  const tab = useTab();
  const { defaultSport } = useSettings() || process.env.REACT_APP_DEFAULT_SPORT;
  const sport = useSport() || defaultSport;

  const [homeTeamData, setHomeTeamData] = useState(null);
  const [awayTeamData, setAwayTeamData] = useState(null);

  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [isPlayerDataLoading, setIsPlayerDataLoading] = useState(false);
  const [onUpdate, setOnUpdate] = useState(false);
  const [slide, setSlide] = useState(slideIndex);

  const handleSlider = (val) => {
    setSlide(val);
    localStorage.setItem("slider", val);
  };

  const fetchTeamPlayerStats = async (key, data) => {
    setHomeTeamData(null);
    setAwayTeamData(null);
    // updateSport(schedule?.sport);
    try {
      setIsPlayerDataLoading(true);
      const res = await Games.playerStats(data);
      if (key === "HOME") {
        setHomeTeamData(res.data.data);
      } else {
        setAwayTeamData(res.data.data);
      }
    } catch (error) {
      setHomeTeamData(null);
      setAwayTeamData(null);
      setIsPlayerDataLoading(false);
      console.error("An error occurred while fetching player stats:", error);
    } finally {
      setIsPlayerDataLoading(false);
    }
  };

  useEffect(() => {
    if (schedule?._id && schedule?.homeTeam?._id && schedule?.awayTeam?._id) {
      fetchTeamPlayerStats("HOME", {
        gameId: schedule?._id,
        teamId: schedule?.homeTeam?._id,
        bettingBetType: getTab(tab),
      });

      fetchTeamPlayerStats("AWAY", {
        gameId: schedule?._id,
        teamId: schedule?.awayTeam?._id,
        bettingBetType: getTab(tab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, tab]);

  const fetchGameNotifications = async (id) => {
    try {
      setIsNotificationLoading(true);
      const res = await Notification.getGameNotifications(id, sport, 0, 50);
      setNotifications(res?.data?.data);
      setIsNotificationLoading(false);
    } catch (error) {
      console.log("Error in getting game notifications");
    } finally {
      setIsNotificationLoading(false);
    }
  };

  useEffect(() => {
    if (schedule?._id && schedule?.homeTeam?._id && schedule?.awayTeam?._id) {
      fetchGameNotifications(schedule?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, onUpdate]);
  console.log(slide);
  console.log(schedule);

  return (
    <div>
      {!isLoading ? (
        <div>
          <MobileMatchSlider
            slide={slide}
            handleSlider={handleSlider}
            scheduleGames={[schedule]}
            isPremiumPage={isPremiumPage}
          />
          {schedule ? <MobilePointsTableHeader /> : null}

          <Notifications
            page="home"
            data={notifications}
            onUpdate={onUpdate}
            setOnUpdate={setOnUpdate}
          />

          <MobilePremiumCard
            search
            showViewMore
            team={schedule?.awayTeam}
            teamData={awayTeamData}
            onlyOne={true}
            premium={schedule?.premium?.awayTeam}
          />
          <MobilePremiumCard
            search
            showViewMore
            team={schedule?.homeTeam}
            teamData={homeTeamData}
            onlyOne={true}
            premium={schedule?.premium?.homeTeam}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PremiumMatchesMobile;
