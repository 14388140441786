import { apiRequest } from "../Utils/apiRequest";
import {  subAdminRoute } from "../Utils/routes";

const SubAdmin = {
    subAdmins: () => {
        return apiRequest(subAdminRoute.getSubAdmins)
      },
    createSubAdmin: (data) => {
        return apiRequest(subAdminRoute.createSubAdmin, "POST", data);
      },

}
export default SubAdmin;
