import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import TextMessage from "../../../Common/TextMessage";
import { ThemeState } from "../../../Context";
import GameStats from "../../Players/PlayersDetails/GameStats";
import SearchPlayerAccordianItem from "./SearchPlayerAccordianItem";
import Loader from "../../../Common/Loader";
import { useState } from "react";
import { getGameTime } from "../../../Utils/dates";
// import ScheduleStats from "../../Players/PlayersDetails/ScheduleStats";
import { NO_CONTENT_MESSAGE } from "../../../Constants";

const SearchPlayerAccordian = ({
  games,
  id,
  tab,
  playerDetails,
  fetchPlayerPreviousGames,
  loading,
  isPlayerDataLoading,
  setIsPlayerDataLoading,
  setPage,
  schedule,
}) => {
  const { mode } = ThemeState();
  const [game, setGame] = useState();
  const [scheduleGame, setScheduleGame] = useState();

  const previousGames = games?.data?.previousGames;
  // console.log(previousGames, "prev");
  console.log(playerDetails, "playeerDetails");
  const hasData = Object.keys(schedule).length > 0;
  const player = schedule?.homeTeamPlayers?.find((player) => player?._id === id)
    ? "homeTeamPlayers"
    : "awayTeamPlayers";
  const teamId = schedule?.homeTeamPlayers?.find((player) => player?._id === id)
    ? schedule?.homeTeam?._id
    : schedule?.awayTeam?._id;

  return (
    <>
      <div className={`player_header_main `}>
        <div
          className="player_header"
          style={{ backgroundColor: "transparent", padding: "20px 15px" }}
        >
          <div>
            <div className="d-flex flex-column align-items-start">
              <h1 className="pb-0 text-white" style={{ fontSize: "32px" }}>
                {playerDetails?.firstName} {playerDetails?.lastName}{" "}
                {playerDetails?.position}
              </h1>
            </div>
          </div>
          {/* <div>
              <Alert page="player" playerId={player?._id} />
              <Info status="playerHeader" />
            </div> */}
        </div>
      </div>
      { hasData ?
        <div
          className={`player_stats_main mt-4 ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div
            className={`player_assets_header_2 ${
              mode === "Light" && "light_theme"
            }`}
          >
            <Row>
              <Col xs={4} md={4} className="header_left_section">
                <div className="d-flex justify-content-between align-items-center">
                  <h3
                    className={`ps-4 py-2 ${
                      mode === "Light" ? "col-green fw-bolder" : "col-yellow"
                    }`}
                  >
                    {tab}
                  </h3>
                  <h3
                    className={`pe-4 py-2 ${
                      mode === "Light" ? "text-black fw-bold" : "text-white"
                    }`}
                  >
                    Results
                  </h3>
                </div>
              </Col>
              <Col xs={8} md={8} className="header_right_section">
                <Row className="align-items-center assets_images">
                  <Col xs={2} md={2}>
                    <img src="/images/fanduel.png" alt="fanduel sportsbook" />
                  </Col>
                  <Col xs={2} md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/draft-light.png"
                        style={{ height: "34px" }}
                        alt="company-logo"
                      />
                    ) : (
                      <img
                        src="/images/draft.png"
                        style={{ height: "34px" }}
                        alt="draftkings sportsbook"
                      />
                    )}
                  </Col>
                  <Col xs={2} md={2}>
                    <img
                      src="/images/betmgm.png"
                      style={{ height: "40px" }}
                      alt="betmgm sportsbook"
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/caesars-light.png"
                        style={{ height: "20px" }}
                        alt="company-logo"
                      />
                    ) : (
                      <img
                        src="/images/caesars.png"
                        style={{ height: "20px" }}
                        alt="caesars sportbook"
                      />
                    )}
                  </Col>
                  <Col xs={2} md={2}>
                    <img
                      src="/images/bet365_img.png"
                      style={{ height: "22px" }}
                      // src="/images/bwin_img.png" style={{ height: "22px" }}
                      alt="bet365 bonus code"
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/pointsbet-light.png"
                        style={{ height: "46px" }}
                        alt="company-logo"
                      />
                    ) : (
                      <img
                        src="/images/pointsbet.png"
                        style={{ height: "46px" }}
                        alt="pointsbet sportsbook"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div
            className={`player_assets_body_2 ${
              mode === "Light" && "light_theme"
            }`}
          >
            <SearchPlayerAccordianItem
              id={id}
              game={schedule}
              player={player}
              playerDetails={playerDetails}
              teamId={teamId}
              fetchPlayerPreviousGames={fetchPlayerPreviousGames}
              loading={loading}
              isPlayerDataLoading={isPlayerDataLoading}
              setIsPlayerDataLoading={setIsPlayerDataLoading}
              games={game}
              setGames={setGame}
              setPage={setPage}
            />
          </div>
        </div>
        :
        (
          <div className="mt-3">
            <TextMessage message="No schedule match available" />

          </div>
        )
      }

      {previousGames?.length ? (
        previousGames?.map((game, index) => {
          // console.log(game?.)
          const gameplayer = game?.homeTeamPlayers?.find(
            (player) => player?._id === id
          )
            ? "homeTeamPlayers"
            : "awayTeamPlayers";
          const gameteamId = game?.homeTeamPlayers?.find(
            (player) => player?._id === id
          )
            ? game?.homeTeam?._id
            : game?.awayTeam?._id;

          return (
            <>
              <div
                key={index}
                className={`player_stats_main mt-4 ${
                  mode === "Light" && "light_theme"
                }`}
              >
                <div
                  className={`player_assets_header_2 ${
                    mode === "Light" && "light_theme"
                  }`}
                >
                  <Row>
                    <Col xs={4} md={4} className="header_left_section">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3
                          className={`ps-4 py-2 ${
                            mode === "Light"
                              ? "col-green fw-bolder"
                              : "col-yellow"
                          }`}
                        >
                          {tab}
                        </h3>
                        <h3
                          className={`pe-4 py-2 ${
                            mode === "Light"
                              ? "text-black fw-bold"
                              : "text-white"
                          }`}
                        >
                          Results
                        </h3>
                      </div>
                    </Col>
                    <Col xs={8} md={8} className="header_right_section">
                      <Row className="align-items-center assets_images">
                        <Col xs={2} md={2}>
                          <img
                            src="/images/fanduel.png"
                            alt="fanduel sportsbook"
                          />
                        </Col>
                        <Col xs={2} md={2}>
                          {mode === "Light" ? (
                            <img
                              src="/images/draft-light.png"
                              style={{ height: "34px" }}
                              alt="company-logo"
                            />
                          ) : (
                            <img
                              src="/images/draft.png"
                              style={{ height: "34px" }}
                              alt="draftkings sportsbook"
                            />
                          )}
                        </Col>
                        <Col xs={2} md={2}>
                          <img
                            src="/images/betmgm.png"
                            style={{ height: "40px" }}
                            alt="betmgm sportsbook"
                          />
                        </Col>
                        <Col xs={2} md={2}>
                          {mode === "Light" ? (
                            <img
                              src="/images/caesars-light.png"
                              style={{ height: "20px" }}
                              alt="company-logo"
                            />
                          ) : (
                            <img
                              src="/images/caesars.png"
                              style={{ height: "20px" }}
                              alt="caesars sportbook"
                            />
                          )}
                        </Col>
                        <Col xs={2} md={2}>
                          <img
                            src="/images/bet365_img.png"
                            style={{ height: "22px" }}
                            // src="/images/bwin_img.png" style={{ height: "22px" }}
                            alt="bet365 bonus code"
                          />
                        </Col>
                        <Col xs={2} md={2}>
                          {mode === "Light" ? (
                            <img
                              src="/images/pointsbet-light.png"
                              style={{ height: "46px" }}
                              alt="company-logo"
                            />
                          ) : (
                            <img
                              src="/images/pointsbet.png"
                              style={{ height: "46px" }}
                              alt="pointsbet sportsbook"
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                <div
                  className={`player_assets_body_2 ${
                    mode === "Light" && "light_theme"
                  }`}
                >
                  <SearchPlayerAccordianItem
                    id={id}
                    game={game}
                    player={gameplayer}
                    playerDetails={playerDetails}
                    teamId={gameteamId}
                    fetchPlayerPreviousGames={fetchPlayerPreviousGames}
                    loading={loading}
                    isPlayerDataLoading={isPlayerDataLoading}
                    setIsPlayerDataLoading={setIsPlayerDataLoading}
                    games={game}
                    setGames={setGame}
                    setPage={setPage}
                  />
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className="mt-5">
          <TextMessage message={"No Previous Game"} />
        </div>
      )}
    </>
  );
};

export default SearchPlayerAccordian;
