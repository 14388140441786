import React from "react";
import { ThemeState } from "../../../Context";
import Info from "../../../Common/Info";
import Alert from "../../../Common/Alert";
import { useNavigate } from "react-router-dom";
import { teamNavigator } from "../../../Utils/navigator";
import { updateSport } from "../../../Redux/Accessors/game.accessors";

const PlayerHeader = ({ player, team, isLoading = false }) => {
  const { mode } = ThemeState();
  const navigate = useNavigate();

  return (
    <>
      {!isLoading && (
        <div
          className={`player_header_main ${mode === "Light" && "light_theme"}`}
        >
          <div className="player_header">
            <div>
              <img
                src={team?.wikipediaLogoUrl}
                alt="logo"
                style={{ cursor: "pointer" }}
                onClick={() => teamNavigator(team, navigate, updateSport)}
              />
              <div className="d-flex flex-column align-items-start">
                <h1>
                  {player?.firstName} {player?.lastName} {player?.position}
                </h1>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => teamNavigator(team, navigate, updateSport)}
                >
                  {team?.city} {team?.name}
                </p>
              </div>
            </div>
            <div>
              <Alert page="player" playerId={player?._id} />
              <Info status="playerHeader" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlayerHeader;
