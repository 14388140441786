import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import StatsBox from "../../../Common/StatsBox";
import Games from "../../../Services/Games";
import { getTab, isResultGreater } from "../../../Utils/getStats";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { useNavigate } from "react-router-dom";
import { ThemeState } from "../../../Context";
import { getMonthAndDay } from "../../../Utils/dates";
import { teamNavigator } from "../../../Utils/navigator";

const GameStats = ({ id, game, player, playerDetails }) => {
  const tab = useTab();
  const { mode } = ThemeState();
  const navigate = useNavigate();

  const [gameStats, setGameStats] = useState(null);

  const team = game?.homeTeamPlayers?.find(
    ({ _id }) => _id === id
  )
    ? "homeTeam"
    : "awayTeam";

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.statsByPlayer(data);
      setGameStats(res.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
      fetchTeamPlayerStats({
        gameId: game?._id,
        playerId: id,
        bettingBetType: getTab(tab),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  return (
    <Row>
      <Col
        md={4}
        className="d-flex align-items-center px-4 gap-2 body_left_section"
      >
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>
            <p>Date {getMonthAndDay(game?.time)}</p>
            <p
              className="cursor-status"
              onClick={() =>
                teamNavigator(
                  game[team === "homeTeam" ? "awayTeam" : "homeTeam"],
                  navigate, updateSport
                )
              }
            >
              Opp:{" "}
              <span style={{ cursor: "pointer" }}>
                {team !== "homeTeam" && "@"}{" "}
                {game[team === "homeTeam" ? "awayTeam" : "homeTeam"]?.name}
              </span>
            </p>
          </div>
          <div
            className="d-flex align-items-center"
            style={
              isResultGreater(gameStats)
                ? { color: "#5caa00" }
                : { color: "#FF003A" }
            }
          >
            <p
              className={
                mode === "Light" && isResultGreater(game) && "text-black"
              }
            >
              {gameStats?.result || 0}
            </p>
            {isResultGreater(gameStats) ? (
              <HiOutlineArrowNarrowUp />
            ) : (
              <HiOutlineArrowNarrowDown />
            )}
          </div>
        </div>
      </Col>
      <Col md={8} className="body_right_section">
        <Row className="align-items-center assets_images">
          <Col md={2}>
            <StatsBox playerStats={gameStats?.fanduel} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.draftkings} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.betmgm} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.caesars} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.consensus} />
          </Col>
          <Col md={2}>
            <StatsBox playerStats={gameStats?.pointsbet} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GameStats;
