import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSport, useTab } from "../../Redux/Accessors/game.accessors";
import { isShow, overValue, underValue } from "../../Utils/getStats";
import { ThemeState } from "../../Context";
import moment from "moment";

const StatsBox = ({ playerStats }) => {
  const tab = useTab();
  const { mode } = ThemeState();

  let sport = useSport();
  sport?.toLowerCase();

  const timestampTooltip = (
    <Tooltip
      // id="timestamp-tooltip"
      className={`tooltip_new ${mode === "Light" && "light_theme"}`}
    >
      <h5>Last updated:</h5>
      <span>{moment(playerStats?.updatedAt).format("llll")}</span>
    </Tooltip>
  );

  return (
    <>
      <OverlayTrigger placement="top" overlay={timestampTooltip}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {playerStats?.value || playerStats?.over || playerStats?.under ? (
            <div className="text-center">
              <h3>
                {!isShow(playerStats?.value, tab) &&
                  (playerStats?.value ? playerStats?.value : "N/A")}
                {!!isShow(playerStats?.value, tab) &&
                  playerStats?.value !== 1.5 &&
                  (overValue(playerStats?.over, sport, tab)
                    ? overValue(playerStats?.over, sport, tab)
                    : "N/A")}
              </h3>
              {!isShow(playerStats?.value, tab) && (
                <div className="flex d-flex align-items-center justify-content-center text-center">
                  <p className="text-center">
                    O {overValue(playerStats?.over, sport)}
                  </p>
                  {!!underValue(playerStats?.under, sport, tab) &&
                    playerStats?.value &&
                    tab !== "1 + Hits" && (
                      <p style={{ marginLeft: "5px" }}>
                        U {underValue(playerStats?.under, sport, tab)}
                      </p>
                    )}
                </div>
              )}
            </div>
          ) : (
            <div className="text-center">
              <p>N/A</p>
            </div>
          )}
        </div>
      </OverlayTrigger>
    </>
  );
};

export default StatsBox;
