import moment from "moment";
import React, { useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import PageWrapper from "../../Common/PageWrapper";
import CouponsSlider from "../../Components/Home/CouponsSlider/CouponsSlider";
import HomeBanner from "../../Components/Home/HomeBanner/HomeBanner";
import HomeMatches from "../../Components/Home/HomeMatches/HomeMatches";
import MobileMatchSlider from "../../Components/Home/MatchSlider/MobileMatchSlider";
import MobileMatchStats from "../../Components/Home/MatchStats/MobileMatchStats";
import OurMobileApp from "../../Components/Home/OurMobileApp/OurMobileApp";
import OurMobileAppResponsive from "../../Components/Home/OurMobileApp/OurMobileAppResponsive";
import MobilePointsTableHeader from "../../Components/Home/PointsTableHeader/MobilePointsTableHeader";
import MobilePremiumCard from "../../Components/Home/PremiumCard/MobilePremiumCard";
import Footer from "../../Components/Layout/Footer/Footer";
import MobileFooter from "../../Components/Layout/Footer/MobileFooter";
import Header from "../../Components/Layout/Header/Header";
import MobileHeader from "../../Components/Layout/Header/MobileHeader";
import Notifications from "../../Components/Layout/Notification/Notifications";
import useDeviceDetect from "../../Hooks/useIsDesktop";
import {
  updateFavourite,
  updateGameNotifications,
} from "../../Redux/Accessors/auth.accessors";
import {
  updateDate,
  updateSchedule,
  updateSlide,
  updateSport,
  useSettings,
  useSlide,
  useSport,
  useTab,
} from "../../Redux/Accessors/game.accessors";
import {
  updateHeaderTab,
  useHeaderTab,
} from "../../Redux/Accessors/header.accessors";
import Games from "../../Services/Games";
import Notification from "../../Services/Notification";
import User from "../../Services/User";
import { getTab } from "../../Utils/getStats";

const Home = () => {
  const settings = useSettings();
  const tab = useTab();
  const sport =
    useSport() || settings?.defaultSport || process.env.REACT_APP_DEFAULT_SPORT;
  const headerTab = useHeaderTab() || "HOME";
  const schedueleSlide = useSlide() || 0;

  // eslint-disable-next-line no-unused-vars
  const [matchData, setMatchData] = useState({});

  const { isMobile, isDesktop } = useDeviceDetect();

  const [isLoading, setIsLoading] = useState(false);
  const [homeTeamData, setHomeTeamData] = useState(null);
  const [awayTeamData, setAwayTeamData] = useState(null);
  const [scheduleGames, setScheduleGames] = useState([]);
  const [week, setWeek] = useState(null);
  const [viewMoreGameA, setViewMoreGameA] = useState(false);
  const [viewMoreGameB, setViewMoreGameB] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [isPlayerDataLoading, setIsPlayerDataLoading] = useState(false);
  const [onUpdate, setOnUpdate] = useState(false);

  const [slide, setSlide] = useState(schedueleSlide);

  const handleSlider = (val) => {
    setSlide(val);
    updateSlide(val);
  };

  const fetchSchedulesGames = async () => {
    try {
      setIsLoading(true);
      const res = await Games.schedules(sport);
      const data = res?.data?.data?.scheduledGames || [];

      if (res?.data?.data?.week) setWeek(res?.data?.data?.week);

      const startedMatch = data?.filter(
        (slot) => moment.utc(slot.dateTimeUTC).local() < moment()
      );

      updateSchedule(data);
      setScheduleGames(data);
      updateDate(moment());

      if (slide > data?.length - 1) {
        handleSlider(0);
      }

      if (slide < startedMatch?.length && startedMatch?.length < data?.length) {
        handleSlider(startedMatch.length);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching schedules:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTeamPlayerStats = async (key, data) => {
    // const matchKey = `${sport}_${key}_${tab}_${slide}`;

    // if (matchData[matchKey]) {
    //   if (key === "HOME") {
    //     setHomeTeamData(matchData[matchKey]);
    //   } else {
    //     setAwayTeamData(matchData[matchKey]);
    //   }

    //   return; // Don't make an API call if data already exists
    // }

    setHomeTeamData(null);
    setAwayTeamData(null);

    try {
      setIsPlayerDataLoading(true);
      const res = await Games.playerStats(data);
      if (key === "HOME") {
        setHomeTeamData(res.data.data);
        // setMatchData((prevState) => ({
        //   ...prevState,
        //   [matchKey]: res.data.data,
        // }));
      } else {
        setAwayTeamData(res.data.data);
        // setMatchData((prevState) => ({
        //   ...prevState,
        //   [matchKey]: res.data.data,
        // }));
      }
    } catch (error) {
      setHomeTeamData(null);
      setAwayTeamData(null);
      setIsPlayerDataLoading(false);
      console.error("An error occurred while fetching player stats:", error);
    } finally {
      setIsPlayerDataLoading(false);
    }
  };

  const fetchGameNotifications = async (id) => {
    // const matchKey = `${sport}_${slide}`;

    // if (matchData[matchKey]) {
    //   setNotifications(matchData[matchKey]);
    //   return; // Don't make an API call if data already exists
    // }

    try {
      setIsNotificationLoading(true);
      const res = await Notification.getGameNotifications(id, sport, 0, 50);
      setNotifications(res?.data?.data);
      updateGameNotifications(res?.data?.data);
      setIsNotificationLoading(false);
    } catch (error) {
      console.log("Error in getting team notifications");
    } finally {
      setIsNotificationLoading(false);
    }
  };

  const getFavouritePlayer = async () => {
    try {
      const res = await User.getFavouritePlayer();
      updateFavourite(res?.data?.data);
    } catch (error) {
      console.log("Error in getting favourite player", error);
    }
  };

  const fetchSettings = async () => {
    try {
      if (headerTab === "HOME") {
        updateSport(settings?.defaultSport);
        return;
      }
      if (
        headerTab !== "HOME" &&
        headerTab !== "NFL" &&
        headerTab !== "NBA" &&
        headerTab !== "MLB" &&
        headerTab !== "NHL"
      ) {
        updateHeaderTab("HOME");
        updateSport(settings?.defaultSport);
      }
    } catch (error) {
      console.log("Error in getting game settings", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (sport) {
      fetchSchedulesGames();
    }

    const intervalId = setInterval(fetchSchedulesGames, 10 * 60 * 1000); // Call the function every 15 minutes

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  useEffect(() => {
    if (
      scheduleGames[slide]?._id &&
      scheduleGames[slide]?.homeTeam?._id &&
      scheduleGames[slide]?.awayTeam?._id &&
      scheduleGames[slide]?.sport === sport
    ) {
      fetchTeamPlayerStats("HOME", {
        gameId: scheduleGames[slide]?._id,
        teamId: scheduleGames[slide]?.homeTeam?._id,
        bettingBetType: getTab(tab),
      });

      fetchTeamPlayerStats("AWAY", {
        gameId: scheduleGames[slide]?._id,
        teamId: scheduleGames[slide]?.awayTeam?._id,
        bettingBetType: getTab(tab),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, slide, tab, scheduleGames]);

  useEffect(() => {
    if (
      scheduleGames[slide]?._id &&
      scheduleGames[slide]?.homeTeam?._id &&
      scheduleGames[slide]?.awayTeam?._id &&
      scheduleGames[slide]?.sport === sport
    ) {
      fetchGameNotifications(scheduleGames[slide]?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport, slide, scheduleGames]);

  useEffect(() => {
    if (sport) {
      fetchSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sport]);

  useEffect(() => {
    getFavouritePlayer();
  }, []);

  return (
    <PageWrapper>
      <div>
        {isDesktop ? (
          <div className="home_desktop">
            <Header />
            <HomeBanner />
            <CouponsSlider />
            {!isLoading ? (
              <HomeMatches
                slide={slide}
                handleSlider={(val) => handleSlider(val)}
                homeTeamData={homeTeamData}
                awayTeamData={awayTeamData}
                scheduleGames={scheduleGames}
                week={week}
                isLoading={isPlayerDataLoading}
                isNotificationLoading={isNotificationLoading}
                data={notifications}
                onUpdate={onUpdate}
                setOnUpdate={setOnUpdate}
              />
            ) : (
              <Loader />
            )}
            <OurMobileApp />
            <Footer />
          </div>
        ) : null}
        {isMobile ? (
          <div className="home_mobile">
            <MobileHeader />
            <HomeBanner settings={settings} />
            <CouponsSlider />
            {!isLoading ? (
              <>
                <MobileMatchSlider
                  slide={slide}
                  handleSlider={handleSlider}
                  scheduleGames={scheduleGames}
                  week={week}
                />
                {scheduleGames?.length ? <MobilePointsTableHeader /> : null}
                {!!scheduleGames?.length && (
                  <>
                    <MobileMatchStats
                      isLoading={isPlayerDataLoading}
                      viewMore={viewMoreGameA}
                      setViewMore={setViewMoreGameA}
                      team={scheduleGames[slide]?.awayTeam}
                      teamData={awayTeamData}
                    />
                    <MobileMatchStats
                      viewMore={viewMoreGameB}
                      setViewMore={setViewMoreGameB}
                      team={scheduleGames[slide]?.homeTeam}
                      teamData={homeTeamData}
                    />
                    <Notifications
                      data={notifications}
                      onUpdate={onUpdate}
                      setOnUpdate={setOnUpdate}
                    />

                    <MobilePremiumCard
                      search
                      showViewMore
                      team={scheduleGames[slide]?.awayTeam}
                      teamData={awayTeamData}
                      onlyOne={true}
                      premium={scheduleGames[slide]?.premium?.awayTeam}
                    />
                    <MobilePremiumCard
                      search
                      showViewMore
                      team={scheduleGames[slide]?.homeTeam}
                      teamData={homeTeamData}
                      onlyOne={true}
                      premium={scheduleGames[slide]?.premium?.homeTeam}
                      list
                    />
                  </>
                )}
              </>
            ) : (
              <Loader />
            )}
            <OurMobileAppResponsive />
            <MobileFooter />
          </div>
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default Home;

// const fetchTeamPlayerStats = async (key, data) => {
//   setHomeTeamData(null);
//   setAwayTeamData(null);
//   const matchKey = `${sport}_${key}_${slide}`;
//   try {
//     setIsPlayerDataLoading(true);
//     const res = await Games.playerStats(data);
//     if (key === "HOME") {
//       setHomeTeamData(res.data.data);
//       matchData[`${sport}_HOME_${slide}`] = res.data.data
//     } else {
//       setAwayTeamData(res.data.data);
//       matchData[`${sport}_AWAY_${slide}`] = res.data.data
//     }
//   } catch (error) {
//     setHomeTeamData(null);
//     setAwayTeamData(null);
//     setIsPlayerDataLoading(false);
//     console.error("An error occurred while fetching player stats:", error);
//   } finally {
//     setIsPlayerDataLoading(false);
//   }
// };
