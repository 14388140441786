import React, { useEffect, useState } from "react";
import Notification from "../../Services/Notification";

// Function to generate RSS feed XML
const generateRssXml = (items) => {
  const title = "Player Totals";
  const url = "http://dev.playertotals.com";
  const channelDescription = "Rss feed for my playertotals.com";

  const itemXml = items
    .map(
      (item) => `
      <item>
        <title>${title}</title>
        <link>${`${url}/player/${item?.firstName}/${item?._id}`}</link>
        <description>Player Totals Description</description>
        <date>${item?.createdAt}</date>
        <author>
          <name>${item?.player?.firstName} ${item?.player?.lastName}</name>
          <link>${item?.team?.city} ${item?.team?.name}</link>
        </author>
      </item>
    `
    )
    .join("");

  return `
  <?xml version="1.0" encoding="UTF-8"?>
  <rss version="2.0">
    <channel>
      <title>${title}</title>
      <link>${url}</link>
      <description>${channelDescription}</description>
      ${itemXml}
    </channel>
  </rss>
    `;
};

const RssFeed = () => {
  const [rssXml, setRssXml] = useState([]);

  const fetchAllNotifications = async () => {
    try {
      const res = await Notification.getAllNotifications("ALL", 0, 5000);
      setRssXml(res?.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching schedules:", error);
    }
  };

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  const feedXml = generateRssXml(rssXml); // Generate XML from your data

  return (
    <div
      style={{
        background: "white",
      }}
    >
      {/* <h1>RSS Feed</h1> */}
      <pre>{feedXml}</pre>
    </div>
  );
};

export default RssFeed;
