import React from "react";
import MatchesHeader from "../../../Components/DataUpdate/MatchesHeader";
import MatchesAccordion from "../../../Components/DataUpdate/MatchesAccordian";
import { useState } from "react";
import Games from "../../../Services/Games";
import { useEffect } from "react";
import moment from "moment";
import {
  MLB_BATTING_TABS,
  MLB_PITCHING_TABS,
  NBA_TABS,
  NFL_TABS,
  SPORTS,
} from "../../../Constants";
import {
  updateDate,
  updateSchedule,
  updateTab,
  useTab,
} from "../../../Redux/Accessors/game.accessors";
import Loader from "../../../Common/Loader";

const DataUpdate = ({ game }) => {
  // const [headerName, setHeaderName] = useState("Home Runs");
  const [isLoading, setIsLoading] = useState(false);
  const [week, setWeek] = useState(null);
  const [NFLGames, setNFLGames] = useState([]);
  const [selectedTabMLB, setSelectedTabMLB] = useState("Batting");

  const [tabs, setTabs] = useState(
    game === "NBA"
      ? NBA_TABS
      : game === "MLB" && selectedTabMLB === "Batting"
      ? MLB_BATTING_TABS
      : game === "MLB" && selectedTabMLB === "Pitching"
      ? MLB_PITCHING_TABS
      : game === "NFL"
      ? NFL_TABS
      : NBA_TABS
  );
  const selectedTab = useTab();
  // console.log(pathname);
  // console.log(week,"week")
  console.log(selectedTab);
  const updateGameTab = (sport) => {
    switch (sport) {
      case "NBA":
        updateTab("Points");
        break;
      case "MLB":
        if (selectedTabMLB === "Batting") {
          updateTab("Home Runs");
        } else {
          updateTab("Strikeouts");
        }
        break;
      case "NFL":
        updateTab("Anytime TD");
        break;
      case "NHL":
        updateTab("Points");
        break;
      default:
        updateTab("Home Runs");
    }
  };
  useEffect(() => {
    updateGameTab(game);
    if (game === SPORTS.NBA) {
      setTabs(NBA_TABS);
      return;
    }
    if (game === SPORTS.MLB) {
      if (selectedTabMLB === "Pitching") {
        // setSelectedTabMLB("Pitching");
        // updateMLBTab("Pitching");
        setTabs(MLB_PITCHING_TABS);
        return;
      } else {
        // setSelectedTabMLB("Batting");
        // updateMLBTab("Batting");
        setTabs(MLB_BATTING_TABS);
        return;
      }
    }
    if (game === SPORTS.NFL) {
      setTabs(NFL_TABS);
      return;
    }
  }, [game, selectedTabMLB]);

  const fetchSchedulesGames = async () => {
    try {
      setIsLoading(true);
      const res = await Games.schedules(`${game}`);
      const data = res?.data?.data?.scheduledGames || [];
      // console.log(res?.data?.data,"daata");

      if (res?.data?.data?.week) setWeek(res?.data?.data?.week);

      // const startedMatch = data?.filter(
      //   (slot) => moment.utc(slot.dateTimeUTC).local() < moment()
      // );

      // console.log(startedMatch, "startedMatches");
      updateSchedule(data);
      setNFLGames(data);
      updateDate(moment());

      // console.log(data, "data");
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching schedules:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log(NFLGames,"NFL games")
  useEffect(() => {
    fetchSchedulesGames();
  }, [selectedTab]);

  return (
    <>
      {game === "MLB" && (
        <div className="d-flex mt-4 batting_and_pitching">
          <button
            className={selectedTabMLB === "Batting" && "active"}
            onClick={() => setSelectedTabMLB("Batting")}
          >
            Batting
          </button>
          <button
            className={selectedTabMLB === "Pitching" && "active"}
            onClick={() => setSelectedTabMLB("Pitching")}
          >
            Pitching
          </button>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MatchesHeader tabs={tabs} selectedTab={selectedTab} />
          <MatchesAccordion
            // name={headerName}
            matches={NFLGames}
            sport={`${game}`}
            loading={isLoading}
            fetchSchedulesGames={fetchSchedulesGames}
          />
        </>
        )
      }
    </>
  );
};

export default DataUpdate;
