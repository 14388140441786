import React, { useEffect, useState } from "react";
import { BsCamera } from "react-icons/bs";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import DeleteModal from "../../../Components/Modal/DeleteModal/DeleteModal";
import Admin from "../../../Services/admin";
import { FaRegEye } from "react-icons/fa";

const CreationTable = ({ subAdmins, fetchSubAdmins }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (success) {
      fetchSubAdmins();
      setSuccess(false);
    }
  }, [success]);
  const HandleDeleteModal = (user) => {
    setShow(true);
    setUserId(user?.id);
    console.log(user);
  };
  const onDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await Admin.deleteUser(userId);
      console.log(res?.data);
      setSuccess(true);
      setLoading(false);
      setUserId("");
    } catch (error) {
      console.error("An error occurred while deleting User:", error);

      setLoading(false);
    }
  };
  return (
    <>
      <div className="billing_table_header mt-4">
        <Container fluid>
          <Row>
            <Col xs={3}>
              <h6 className="text-start">Name</h6>
            </Col>
            <Col xs={9} className="text-center">
              <Row>
                <Col xs={3}>
                  <h6>Email</h6>
                </Col>
                <Col xs={3}>
                  <h6>Phone</h6>
                </Col>
                <Col xs={3}>
                  <h6>Created At</h6>
                </Col>
                <Col xs={3}>
                  <h6>Actions</h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {subAdmins &&
        subAdmins.map((subAdmin) => (
          <div className="billing_table_body">
            <Container fluid>
              <Row>
                <Col xs={3} className="d-flex align-items-center">
                  <h6 className="text-start">
                    {subAdmin?.firstName ? subAdmin?.firstName : "-"}{" "}
                    {subAdmin?.lastName ? subAdmin?.lastName : "-"}
                  </h6>
                </Col>
                <Col xs={9} className="text-center">
                  <Row>
                    <Col
                      xs={3}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <h6>{subAdmin?.email ? subAdmin?.email : "-"}</h6>
                    </Col>
                    <Col
                      xs={3}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <h6>{subAdmin?.phone ? subAdmin?.phone : "-"}</h6>
                    </Col>
                    <Col
                      xs={3}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <h6>
                        {subAdmin?.createdAt
                          ? moment(subAdmin?.createdAt).format("DD-MM-YYYYY")
                          : "-"}
                      </h6>
                    </Col>
                    <Col
                      xs={3}
                      className="d-flex align-items-center justify-content-center gap-2"
                    >
                      {/* <button
                        className="admin_view_btn"
                        // onClick={() => HandleDeleteModal(user)}
                      >
                        <FaRegEye />
                      </button> */}
                      <button
                        className="admin_delete_btn"
                        onClick={() => HandleDeleteModal(subAdmin)}
                      >
                        <img src="/images/admin-delete.png" />
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
      <DeleteModal
        show={show}
        setShow={setShow}
        loading={loading}
        onDeleteSubmit={onDeleteSubmit}
      />
    </>
  );
};

export default CreationTable;
