import React from "react";

const Subscription = () => {
  return (
    <>
      <h1>Subscription</h1>
      <div className="layout_page_section subscripton_section">
        <div className="status_box">
          <h3>Subscription Status:</h3>
          <h3>Premium</h3>
        </div>
        <div className="current_subscription">
          <div className="d-flex align-items-center gap-3">
            <p>Membership Renew On:</p>
            <h3>08/06/2024</h3>
          </div>
          <button>Cancel</button>
        </div>
      </div>
    </>
  );
};

export default Subscription;
