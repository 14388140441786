import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateSport, useTab } from "../../../Redux/Accessors/game.accessors";
import { ThemeState } from "../../../Context";
import StatsBox from "../../../Common/StatsBox";
import Games from "../../../Services/Games";
import { getTab } from "../../../Utils/getStats";
import Info from "../../../Common/Info";
import Alert from "../../../Common/Alert";
import { usePlayerGames } from "../../../Redux/Accessors/previousGames.accessors";
import { getGameTime } from "../../../Utils/dates";
import { teamNavigator } from "../../../Utils/navigator";

const ScheduleStatsMobile = ({ id, playerDetail, schedule, player }) => {
  const tab = useTab();
  const { mode } = ThemeState();
  const games = usePlayerGames();
  const navigate = useNavigate();

  const [playerStats, setPlayerStats] = useState(null);
  const gameStats = playerStats?.find(({ player }) => player?._id === id);
  const playerInfo = games?.data?.playerDetails;

  const team = ((schedule && schedule?.homeTeamPlayers) || [])?.find(
    ({ _id }) => _id === id
  )
    ? "homeTeam"
    : "awayTeam";

  const fetchTeamPlayerStats = async (data) => {
    try {
      const res = await Games.playerStats(data);
      setPlayerStats(res?.data?.data);
    } catch (error) {
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    fetchTeamPlayerStats({
      gameId: schedule?._id,
      teamId: schedule[team]?._id,
      bettingBetType: getTab(tab),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  return (
    <div
      className={`player_match_stats_main bg_grey ${
        mode === "Light" && "light_theme"
      }`}
    >
      <div style={{ overflow: "auto" }}>
        <div
          className={`d-flex text-white player_assists_header bg_grey ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div>
            <h3 className={`${mode === "Light" ? "col-green" : "col-yellow"}`}>
              {tab}
            </h3>
          </div>
          <div>
            <img src="/images/fanduel.png" alt="fanduel sportsbook" />
          </div>
          <div>
            {mode === "Light" ? (
              <img src="/images/draft-light.png" alt="company-logo" />
            ) : (
              <img src="/images/draft.png" alt="draftkings sportsbook" />
            )}
          </div>
          <div>
            <img src="/images/betmgm.png" alt="betmgm sportsbook" />
          </div>
          <div>
            {mode === "Light" ? (
              <img
                src="/images/caesars-light.png"
                style={{ height: "25px" }}
                alt="company-logo"
              />
            ) : (
              <img
                src="/images/caesars.png"
                style={{ height: "25px" }}
                alt="caesars sportbook"
              />
            )}
          </div>
          <div>
            <img
              src="/images/bet365_img.png"
              style={{ height: "30px" }}
              alt="bet365 bonus code"
            />
          </div>
          <div>
            {mode === "Light" ? (
              <img
                src="/images/pointsbet-light.png"
                style={{ height: "55px" }}
                alt="company-logo"
              />
            ) : (
              <img
                src="/images/pointsbet.png"
                style={{ height: "55px" }}
                alt="pointsbet sportsbook"
              />
            )}
          </div>
        </div>
        <div
          className={`player_mobile_assists_body bg_grey ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div>
            <h4 className="text-white">
              {playerInfo?.firstName} {playerInfo?.lastName}
            </h4>
            <div className="d-flex gap-3 align-items-center">
              <Alert playerId={playerInfo?._id} />
              <Info />
            </div>
          </div>
          <div>
            <StatsBox playerStats={gameStats?.fanduel} />
          </div>
          <div>
            <StatsBox playerStats={gameStats?.draftkings} />
          </div>
          <div>
            <StatsBox playerStats={gameStats?.betmgm} />
          </div>
          <div>
            <StatsBox playerStats={gameStats?.caesars} />
          </div>
          <div>
            <StatsBox playerStats={gameStats?.consensus} />
          </div>
          <div>
            <StatsBox playerStats={gameStats?.pointsbet} />
          </div>
        </div>
        <div
          className={`player_mobile_assists_footer ${
            mode === "Light" && "light_theme"
          }`}
        >
          <div>
            <p
              className="cursor-status"
              onClick={() =>
                teamNavigator(
                  schedule[team === "homeTeam" ? "awayTeam" : "homeTeam"],
                  navigate,
                  updateSport
                )
              }
            >
              Opp:{" "}
              <span>
                {team !== "homeTeam" && "@"}{" "}
                {schedule[team === "homeTeam" ? "awayTeam" : "homeTeam"]?.name}
              </span>
            </p>
          </div>
          <div>
            <p>
              Date: <span>{}</span>
              {getGameTime(schedule?.dateTimeUTC)}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleStatsMobile;
