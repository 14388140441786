import React from 'react'
import { ThemeState } from '../../Context';

const TextMessage = ({ message }) => {
    const { mode } = ThemeState();

  return (
    <div className={`text-center ${mode === "Light" ? "text-black" : "text-white"}`}>{message}</div>
  )
}

export default TextMessage