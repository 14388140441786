import React from "react";
import { ThemeState } from "../../../Context";
import { useState } from "react";
import Games from "../../../Services/Games";
import { useTab } from "../../../Redux/Accessors/game.accessors";
import ViewMore from "../../../Common/ViewMore";
import MatchPlayerStatsBox from "../MatchPlayerStatsBox";
import {
  getDataValues,
  getTab,
  isResultGreater,
} from "../../../Utils/getStats";
import {
  HiOutlineArrowNarrowDown,
  HiOutlineArrowNarrowUp,
} from "react-icons/hi";
import { Col, Row, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import Loader from "../../../Common/Loader";
import dataUpdates from "../../../Services/dataUpdate";
import Modal from "../../Modal/SiteModal";
import { getUser } from "../../../Redux/Accessors/auth.accessors";

const SearchTeamAccordianItem = ({
  id,
  game,
  teamId,
  fetchSchedulesGames,
  loading = false,
  setPage,
}) => {
  const tab = useTab();
  const { mode } = ThemeState();
  const [viewMore, setViewMore] = useState(false);

  const [playerStats, setPlayerStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(false);

  const [resultShow, setResultShow] = useState(false);
  const [data, setData] = useState({});
  const [resultLoading, setResultLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { user } = getUser();

  // const handleNavigate = (id) => {
  //   navigate(`/player/${id}`);
  // };

  const fetchTeamPlayerStats = async () => {
    try {
      setStatsLoading(true);
      const res = await Games.playerStats({
        gameId: game?._id,
        teamId,
        bettingBetType: getTab(tab),
      });
      setPlayerStats(res.data.data);
      setStatsLoading(false);
    } catch (error) {
      setStatsLoading(false);
      console.error("An error occurred while fetching player stats:", error);
    }
  };

  useEffect(() => {
    fetchTeamPlayerStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tab]);

  const filteredTeamData =
    playerStats?.filter((game) => !getDataValues(game, tab)) || [];

  useEffect(() => {
    if (updateSuccess) {
      onResultCloseModal();
      setData({});
      fetchTeamPlayerStats();
      setUpdateSuccess(false);
    }
  }, [updateSuccess]);
  // console.log(user,"role")
  const handleResultModalOpen = (game) => {
    if (user?.role === "admin") {
      setResultShow(true);
      setData({
        teamId: teamId,
        bettingBetType: game?.bettingBetType,
        playerStatsId: game?._id,
        gameId: game.game,
        playerId: game?.player?._id,
        result: game.result || 0,
      });
    }
  };
  const handleUpdatedData = () => (e) => {
    const inputValue = e.target.value;

    setData((prev) => {
      return {
        ...prev,
        result: inputValue,
      };
    });
  };
  const handleFormSumbit = async (e) => {
    e.preventDefault();
    try {
      setResultLoading(true);
      // console.log(data,"data")
      const res = await dataUpdates.updatePlayerStats(data);
      console.log(res, "response");
      setUpdateSuccess(true);
      setResultShow(false);
    } catch (error) {
      setResultLoading(false);
      console.error("An error occurred while updating banner:", error);
    } finally {
      setResultLoading(false);
    }
  };
  const onResultCloseModal = () => {
    setResultShow(false);
  };

  return (
    <div>
      <div
        className={`player_stats_main team_assist_table  ${
          mode === "Light" && "light_theme"
        }`}
      >
        <>
          <div className="player_assets_header ">
            <Row>
              <Col xs={4} md={4} className="header_left_section">
                <div className="d-flex justify-content-between align-items-center">
                  <h4
                    className={`ps-4 py-2  ${
                      mode === "Light" ? "col-green" : "col-yellow"
                    }`}
                  >
                    {tab}
                  </h4>
                  <h4
                    className={`pe-4 py-2 ${
                      mode === "Light" ? "text-black" : "text-white"
                    }`}
                  >
                    Results
                  </h4>
                </div>
              </Col>
              <Col xs={8} md={8} className="header_right_section">
                <Row className="align-items-center assets_images teams_section">
                  <Col xs={2} md={2}>
                    <img src="/images/fanduel.png" />
                  </Col>
                  <Col xs={2} md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/draft-light.png"
                        style={{ height: "34px" }}
                      />
                    ) : (
                      <img src="/images/draft.png" style={{ height: "34px" }} />
                    )}
                  </Col>
                  <Col xs={2} md={2}>
                    <img src="/images/betmgm.png" style={{ height: "40px" }} />
                  </Col>
                  <Col xs={2} md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/caesars-light.png"
                        style={{ height: "20px" }}
                      />
                    ) : (
                      <img
                        src="/images/caesars.png"
                        style={{ height: "20px" }}
                      />
                    )}
                  </Col>
                  <Col xs={2} md={2}>
                    <img
                      src="/images/bet365_img.png"
                      style={{ height: "22px" }}
                      // src="/images/bwin_img.png" style={{ height: "22px" }}
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    {mode === "Light" ? (
                      <img
                        src="/images/pointsbet-light.png"
                        style={{ height: "45px" }}
                      />
                    ) : (
                      <img
                        src="/images/pointsbet.png"
                        style={{ height: "45px" }}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div
            className={`player_assets_body teams_section ${
              mode === "Light" && "light_theme"
            }`}
          >
            {loading || statsLoading ? (
              <Loader />
            ) : !!filteredTeamData?.length ? (
              filteredTeamData
                ?.slice(0, viewMore ? filteredTeamData?.length : 3)
                .map((game, index) => {
                  console.log(game, "game");
                  const allStatsNull = getDataValues(game, tab);

                  if (allStatsNull) {
                    return null;
                  }

                  return (
                    <Row key={index}>
                      <Col
                        xs={4}
                        md={4}
                        className="d-flex align-items-center px-4 justify-content-between body_left_section"
                        style={{ position: "relative" }}
                      >
                        <div className="d-flex align-items-center gap-2 ">
                          <h4
                          // onClick={() =>
                          //   playerNavigator(
                          //     game?.player,
                          //     navigate,
                          //     updateSport
                          //   )
                          // }
                          >
                            {game?.player?.firstName} {game?.player?.lastName}
                          </h4>

                          {mode === "Light" ? (
                            <img src="/images/info-light.png" />
                          ) : (
                            <img src="/images/info.png" />
                          )}
                        </div>
                        <div>
                          <div
                            className="d-flex align-items-center"
                            style={
                              isResultGreater(game)
                                ? {
                                    color: "#5caa00",
                                    cursor:
                                      user?.user?.role === "admin"
                                        ? "pointer"
                                        : "default",
                                  }
                                : {
                                    color: "#FF003A",
                                    cursor:
                                      user?.user?.role === "admin"
                                        ? "pointer"
                                        : "default",
                                  }
                            }
                            onClick={() => handleResultModalOpen(game)}
                          >
                            <p
                              className={
                                mode === "Light" &&
                                isResultGreater(game) &&
                                "text-black"
                              }
                            >
                              {game?.result || 0}
                            </p>
                            {isResultGreater(game) ? (
                              <HiOutlineArrowNarrowUp />
                            ) : (
                              <HiOutlineArrowNarrowDown />
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col xs={8} md={8} className="body_right_section">
                        <Row className="align-items-center assets_images">
                          <MatchPlayerStatsBox
                            playerStats={game?.fanduel}
                            gameType="fanduel"
                            game={game}
                            fetchSchedulesGames={fetchTeamPlayerStats}
                            teamId={teamId}
                            statsLoading={statsLoading}
                            // setPage={setPage}
                          />
                          <MatchPlayerStatsBox
                            playerStats={game?.draftkings}
                            gameType="draftkings"
                            game={game}
                            fetchSchedulesGames={fetchTeamPlayerStats}
                            teamId={teamId}
                            statsLoading={statsLoading}
                            // setPage={setPage}
                          />
                          <MatchPlayerStatsBox
                            playerStats={game?.betmgm}
                            gameType="betmgm"
                            game={game}
                            fetchSchedulesGames={fetchTeamPlayerStats}
                            teamId={teamId}
                            statsLoading={statsLoading}
                            // setPage={setPage}
                          />
                          <MatchPlayerStatsBox
                            playerStats={game?.caesars}
                            gameType="caesars"
                            game={game}
                            fetchSchedulesGames={fetchTeamPlayerStats}
                            teamId={teamId}
                            statsLoading={statsLoading}
                            // setPage={setPage}
                          />
                          <MatchPlayerStatsBox
                            playerStats={game?.consensus}
                            gameType="consensus"
                            game={game}
                            fetchSchedulesGames={fetchTeamPlayerStats}
                            teamId={teamId}
                            statsLoading={statsLoading}
                            // setPage={setPage}
                          />
                          <MatchPlayerStatsBox
                            playerStats={game?.pointsbet}
                            gameType="pointsbet"
                            game={game}
                            fetchSchedulesGames={fetchTeamPlayerStats}
                            teamId={teamId}
                            statsLoading={statsLoading}
                            // setPage={setPage}
                          />
                        </Row>
                      </Col>
                    </Row>
                  );
                })
            ) : (
              <div
                className={`text-center p-2 ${
                  mode === "Light" ? "text-dark" : "text-white"
                }`}
              >
                No players to show
              </div>
            )}
          </div>
        </>
      </div>
      <div
        className={`d-flex justify-content-center pt-3 pb-4 view_more_btn ${
          mode === "Light" && "light_theme"
        }`}
      >
        <ViewMore viewMore={viewMore} setViewMore={setViewMore} />
      </div>
      <Modal
        show={resultShow}
        setShow={setResultShow}
        onCloseModal={onResultCloseModal}
      >
        <div className="edit_value_modal">
          <div style={{ width: "80%" }}>
            <form style={{ width: "100%" }} onSubmit={handleFormSumbit}>
              <label>Result</label>
              <input
                type="number"
                value={data?.result}
                style={{ width: "100%" }}
                onChange={handleUpdatedData()}
              />
              <div className="text-end">
                <button type="submit" disabled={resultLoading}>
                  {resultLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SearchTeamAccordianItem;
