import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const TEST_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const Recaptcha = React.forwardRef(({ setRecaptcha }, ref) => {
  const handleChange = (value) => {
    setRecaptcha(value);
  };
  return (
    <div
      className="captcha"
      //   style={{ transform: "scale(0.85)", transformOrigin: "0 0" }}
    >
      <ReCAPTCHA ref={ref} sitekey={TEST_SITE_KEY} onChange={handleChange} />
    </div>
  );
});

export default Recaptcha;
