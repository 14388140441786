import { createSlice } from "@reduxjs/toolkit";

const promoSlice = createSlice({
    name: "promos",
    initialState: {
      promos: {},
    },
    reducers: {
        updatePromos: (state, action) => {
            state.promos = action.payload;
          },
    }
})
export default promoSlice;
